import { showError, showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Container, Divider, Header, Icon, Popup, Segment, Select, Table} from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getFiles, upload, downloadFile } from '@/features/files/filesSlice';
import { useHistory } from 'react-router';
import { FileUploadPayload } from '@/features/files/filesTypes';
import LoadingPage from '@/pages/LoadingPage';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import useWhiteLabelLov from '@helpers/customHook/useWhiteLabelLov';

interface Props {
	writePermission: boolean,
}

const Files = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.files
	);

	const { whitelabel } = useSelector((state: RootState) => state.whitelabel);
	const [wlpId, setWlpId] = useState<string>(whitelabel);
	const { whitelabelOptions } = useWhiteLabelLov();

	const paginateInitialState: PaginateParams = { orderBy: 'uploadedDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams} = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const [fileLoading, setFileLoading] = useState<boolean>(false);
	const [provider, setProvider] = useState<string>('CAMBRIDGE');
	const headers: Array<Headers> = [
		{
			field: 'fileName',
			name: 'File Name',
			position: 1,
			sortable: true
		},
		{
			field: 'originalFileName',
			name: 'Original file name',
			position: 2,
			sortable: true
		},
		{
			field: 'provider',
			name: 'Provider',
			position: 3,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 4,
			sortable: true
		},
		{
			field: 'uploadedDate',
			name: 'Uploaded date',
			position: 5,
			sortable: true
		},
		{
			field: 'uploadedBy',
			name: 'Uploaded by',
			position: 6,
			sortable: true
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getFiles(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [dispatch, pagination]);

	useEffect(() => {
		fetchData();
	}, [dispatch, pagination, fetchData]);

	const providerOptions = [
		{
			key: 'CAMBRIDGE',
			text: 'CAMBRIDGE',
			value: 'CAMBRIDGE',
		},
		{
			key: 'DECTA',
			text: 'DECTA',
			value: 'DECTA',
		},
		{
			key: 'TOKEN',
			text: 'TOKEN',
			value: 'TOKEN',
		},
	];

	const handleProviderChange = (value) => {
		setProvider(value);
	};

	const handleWlpIdChange = (value) => {
		setWlpId(value);
	};

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};
	const hiddenFileInput = React.useRef(null);


	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};
	const download = async (fileId: number, fileName: string) => {
		await downloadFile(fileId, fileName);
	};

	const uploadFiles = async (files: FileList) => {
		setFileLoading(true);
		if (files.length !== 1) {
			setFileLoading(false);
			return;
		}

		const file = files[0];

		if (!file.name.endsWith('.csv')) {
			showError({ title: 'Wrong file type' });
			setFileLoading(false);
			return;
		}

		const fileContent = await readFileContent(file);
		const fileContentEnc = window.btoa(fileContent);


		const formData: FileUploadPayload = {
			provider: provider,
			fileName: file.name,
			content: fileContentEnc,
			wlpId
		};

		try {
			await upload(formData);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
			setFileLoading(false);
		}
		setFileLoading(false);
	};

	const readFileContent = async (file: File): Promise<string> => {
		return new Promise<string>((resolve) => {
			if (!file) {
				resolve('');
			}

			const reader = new FileReader();

			reader.onload = () => {
				const text = reader.result.toString();
				resolve(text);
			};

			reader.readAsText(file);
		});
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.fileName}</Table.Cell>
						<Table.Cell width={2}>{entry.originalFileName}</Table.Cell>
						<Table.Cell width={2}>{entry.provider}</Table.Cell>
						<Table.Cell width={2}>{entry.status}</Table.Cell>
						<Table.Cell width={2}>{entry.uploadedDate}</Table.Cell>
						<Table.Cell width={2}>
							<Popup content='View system user' trigger={<Icon className='actionIcon' name="user" size='large' onClick={() => openUser(entry.uploadedBy)} />} />
							{entry.provider === 'TOKEN' && <Popup content='Download' trigger={<Icon className='actionIcon' name="download" size='large' onClick={() => download(entry.id, entry.fileName)} />} />}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={6}>Not files uploaded yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (fileLoading) return <LoadingPage />;
	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>File Upload</Header>
				<Divider hidden />
				<input type="file"
					accept=".csv"
					name="files"
					ref={hiddenFileInput}
					onChange={e => {
						uploadFiles(e.currentTarget.files);
					}}
					style={{ display: 'none' }}
				/>
				<Button type="button" primary disabled={!writePermission} onClick={handleUploadClick} content='Upload' icon="upload" />
				<Select
					name="provider"
					placeholder="Select provider"
					options={providerOptions}
					value={provider}
					onChange={(e, { value }) => handleProviderChange(value)}
					style={{ marginLeft: '1em' }}
				/>
				<Select
					className="onBehalfOf"
					name="onBehalfOf"
					placeholder="Select whitelabel"
					options={whitelabelOptions}
					value={wlpId}
					onChange={(e, { value }) => handleWlpIdChange(value)}
					style={{ marginLeft: '1em' }}
					disabled={true}
				/>
				<Divider hidden />
			</Segment>
			<Segment>
				<Header as='h2'>Files</Header>
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default Files;
