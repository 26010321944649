import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { showErrorNotification } from '../swal/slice';
import {  TrustPayment, TrustPaymentExecutePayload, TrustPaymentsStore,  } from './types';

const initialState: TrustPaymentsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'trustPayments',
	initialState,
	reducers: {
		setPayments(state, action: PayloadAction<Array<TrustPayment>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setPayments, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getTrustPayments = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/trust/payments', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setPayments(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const rejectPayment = async (trustPaymentId: number) => {
	const response = await request.post('/api/admin/trust/payments/reject', {trustPaymentId});
	const { data } = response;
	return data;
};

export const executePayment = async (payload: TrustPaymentExecutePayload) => {
	const response = await request.post('/api/admin/trust/payments/execute', payload);
	const { data } = response;
	return data;
};




export default slice.reducer;
