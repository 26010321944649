/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, List, Popup, Segment, Table } from 'semantic-ui-react';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import Loading from '@/components/Shared/Loading';
import '../Clients/ClientDetails/Profile.css';
import { RootState } from '@/rootReducer';
import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { faqDetails, faqDetailsPayload, FaqHeader } from '@/features/faq/faqTypes';
import { getFaqHeaderDetails } from '@/features/faq/faqSlice';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { PaginateParams } from '@/helpers/globalTypes';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { deleteFaqDetails, getFaqDetails, postFaqDetails, putFaqDetails } from '@/features/faq/faqDetailsSlice';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import FaqDetailsModal from './FaqDetailsModal';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';

const FaqHeaderDetails = (props): React.ReactElement => {

	//const dispatch = useDispatch();
	const [faqId] = useState<number>(props.match.params.faqId);
	const [faqHeader, setFaqHeader] = useState<FaqHeader>(null);
	const countries = getNames('en');

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getFaqHeaderDetails(faqId);
				setFaqHeader(data);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [faqId]);

	useEffect(() => {
		fetchData();
	}, [faqId, fetchData]);


	if (!faqHeader) {
		return <Loading />;
	}


	return (
		<Container fluid>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>FAQ Header</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable columns={2} style={{ marginTop: 0 }}>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic style={{ marginTop: 0 }}>
							<List id="profile-data">
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Name</List.Header>
										<List.Description>{faqHeader.name}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Language</List.Header>
										<List.Description>{faqHeader.languageCode}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Country</List.Header>
										<List.Description>{faqHeader.countryCode === null ? 'None' : countries[alpha3ToAlpha2(faqHeader.countryCode)]}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Enabled</List.Header>
										<List.Description>{faqHeader.disabled ? 'Disabled' : 'Enabled'}</List.Description>
									</List.Content>
								</List.Item>
							</List>
						</Segment>
					</Grid.Column>
				</Grid>
			</Segment>
			<FaqQuestionsAndAnswers headerId={faqHeader.id} writePermission={checkWritePermission(permissions, PermissionType.FAQ)} />
		</Container>
	);
};

interface Props {
	headerId: number,
	writePermission: boolean
}

const FaqQuestionsAndAnswers = ({ headerId, writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.faqDetails
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: `faqHeaderId=${headerId}`, take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);
	const [openFaqModal, setOpenFaqModal] = useState<boolean>(false);
	const [editFaqDetail, setEditFaqDetails] = useState<faqDetails>(null);

	const filter: FilterParam[] = [
		{
			field: 'question',
			label: 'Question',
			type: 'input'
		},
		{
			field: 'answer',
			label: 'Answer',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'question',
			name: 'Question',
			position: 1,
			sortable: true
		},
		{
			field: 'answer',
			name: 'Answer',
			position: 2,
			sortable: true
		},
		{
			field: 'disabled',
			name: 'Disabled',
			position: 3,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 4,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getFaqDetails(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);


	const removeFaqDetail = async (id: number) => {
		try {
			await deleteFaqDetails(id);
			showSuccessNotification('Answer removed');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};


	const handleCloseFaqModal = async (question?: string, answer?: string, disabled?: boolean) => {
		setOpenFaqModal(false);
		if (question && answer) {
			const payload: faqDetailsPayload = {
				question,
				answer,
				disabled,
				faqHeaderId: headerId
			};
			try {
				if (editFaqDetail) {
					payload.id = editFaqDetail.id;
					await putFaqDetails(payload);
				} else {
					await postFaqDetails(payload);
				}
				showSuccessNotification(editFaqDetail ? 'FAQ updated' : 'FAQ added');
				fetchData();
			} catch (e) {
				showErrorNotification(e);
			}
		}
		setEditFaqDetails(null);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.question}</Table.Cell>
						<Table.Cell width={2}>
							<Popup
								trigger={<div>{entry.answer.length > 50 ? entry.answer.slice(0, 50).concat('...') : entry.answer}</div>}
								content={entry.answer}
								position='top center'
								wide='very'
							/>
						</Table.Cell>
						<Table.Cell width={2}>
							{!entry.disabled && <Icon color='green' size='large' name="check circle" />}
							{entry.disabled && <Icon color='red' size='large' name="times circle" />}
						</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Grid>
								<Grid.Row textAlign='center' centered>
									<Popup content='Edit FAQ' trigger={<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="edit outline" onClick={() => { setEditFaqDetails(entry); setOpenFaqModal(true); }} />} />
									<ComponentWithConfirmation popup='Delete FAQ' onConfirm={() => removeFaqDetail(entry.id)} confirmContent={'Are you sure you want delete FAQ?'}>
										<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="trash" />
									</ComponentWithConfirmation>
								</Grid.Row>
							</Grid>
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={4}>Not questions yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Segment>
			<FaqDetailsModal
				open={openFaqModal}
				onClose={handleCloseFaqModal}
				faqDetail={editFaqDetail}
			/>
			<Grid>
				<Grid.Row columns={2}>
					<Grid.Column><Header as='h2'>Questions and answers</Header></Grid.Column>
					<Grid.Column floated='right'>
						<Button
							floated='right'
							primary
							disabled={!writePermission}
							onClick={() => setOpenFaqModal(true)}
						>
							Add FAQ</Button>
					</Grid.Column>
				</Grid.Row>
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Grid>
		</Segment>
	);
};

export default FaqHeaderDetails;
