import React, { useCallback, useState } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
	Icon,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Checkbox, Form, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { showErrorNotification } from '@/features/swal/slice';
import { completeSecurityReport } from '@/features/userSecurityReport/slice';
import { CompleteUserSecurityReportPayload } from '@/features/userSecurityReport/types';


interface Props {
	reportId: number,
	open: boolean;
	onSuccess: () => void,
	onClose: () => void,
}

const CompleteReportModal = ({ reportId, open, onClose, onSuccess }: Props): React.ReactElement => {

	const [file, setFile] = useState(null);

	const onDrop = useCallback((acceptedFiles) => {
		setFile(acceptedFiles[0]);
	}, []);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: 'image/png,image/jpg,image/jpeg,application/pdf',
		multiple: false,
	});


	const removeAll = useCallback(() => {
		setFile(null);
		acceptedFiles.splice(0);
	}, []);

	const initialValues = {
		reportedToAuthority: false,
		explanation: ''
	};

	const validationSchema = Yup.object({
		reportedToAuthority: Yup.boolean().required(),
		explanation: Yup.string().test('explanation', 'Required, min 10 char', function (value, ctx) {
			return ctx.parent.reportedToAuthority ? true : value?.length > 10;
		}),

	});

	const handleDiscard = () => {
		onClose();
		removeAll();
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			setSubmitting(true);
			const { reportedToAuthority, explanation } = formData;

			const payload: CompleteUserSecurityReportPayload = {
				reportId, reportedToAuthority, explanation, file
			};

			await completeSecurityReport(payload);
			onSuccess();
		}
		catch (e) {
			showErrorNotification(e);
		}
		finally {
			setSubmitting(false);
			removeAll();
			onClose();
		}
	};


	return <Modal
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, values, dirty }) => {
					return (
						<Form
							size="large">
							<Header content="Complete Report" />
							<Checkbox fitted
								label='Reported to relevant national authority?'
								name="reportedToAuthority" />
							<TextArea
								label='If not report to national authority, please explain.'
								name="explanation"
								errorPrompt />
							<Divider />

							<Grid className='uploadFile' style={{ marginLeft: '0', marginRight: '0', marginBottom: '1em', marginTop: '1em' }}>
								<Grid.Row>
									<div {...getRootProps()} id='file-container'>
										<input {...getInputProps()} id='file-drop' />
										<span><Icon name='upload' /> If report to relevant national authority, attach copy of findings report</span>
									</div>
								</Grid.Row>
								{file &&
									<Grid.Row>
										<Icon size="large" name="file outline" />
										<div className="fileName">{file.name}</div>
										<Icon className="fileName" size="large" name='trash' link onClick={() => removeAll()} />
									</Grid.Row>
								}
							</Grid>
							<Grid columns={2}>
								<Grid.Row>
									<Grid.Column>
										<SubmitButton
											disabled={isSubmitting || !isEmpty(errors) || !dirty || (values.reportedToAuthority && file === null)}
											primary size="large" type="submit"
											floated='right'>
											Save
										</SubmitButton>
									</Grid.Column>
									<Grid.Column>
										<Button floated='left' secondary size="large" content='Discard' onClick={handleDiscard} />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>);
				}}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default CompleteReportModal;
