import React, { useState } from 'react';
import {
	Modal,
	Divider,
	List,
	Icon
} from 'semantic-ui-react';
import { TrustPayment } from '@/features/trustPayments/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NumberFormat from 'react-number-format';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';


interface Props {
	open: boolean;
	onClose: () => void,
	trustPayment: TrustPayment
}

const TrustPaymentDetailsModal = ({ open, onClose, trustPayment }: Props): React.ReactElement => {
	const [countries] = useState(getNames('en'));


	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			{trustPayment && <List id="profile-data">
				<List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Type</List.Header>
						<List.Description>{trustPayment.beneficiaryIsBusiness ? 'Business' : 'Individual'}</List.Description>
					</List.Content>
				</List.Item>
				<Divider />
				<List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Name</List.Header>
						{trustPayment.beneficiaryName ? <CopyToClipboard text={trustPayment.beneficiaryName}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryName} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider />
				{trustPayment.beneficiaryIdentityNo && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Identity No.</List.Header>
						{trustPayment.beneficiaryIdentityNo ? <CopyToClipboard text={trustPayment.beneficiaryIdentityNo}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryIdentityNo} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryIban && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Iban</List.Header>
						{trustPayment.beneficiaryIban ? <CopyToClipboard text={trustPayment.beneficiaryIban}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryIban} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryAccountNumber && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Account Number</List.Header>
						{trustPayment.beneficiaryAccountNumber ? <CopyToClipboard text={trustPayment.beneficiaryAccountNumber}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryAccountNumber} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryRoutingNumber && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Routing Number</List.Header>
						{trustPayment.beneficiaryRoutingNumber ? <CopyToClipboard text={trustPayment.beneficiaryRoutingNumber}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryRoutingNumber} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryBicSwift && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary BIC/SWIFT</List.Header>
						{trustPayment.beneficiaryBicSwift ? <CopyToClipboard text={trustPayment.beneficiaryBicSwift}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryBicSwift} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryBankName && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Bank</List.Header>
						{trustPayment.beneficiaryBankName ? <CopyToClipboard text={trustPayment.beneficiaryBankName}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryBankName} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryBankAddress && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Bank Address</List.Header>
						{trustPayment.beneficiaryBankAddress ? <CopyToClipboard text={trustPayment.beneficiaryBankAddress}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryBankAddress} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryAddressCountry && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Country</List.Header>
						{trustPayment.beneficiaryAddressCountry ? <CopyToClipboard text={countries[alpha3ToAlpha2(trustPayment.beneficiaryAddressCountry)]}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{countries[alpha3ToAlpha2(trustPayment.beneficiaryAddressCountry)]} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryAddressCity && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary City</List.Header>
						{trustPayment.beneficiaryAddressCity ? <CopyToClipboard text={trustPayment.beneficiaryAddressCity}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryAddressCity} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryAddressStreet && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Street</List.Header>
						{trustPayment.beneficiaryAddressStreet ? <CopyToClipboard text={trustPayment.beneficiaryAddressStreet}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryAddressStreet} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryRegion && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Region</List.Header>
						{trustPayment.beneficiaryRegion ? <CopyToClipboard text={trustPayment.beneficiaryRegion}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryRegion} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryPostalCode && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Beneficiary Postal code</List.Header>
						{trustPayment.beneficiaryPostalCode ? <CopyToClipboard text={trustPayment.beneficiaryPostalCode}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryPostalCode} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryFurtherCreditAccountName && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Further Credit Account Name</List.Header>
						{trustPayment.beneficiaryFurtherCreditAccountName ? <CopyToClipboard text={trustPayment.beneficiaryFurtherCreditAccountName}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryFurtherCreditAccountName} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				{trustPayment.beneficiaryFurtherCreditAccountNumber && <><List.Item>
					<List.Content>
						<List.Header className="color-grey">Further Credit Account Number</List.Header>
						{trustPayment.beneficiaryFurtherCreditAccountNumber ? <CopyToClipboard text={trustPayment.beneficiaryFurtherCreditAccountNumber}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.beneficiaryFurtherCreditAccountNumber} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider /></>}
				<List.Item>
					<List.Content>
						<List.Header className="color-grey">Amount</List.Header>
						<CopyToClipboard text={Math.abs(trustPayment.amount)}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${trustPayment.ccy} `} value={Math.abs(trustPayment.amount)} /> <Icon name='clone outline' /></List.Description>
						</CopyToClipboard>
					</List.Content>
				</List.Item>
				<Divider />
				<List.Item>
					<List.Content>
						<List.Header className="color-grey">Reference</List.Header>
						{trustPayment.details ? <CopyToClipboard text={trustPayment.details}>
							<List.Description style={{ cursor: 'pointer' }} basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{trustPayment.details} <Icon name='clone outline' /></List.Description>
						</CopyToClipboard> : <List.Description>⠀</List.Description>}
					</List.Content>
				</List.Item>
				<Divider />
			</List>}

		</Modal.Content>
	</Modal>;
};

export default TrustPaymentDetailsModal;
