import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { Field } from '@/features/compliance/types';


interface Props {
	open: boolean;
	onClose: (newValue?: string, field?: Field, add?: boolean, file?: boolean) => void,
	field: Field;
}

const ComplianceEditValueModal = ({ open, onClose, field }: Props): React.ReactElement => {

	const initialValues = {
		newValue: field?.value?.value??'',
	};

	const validationSchema = Yup.object({
		newValue: Yup.string()
			.required('You must enter a new value')

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { newValue } = formData;
		onClose(newValue, field, field.value?false:true, false);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content="Edit Compliance form field value" />

						<TextArea
							label='Value'
							name="newValue"
						/>
						
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										Save
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Cancel' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default ComplianceEditValueModal;
