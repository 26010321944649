import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Grid, Icon, Popup, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import {
	changeAccountStatus, changeClosureDate,
	getAccounts,
	postCreateBusinessCJAccount,
	postCreateChildAccount,
	postCreateCustodyAccount,
	postUpdateBalance
} from '@/features/accounts/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Permission, PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { Account, DepositRequest } from '@/features/accounts/types';
import UpdateBalance from '../../Accounts/UpdateBalance';
import { Client } from '@/features/clients/types';
import UpdateIBAN from '../../Accounts/UpdateIBAN';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { postTokenUpdateBalance } from '@/features/tokens/slice';
import { TokenDepositRequest } from '@/features/tokens/types';
import UpdateTokenBalance from '../../Accounts/UpdateTokenBalance';
import ChangeClosureDate from '@/components/Dashboard/Accounts/ChangeClosureDate';
import { formatDateWithPattern } from '@helpers/date';

interface Props {
	userId: number,
	type: string,
	permissions?: Array<Permission>
	user?: Client
}

export const getClientAccountInitPagination = (userId: number) => { return { orderBy: 'balance', sort: 'DESC', skip: 0, filter: `userId=${userId}`, take: 5 } as PaginateParams; };


const ClientAccounts = ({ userId, type, permissions, user }: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.accounts
	);

	const paginateInitialState: PaginateParams = getClientAccountInitPagination(userId);
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);
	const [updateBalance, setUpdateBalance] = useState<boolean>(false);
	const [updateTokenBalance, setUpdateTokenBalance] = useState<boolean>(false);
	const [updateIban, setUpdateIban] = useState<boolean>(false);
	const [withdrawal, setWithdrawal] = useState<boolean>(false);
	// const [showServiceFeeModal, setShowServiceFeeModal] = useState<boolean>(false);
	// const [serviceFeeWalletId, setServiceFeeWalletId] = useState<string>('');
	// const [serviceFeeCurrency, setServiceFeeCurrency] = useState<string>('');
	const [currentAccount, setCurrentAccount] = useState<Account>(null);
	const [showChangeClosureDate, setShowChangeClosureDate] = useState<boolean>(false);

	const headers: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Account number',
			position: 1,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 2,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 3,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 4,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 5,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 6,
			sortable: true
		},
		{
			field: 'closureDate',
			name: 'Closure date',
			position: 7,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 8,
			sortable: false
		}
	];

	const trustHeaders: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Account number',
			position: 1,
			sortable: true
		},
		{
			field: 'label',
			name: 'Label',
			position: 2,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 3,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 4,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 5,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 6,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 7,
			sortable: true
		},
		{
			field: 'closureDate',
			name: 'Closure date',
			position: 8,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 9,
			sortable: false
		}
	];

	const tokenHeaders: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Account number',
			position: 1,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 2,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 3,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 4,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 5,
			sortable: true
		},
		{
			field: 'closureDate',
			name: 'Closure date',
			position: 6,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 7,
			sortable: false
		}
	];

	const tablesHeaders = type === 'TOKEN'
		? tokenHeaders
		: type === 'TRUST'
			? trustHeaders
			: headers;


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getAccounts(type, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch, type]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const history = useHistory();
	const openAccount = (id: number) => {
		history.push(`/account/${id}`);
	};

	// const openAddServiceFee = (walletId: string, currency: string) => {
	// 	setServiceFeeWalletId(walletId);
	// 	setServiceFeeCurrency(currency);
	// 	setShowServiceFeeModal(true);
	// };

	const handleBalanceUpdate = (account: Account, withdrawal: boolean, type?: string) => {
		setWithdrawal(withdrawal);
		setCurrentAccount(account);
		type === 'TOKEN' ? setUpdateTokenBalance(true) : setUpdateBalance(true);
	};

	const onCloseUpdateBalanceModal = async (amount?: number, details?: string) => {
		setUpdateBalance(false);
		if (amount && details) {
			try {
				const formData: DepositRequest = {
					accountId: currentAccount.id,
					userId: currentAccount.userId,
					ccy: currentAccount.currency,
					amount: amount,
					details: details,
				};
				await postUpdateBalance(formData, withdrawal ? 'MANUAL_WITHDRAWAL' : 'MANUAL_DEPOSIT');
				showSuccessNotification(withdrawal ? 'Balance withdrew!' : 'Balance added!');
				fetchData();
			}
			catch (e) {
				showErrorNotification(e);
			}
		}
		setCurrentAccount(null);
	};

	const onCloseTokenUpdateBalanceModal = async (amount?: number, details?: string, externalId?: number) => {
		setUpdateTokenBalance(false);
		if (amount && details) {
			try {
				const formData: TokenDepositRequest = {
					accountId: currentAccount.id,
					userId: currentAccount.userId,
					ccy: currentAccount.currency,
					amount: amount,
					details: details,
					externalId: externalId
				};
				await postTokenUpdateBalance(formData, withdrawal ? 'MANUAL_WITHDRAWAL' : 'MANUAL_DEPOSIT');
				showSuccessNotification(withdrawal ? 'Balance withdrew!' : 'Balance added!');
				fetchData();
			}
			catch (e) {
				showErrorNotification(e);
			}
		}
		setUpdateTokenBalance(null);
	};

	const onCloseUpdateIbanModal = async (iban?: string) => {
		setUpdateIban(false);
		if (iban) {
			try {
				await postCreateBusinessCJAccount(userId, iban, currentAccount.id);
				showSuccessNotification('CJ account IBAN updated');
				fetchData();
			}
			catch (e) {
				showErrorNotification(e);
			}
		}
		setCurrentAccount(null);
	};

	const createCJAccount = async () => {
		try {
			await postCreateBusinessCJAccount(userId);
			fetchData();
			showSuccessNotification('CJ account created');
		} catch (e) {
			showErrorNotification(e);
		}
	};
	const createCustodyAccount = async () => {
		try {
			await postCreateCustodyAccount(userId);
			fetchData();
			showSuccessNotification('Custody account created');
		} catch (e) {
			showErrorNotification(e);
		}
	};
	const createChildAccount = async (parentAccountId) => {
		try {
			await postCreateChildAccount(parentAccountId);
			fetchData();
			showSuccessNotification('Process sent');
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const showCreateChildAccountBtn = (type, entry) => {
		let show = false;
		if (
			(type === 'WALLET' || type === 'BANK')
			&& (entry.integration === 'CLEARJUNCTION_POOL' || entry.integration === 'CLEARJUNCTION_WALLET')
			&& entry.currency === 'EUR'
		) {
			show = true;
		}
		return show;
	};

	const showAccountStatusToggleBtn = (status: string) => {
		return status === 'ACTIVE' || status === 'BLOCKED';
	};

	const toggleAccountStatus = async (account: Account) => {
		const status = account.status === 'ACTIVE' ? 'BLOCKED' : 'ACTIVE';
		await changeAccountStatus(account.id, status);
		fetchData();
	};

	const resolveStatusToggleBtnText = (status: string) => {
		return status === 'ACTIVE'
			? 'Block account'
			: 'Activate account';
	};

	const resolveStatusToggleBtnIcon = (status: string) => {
		return status === 'ACTIVE'
			? 'lock open'
			: 'lock';
	};

	const resolveStatusConfirmChangeText = (status: string) => {
		return status === 'ACTIVE'
			? 'Are you sure you want block account?'
			: 'Are you sure you want activate account?';
	};

	const handleChangeCommonDate = (account: Account) => {
		setCurrentAccount(account);
		setShowChangeClosureDate(true);
	};

	const onCloseChangeCommonDate = async (closureDate: number) => {
		setShowChangeClosureDate(false);
		if (closureDate) {
			try {
				await changeClosureDate(currentAccount.id, closureDate);
				showSuccessNotification('Closure date changed');
				fetchData();
			} catch (err) {
				showErrorNotification(err);
			}
		}

		setCurrentAccount(null);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			<ChangeClosureDate
				onClose={onCloseChangeCommonDate}
				open={showChangeClosureDate}
			/>
			<UpdateBalance
				onClose={onCloseUpdateBalanceModal}
				open={updateBalance}
				withdrawal={withdrawal}
			/>
			<UpdateTokenBalance
				balance={currentAccount?.balance}
				onClose={onCloseTokenUpdateBalanceModal}
				open={updateTokenBalance}
				withdrawal={withdrawal}
			/>
			<UpdateIBAN
				onClose={onCloseUpdateIbanModal}
				open={updateIban}
			/>
			{/* <ServiceFeeModal
				walletId={serviceFeeWalletId}
				currency={serviceFeeCurrency}
				userId={userId}
				onClose={() => setShowServiceFeeModal(false)}
				open={showServiceFeeModal}
			/> */}
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.walletId}${index}`} style={{ backgroundColor: entry.status === 'ORDERED' && entry.integration === 'CLEARJUNCTION_POOL' ? 'yellow' : '' }} >
						<Table.Cell width={1}>{entry.walletId}</Table.Cell>
						{type === 'TRUST' && <Table.Cell width={3}>
							{entry.label}
						</Table.Cell>}
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.integration)}</Table.Cell>
						<Table.Cell width={3}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balance} />
						</Table.Cell>
						<Table.Cell width={3}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balanceOnHold} />
						</Table.Cell>
						{type !== 'TOKEN' && <Table.Cell width={3}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balanceFrozen} />
						</Table.Cell>
						}
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.status)}</Table.Cell>
						<Table.Cell width={1}>
							{entry.closureDate ? formatDateWithPattern(entry.closureDate, 'dd/MM/yyyy') : 'No date set yet'}
							<Popup content='Change commission date' trigger={<Icon style={{ marginLeft: '0.5em' }} className='tableActionIcon' size='large' name="edit" onClick={() => handleChangeCommonDate(entry)} />} />
						</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							{/* {(type === 'WALLET' || type === 'BANK') && <Popup content='Add/Return Fee' trigger={<Icon className='tableActionIcon' size='large' name="tasks" onClick={() => openAddServiceFee(entry.walletId, entry.currency)} />} />} */}
							<Popup content='Open account details' trigger={<Icon className='tableActionIcon' size='large' name="money bill alternate outline" onClick={() => openAccount(entry.id)} />} />
							{type === 'TRUST' && <Popup content='Update balance' trigger={<Icon className='tableActionIcon' size='large' name="plus" disabled={!checkWritePermission(permissions, PermissionType.ACCOUNTS)} onClick={() => handleBalanceUpdate(entry, false)} />} />}
							{type === 'TRUST' && <Popup content='Withdraw balance' trigger={<Icon className='tableActionIcon' size='large' name="minus" disabled={!checkWritePermission(permissions, PermissionType.ACCOUNTS)} onClick={() => handleBalanceUpdate(entry, true)} />} />}
							{type === 'TOKEN' && entry.customToken && <Popup content='Update balance' trigger={<Icon className='tableActionIcon' size='large' name="plus" disabled={!checkWritePermission(permissions, PermissionType.ACCOUNTS)} onClick={() => handleBalanceUpdate(entry, false, type)} />} />}
							{type === 'TOKEN' && entry.customToken && <Popup content='Withdraw balance' trigger={<Icon className='tableActionIcon' size='large' name="minus" disabled={!checkWritePermission(permissions, PermissionType.ACCOUNTS) || entry.balance <= 0} onClick={() => handleBalanceUpdate(entry, true, type)} />} />}
							{entry.status === 'ORDERED' && entry.integration === 'CLEARJUNCTION_POOL' && <Popup content='Update IBAN' trigger={<Icon className='tableActionIcon' size='large' name="edit" disabled={!checkWritePermission(permissions, PermissionType.USERS)} onClick={() => { setCurrentAccount(entry); setUpdateIban(true); }} />} />}
							{
								showCreateChildAccountBtn(type, entry)
								&& <Popup content='Create child account' trigger={<Icon className='tableActionIcon' size='large' name="plus square outline" onClick={() => createChildAccount(entry.id)} />} />
							}
							{showAccountStatusToggleBtn(entry.status) && <ComponentWithConfirmation popup={resolveStatusToggleBtnText(entry.status)} onConfirm={() => toggleAccountStatus(entry)} confirmContent={resolveStatusConfirmChangeText(entry.status)}>
								<Icon className='tableActionIcon' size='large' name={resolveStatusToggleBtnIcon(entry.status)} />
							</ComponentWithConfirmation>}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={7}>Client does not have any {type.toLowerCase()} accounts.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<>
			{user && user.profile.type === 'BUSINESS' && type === 'BANK' && <Grid>
				<Grid.Row columns={1}>
					<Grid.Column floated='right' style={{ margin: '1rem' }}>
						<ComponentWithConfirmation onConfirm={createCJAccount} confirmContent={'Are you sure you want create CJ bussiness account?'}>
							<Button
								floated='right'
								primary
								disabled={!checkWritePermission(permissions, PermissionType.USERS)}
							>
								Create CJ account</Button>
						</ComponentWithConfirmation>
					</Grid.Column>
				</Grid.Row>
			</Grid>}
			{user && type === 'TRUST' && <Grid>
				<Grid.Row columns={1}>
					<Grid.Column floated='right' style={{ margin: '1rem' }}>
						<ComponentWithConfirmation onConfirm={createCustodyAccount} confirmContent={'Are you sure you want create Custody account?'}>
							<Button
								floated='right'
								primary
								disabled={!checkWritePermission(permissions, PermissionType.USERS)}
							>
								Create Custody account</Button>
						</ComponentWithConfirmation>
					</Grid.Column>
				</Grid.Row>
			</Grid>}
			<Divider hidden />
			<CustomTable
				fetchData={fetchData}
				loading={loading}
				paginateInitialState={paginateInitialState}
				tableBody={tableBody}
				totalPages={totalPages}
				setPagination={setPagination}
				header={tablesHeaders}
				pagination={pagination}
				hideItemsPerPage={true}
			/>
		</>
	);

};

export default ClientAccounts;
