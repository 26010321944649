import React, { useState } from 'react';
import './Reconciliation.css';
import { Button, Container, Grid, Segment } from 'semantic-ui-react';
import ReconcileTrx from './Components/ReconcileTrx';
import CustomerAccount from './Components/CustomerAccount';
import { useHistory } from 'react-router-dom';

interface Props {
	writePermission: boolean
}

export const changeState = (id: number, event: any, data: any, stateObject: any, setStateObject: any) => {
	const itemName = data.name.split('-')[0];
	const itemValue = data.type === 'checkbox' ? data.checked : data.value;
	const changedProperty = { [itemName]: itemValue };
	const existingItem = stateObject[id] || {};
	const updatedItem = { ...existingItem, ...changedProperty };
	setStateObject({ ...stateObject, [id]: { ...updatedItem } });
};

export interface ReconcilationPayload {
	wlpId?: string,
	bankAccountId?: string,
	reconciled?: boolean,
	amount?: number
}

export interface ReconciliationState {
	[id: number]: ReconcilationPayload
}

export interface DocumentPayload {
	reconciled?: boolean
}

export interface DocumentState {
	[id: number]: DocumentPayload
}

export const editItemAllowed = (id: number, itemReconciled: boolean, state: ReconciliationState | DocumentState, writePermission: boolean) => {
	return writePermission && ((state[id]?.reconciled ?? itemReconciled) === false);
};

const Reconcile = ({ writePermission }: Props): React.ReactElement => {

	const history = useHistory();

	const [reconcile, setReconcile] = useState<ReconciliationState>({});
	const [documents, setDocuments] = useState<DocumentState>({});

	const changeReconciliationState = (id: number, event: any, data: any) => {
		changeState(id, event, data, reconcile, setReconcile);
	};

	const changeDocumentState = (id: number, event: any, data: any) => {
		changeState(id, event, data, documents, setDocuments);
	};

	const onReconcile = () => {
		alert('Not yet supported');
		setReconcile({});
		setDocuments({});
	};

	return (
		<Container fluid id='reconciliations'>
			<Segment>
				<Grid columns={2}>
					<Grid.Row columns={2}>
						<Grid.Column><Button primary floated='left' size="large" content='Reconcile' onClick={onReconcile} /></Grid.Column>
						<Grid.Column><Button secondary floated='right' size="large" content='Go back' onClick={() => history.goBack()} /></Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={9} className='reconcile-columns' stretched>
							< ReconcileTrx writePermission={writePermission}
								reconcile={reconcile}
								changeReconciliationState={changeReconciliationState} />
						</Grid.Column>
						<Grid.Column width={7} className='reconcile-columns' stretched>
							<CustomerAccount writePermission={writePermission}
								documents={documents}
								changeDocumentState={changeDocumentState}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</Container >
	);
};

export default Reconcile; 
