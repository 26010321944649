import { downloadAttachment } from '@/features/compliance/slice';
import { Shareholder } from '@/features/compliance/types';
import { showErrorNotification } from '@/features/swal/slice';
import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionTitle, Divider, Grid, Icon, List, Segment } from 'semantic-ui-react';
import '../../Clients/ClientDetails/Profile.css';

interface Props {
    shareholders: Shareholder[];
}

const Shareholders = ({ shareholders }: Props) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    };

    return (
        <>
            {shareholders.map((shareholder, index) =>
                <Accordion fluid styled key={index} style={{ marginBottom: '4px' }}>
                    <AccordionTitle
                        active={activeIndex === index}
                        index={index}
                        onClick={handleClick}
                    >
                        {`${shareholder.shareHolderFirstName} ${shareholder.shareHolderLastName}`}
                        <Icon name={activeIndex === index ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === index}>
                        {activeIndex === index && <ShareholderForm shareholder={shareholder} />}
                    </AccordionContent>
                </Accordion>
            )}
        </>
    );
};

const download = (key: string, name: string) => {
    const get = async () => {
        try {
            downloadAttachment(key, name);
        } catch (e) {
            showErrorNotification(e);
        }
    };
    get();
};

interface DProps {
    shareholder: Shareholder;
}

const ShareholderForm = ({ shareholder }: DProps) => {
    return (
        <Grid stackable columns={2}>
            <Grid.Column className="profiledatacolumn">
                <Segment className="usersegment" basic>
                    <List id="profile-data">
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">First name</List.Header>
                                <List.Description>{shareholder.shareHolderFirstName}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">Last Name</List.Header>
                                <List.Description>{shareholder.shareHolderLastName}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">Birth Date</List.Header>
                                <List.Description>{shareholder.shareHolderBirthDate}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">% of Ownership</List.Header>
                                <List.Description>{shareholder.shareHolderPercentageOfOwnerShip}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                    </List>
                </Segment>
            </Grid.Column>
            <Grid.Column className="profiledatacolumn">
                <Segment className="usersegment" basic>
                    <List id="profile-data">
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">Shereholder POA</List.Header>
                                <List.Description><a onClick={() => download(shareholder.shareHolderPOA, 'shareholderPoa')}> Download</a></List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">Political Exposed</List.Header>
                                <List.Description>{shareholder.shareHolderPep ? 'Yes' : 'No'}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">US Citizen</List.Header>
                                <List.Description>{shareholder.shareHolderUsCitizen ? 'Yes' : 'No'}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                    </List>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default Shareholders;
