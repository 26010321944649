import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { deleteProcessFee, getProcessFee, postProcessFee } from '@/features/processes/processesSlice';
import { Process, ProcessFee } from '@/features/processes/processesTypes';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Icon, Popup, Table } from 'semantic-ui-react';
import ProcessFeeModal from './ProcessFeeModal';

const formatDate = (date: Date): string => {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return `${year}-${month}-${day}`;
};

interface Props {
	writePermission: boolean,
	process: Process
}

const ProcessFeeTable = ({ writePermission, process }: Props): React.ReactElement => {

	const [fees, setFees] = useState<Array<ProcessFee>>([]);
	const [open, setOpen] = useState<boolean>(false);
	const [edit, setEdit] = useState<ProcessFee>(null);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getProcessFee(process.proc);
				setFees(data);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [process]);

	useEffect(() => {
		fetchData();
	}, [process, fetchData]);

	const deleteFee = async (feeId: number) => {
		try {
			await deleteProcessFee(feeId);
			showSuccessNotification('Fee removed from process');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const handleCloseFeeModal = async (feeType?: string, feeAmount?: number, feePercent?: number, feeMonth?: number, minFee?: number,
		userProfileType?: string, userLevelId?: number, riskGroupCode?: string, clientId?: string, country?: string, currency?: string) => {
		setOpen(false);
		const today = new Date();
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		if (feeType) {
			const payload: ProcessFee = {
				proc: process.proc,
				fee: feeType,
				feeAmount,
				feePercent,
				feeMonth,
				minFee,
				userProfileType,
				userLevelId,
				riskGroupCode,
				clientId,
				countryCode: country,
				ccy: currency,
				config: null,
				startDate: formatDate(tomorrow),
				endDate: formatDate(today),
				newStartDate: null
			};

			try {
				await postProcessFee(payload);
				showSuccessNotification(edit ? 'Process fee updated' : 'Process fee added');
				fetchData();
			} catch (e) {
				showErrorNotification(e);
			}
		}
		setEdit(null);
	};

	const editProcessFee = (fee: ProcessFee) => {
		setEdit(fee);
		setOpen(true);
	};

	return (
		<>
			<ProcessFeeModal
				open={open}
				onClose={handleCloseFeeModal}
				edit={edit}
			/>
			<Grid>
				<Grid.Row>
					<Button
						primary
						content='Add fee'
						onClick={() => setOpen(true)}
						style={{ marginLeft: '1rem' }}
					/>
				</Grid.Row>
			</Grid>
			<Table id='table' celled sortable textAlign='center' padded='very' striped>
				<Table.Header fullWidth className='tableHeader'>
					<Table.Row className="tableHeaders">
						<Table.HeaderCell>Fee</Table.HeaderCell>
						<Table.HeaderCell>Name</Table.HeaderCell>
						<Table.HeaderCell>Currency</Table.HeaderCell>
						<Table.HeaderCell>Fee amount</Table.HeaderCell>
						<Table.HeaderCell>Fee month</Table.HeaderCell>
						<Table.HeaderCell>Fee percent</Table.HeaderCell>
						<Table.HeaderCell>Min fee</Table.HeaderCell>
						<Table.HeaderCell>Country</Table.HeaderCell>
						<Table.HeaderCell>Client ID</Table.HeaderCell>
						<Table.HeaderCell>User level</Table.HeaderCell>
						<Table.HeaderCell>Profile type</Table.HeaderCell>
						<Table.HeaderCell>Risk Group</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body className="tableBody">
					{fees.length > 0 && fees.map((entry) => {
						return (
							<Table.Row key={`${entry.id}$`} >
								<Table.Cell width={2}>{entry.description}</Table.Cell>
								<Table.Cell width={2}>{entry.name}</Table.Cell>
								<Table.Cell width={1}>{entry.ccy}</Table.Cell>
								<Table.Cell width={1}>{entry.feeAmount}</Table.Cell>
								<Table.Cell width={1}>{entry.feeMonth}</Table.Cell>
								<Table.Cell width={1}>{entry.feePercent}</Table.Cell>
								<Table.Cell width={1}>{entry.minFee}</Table.Cell>
								<Table.Cell width={1}>{entry.countryCode}</Table.Cell>
								<Table.Cell width={1}>{entry.clientId}</Table.Cell>
								<Table.Cell width={1}>{entry.userLevelId}</Table.Cell>
								<Table.Cell width={1}>{entry.userProfileType}</Table.Cell>
								<Table.Cell width={1}>{entry.riskGroupCode}</Table.Cell>
								<Table.Cell width={2}>
									<Grid>
										<Grid.Row textAlign='center' centered>
											<Popup content='Edit process fee' trigger={
												<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="edit" onClick={() => editProcessFee(entry)} />} />
											<ComponentWithConfirmation popup='Delete process fee' onConfirm={() => deleteFee(entry.id)} confirmContent={'Are you sure you want to remove fee?'}>
												<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="trash" />
											</ComponentWithConfirmation>
										</Grid.Row>
									</Grid>
								</Table.Cell>
							</Table.Row>
						);
					})}
					{fees.length === 0 && <Table.Row>
						<Table.Cell textAlign='center' colSpan={13}>Not fees added for this process.</Table.Cell>
					</Table.Row>}
				</Table.Body>
			</Table>
		</>
	);
};


export default ProcessFeeTable;
