/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showException } from '../swal/slice';
import { BankAccount, BankAccountsStore } from './bankAccountsTypes';

const initialState: BankAccountsStore = {
	status: 'idle',
	error: null,
	wlpId: null,
	list: []
};


const slice = createSlice({
	name: 'bankAccounts',
	initialState,
	reducers: {
		setBankAccounts(state, action: PayloadAction<Array<BankAccount>>) {
			state.list = action.payload;
		},
		setLoading: (state) => {
			state.status = 'loading';
		},
		setSucceeded: (state) => {
			state.status = 'succeeded';
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
			state.status = 'failed';
		},
		setWlp: (state, { payload }: PayloadAction<string>) => {
			state.wlpId = payload; 
		},
	}
});

export const { setBankAccounts, setLoading, setSucceeded, setError, setWlp } = slice.actions;


export const getBankAccounts = (wlpId: string): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading());
			dispatch(setWlp(wlpId));
			const response = await request.get(`/api/admin/statements/bankaccountslov/${wlpId}`, { params: { orderBy: 'description', sort: 'ASC' } });
			const { data } = response;
			dispatch(setBankAccounts(data));
			dispatch(setSucceeded());
			return data;
		} catch (e) {
			showException(e);
			dispatch(setError(e.statusText));
		}
	};
};

export default slice.reducer;
