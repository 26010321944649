import React from 'react';
import { Container, Header, Icon, Segment } from 'semantic-ui-react';

const NotPermitted = (): React.ReactElement => {
	return (
		<Container fluid>
			<Segment textAlign='center'>
				<Header as='h1' content="403" />
				<Icon style={{ color: 'red' }} name='times circle' size='massive' />
				<Header as='h2' content="You don't have permission to view this page" />
				<Header as='h4' content="Please contact your administrator to get access." />
			</Segment>
		</Container>
	);
};

export default NotPermitted;
