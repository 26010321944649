import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Divider,
	Grid, Header,
	Modal
} from 'semantic-ui-react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import { MaintenancePostType, MaintenanceStatus, MaintenanceType } from '@/features/maintenance/maintenanceType';
import { updateMaintenanceMode } from '@/features/maintenance/maintenanceSlice';
import { convertDate } from '@/helpers/date';
import useWhiteLabelLov from '@/helpers/customHook/useWhiteLabelLov';

interface Props {
	item: MaintenanceType,
	open: boolean;
	onClose: () => void,
	onSuccess: () => void,
}

const dateFormat = 'yyyy-MM-dd hh:mm';

export const maintenanceStatusOptions = [
	{
		key: MaintenanceStatus.ACTIVE,
		text: 'Active',
		value: MaintenanceStatus.ACTIVE,
	},
	{
		key: MaintenanceStatus.COMPLETED,
		text: 'Completed',
		value: MaintenanceStatus.COMPLETED,
	},
	{
		key: MaintenanceStatus.UPCOMMING,
		text: 'Upcoming',
		value: MaintenanceStatus.UPCOMMING,
	},
];


const MaintenanceModal = ({ item, open, onClose, onSuccess }: Props): React.ReactElement => {
	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const validationSchema = Yup.object({
		description: Yup.string().required('Description is required'),
		message: Yup.string().required('Message is required'),
		startDate: Yup.date().required('Start Date is required'),
		//on hour from now
		endDate: Yup.date().required('End Date is required')
			.test('endDate', 'End date should be at least 30 min from now', (value) =>
				item?.id ? true :
					value > new Date(Date.now() + (60 * 30 * 1000))),
	});

	const { whitelabelEmiFlagged } = useWhiteLabelLov();

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const {
			startDate,
			endDate,
			integration,
			processes,
			description,
			message,
			wlpId,
		} = formData;
		try {
			const processArray = processes ? processes.toString().split(',').map(p => p.trim()) : undefined;
			const payload: MaintenancePostType = {
				id: item?.id,
				startDate: new Date(startDate),
				endDate: new Date(endDate),
				integration,
				processes: processArray,
				description,
				message,
				...(wlpId && { wlpId: wlpId }),
			};

			await updateMaintenanceMode(payload);
			onSuccess();
			onClose();
			showSuccessNotification('Operation successfully completed');
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
		}
	};

	return (<Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={{
					startDate: item?.startDate ? convertDate(item.startDate, dateFormat) : convertDate(new Date(), dateFormat),
					endDate: item?.endDate ? convertDate(item.endDate, dateFormat) : convertDate(new Date(), dateFormat),
					integration: item?.integration ?? '',
					processes: item?.processes ?? '',
					description: item?.description ?? '',
					message: item?.message ?? '',
					wlpId: item?.agent ?? '',
				}}
				onSubmit={submit}
				validationSchema={validationSchema}
				render={({ isSubmitting, errors }) => {
					return (
						<Form>
							<Header content={item?.id ? 'Update' : 'Create'} />
							<Input
								label='Start Date'
								name="startDate"
								type='datetime-local'
								errorPrompt
							/>
							<Input
								label='End Date'
								name="endDate"
								type='datetime-local'
								errorPrompt
							/>
							<Select
								label='White Label'
								fluid
								name='wlpId'
								search
								options={whitelabelEmiFlagged}
								errorPrompt
								clearable
							/>
							<Grid.Row columns={1} >
								<Grid.Column> <strong>Empty</strong> - maintenance is applied to all</Grid.Column>
								<Grid.Column> <strong>White label</strong> - maintenance is applied to one white label</Grid.Column>
								<Grid.Column> <strong>White label of EMI</strong> -  maintenance will be applied for emi and all wlps under the emi</Grid.Column>
							</Grid.Row>
							<br />
							<Input
								label='Integration (use ALL for whole portal)'
								name="integration"
								fluid
								errorPrompt
							/>
							<TextArea
								label='Processes (separated by , )'
								name="processes"
								errorPrompt
							/>
							<TextArea
								label='Description'
								name="description"
								errorPrompt
							/>
							<TextArea
								label='Message (client facing)'
								name="message"
								errorPrompt
							/>
							<Divider hidden />
							<Grid columns={2}>
								<Grid.Row centered>
									<Grid.Column>
										<SubmitButton primary
											type="submit"
											loading={isSubmitting}
											disabled={!checkWritePermission(permissions, PermissionType.MAINTENANCE) || isSubmitting || !isEmpty(errors)}
											floated='right'>
											Save
										</SubmitButton>
									</Grid.Column>
									<Grid.Column>
										<Button floated='left' secondary content='Discard' onClick={() => onClose()} />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					);
				}}
			/>

		</Modal.Content>
	</Modal >);
};

export default MaintenanceModal;
