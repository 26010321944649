/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, List, Segment, Table } from 'semantic-ui-react';
import { showErrorNotification } from '@/features/swal/slice';
import Loading from '@/components/Shared/Loading';
import { getAccount } from '@/features/accounts/slice';
import { Account } from '@/features/accounts/types';
import '../Clients/ClientDetails/Profile.css';
import { RootState } from '@/rootReducer';
import { PermissionType } from '@/features/user/types';
import { checkPermission } from '@/helpers/permissions';
import { useHistory } from 'react-router-dom';
import Transactions from '../Transactions/Transactions';
import NumberFormat from 'react-number-format';
import AccountPaymentPlan from '@/components/Dashboard/Accounts/AccountPaymentPlan';

const AccountDetails = (props): React.ReactElement => {

	const history = useHistory();
	const [accountId] = useState<number>(props.match.params.accountId);
	const [account, setAccount] = useState<Account>(null);

	const addressesColumns: Array<string> = [
		'Address',
		'Token',
		'Network',
		'Label',
	];

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getAccount(accountId);
				setAccount(data);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [accountId]);

	useEffect(() => {
		fetchData();
	}, [accountId, fetchData]);

	if (!account) {
		return <Loading />;
	}

	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	return (
		<Container fluid>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Account</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
							<Button
								style={{ marginLeft: 10 }}
								secondary
								content='View user'
								disabled={!checkPermission(permissions, PermissionType.USERS)}
								onClick={() => openUser(account.userId)}
								floated='right'
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable columns={2}>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Type</List.Header>
										<List.Description>{account.type}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Currency</List.Header>
										<List.Description>{account.currency}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Balance</List.Header>
										<List.Description><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={account.balance} /></List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Balance on hold</List.Header>
										<List.Description><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={account.balanceOnHold} /></List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Balance frozen</List.Header>
										<List.Description><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={account.balanceFrozen} /></List.Description>
									</List.Content>
								</List.Item>
							</List>
						</Segment>
					</Grid.Column>

					{account.accountAddresses?.length > 1 && <Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<Header as='h3'>Addresses</Header>
							<Divider hidden />
							<Table id='table' celled sortable textAlign='center' padded='very' striped>
								<Table.Header fullWidth className='tableHeader'>
									<Table.Row className="tableHeaders">
										{addressesColumns.map((column, index) => <Table.HeaderCell
											key={`${column}${index}`}>{column}
										</Table.HeaderCell>)}
									</Table.Row>
								</Table.Header>
								<Table.Body className="tableBody">
									{account.accountAddresses?.map((accountAddress) => {
										return (
											<Table.Row key={`${accountAddress.accountAddressId}$`} >
												<Table.Cell width={2}>{accountAddress.address}</Table.Cell>
												<Table.Cell width={2}>{accountAddress.token}</Table.Cell>
												<Table.Cell width={2}>{accountAddress.network}</Table.Cell>
												<Table.Cell width={2}>{accountAddress.label}</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						</Segment>
					</Grid.Column>}

					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Client ID</List.Header>
										<List.Description>{account.clientId}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Client email</List.Header>
										<List.Description>{account.email}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								{account.accountAddresses?.length === 1 && <>
									{account.accountAddresses[0].address && <>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Address</List.Header>
												<List.Description>{account.accountAddresses[0].address}</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</>}
									{account.accountAddresses[0].token && <>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Token</List.Header>
												<List.Description>{account.accountAddresses[0].token}</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</>}
									{account.accountAddresses[0].network && <>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Network</List.Header>
												<List.Description>{account.accountAddresses[0].network}</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</>}
									{account.accountAddresses[0].label && <>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Label</List.Header>
												<List.Description>{account.accountAddresses[0].label}</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</>}
								</>}
								{account.type === 'BANK' && <>
									{account.recipient && <>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Recipient</List.Header>
												<List.Description>{account.recipient}</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</>}
									{account.iban && <>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">IBAN</List.Header>
												<List.Description>{account.iban}</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</>}
									{account.bankName && <>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Bank Name</List.Header>
												<List.Description>{account.bankName}</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</>}
									{account.bankSwift && <>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Bank Swift</List.Header>
												<List.Description>{account.bankSwift}</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</>}
								</>}
							</List>
						</Segment>
					</Grid.Column>

				</Grid>
			</Segment>
			{account.type === 'VAULT' && <Segment>
				<Header as='h2'>Account Payment Plan</Header>
				<AccountPaymentPlan accountId={account.id} />
			</Segment>}
			<Segment>
				<Header as='h2'>Transactions</Header>
				<Transactions accountId={account.id} />
			</Segment>
		</Container>
	);
};

export default AccountDetails;
