import { PaginateParams } from '@/helpers/globalTypes';
import { useLocation } from 'react-router-dom';


const usePaginationUrlState = (initPagination: PaginateParams) => {

	const location = useLocation();

	const search = new URLSearchParams(location.search);
	const searchTake = parseInt(search.get('take') ?? '0');
	const searchSkip = parseInt(search.get('skip') ?? '0');
	const searchOrderBy = search.get('orderBy');
	const searchSort = search.get('sort');
	const searchFilter = search.get('filter');

	const safeSearchTake = [5, 10, 25, 50].includes(searchTake) ? searchTake : initPagination.take;
	const urlPaginateParams: PaginateParams = {
		filter: searchFilter ?? initPagination.filter,
		take: safeSearchTake as 5 | 10 | 25 | 50,
		skip: searchSkip === 0 ? initPagination.skip : searchSkip,
		orderBy: searchOrderBy ?? initPagination.orderBy,
		sort: searchSort as 'DESC' | 'ASC' ?? initPagination.sort
	};

	return { urlPaginateParams, searchTake, searchSkip, searchOrderBy, searchSort };

};

export default usePaginationUrlState;
