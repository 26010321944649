/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import NumberFormat from 'react-number-format';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { getVaultPendingRequests, tooglePendingRequest } from '@/features/vaultPendingRequests/slice';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { formatDateWithPattern } from '@/helpers/date';

interface Props {
	writePermission: boolean,
}

const PendingWithdrawalRequests = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.vaultPendingRequests
	);

	const paginateInitialState: PaginateParams = { orderBy: 'ccy', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const filter: FilterParam[] = [
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'ccy',
			label: 'Currency',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'clientId',
			name: 'Client ID',
			position: 1,
			sortable: true
		},
		{
			field: 'vaultName',
			name: 'Vault Name',
			position: 2,
			sortable: true
		},
		{
			field: 'vaultHolding',
			name: 'Holding',
			position: 3,
			sortable: true
		},
		{
			field: 'interestRate',
			name: 'Interest rate',
			position: 4,
			sortable: true
		},
		{
			field: 'depositPeriod',
			name: 'Deposit period',
			position: 5,
			sortable: true
		},
		{
			field: 'createdAt',
			name: 'Created at',
			position: 6,
			sortable: true
		},
		{
			field: 'dateOfDepositReturn',
			name: 'Deposit return date',
			position: 7,
			sortable: true
		},
		{
			field: 'autoExtendOffRequestTime',
			name: 'Request time',
			position: 8,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 9,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getVaultPendingRequests(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);


	const toogle = async (off: boolean, vaultId: number) => {
		try {
			await tooglePendingRequest(off, vaultId);
			fetchData();
			showSuccessNotification(`Pending request are ${off ? 'approved' : 'rejected'}`);
		} catch (err) {
			showErrorNotification(err);
		}
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.accountId}${index}`} >
						<Table.Cell width={2}>{entry.clientId}</Table.Cell>
						<Table.Cell width={2}>{entry.vaultName}</Table.Cell>
						<Table.Cell width={1}>
							<NumberFormat prefix={`${entry.ccy} `} displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.vaultHolding} />
						</Table.Cell>
						<Table.Cell width={1}>
							<NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.interestRate} suffix={'%'} />
						</Table.Cell>
						<Table.Cell width={1}>
							<NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.depositPeriod * 30} suffix=' days' />
						</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.createdAt, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={1}>{formatDateWithPattern(entry.dateOfDepositReturn, 'dd/MM/yyyy')}</Table.Cell>
						<Table.Cell width={1}>{entry.autoExtendOffRequestTime ? formatDateWithPattern(entry.autoExtendOffRequestTime, 'dd/MM/yyyy HH:mm:ss') : ''}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<ComponentWithConfirmation onConfirm={() => toogle(true, entry.vaultId)} confirmContent={'Are you sure you want approve vault pending request?'}>
								<Button color='green' disabled={!writePermission} content='Approve' />
							</ComponentWithConfirmation>
							<ComponentWithConfirmation onConfirm={() => toogle(false, entry.vaultId)} confirmContent={'Are you sure you want reject vault pending request?'}>
								<Button color='red' disabled={!writePermission} content='Reject' />
							</ComponentWithConfirmation>
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={9}>No data yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Grid.Row columns={2}>
					<Grid.Column><Header as='h2'>Vaults - Pending requests</Header></Grid.Column>
				</Grid.Row>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default PendingWithdrawalRequests;
