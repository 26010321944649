import React, { useEffect, useState } from 'react';
import { Dimmer, Divider, Grid, Icon, Loader, PaginationProps, Popup, Select, Table } from 'semantic-ui-react';
import TableFilters, { FilterParam } from './TableFilters';
import TablePagination, { PaginatorParams } from './TablePagination';
import './Table.css';
import { PaginateParams } from '@/helpers/globalTypes';
import { useHistory } from 'react-router-dom';
import usePaginationUrlState from './usePaginationUrlState';
import _ from 'lodash';

export enum SortDirection {
	asc = 'ascending',
	desc = 'descending'
}

export interface Headers {
	field: string,
	name: string | JSX.Element,
	position: number,
	sortable: boolean
}

export const transformFilterToObject = (filter: string) => {
	if (filter) {
		return filter.split(';').reduce(
			(obj, str) => {
				const parts = str.split('=');
				return { ...obj, [parts[0]]: parts[1] };
			}, {});
	} else {
		return {};
	}
};

export const getDefaultFilterUpdates = (defaultFilter: string, newFilter: string) => {

	let filter: string;
	if (defaultFilter !== null && defaultFilter !== '') {
		const defaultFilterKeys = Object.keys(transformFilterToObject(defaultFilter)) || [];
		const defaultFilterObj = transformFilterToObject(defaultFilter);
		const newFilterObj = transformFilterToObject(newFilter);
		const newFilterKeys = Object.keys(newFilterObj) || [];
		// if  default filter includes any of new keys, update default filter
		const updateDefaults = defaultFilterKeys.some(p => {
			return newFilterKeys.includes(p);
		});
		if (updateDefaults) {
			filter = newFilterKeys.map(p => `${p}=${newFilterKeys.includes(p) ? newFilterObj[p] : defaultFilterObj[p]}`).join(';');
		}
		else {
			//add new filter key to default filter
			filter = defaultFilter + ';' + newFilter;
		}

	}
	else {
		filter = newFilter;
	}

	return filter;

};

export const getPaginationToUrl = (pagination: PaginateParams, url: string) => {
	return `${url}?filter=${encodeURIComponent(pagination.filter)}&take=${pagination.take}&skip=${pagination.skip.toString()}&orderBy=${pagination.orderBy}&sort=${pagination.sort}`;
};


type Props = {
	fetchData?: () => void,
	setPagination: (pagination: PaginateParams) => void,
	pagination: PaginateParams,
	tableBody: JSX.Element,
	filter?: FilterParam[],
	paginateInitialState: PaginateParams,
	totalPages: number,
	loading: boolean,
	header?: Headers[],
	hideItemsPerPage?: boolean,
	hideRefresh?: boolean,
	urlPagination?: boolean,
	hideFooter?: boolean,
	contentAfterFilter?: JSX.Element,
}


const CustomTable = ({ setPagination, tableBody, filter, paginateInitialState, totalPages, loading, header, pagination, hideItemsPerPage = false, hideRefresh = false, urlPagination = false, hideFooter = false ,
	contentAfterFilter}: Props): React.ReactElement => {

	const history = useHistory();
	const { urlPaginateParams, searchSort, searchSkip, searchOrderBy, searchTake } = usePaginationUrlState(paginateInitialState);

	const searchPaginateInitialState = urlPagination ? urlPaginateParams : paginateInitialState; 

	const [paginator, setPaginator] = useState<PaginatorParams>({ activePage: 1 });
	const [sorted, setSorted] = useState<any>(
		{
			column: searchPaginateInitialState.orderBy,
			direction: searchPaginateInitialState.sort === 'ASC' ? SortDirection.asc : SortDirection.desc
		});

	//handles go back
	useEffect(() => {
		if (urlPagination) {
			setPaginator({ activePage: (searchSkip + 1) });
			setSorted({ column: searchOrderBy, direction: searchSort === 'ASC' ? SortDirection.asc : SortDirection.desc });
			if (!_.isEqual(urlPaginateParams, pagination)) {
				setPagination(urlPaginateParams);
			}
		}
	}, [searchSkip, searchOrderBy, searchSort, searchTake]);


	const updatePagination = (pagination: PaginateParams) => {
		if (urlPagination) {
			getPaginationToUrl(pagination, location.pathname);
			history.push(getPaginationToUrl(pagination, location.pathname));
		}
		setPagination(pagination);
	};

	const handlePaginationChange = (e, { activePage }: PaginationProps) => {
		setPaginator({ activePage: +activePage });
		const paginationState = { ...pagination };
		paginationState.skip = +activePage - 1;
		updatePagination(paginationState);
	};


	const handleFilter = (value, clear) => {
		if (clear) {
			const paginationState = { ...paginateInitialState };
			paginationState.take = pagination.take;
			paginationState.filter = '';
			updatePagination(paginationState);
			setSorted({
				column: paginateInitialState.orderBy,
				direction: paginateInitialState.sort === 'ASC' ? SortDirection.asc : SortDirection.desc
			});
			setPaginator({ activePage: 1 });
			return;
		}
		if (value !== null) {
			const paginationState = { ...pagination };
			const filter = getDefaultFilterUpdates(paginateInitialState.filter, value);
			paginationState.filter = filter;
			paginationState.skip = 0; 
			updatePagination(paginationState);
		}
	};

	const headerElement = (index) => {
		const headerObj = header.find(element => element.position == index + 1);
		if (headerObj.sortable) {
			return (<Table.HeaderCell
				key={headerObj.name + index}
				sorted={headerObj.field === sorted.column ? sorted.direction : undefined}
				onClick={() => handleSort(headerObj.field)}
				className='sortable'
			>{headerObj.name}</Table.HeaderCell>);
		} else {
			return (<Table.HeaderCell
				key={headerObj.name + index}
			>{headerObj.name}</Table.HeaderCell>);
		}

	};

	const handleSort = (column) => {
		const direction = sorted.column !== column ? SortDirection.asc
			: sorted.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
		setSorted({ column, direction });
		const paginationState = { ...pagination };
		paginationState.orderBy = column;
		paginationState.sort = direction === SortDirection.asc ? 'ASC' : 'DESC';
		updatePagination(paginationState);
	};

	const handleItemsPerPage = (val) => {
		const paginationState = { ...pagination };
		paginationState.skip = 0;
		paginationState.take = val;
		setPaginator({ activePage: 1 });
		updatePagination(paginationState);
	};

	const refresh = () => {
		const paginationState = { ...pagination };
		updatePagination(paginationState);
	};

	const itemPerPageOptions = [
		{
			key: '5',
			text: '5',
			value: 5
		},
		{
			key: '10',
			text: '10',
			value: 10
		},
		{
			key: '25',
			text: '25',
			value: 25,
		},
		{
			key: '50',
			text: '50',
			value: 50,
		},
		{
			key: '100',
			text: '100',
			value: 100,
		}
	];

	const itemsPerPage = parseInt(pagination.take.toString());

	return (
		<div id='table-layout' style={{ marginBottom: hideItemsPerPage ? '0' : '10rem' }}>
			<Dimmer active={loading} inverted>
				<Loader />
			</Dimmer>
			<Divider hidden />
			<Grid>
				<Grid.Row>
					<Grid.Column width={15}>
						{filter && <div>
							<TableFilters filter={filter} handleFilterChange={handleFilter} activeFilter={pagination.filter} />
						</div>}
					</Grid.Column>
					<Grid.Column width={1}>
						{!hideRefresh && <Popup
							trigger={<Icon size='large' className='sync' style={{ float: 'right', cursor: 'pointer' }} onClick={refresh} />}
							content={'Refresh table'}
							position='top center'
							wide='very'
						/>}
						{/* <Icon size='large' className='sync' style={{ float: 'right', cursor: 'pointer' }} onClick={refresh} /> */}
					</Grid.Column>
					<Divider hidden />
				</Grid.Row>
			</Grid> 
           {contentAfterFilter}
			<div id='table'>
				<Table celled sortable textAlign='center' padded='very' striped>
					{header &&
						<Table.Header fullWidth className='tableHeader'>
							<Table.Row className="tableHeaders">
								{header.map((entry, index) => headerElement(index))}
							</Table.Row>
						</Table.Header>}
					{tableBody}
					{hideFooter === false &&
						<Table.Footer>
							<Table.Row>
								<Table.HeaderCell textAlign='left' colSpan={header?.length}>
									<Grid>
										<Grid.Row columns={3}>
											<Grid.Column verticalAlign='middle'>
												<div className='totalPages'>Total pages: <strong>{totalPages}</strong></div>
											</Grid.Column>
											<Grid.Column verticalAlign='middle' floated='right' className='pagination-column' >
												{!hideItemsPerPage && <div className='totalPages' style={{ float: 'right' }}>
													Items per page:
													<Select
														className='itemsPerPage'
														compact
														value={itemsPerPage}
														options={itemPerPageOptions}
														onChange={(e, data) => handleItemsPerPage(data.value)}
													/>
												</div>}
											</Grid.Column>
											<Grid.Column floated='right' className='pagination-column'>
												{totalPages > 1 && <TablePagination
													totalPages={totalPages}
													handlePaginationChange={handlePaginationChange}
													paginator={paginator}
												/>}
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>}
				</Table>
			</div>
		</div>
	);
};


export default CustomTable;
