import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';


interface Props {
	open: boolean;
	onClose: (amount?: number, details?: string, withdrawal?: boolean) => void,
	withdrawal: boolean
}

const UpdateBalance = ({ open, onClose, withdrawal }: Props): React.ReactElement => {

	const initialValues = {
		amount: '',
		details: ''
	};

	const validationSchema = Yup.object({
		amount: Yup.number()
			.required('You must enter an amount'),
		details: Yup.string()
			.required('You must enter a details')

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { amount, details } = formData;
		onClose(amount, details, withdrawal);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content={withdrawal ? 'Withdraw balance' : 'Deposit balance'} />

						<Input
							label='Amount'
							fluid
							name="amount"
						/>
						<Input
							label='Details'
							fluid
							name="details"
						/>
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										{withdrawal ? 'Withdraw' : 'Deposit'}
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default UpdateBalance;
