/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dimmer, Header, Loader, Segment } from 'semantic-ui-react';
import { showErrorNotification } from '@/features/swal/slice';
import '../Clients/ClientDetails/Profile.css';
import { getVault } from '@features/fireblocks/slice';
import './Assets.css';
import NumberFormat from 'react-number-format';
import { Vault } from '@features/fireblocks/types';
import ETHGasStationLogo from '@/assets/images/ETH_GAS_STATION.png';
import './Withdrawal.css';

interface Props {
	filterByCurrency?: (ccy: string) => void,
}

const Withdrawal = ({ filterByCurrency }: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [vault, setVault] = useState<Vault>(null);

	const [gasStationBalance, setGasStationBalance] = useState<string>('');

	const fetchData = useCallback(() => {
		const get = async () => {
			setLoading(true);
			try {
				const data = await getVault('WITHDRAWAL');
				setVault(data);

				if (data?.assets?.length>0) {
					const balance = data.assets.filter(asset => asset.gasStationBalance)[0] || null;
					if (balance?.gasStationBalance) {
						setGasStationBalance(balance.gasStationBalance);
					}
				}
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setLoading(false);
			}
		};
		get();
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData, dispatch]);

	return (
		<div>
			<Segment>
				<Dimmer active={loading} inverted>
					<Loader />
				</Dimmer>
				{!loading && vault && <div>
					<Header as='h2'>{vault.name.replaceAll('-', ' ')}</Header>
					<div className='assets'>
						{vault.assets?.map((entry, index) => {
							return (
								<div className='assets__element' key={index}>
									<div className="ui mini statistic" onClick={() => { filterByCurrency ? filterByCurrency(entry.id) : null; }} >
										<div className="value">
											<NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.total} /></div>
										<div className="label">{entry.id}</div>
									</div>
								</div>
							);
						})}
						{gasStationBalance && <div className="ui mini statistic">
							<div className="value">
								<img className='ETH-gas-station-logo' src={ETHGasStationLogo} alt="ETH gas station logo" />
							</div>
							<div className="label">
								<NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={gasStationBalance} />
							</div>
						</div>}
						{vault.assets.length === 0 && <div>You dont have documents yet.</div>}
					</div>
				</div>}
			</Segment>
		</div>
	);
};

export default Withdrawal;
