import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getAccounts } from '@/features/accounts/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Segment, Table, Checkbox } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import NumberFormat from 'react-number-format';
import { DocumentState } from '../Reconcile';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	writePermission: boolean,
	documents: DocumentState,
	changeDocumentState: (id: number, event: any, data: any) => void
}

const CustomerAccount = ({ writePermission, documents, changeDocumentState }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const type = 'BANK';
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.accounts
	);

	const newList = list.map(el => ({ ...el, reconciled: false }));

	const paginateInitialState: PaginateParams = { orderBy: 'balance', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const filter: FilterParam[] = [
		{
			field: 'walletId',
			label: 'Wallet ID',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'reconcile',
			name: 'Reconcile',
			position: 1,
			sortable: false
		},
		{
			field: 'account',
			name: <div>Account<br /> Customer </div>,
			position: 2,
			sortable: true
		},
		{
			field: 'document',
			name: <div>Document Nr<br /> Document date <br /> Curr  <br /> Amount</div>,
			position: 3,
			sortable: true
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 4,
			sortable: true
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getAccounts(type, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch, type]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, type, fetchData]);


	const tableBody = (
		<Table.Body className="tableBody">
			{newList.map((entry) => {
				return (
					<Table.Row key={`${entry.walletId}$`} >
						<Table.Cell width={1} className='editable-cell'>
							<Checkbox name={`reconciled-${entry.id}`}
								checked={documents[entry.id]?.reconciled ?? entry?.reconciled ?? false}
								onChange={(e, data) => changeDocumentState(entry.id, e, data)}
								disabled={!writePermission}
							/>
						</Table.Cell>
						<Table.Cell width={1}>{entry.walletId}</Table.Cell>
						<Table.Cell width={2}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balance} />
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balanceFrozen} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>You dont have accounts yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;

	/* 	const onSave = (formData, setSubmitting) => {
			const fieldArray = _.entries(formData);
			const objectGroups = _.groupBy(fieldArray, item => (item[0].split('-').reverse()[0]));
			const objectWithId = _.map(objectGroups, (item, idx) => { return { ...Object.fromEntries(item), ['id']: idx }; });
			const fixedKeys = objectWithId.map(obj => _.mapKeys(obj, (value, key) => key.toString().split('-')[0]));
			console.log(fixedKeys);
			setSubmitting(false);
		}; 
	
		const initialValues = list.reduce((obj, item) => {
			return {
				...obj
				, ['reconciled-' + item.id.toString()]: false
				, ['reconciledAmount-' + item.id.toString()]: item.balance ?? '',
			};
		}, {});
	
		const validationSchema = Yup.object();
	*/
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>{capitalizeFirstLetter(type)} Accounts</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container >
	);
};


export default CustomerAccount;
