/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { Confirm, Popup } from 'semantic-ui-react';

interface Props {
	children?: any,
	onConfirm: () => void,
	confirmContent: string,
	popup?: string,
	disabled?: boolean,
}

const ComponentWithConfirmation = ({ children, onConfirm, confirmContent, popup, disabled }: Props): React.ReactElement => {
	const [open, setOpen] = useState<boolean>(false);


	return (
		<>
			{popup && <Popup content={popup} trigger={React.cloneElement(children, { onClick: () => !disabled ? setOpen(true) : null })} />}
			{!popup && React.cloneElement(children, { onClick: () => !disabled ? setOpen(true) : null })}
			<Confirm
				open={open}
				onCancel={() => setOpen(false)}
				onConfirm={() => { onConfirm(); setOpen(false); }}
				content={confirmContent}
				size='mini'
				cancelButton='No'
				confirmButton='Yes'
			/>
		</>

	);
};

export default ComponentWithConfirmation;
