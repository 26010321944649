import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { Card, CardsStore } from './types';
import { showErrorNotification } from '../swal/slice';

const initialState: CardsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'cards',
	initialState,
	reducers: {
		setCards(state, action: PayloadAction<Array<Card>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setCards, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getCards = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/cards/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setCards(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getCard = async (cardId: number) => {
	const response = await request.get(`/api/admin/cards/${cardId}`);
	const { data } = response;
	return data;
};

export const blockCard = async (cardId: number, accountToId: number, userId: number) => {
	const response = await request.post(`/api/admin/cards/block/${cardId}`, { accountToId, userId });
	const { data } = response;
	return data;
};

export const pullOutCard = async (cardId: number, accountToId: number, accountFromId: number, amount: number, userId: number) => {
	const response = await request.post(`/api/admin/cards/pullout/${cardId}`, { accountToId, userId, amount, accountFromId });
	const { data } = response;
	return data;
};

export const topUpAndCloseCard = async (cardId: number, sourceWalletId: string) => {
	const response = await request.post(`/api/admin/cards/topup-and-close/${cardId}`, { sourceWalletId });
	const { data } = response;
	return data;
};

export const topUpCard = async (cardId: number, sourceWalletId: string, amount: number) => {
	const response = await request.post('/api/admin/cards/top-up/', { cardId, sourceWalletId, amount });
	const { data } = response;
	return data;
};


export default slice.reducer;
