import { PaginateParams } from '@/helpers/globalTypes';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatementTransactions, setStatementTransactions } from '@/features/statements/statementTransactionsSlice';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import useBankLov from '@/helpers/customHook/useBankLov';
import { getBankAccountList } from '@/features/statements/statementsSlice';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { Button, Container, Divider, Grid, Header, Segment, Table } from 'semantic-ui-react';
import { formatDateWithPattern } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import { RootState } from '@/rootReducer';
import { useHistory } from 'react-router-dom';

interface Props {
	match: {
		params: {
			batchId: number,
		}
	}
}

const StatementTransactions = (props: Props): React.ReactElement => {

	const [batchId] = useState<number>(props.match.params.batchId);

	const dispatch = useDispatch();
	const history = useHistory();

	const { list, totalPages, error, loading } = useSelector((state: RootState) => state.statementTransactions);

	const paginateInitialState: PaginateParams = { orderBy: 'id', sort: 'DESC', skip: 0, filter: batchId ? `stmBatchId=${batchId}` : '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const { bankLov } = useBankLov();
	const [bankAccountLov, setBankAccountLov] = useState<any>();

	useEffect(() => {
		const fetch = async () => {
			const list = await getBankAccountList({ orderBy: 'treeAccountPath', skip: 0, sort: 'ASC', filter: 'accountType=ROOT_ACCOUNT', take: 100 });
			const result = list.map((p) => { return { key: p.bankAccountIdLov.toString(), text: p.description, value: p.bankAccountIdLov.toString() }; });
			setBankAccountLov(result);
		};
		fetch();
	}, []);


	useEffect(() => {
		if (pagination.filter !== '') {
			dispatch(getStatementTransactions(pagination));
		}
		else {
			dispatch(setStatementTransactions([]));
		}
	}, [batchId, pagination, dispatch]);

	const filter: FilterParam[] = [
		{
			field: 'bankId',
			label: 'Bank (mandatory)',
			type: 'select',
			options: bankLov
		},
		{
			field: 'rootBankAccountId',
			label: 'Bank Account / Ledger',
			type: 'select',
			options: bankAccountLov
		},
		{
			field: 'stmBatchId',
			label: 'Batch Id',
			type: 'input',
			required: true
		},
		{
			field: 'transactionDate',
			label: 'Date',
			type: 'input',
			inputType: 'date'
		},
		{
			field: 'currencyCode',
			label: 'Currency',
			type: 'input',
		},
		{
			field: 'customerName',
			label: 'Client',
			type: 'input'
		},
		{
			field: 'cpName',
			label: 'Beneficiary/Remitter Name',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'description',
			name: <div>Description <br /> Reconciliation</div>,
			position: 1,
			sortable: true
		},
		{
			field: 'wlpId',
			name: 'WLP',
			position: 2,
			sortable: true
		},
		{
			field: 'walletId',
			name: 'Wallet',
			position: 3,
			sortable: true
		},
		{
			field: 'transactionDate',
			name: <div>Date <br /> Order Number</div>,
			position: 4,
			sortable: true
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 5,
			sortable: false
		},
		{
			field: 'feeAmount',
			name: 'Fee Amount',
			position: 6,
			sortable: false
		},
		{
			field: 'customerName',
			name: 'Client',
			position: 7,
			sortable: true
		},
		{
			field: 'cpName',
			name: 'Beneficiary/Remitter',
			position: 8,
			sortable: true
		},
		{
			field: 'paymentDetails',
			name: 'Details',
			position: 9,
			sortable: true
		}
	];


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, idx) => {
				return (
					<Table.Row key={`${entry.id}-${idx}$`} >
						<Table.Cell width={1}>{entry.description}<br /> {entry.reconciliationStatus}</Table.Cell>
						<Table.Cell width={1}>{entry.wlpId}</Table.Cell>
						<Table.Cell width={1}>{entry.walletId}</Table.Cell>
						<Table.Cell width={2}>{
							formatDateWithPattern(entry.transactionDate, 'dd/MM/yyyy HH:mm:ss')}
							<br /> {entry.orderNumber}
						</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								prefix={`${entry.currencyCode} `}
								value={entry.amount} />
						</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={entry.feeAmount} />
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.customerName}
							<br /> {entry.customerIban}
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.cpName}
							<br /> {entry.cpIban}
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.paymentDetails}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>No transactions yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);


	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				{batchId &&
					<Grid>
						<Grid.Row columns={2}>
							<Grid.Column><Header as='h2'>Transations</Header></Grid.Column>
							<Grid.Column floated='right'><Button floated='right' primary onClick={() => history.goBack()}>Go back</Button></Grid.Column>
						</Grid.Row>
					</Grid>}
				<Divider hidden />
				<CustomTable
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};

export default StatementTransactions;
