import { PermissionType } from '@/features/user/types';
import { checkPermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Divider, Icon, Menu, Sidebar } from 'semantic-ui-react';
import './Navbar.css';

interface Props {
	sideBarVisible: boolean;
}

const VerticalNavbar = ({ sideBarVisible }: Props): React.ReactElement => {

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const { whitelabel } = useSelector(
		(state: RootState) => state.whitelabel
	);

	return (
		<Sidebar
			as={Menu}
			visible={sideBarVisible}
			width='wide'
			direction='left'
			vertical
			animation='push'
			className="vertical-menu"
		>
			{/* 	<Menu fluid vertical className="vertical-menu"> */}
			<Menu.Item
				header
				name='Dashboard'
				as={NavLink}
				to="/dashboard"
				icon="chevron right"
			/>
			{checkPermission(permissions, PermissionType.USERS) && <Menu.Item
				header
				className='subMenuHeader'>
				Clients
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='private clients'
					as={NavLink}
					to="/clients/private"
				/>
				<Menu.Item
					className='submenu'
					name='business clients'
					as={NavLink}
					to="/clients/business"
				/>
				<Menu.Item
					className='submenu'
					name='freelancers'
					as={NavLink}
					to="/clients/freelancer"
				/>
				<Menu.Item
					className='submenu'
					name='system users'
					as={NavLink}
					to="/clients/system"
				/>
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.ACCOUNTS) && <Menu.Item
				header
				className='subMenuHeader'>
				Accounts
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='bank accounts'
					as={NavLink}
					to="/accounts/bank"
				/>
				<Menu.Item
					className='submenu'
					name='wallet accounts'
					as={NavLink}
					to="/accounts/wallet"
				/>
				<Menu.Item
					className='submenu'
					name='crypto accounts'
					as={NavLink}
					to="/accounts/crypto"
				/>
				<Menu.Item
					className='submenu'
					name='trust accounts'
					as={NavLink}
					to="/accounts/trust"
				/>
				<Menu.Item
					className='submenu'
					name='system accounts'
					as={NavLink}
					to="/accounts/system"
				/>
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.CARDS) && <Menu.Item
				header
				name='cards'
				as={NavLink}
				to="/cards/all"
				icon="chevron right"
			/>}
			{(checkPermission(permissions, PermissionType.BANK_DOCUMENTS) || checkPermission(permissions, PermissionType.CARD_DOCUMENTS) || checkPermission(permissions, PermissionType.TRUST_DOCUMENTS) || checkPermission(permissions, PermissionType.CRYPTO_DOCUMENTS)) && <Menu.Item
				header
				className='subMenuHeader'>
				Documents
				<Icon name='chevron down' />
				{checkPermission(permissions, PermissionType.BANK_DOCUMENTS) && <Menu.Item
					className='submenu'
					name='bank documents'
					as={NavLink}
					to="/documents/bank"
				/>}
				{checkPermission(permissions, PermissionType.BANK_DOCUMENTS) && <Menu.Item
					className='submenu'
					name='wallet documents'
					as={NavLink}
					to="/documents/wallet"
				/>}
				{checkPermission(permissions, PermissionType.CARD_DOCUMENTS) && <Menu.Item
					className='submenu'
					name='card documents'
					as={NavLink}
					to="/documents/card"
				/>}
				{checkPermission(permissions, PermissionType.TRUST_DOCUMENTS) && <Menu.Item
					className='submenu'
					name='trust documents'
					as={NavLink}
					to="/documents/trust"
				/>}
				{checkPermission(permissions, PermissionType.CRYPTO_DOCUMENTS) && <Menu.Item
					className='submenu'
					name='crypto documents'
					as={NavLink}
					to="/documents/crypto"
				/>}
				{checkPermission(permissions, PermissionType.TOKENS) && <Menu.Item
					className='submenu'
					name='tokens documents'
					as={NavLink}
					to="/documents/token"
				/>}
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.USERS) && <Menu.Item
				header
				name='user groups'
				as={NavLink}
				to="/user-groups"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.TRUST) && <Menu.Item
				header
				name='trust payments'
				as={NavLink}
				to="/trust/payments"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.UNASSIGNED_PAYMENTS) && <Menu.Item
				header
				className='subMenuHeader'>
				Unassigned payments
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='files unassigned payments'
					as={NavLink}
					to="/files/unassigned-payments"
				/>
				<Menu.Item
					className='submenu'
					name='integrations unassigned payments'
					as={NavLink}
					to="/integrations/unassigned-payments"
				/>
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.REPORTS) && <Menu.Item
				header
				className='subMenuHeader'>
				Reports
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='AML Large Transactions'
					as={NavLink}
					to="/reports/aml"
				/>
				<Menu.Item
					className='submenu'
					name='All Documents'
					as={NavLink}
					to="/reports/system-documents"
				/>
				<Menu.Item
					className='submenu'
					name='IT Vasp OAM Transaction Reports'
					as={NavLink}
					to="/reports/aml-vasp"
				/>
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.RECONCILIATIONS) && <Menu.Item
				header
				className='subMenuHeader'>
				Finance Admin
				<Icon name='chevron down' />
				{/* <Menu.Item
					className='submenu first'
					name='reconciliations'
					as={NavLink}
					to="/reconciliations"
				/> */}
				<Menu.Item
					className='submenu first'
					name='bank statement batches'
					as={NavLink}
					to="/statements"
				/>
				<Menu.Item
					className='submenu'
					name='bank statement transactions'
					as={NavLink}
					to="/statements-transactions"
				/>
				<Menu.Item
					className='submenu'
					name='Account balances'
					as={NavLink}
					to="/statement-balancies"
				/>
				<Menu.Item
					className='submenu'
					name='All Documents'
					as={NavLink}
					to="/system-documents"
				/>
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.RECONCILIATIONS) && <Menu.Item
				header
				className='subMenuHeader'>
				Payments
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='configuration'
					as={NavLink}
					to="/payment/config"
				/>
				<Menu.Item
					className='submenu'
					name='system account summary'
					as={NavLink}
					to="/payment/accounts"
				/>
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.SUPPORT) && <Menu.Item
				header
				name='support management'
				as={NavLink}
				to="/support-management"
				icon="chevron right"
			/>}

			{checkPermission(permissions, PermissionType.POA_COMPLIANCE) && <Menu.Item
				header
				className='subMenuHeader'>
				Compliance
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu'
					name='external compliance'
					as={NavLink}
					to="/compliance"
				/>
				<Menu.Item
					className='submenu'
					name='internal compliance'
					as={NavLink}
					to="/poa"
				/>
				<Menu.Item
					className='submenu'
					name='KYC issues'
					as={NavLink}
					to="/kyc-issues"
				/>
				<Menu.Item
					className='submenu'
					name='KYC calendar'
					as={NavLink}
					to="/kyc-calendar"
				/>
				{whitelabel === 'DTSUS' && <Menu.Item
					className='submenu'
					name='Tax form'
					as={NavLink}
					to="/tax-form"
				/>}
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.PROCESSES) && <Menu.Item
				header
				name='Processes'
				as={NavLink}
				to="/processes"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.FILE_UPLOAD) && <Menu.Item
				header
				name='file uploads'
				as={NavLink}
				to="/file-uploads"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.SYSTEM_ERRORS) && <Menu.Item
				header
				name='activity errors'
				as={NavLink}
				to="/system-errors"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.FAQ) && <Menu.Item
				header
				name='FAQ'
				as={NavLink}
				to="/faq"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.LANGUAGES) && <Menu.Item
				header
				name='languages'
				as={NavLink}
				to="/languages"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.ROLES) && <Menu.Item
				header
				name='roles'
				as={NavLink}
				to="/roles"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.SYSTEM_ADMINS) && <Menu.Item
				header
				name='admins for console'
				as={NavLink}
				to="/admins"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.ACTIVITY_LOGS) && <Menu.Item
				header
				className='subMenuHeader'>
				Activity Logs
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='user activity logs'
					as={NavLink}
					to="/activity-logs/users"
				/>
				<Menu.Item
					className='submenu'
					name='accounts activity logs'
					as={NavLink}
					to="/activity-logs/accounts"
				/>
				<Menu.Item
					className='submenu'
					name='transactions activity logs'
					as={NavLink}
					to="/activity-logs/transactions"
				/>
				<Menu.Item
					className='submenu'
					name='other activity logs'
					as={NavLink}
					to="/activity-logs/other"
				/>
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.TOKENS) && <Menu.Item
				header
				className='subMenuHeader'>
				Tokens
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='all tokens'
					as={NavLink}
					to="/tokens/all"
				/>
				<Menu.Item
					className='submenu'
					name='Accounts'
					as={NavLink}
					to="/tokens/accounts"
				/>
				<Menu.Item
					className='submenu'
					name='token requests'
					as={NavLink}
					to="/tokens/requests"
				/>
				<Menu.Item
					className='submenu'
					name='token trades'
					as={NavLink}
					to="/tokens/trades"
				/>
				<Menu.Item
					className='submenu'
					name='token withdrawals'
					as={NavLink}
					to="/tokens/withdrawals"
				/>
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.VAULTS) && <Menu.Item
				header
				className='subMenuHeader'>
				Vaults
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='vaults setup'
					as={NavLink}
					to="/vaults/all"
				/>
				<Menu.Item
					className='submenu'
					name='vaults'
					as={NavLink}
					to="/vaults/list"
				/>
				<Menu.Item
					className='submenu'
					name='Pending requests'
					as={NavLink}
					to="/vaults/pending-requests"
				/>
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.REFERRALS) && <Menu.Item
				header
				className='subMenuHeader'>
				Referrals
				<Icon name='chevron down' />
				<Menu.Item
					className='submenu first'
					name='programs'
					as={NavLink}
					to="/referrals/programs"
				/>
				<Menu.Item
					className='submenu'
					name='payments'
					as={NavLink}
					to="/referrals/payments"
				/>
				
			</Menu.Item>}
			{checkPermission(permissions, PermissionType.CURRENCY_EXCHANGE) && <Menu.Item
				header
				name='currency exchange'
				as={NavLink}
				to="/exchange"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.MAINTENANCE) && <Menu.Item
				header
				name='maintenance'
				as={NavLink}
				to="/maintenance"
				icon="chevron right"
			/>}
			{/* {checkPermission(permissions, PermissionType.USER_LEVEL) && <Menu.Item
				header
				name='user levels'
				as={NavLink}
				to="/user-levels"
				icon="chevron right"
			/>}
			{checkPermission(permissions, PermissionType.ACTION_CENTER) && <Menu.Item
				header
				name='action center'
				as={NavLink}
				to="/action-center"
				icon="chevron right"
			/>} */}
			<Divider hidden />
		</Sidebar>
	);
};

export default VerticalNavbar;
