import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Dropdown, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import NumberFormat from 'react-number-format';
import { getSystemAccountSummary } from '@/features/systemAccountDetails/summarySlice';
import { useHistory } from 'react-router-dom';
import PaymentModal from '@/components/Dashboard/Payments/PaymentModal/PaymentModal';
import { getPaymentConfig } from '@/features/payments/configSlice';
import { PaginateParams } from '@/helpers/globalTypes';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import './Payment.css';
import { SystemAccountSummaryType } from '@/features/systemAccountDetails/types';

interface Props {
	writePermission: boolean
}

const SystemAccountSummary = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const history = useHistory();
	const { list, totalPages, error, loading } = useSelector((state: RootState) => state.systemAccountSummary);

	const paginateInitialState: PaginateParams = { orderBy: 'accountId', skip: 0, sort: 'ASC', filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<SystemAccountSummaryType>();

	const filter: FilterParam[] = [
		{
			field: 'description',
			label: 'Description',
			type: 'input'
		},
		{
			field: 'walletId',
			label: 'Wallet ID',
			type: 'input'
		},
		{
			field: 'currencyCode',
			label: 'Currency',
			type: 'input'
		},
	];

	const headers: Array<Headers> = [

		{
			field: 'description',
			name: 'Description',
			position: 1,
			sortable: true
		},
		{
			field: 'walletId',
			name: 'Wallet ID',
			position: 2,
			sortable: true
		},
		{
			field: 'accountBalance',
			name: 'Balance',
			position: 3,
			sortable: false
		},
		{
			field: 'balanceOnHold',
			name: 'Balance On Hold',
			position: 4,
			sortable: false
		},
		{
			field: 'transactionSum',
			name: 'Control',
			position: 5,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 6,
			sortable: false
		},
	];

	useEffect(() => {
		dispatch(getSystemAccountSummary(pagination));
	}, [dispatch, pagination]);


	const handleOpenModal = async (entry: SystemAccountSummaryType) => {
		setSelectedItem(entry);
		setOpenModal(true);
	};

	useEffect(() => {
		dispatch(getPaymentConfig({ orderBy: 'paymentConfigId', skip: 0, sort: 'ASC', filter: '', take: null }));
	}, [dispatch]);

	const handleCloseModal = async () => {
		setOpenModal(false);
	};

	const today = new Date();

	let monthAgo = new Date();
	monthAgo = new Date(monthAgo.setMonth(monthAgo.getMonth() - 1));

	const actionAraay = [
		{
			key: 'Details',
			text: 'Details',
			value: 'Details',
		},
		{
			key: 'Transactions',
			text: 'Account Transactions',
			value: 'Transactions',
		},
		{
			key: 'History',
			text: 'Payment History',
			value: 'History',
		}
		,
		{
			key: 'Payment',
			text: 'Make Payment',
			value: 'Payment',
		}
	];

	const handleActionChange = (e, data, entry) => { 
 
		if (data.value === 'Details') {
			history.push(`account-details/${entry.bankAccountId}?filter=bankAccountId=${entry.bankAccountId};fromDate=${monthAgo.toISOString().slice(0, 10)};toDate=${today.toISOString().slice(0, 10)}`);
		}

		if (data.value === 'Transactions') {
			history.push(`/accounts/system?filter=walletId=${entry.walletId ?? ''}`);
		}

		if (data.value === 'History') {
			history.push(`history/${entry.bankAccountId}`);
		}

		if (data.value === 'Payment') {
			handleOpenModal(entry);
		}
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.bankAccountId}${index}`} >
						<Table.Cell width={2}>{entry.description} <br />{entry?.integration}</Table.Cell>
						<Table.Cell width={1}>{entry.walletId} <br /> {entry?.sysAcctType}</Table.Cell>
						<Table.Cell width={1} textAlign='right'>
							{`${entry?.currencyCode} `}
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={entry.accountBalance ?? 0} />
						</Table.Cell>
						<Table.Cell width={1}>
							{`${entry?.currencyCode} `}
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={entry.balanceOnHold ?? 0} />
						</Table.Cell>
						<Table.Cell width={1}>
							{`${entry?.currencyCode} `}
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={entry.transactionSum ?? 0} />
						</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Dropdown
								placeholder='Please select'
								fluid
								selection
								value={null}
								options={actionAraay}
								onChange={(e, data) => handleActionChange(e, data, entry)}
							/>
							{/* 	<Link to={`account-details/${entry.bankAccountId}?filter=bankAccountId=${entry.bankAccountId};fromDate=${monthAgo.toISOString().slice(0, 10)};toDate=${today.toISOString().slice(0, 10)}`}>
								Details</Link>
							<br />
							<Link to={`/accounts/system?filter=walletId=${entry.walletId ?? ''}`}>Account Transactions</Link>
							<br />
							<Link to={`history/${entry.bankAccountId}`} >Payment History</Link>
							<br />
							<a style={{ cursor: 'pointer' }} onClick={() => handleOpenModal(entry)}> Make Payment</a> */}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>No reconciliations yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid id='system-account-summary'>
			<Segment>
				<PaymentModal
					open={openModal}
					onClose={handleCloseModal}
					writePermission={writePermission}
					systemAccount={selectedItem} />
				<Header as='h2'>System Account Summary</Header>
				<Divider hidden />
				<CustomTable
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};

export default SystemAccountSummary;
