import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { deleteProcessCountry, putProcessCountry } from '@/features/processes/processesSlice';
import { Process } from '@/features/processes/processesTypes';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { alpha2ToAlpha3, alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon, Select, Table } from 'semantic-ui-react';

interface Props {
	writePermission: boolean,
	fetchData: () => void,
	process: Process
}

const ProcessCountries = ({ writePermission, fetchData, process }: Props): React.ReactElement => {

	const countries = getNames('en');
	const [countriesOptions, setCountriesOptions] = useState<any>(null);
	const [selectedCountry, setSelectedCountry] = useState<any>(null);

	useEffect(() => {
		if (!process) return;

		const options = [];
		Object.keys(countries)
			.map($code => {
				if (!process.countryCodes.includes(alpha2ToAlpha3($code))) {
					options.push({ key: alpha2ToAlpha3($code), text: countries[$code], value: alpha2ToAlpha3($code), flag: $code.toLowerCase() });
				}
			});
		setCountriesOptions(options);
	}, [process]);

	const deleteCountry = async (countryCode: string) => {
		try {
			await deleteProcessCountry(process.proc, countryCode);
			showSuccessNotification('Country removed from process');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const putCountry = async () => {
		try {
			await putProcessCountry(process.proc, selectedCountry);
			showSuccessNotification('Country added to process');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	return (
		<>
			<Grid>
				<Grid.Row>
					<Select
						label='Country'
						name="country"
						options={countriesOptions}
						onChange={(element, { value }) => setSelectedCountry(value)}
						search
						clearable
						selection
						style={{ marginLeft: '1rem' }}
					/>
					<Button
						primary
						content='Add country'
						onClick={putCountry}
						style={{ marginLeft: '1rem' }}
					/>
				</Grid.Row>
			</Grid>

			<Table id='table' celled sortable textAlign='center' padded='very' striped>
				<Table.Header fullWidth className='tableHeader'>
					<Table.Row className="tableHeaders">
						<Table.HeaderCell>Name</Table.HeaderCell>
						<Table.HeaderCell>Code</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body className="tableBody">
					{process.countryCodes.length > 0 && process.countryCodes.map((entry) => {
						return (
							<Table.Row key={`${entry}$`} >
								<Table.Cell width={12}>{countries[alpha3ToAlpha2(entry)]}</Table.Cell>
								<Table.Cell width={2}>{entry}</Table.Cell>
								<Table.Cell width={2}>
									<ComponentWithConfirmation popup='Delete process country' onConfirm={() => deleteCountry(entry)} confirmContent={'Are you sure you want to remove country?'}>
										<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="trash" />
									</ComponentWithConfirmation>
								</Table.Cell>
							</Table.Row>
						);
					})}
					{process.countryCodes.length === 0 && <Table.Row>
						<Table.Cell textAlign='center' colSpan={3}>Not countries added for this process.</Table.Cell>
					</Table.Row>}
				</Table.Body>
			</Table>
		</>
	);
};


export default ProcessCountries;
