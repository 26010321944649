import { showErrorNotification } from '@/features/swal/slice';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionContent, AccordionTitle, Button, Grid, Icon, Segment, Table } from 'semantic-ui-react';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { downloadAttachment, markAsInProgress, toogleDocument } from '@/features/poa/slice';
import { formatDateWithPattern } from '@/helpers/date';
import { Status, UserPOA } from '@/features/poa/types';
import { checkWritePermission } from '@/helpers/permissions';
import { PermissionType } from '@/features/user/types';
import { RootState } from '@/rootReducer';
import RejectReasonModal from '../Poa/RejectReasonModal';

interface Props {
	userPoa: UserPOA | null,
	isKycRequiredDataPresent: boolean,
	fetchData: () => void
}
const PoaDocuments = ({ userPoa, isKycRequiredDataPresent, fetchData }: Props): React.ReactElement => {

	const [active, setActive] = useState<boolean>(false);
	const [togglePoaLoading, setTogglePoaLoading] = useState<boolean>(false);
	const [rejectionReasonModal, setRejectionReasonModal] = useState<boolean>(false);
	const [rejectionId, setRejectionId] = useState<number>(null);

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const handleClick = () => {
		setActive(prev => !prev);
	};

	const download = (key: string, name: string) => {
		const get = async () => {
			try {
				downloadAttachment(key, name);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	};


	const approve = async (id: number) => {
		setTogglePoaLoading(true);
		try {
			await toogleDocument(id, Status.APPROVED);
			fetchData();
			setTogglePoaLoading(false);
		} catch (e) {
			setTogglePoaLoading(false);
			showErrorNotification(e);
		}
	};

	const inProgress = async (id: number) => {
		try {
			await markAsInProgress(id);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};


	const onCloseRejectReasonModal = async (reason?: string) => {
		setRejectionReasonModal(false);
		if (reason) {
			try {
				setTogglePoaLoading(true);
				await toogleDocument(rejectionId, Status.REJECTED, reason);
				fetchData();
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setRejectionId(null);
				setTogglePoaLoading(false);
			}
		}
	};

	return (
		<><RejectReasonModal
			onClose={onCloseRejectReasonModal}
			open={rejectionReasonModal}
		/>
			<Accordion fluid styled style={{ marginBottom: 5 }} >
				<AccordionTitle
					active={active}
					index={1}
					onClick={handleClick} >
					POA Documents
					<Icon name={active ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
				</AccordionTitle>
				<AccordionContent active={active}>
					<Segment>
						<Table id='table' celled textAlign='center' padded='very' striped>
							<Table.Header fullWidth className='tableHeader'>
								<Table.Row>
									<Table.HeaderCell className="tableHeaders">Document Name</Table.HeaderCell>
									<Table.HeaderCell className="tableHeaders">Creation Date</Table.HeaderCell>
									<Table.HeaderCell className="tableHeaders">Status</Table.HeaderCell>
									<Table.HeaderCell className="tableHeaders">Updated By</Table.HeaderCell>
									<Table.HeaderCell className="tableHeaders">Rejection Reason</Table.HeaderCell>
									<Table.HeaderCell className="tableHeaders"></Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body className="tableBody">
								{userPoa.poa.map((entry) => {
									return (
										<Table.Row key={`${entry.key}$`} >
											<Table.Cell width={2}><a onClick={() => download(entry.key, entry.name)}>{entry.name}</a></Table.Cell>
											<Table.Cell width={2}>{formatDateWithPattern(entry.createdDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
											<Table.Cell width={2}>{entry.status}</Table.Cell>
											<Table.Cell width={2}>{entry?.poaUpdatedByUser?.firstName} {entry?.poaUpdatedByUser?.lastName}</Table.Cell>
											<Table.Cell width={6}>{entry.rejectionReason}</Table.Cell>
											<Table.Cell width={4}>
												<Grid>
													<Grid.Row style={{ justifyContent: 'center', alignItems: 'center' }}>
														<div className="downloadAttachments" onClick={() => download(entry.key, entry.name)}>
															<Icon size="large" name="download" />
														</div>
														{entry.status === Status.PENDING &&
															<>
																<ComponentWithConfirmation disabled={togglePoaLoading} onConfirm={() => approve(entry.id)} confirmContent={'Are you sure you want approve user POA?'}>
																	<Button
																		color='green'
																		loading={togglePoaLoading}
																		content='Approve'
																		disabled={!checkWritePermission(permissions, PermissionType.POA_COMPLIANCE) || userPoa.kycTier === 0 || !isKycRequiredDataPresent || !userPoa?.risksVerified || userPoa?.kycIdentity.issueDate == null}
																		floated='right'
																	/>
																</ComponentWithConfirmation>
																<Button
																	color='red'
																	content='Reject'
																	loading={togglePoaLoading}
																	disabled={togglePoaLoading || !checkWritePermission(permissions, PermissionType.POA_COMPLIANCE) || userPoa.kycTier === 0}
																	onClick={() => { setRejectionReasonModal(true); setRejectionId(entry.id); }}
																	floated='left'
																/>
																<ComponentWithConfirmation onConfirm={() => inProgress(entry.id)} confirmContent={'Are you sure you want mark as in progress?'}>
																	<Icon name={entry.adminInProgress ? 'eye' : 'eye slash'} color={entry.adminInProgress ? 'green' : 'red'} size="large" style={{ cursor: 'pointer' }} />
																</ComponentWithConfirmation>
															</>
														}

													</Grid.Row>
												</Grid>
											</Table.Cell>
										</Table.Row>
									);
								})}
								{userPoa.poa.length === 0 && <Table.Row>
									<Table.Cell textAlign='center' colSpan={5}>User not uploaded proof of address documents yet.</Table.Cell>
								</Table.Row>}
							</Table.Body>

						</Table>
					</Segment>
				</AccordionContent>
			</Accordion>
		</>
	);
};


export default PoaDocuments;
