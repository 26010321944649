/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PaginateParams } from '@/helpers/globalTypes';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '../swal/slice';
import { UserType } from '../user/types';
import { AuthorData, Ticket, TicketMessageAttachment, TicketsStore } from './ticketsType';

const initialState: TicketsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'tickets',
	initialState,
	reducers: {
		setTickets(state, action: PayloadAction<Array<Ticket>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setTickets, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;


export const getTickets = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/tickets/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setTickets(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getTicket = async (id: number) => {
	const response = await request.get(`/api/admin/tickets/${id}`);
	const { data } = response;
	return data;
};

export const getSupportEmailTicket = async (id: number) => {
	const response = await request.get(`/api/admin/tickets/support-email/${id}`);
	const { data } = response;
	return data;
};

export const updateTicketStatus = async (ticketId: number, status: 'solved' | 'rejected') => {
	const response = await request.post('/api/admin/tickets/status', { ticketId, status });
	const { data } = response;
	return data;
};

export const replyOnTicket = async (formData: FormData) => {
	const response = await request.post('/api/admin/tickets/replay', formData);
	const { data } = response;
	return data;
};

export const createTicketForUser = async (formData: FormData) => {
	const response = await request.post('/api/admin/tickets/user', formData);
	const { data } = response;
	return data;
};

export const getAuthorInitials = (author: AuthorData): string => {
	if (author.type === UserType.BUSINESS) {
		if (author.companyName) {
			return author.companyName?.charAt(1).toUpperCase() ?? '';
		} else {
			return '';
		}
	} else {
		if (author.firstName && author.lastName) {
			return author.firstName?.charAt(0).toUpperCase() + author.lastName?.charAt(0).toUpperCase();
		} else {
			return '';
		}
	}
};

export const downloadAttachment = async (id: number, attachment: TicketMessageAttachment) => {
	const response = await request.get(`/api/admin/tickets/attachment/${id}/${attachment.key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = attachment.name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};


export default slice.reducer;
