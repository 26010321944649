import React, { useCallback, useEffect, useState } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
	Icon
} from 'semantic-ui-react';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { Vault, VaultApprovalType, VaultPeriodEnum } from '@/features/vaults/vaultsTypes';
import { UserType } from '@/features/user/types';
import { useDropzone } from 'react-dropzone';
import { getVaultsCurrencies, getVaultsProviders } from '@features/vaults/vaultsSlice';
import { showErrorNotification } from '@features/swal/slice';
import { normalizeString } from '../Poa/Poa';

interface Props {
	open: boolean;
	onClose: (
		name?: string,
		description?: string,
		currency?: string,
		yearlyInterestRate?: number,
		interestPaymentPeriod?: VaultPeriodEnum,
		depositPeriodMonth?: number,
		minDepositAmount?: number,
		autoExtendDays?: number,
		skipPaymentIfAmountLessThan?: number,
		skipPaymentIfDaysLessThan?: number,
		minimalTransferableAmount?: number,
		maxAmountWithoutApproval?: number,
		systemAccountIntegration?: string,
		approvalType?: VaultApprovalType,
		userLevelId?: number, userGroupId?: number, profileType?: string,
		vault?: Vault,
		depositSystemAccount?: string,
		interestSystemAccount?: string,
		repaymentSystemAccount?: string,
		logo?: any,
		depositTrade?: boolean,
		compoundInterest?: boolean,
		serviceProvider?: string,
	) => void,
	vault: Vault,
	writePermission: boolean
}

const VaultModal = ({ open, onClose, vault, writePermission }: Props): React.ReactElement => {

	const [logoFile, setLogoFile] = useState(null);
	const [availableCcy, setAvailableCcy] = useState(null);
	const [providers, setProviders] = useState(null);
	const [uploadNewLogo, setUploadNewLogo] = useState<boolean>(vault?.config?.config?.logoLink ? false : true);

	const vaultPeriodOptions = Object.keys(VaultPeriodEnum).map(item => ({ value: item, key: item, text: normalizeString(item) }));

	const initialValues = {
		name: vault?.name ?? '',
		description: vault?.description ?? '',
		currency: vault?.processProperties?.ccy ?? '',
		yearlyInterestRate: vault?.config?.config?.yearlyInterestRate ? (parseFloat(vault?.config?.config?.yearlyInterestRate) * 100).toFixed(2) : '0',
		interestPaymentPeriod: vault?.config?.config?.interestPaymentPeriod ?? VaultPeriodEnum.MONTH,
		depositPeriodMonth: vault?.config?.config.depositPeriodMonths ?? '1',
		minDepositAmount: vault?.config?.config?.minDepositAmount ?? '0.01',
		skipPaymentIfAmountLessThan: vault?.config?.config?.skipPaymentIfAmountLessThan ?? '1',
		skipPaymentIfDaysLessThan: vault?.config?.config?.skipPaymentIfDaysLessThan ?? '1',
		minimalTransferableAmount: vault?.config?.config?.minimalTransferableAmount ?? '0.01',
		maxAmountWithoutApproval: vault?.config?.config?.maxWithoutApprove ?? '0',
		approvalType: vault?.config?.config?.approvalType ?? 'NONE',
		userLevelId: vault?.userLevelId ?? '',
		autoExtendDays: vault?.config?.config?.autoExtendDays ?? '0',
		userGroupId: vault?.userGroupId ?? '',
		profileType: vault?.userProfileType ?? 'NONE',
		systemAccountIntegration: vault?.config?.config.systemAccountIntegration ?? '',
		depositSystemAccount: vault?.config?.config?.systemVaultAccountWalletId ?? '',
		interestSystemAccount: vault?.config?.config?.systemVaultInterestAccountWalletId ?? '',
		repaymentSystemAccount: vault?.config?.config?.systemVaultRepaymentAccountWalletId ?? '',
		logoLink: vault?.config?.config?.logoLink ?? '',
		depositTrade: vault?.config?.config?.depositTrade ?? false,
		compoundInterest: vault?.config?.config?.compoundInterest ?? false,
		serviceProvider: vault?.config?.config?.serviceProvider ?? '',
	};

	const validationSchema = Yup.object({
		serviceProvider: Yup.string()
			.required('You must select service provider'),
		name: Yup.string()
			.required('You must enter a name'),
		description: Yup.string()
			.required('You must enter a description'),
		currency: Yup.string()
			.required('You must enter a currency')
			.test('currency', 'Only capital letters allowed', (value) => /[A-Z]/.test(value)),
		yearlyInterestRate: Yup.number()
			.min(0.01, 'Min rate 0.01')
			.required('You must enter a interest rate'),
		interestPaymentPeriod: Yup.string().required('You must enter a vault period'),
		depositPeriodMonth: Yup.number()
			.min(1, 'Min period 1')
			.required('You must enter a deposit period'),
		minDepositAmount: Yup.number()
			.min(0.01, 'Min period 0.01')
			.required('You must enter a deposit period'),
		autoExtendDays: Yup.number()
			.min(1, 'Min auto extend days is 1')
			.required('You must enter a auto extend days'),
		skipPaymentIfAmountLessThan: Yup.number()
			.min(0.1, 'Min skip payment if amount less than 1')
			.required('You must enter a skip payment if amount less than'),
		skipPaymentIfDaysLessThan: Yup.number()
			.min(1, 'Min skip payment if days less than 1')
			.required('You must enter a skip amount if days less than'),
		minimalTransferableAmount: Yup.number()
			.min(0.01, 'Min transferable amount 0.01')
			.required('You must enter a minimal transferable amount'),
		maxAmountWithoutApproval: Yup.number()
			.min(0, 'Min amount without approval is 0')
			.required('You must enter a maximal amount without approval'),
	});

	useEffect(() => {
		setUploadNewLogo(vault?.config?.config?.logoLink ? false : true);
	}, [vault?.proc]);


	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);

		const {
			name,
			description,
			currency,
			yearlyInterestRate,
			interestPaymentPeriod,
			depositPeriodMonth,
			minDepositAmount,
			autoExtendDays,
			skipPaymentIfAmountLessThan,
			skipPaymentIfDaysLessThan,
			minimalTransferableAmount,
			maxAmountWithoutApproval,
			systemAccountIntegration,
			approvalType,
			userLevelId,
			userGroupId,
			profileType,
			depositSystemAccount,
			interestSystemAccount,
			repaymentSystemAccount,
			depositTrade,
			compoundInterest,
			serviceProvider,
		} = formData;

		const logo = logoFile;
		setLogoFile(null);
		setUploadNewLogo(false);
		onClose(
			name,
			description,
			currency,
			yearlyInterestRate,
			interestPaymentPeriod,
			depositPeriodMonth,
			minDepositAmount,
			autoExtendDays,
			skipPaymentIfAmountLessThan,
			skipPaymentIfDaysLessThan,
			minimalTransferableAmount,
			maxAmountWithoutApproval,
			systemAccountIntegration,
			approvalType,
			userLevelId,
			userGroupId,
			profileType,
			vault ?? null,
			depositSystemAccount.length > 0 ? depositSystemAccount : undefined,
			interestSystemAccount.length > 0 ? interestSystemAccount : undefined,
			repaymentSystemAccount.length > 0 ? repaymentSystemAccount : undefined,
			logo,
			depositTrade,
			compoundInterest,
			serviceProvider,
		);
	};

	const closeModal = () => {
		setLogoFile(null);
		setUploadNewLogo(false);
		onClose();
	};

	const handleLogoUpload = (file) => {
		setLogoFile(file);
	};

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getVaultsCurrencies();
				const availableCcy = data?.map(item => {
					return { value: item.lookupCode, key: item.lookupCode, text: item.lookupCode };
				});
				setAvailableCcy(availableCcy);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, []);

	const fetchProviderData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getVaultsProviders();
				const date = new Date();
				const filtered = data?.filter(p => p.enabled && (new Date(p.activeTo) > date || p.activeTo === undefined) || p.activeTo === null) || [];
				const availableProviders = filtered?.map(item => {
					return { value: item.serviceOwner, key: item.serviceOwner, text: `${item.serviceOwner}  (emi: ${item.emiWlpId} wlp: ${item.wlpId})` };
				});
				setProviders(availableProviders);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		fetchProviderData();
	}, [fetchProviderData]);


	return <Modal
		size="tiny"
		open={open}
		onClose={closeModal}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
				enableReinitialize={true}
			>
				{({ isSubmitting }) => (
					<Form
						size="large">
						<Header content={vault ? 'Edit vault' : 'Add new vault'} />
						<Select
							label='Service Provider'
							fluid
							name="serviceProvider"
							options={providers}
							selection
							errorPrompt
							disabled={!writePermission}
						/>
						<Input
							label='Name'
							fluid
							name="name"
							disabled={!writePermission}
							errorPrompt
						/>
						<TextArea
							label='Description'
							fluid
							name="description"
							disabled={!writePermission}
							errorPrompt
						/>
						<Select
							label='Currency'
							fluid
							name="currency"
							options={availableCcy}
							clearable
							selection
							disabled={!writePermission}
						/>
						<Input
							label='Interest Rate'
							fluid
							name="yearlyInterestRate"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Select
							label='Vault Interest Payout Period'
							fluid
							name="interestPaymentPeriod"
							options={vaultPeriodOptions}
							clearable
							selection
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='Deposit Period'
							fluid
							name="depositPeriodMonth"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Checkbox fitted
							name="compoundInterest"
							label='Compound Interest'
							disabled={!writePermission}
						/>
						<Input
							label='Min deposit amount'
							fluid
							name="minDepositAmount"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='Auto Extend Days'
							fluid
							name="autoExtendDays"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='Skip Payment if Amount Less Than'
							fluid
							name="skipPaymentIfAmountLessThan"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='Skip Payment if Days Less Than'
							fluid
							name="skipPaymentIfDaysLessThan"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='Minimal Transferable Amount'
							fluid
							name="minimalTransferableAmount"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='Max Amount Without Approval'
							fluid
							name="maxAmountWithoutApproval"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Select
							label='Approval type'
							fluid
							name="approvalType"
							options={[{ key: VaultApprovalType.NONE, value: VaultApprovalType.NONE, text: VaultApprovalType.NONE }, { key: VaultApprovalType.ONE_STAGE, value: VaultApprovalType.ONE_STAGE, text: VaultApprovalType.ONE_STAGE },
							{ key: VaultApprovalType.ONE_STAGE_ONCE_BY_INTEGRATION, value: VaultApprovalType.ONE_STAGE_ONCE_BY_INTEGRATION, text: VaultApprovalType.ONE_STAGE_ONCE_BY_INTEGRATION }, { key: VaultApprovalType.TWO_STAGE, value: VaultApprovalType.TWO_STAGE, text: VaultApprovalType.TWO_STAGE },
							{ key: VaultApprovalType.THREE_STAGE, value: VaultApprovalType.THREE_STAGE, text: VaultApprovalType.THREE_STAGE }]}
							clearable
							selection
						/>
						<Input
							label='User Level'
							fluid
							name="userLevelId"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='User Group'
							fluid
							name="userGroupId"
							type='number'
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='System Account Integration'
							fluid
							name="systemAccountIntegration"
							disabled={!writePermission || vault}
							errorPrompt
						/>
						<Select
							label='Profile type'
							fluid
							name="profileType"
							options={[{ key: 'NONE', value: 'NONE', text: 'NONE' }, { key: UserType.INDIVIDUAL, value: UserType.INDIVIDUAL, text: UserType.INDIVIDUAL },
							{ key: UserType.BUSINESS, value: UserType.BUSINESS, text: UserType.BUSINESS }]}
							clearable
							selection
						/>
						<Input
							label='Deposit System Account'
							fluid
							name="depositSystemAccount"
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='Interest System Account'
							fluid
							name="interestSystemAccount"
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='Repayment System Account'
							fluid
							name="repaymentSystemAccount"
							disabled={!writePermission}
							errorPrompt
						/>
						<Checkbox fitted
							name="depositTrade"
							label='UNITS Vault'
							disabled={!writePermission}
						/>
						<Divider />
						{vault?.config?.config?.logoLink && !uploadNewLogo &&
							<>
								<Input
									label='Logo'
									fluid
									value={vault?.config?.config?.logoLink}
									name="logo"
									disabled
								/>
								<a onClick={() => setUploadNewLogo(true)}>Upload new logo</a>
							</>}
						{uploadNewLogo && <UploadLogo onUpload={handleLogoUpload} />}
						<Divider hidden />
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !writePermission}
										primary size="large" type="submit"
										floated='right' >
										{vault ? 'Edit' : 'Add'}
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={closeModal} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

interface UploadLogoProps {
	onUpload: (file) => void
}

const UploadLogo = ({ onUpload }: UploadLogoProps) => {

	const onDrop = useCallback((acceptedFiles) => {
		onUpload(acceptedFiles[0]);
	}, []);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/png,image/jpg,image/jpeg', multiple: false });

	const removeAll = useCallback(() => {
		onUpload(null);
		acceptedFiles.splice(0);
	}, [onUpload]);

	return (
		<>
			<Grid style={{ marginLeft: '0', marginRight: '0', marginBottom: '1em', marginTop: '1em' }}>
				<Grid.Row><strong>Logo</strong></Grid.Row>
				<Grid.Row>
					<div {...getRootProps()} id='file-container'>
						<input {...getInputProps()} id='file-drop' />
						<span><Icon name='upload' /> Upload Logo...</span>
					</div>
				</Grid.Row>
				{acceptedFiles.length > 0 &&
					<Grid.Row>
						<Icon size="large" name="file outline" />
						<div className="fileName">{acceptedFiles[0].name}</div>
						<Icon className="fileName" size="large" name='trash' link onClick={() => removeAll()} />
					</Grid.Row>}
			</Grid>
		</>
	);
};

export default VaultModal;
