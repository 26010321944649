/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import '../Clients/ClientDetails/Profile.css';
import {
	Grid,
	Segment,
	List,
	Container,
	Header,
	Divider,
	Button,
} from 'semantic-ui-react';
import { Permission } from '@/features/user/types';
import Loading from '@/components/Shared/Loading';
import { Process, ProcessConfig } from '@/features/processes/processesTypes';
import { postProcessResetToDefault, toogleProcess } from '@/features/processes/processesSlice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';

type Props = {
	goBack: () => void,
	fetchData: () => void,
	process: Process,
	writePermission: boolean
	permissions: Array<Permission>
}


const ProcessDetailsSection = ({ process, goBack, fetchData, writePermission, permissions }: Props): React.ReactElement => {

	const reset = async () => {
		try {
			await postProcessResetToDefault(process.proc);
			showSuccessNotification('Process settings reseted to default');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const toogleProc = async () => {
		try {
			const config: ProcessConfig = {
				config: process.config.config,
				rolesAllowed: process.config.rolesAllowed,
				disabled: process.config.disabled ? false : true
			};

			await toogleProcess({ proc: process.proc, config });
			showSuccessNotification(process.config.disabled ? 'Process enabled' : 'Process disabled');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	if (!process) {
		return <Loading />;
	}
	return (
		<Container>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Process</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button floated='right' primary onClick={goBack} style={{ marginLeft: 10 }}>Go back</Button>
							<Button
								style={{ marginLeft: 10 }}
								secondary
								content='Reset to default settings'
								disabled={!writePermission}
								onClick={reset}
								floated='right'
							/>
							<Button
								style={{ marginLeft: 10 }}
								content={process.config.disabled ? 'Enable' : 'Disable'}
								color={process.config.disabled ? 'green' : 'red'}
								disabled={!writePermission}
								onClick={toogleProc}
								floated='right'
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable columns={2}>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Name</List.Header>
										<List.Description>{process.name}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Description</List.Header>
										<List.Description>{process.description}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Type</List.Header>
										<List.Description>{process.type}</List.Description>
									</List.Content>
								</List.Item>

							</List>
						</Segment>
					</Grid.Column>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Integration</List.Header>
										<List.Description>{process.integration}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Process</List.Header>
										<List.Description>{process.proc}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Enabled/Disabled</List.Header>
										<List.Description>{process.config.disabled ? 'Disabled' : 'Enabled'}</List.Description>
									</List.Content>
								</List.Item>
							</List>
						</Segment>
					</Grid.Column>

				</Grid>
			</Segment>
		</Container >
	);
};

export default ProcessDetailsSection;
