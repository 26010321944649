/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Button, Container, Grid, Segment, Sidebar } from 'semantic-ui-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './Dashboard.css';
import VerticalNavbar from '@/components/Navbar/VerticalNavbar';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import LoadingPage from './LoadingPage';
import PermissionRoute from '@/PermissionRoute';
import { PermissionType } from '@/features/user/types';
import Statistics from '@/components/Dashboard/Statistics/Statistics';
import IndividualClients from '@/components/Dashboard/Clients/IndividualClients';
import BusinessClients from '@/components/Dashboard/Clients/BusinessClients';
import SystemUsers from '@/components/Dashboard/Clients/SystemUsers';
import Accounts from '@/components/Dashboard/Accounts/Accounts';
import NotPermitted from './NotPermitted';
import Navbar from '@/components/Navbar/Navbar';
import SystemAccounts from '@/components/Dashboard/Accounts/SystemAccounts';
import Cards from '@/components/Dashboard/Cards/Cards';
import NotFound from './NotFound';
import Documents from '@/components/Dashboard/Documents/Documents';
import ClientDetails from '@/components/Dashboard/Clients/ClientDetails/Client';
import AccountDetails from '@/components/Dashboard/Accounts/Account';
import CardDetails from '@/components/Dashboard/Cards/CardDetails';
import DocumentDetails from '@/components/Dashboard/Documents/DocumentDetails';
import CountryLimits from '@/components/Dashboard/CountryLimit/CountryLimits';
import SystemErrors from '@/components/Dashboard/SystemErrors/SystemErrors';
import Languages from '@/components/Dashboard/Languages/Languages';
import Files from '@/components/Dashboard/Files/Files';
import Roles from '@/components/Dashboard/Roles/Roles';
import FaqHeader from '@/components/Dashboard/FAQ/FaqHeader';
import FaqHeaderDetails from '@/components/Dashboard/FAQ/FaqHeaderDetails';
import UnassignedPayments from '@/components/Dashboard/UnassignedPayments/UnassignedPayments';
import ActivityLogsTable from '@/components/Dashboard/ActivityLogs/ActivityLogsTable';
import Tickets from '@/components/Dashboard/Tickets/Tickets';
import TicketDetails from '@/components/Dashboard/Tickets/TicketDetails';
import RootProcesses from '@/components/Dashboard/Processes/RootProcesses';
import ProcessDetails from '@/components/Dashboard/Processes/Process';
import PaymentConfig from '@/components/Dashboard/Payments/PaymentConfig/PaymentConfig';
import SystemAccountSummary from '@/components/Dashboard/Payments/SystemAccountSummary';
import PaymentHistory from '@/components/Dashboard/Payments/PaymentHistory';
import Reconcile from '@/components/Dashboard/Reconciliation/Reconcile';
import FreelancerClients from '@/components/Dashboard/Clients/FreelancerClients';
import Poa from '@/components/Dashboard/Poa/Poa';
import UserPoa from '@/components/Dashboard/Poa/UserPoa';
import Tokens from '@/components/Dashboard/Tokens/Tokens';
import VaultsSetup from '@/components/Dashboard/Vaults/VaultsSetup';
import Vaults from '@/components/Dashboard/Vaults/Vaults';
import VaultsProcessesVaults from '@/components/Dashboard/Vaults/ProcessVaults';
import VaultsLiabilities from '@/components/Dashboard/Vaults/Liabilities';
import TokenRequests from '@/components/Dashboard/Tokens/TokenRequests';
import Compliance from '@/components/Dashboard/Compliance/Compliance';
import ComplianceDetails from '@/components/Dashboard/Compliance/ComplianceDetails';
import TokenTrades from '@/components/Dashboard/Tokens/TokenTrades';
import TokenWithdrawal from '@/components/Dashboard/Tokens/TokenWithdrawal';
import PlannedTransfers from '@/components/Dashboard/Vaults/PlannedTransfers';
import UserGroups from '@/components/Dashboard/UserGroups/UserGroups';
import UserGroupUsers from '@/components/Dashboard/UserGroups/UserGroupUsers';
import SystemAccountDetails from '@/components/Dashboard/Payments/SystemAccountDetails';
import TrustPayments from '@/components/Dashboard/Trust/TrustPayments';
import ReconciliationSummary from '@/components/Dashboard/Reconciliation/ReconciliationSummary';
import KYCIssues from '@/components/Dashboard/Compliance/KYCIssues';
import KYCCalendar from '@/components/Dashboard/Compliance/KYCCalendar';
import IntegrationsUnassignedPayments from '@/components/Dashboard/UnassignedPayments/IntegrationsUnassignedPayments';
import TaxForm from '@/components/Dashboard/Compliance/TaxForm';
import SystemDocuments from '@/components/Dashboard/SystemDocuments/SystemDocuments';
import AMLReports from '@/components/Dashboard/Reports/AML';
import ExchangeOrders from '@/components/Dashboard/ExchangeOrders/ExchangeOrders';
import V3ComplianceDetails from '@/components/Dashboard/Compliance/V3ComplianceDetails';
import StatementBatches from '@/components/Dashboard/StatementBatches/StatementBatches/StatementBatches';
import StatementSummaries from '@/components/Dashboard/StatementBatches/StatementBatches/StatementSummaries';
import StatementTransactions from '@/components/Dashboard/StatementBatches/StatementBatches/StatementTransactions';
import BankAccountBalances from '@/components/Dashboard/StatementBatches/Balances/BankAccountBalances';
import VaultDetails from '@/components/Dashboard/Fireblocks/VaultDetails';
import PendingWithdrawalRequests from '@/components/Dashboard/Vaults/PendingWithdrawalRequests';
import Admins from '@/components/Dashboard/Admins/Admins';
import ReferralPrograms from '@/components/Dashboard/Referrals/ReferralPrograms';
import ReferralPayments from '@/components/Dashboard/Referrals/ReferralPayments';
import AMLVsapReports from '@/components/Dashboard/Reports/AMLVsapReports';
import Maintenance from '@/components/Dashboard/Maintenance/Maintenance';

const Dashboard = (): React.ReactElement => {

	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const [sideBarVisible, setSideBarVisible] = React.useState(true);

	if (!status) return <LoadingPage />;

	return (
		<div id="dashboard">
			<Navbar />
			<Container fluid >
				<Grid >
					<Grid.Column >
						{sideBarVisible &&
							<Button
								basic
								content={'Hide menu'}
								onClick={() => setSideBarVisible(!sideBarVisible)}
							/>}
						{!sideBarVisible &&
							<Container>
								<Button
									basic
									content={'Show menu'}
									onClick={() => setSideBarVisible(!sideBarVisible)}
								/>
							</Container>}
						<Sidebar.Pushable as={Segment} style={{ background: 'unset', border: 'none', boxShadow: 'unset', padding: 0 }} >
							<VerticalNavbar sideBarVisible={sideBarVisible} />
							<Sidebar.Pusher >
								<Switch>
									<Route path='/dashboard' component={Statistics} />
									<PermissionRoute path='/clients/private' key={window.location.pathname} component={IndividualClients} permission={PermissionType.USERS} />
									<PermissionRoute path='/clients/business' key={window.location.pathname} component={BusinessClients} permission={PermissionType.USERS} />
									<PermissionRoute path='/clients/system' key={window.location.pathname} component={SystemUsers} permission={PermissionType.USERS} />
									<PermissionRoute path='/clients/freelancer' key={window.location.pathname} component={FreelancerClients} permission={PermissionType.USERS} />
									<PermissionRoute path='/accounts/bank' key={window.location.pathname} component={Accounts} type='BANK' permission={PermissionType.ACCOUNTS} />
									<PermissionRoute path='/accounts/wallet' key={window.location.pathname} component={Accounts} type='WALLET' permission={PermissionType.ACCOUNTS} />
									<PermissionRoute path='/accounts/crypto' key={window.location.pathname} component={Accounts} type='CRYPTO' permission={PermissionType.ACCOUNTS} />
									<PermissionRoute path='/accounts/trust' key={window.location.pathname} component={Accounts} type='TRUST' permission={PermissionType.ACCOUNTS} />
									<PermissionRoute path='/accounts/system' key={window.location.pathname} component={SystemAccounts} type='SYSTEM' permission={PermissionType.ACCOUNTS} />
									<PermissionRoute path='/cards/all' component={Cards} permission={PermissionType.CARDS} />
									<PermissionRoute path='/documents/crypto' key={window.location.pathname} component={Documents} type='CRYPTO' permission={PermissionType.CRYPTO_DOCUMENTS} />
									<PermissionRoute path='/documents/trust' key={window.location.pathname} component={Documents} type='TRUST' permission={PermissionType.TRUST_DOCUMENTS} />
									<PermissionRoute path='/documents/bank' key={window.location.pathname} component={Documents} type='BANK' permission={PermissionType.BANK_DOCUMENTS} />
									<PermissionRoute path='/documents/wallet' key={window.location.pathname} component={Documents} type='WALLET' permission={PermissionType.BANK_DOCUMENTS} />
									<PermissionRoute path='/documents/card' key={window.location.pathname} component={Documents} type='CARD' permission={PermissionType.CARD_DOCUMENTS} />
									<PermissionRoute path='/documents/token' key={window.location.pathname} component={Documents} type='TOKEN' permission={PermissionType.TOKENS} />
									<PermissionRoute path='/country-limits' component={CountryLimits} permission={PermissionType.COUNTRY_LIMITS} />
									<PermissionRoute path='/system-errors' component={SystemErrors} permission={PermissionType.SYSTEM_ERRORS} />
									<PermissionRoute path='/languages' component={Languages} permission={PermissionType.LANGUAGES} />
									<PermissionRoute path='/file-uploads' component={Files} permission={PermissionType.FILE_UPLOAD} />
									<PermissionRoute path='/roles' component={Roles} permission={PermissionType.ROLES} />
									<PermissionRoute path='/faq' exact component={FaqHeader} permission={PermissionType.FAQ} />
									<PermissionRoute path='/files/unassigned-payments' component={UnassignedPayments} permission={PermissionType.UNASSIGNED_PAYMENTS} />
									<PermissionRoute path='/integrations/unassigned-payments' component={IntegrationsUnassignedPayments} permission={PermissionType.UNASSIGNED_PAYMENTS} />
									<PermissionRoute path='/activity-logs/users' exact component={ActivityLogsTable} region={'USERS'} permission={PermissionType.ACTIVITY_LOGS} />
									<PermissionRoute path='/activity-logs/transactions' exact component={ActivityLogsTable} region={'TRANSACTIONS'} permission={PermissionType.ACTIVITY_LOGS} />
									<PermissionRoute path='/activity-logs/accounts' exact component={ActivityLogsTable} region={'ACCOUNTS'} permission={PermissionType.ACTIVITY_LOGS} />
									<PermissionRoute path='/activity-logs/other' exact component={ActivityLogsTable} region={'OTHER'} permission={PermissionType.ACTIVITY_LOGS} />
									<PermissionRoute path='/support-management' component={Tickets} permission={PermissionType.SUPPORT} />
									<PermissionRoute path='/processes' component={RootProcesses} permission={PermissionType.PROCESSES} />
									<PermissionRoute path='/poa' exact component={Poa} permission={PermissionType.POA_COMPLIANCE} />
									<PermissionRoute path='/poa/:userId' component={UserPoa} permission={PermissionType.POA_COMPLIANCE} />

									<PermissionRoute path='/tokens/all' exact component={Tokens} permission={PermissionType.TOKENS} />
									<PermissionRoute path='/tokens/requests' exact component={TokenRequests} permission={PermissionType.TOKENS} />
									<PermissionRoute path='/tokens/trades' exact component={TokenTrades} permission={PermissionType.TOKENS} />
									<PermissionRoute path='/tokens/withdrawals' exact component={TokenWithdrawal} permission={PermissionType.TOKENS} />
									<PermissionRoute path='/tokens/accounts' key={window.location.pathname} component={Accounts} type='TOKEN' permission={PermissionType.TOKENS} />

									<PermissionRoute path='/vaults/all' exact component={VaultsSetup} permission={PermissionType.VAULTS} />
									<PermissionRoute path='/vaults/list' exact component={Vaults} permission={PermissionType.VAULTS} />
									<PermissionRoute path='/vaults/list/:proc' exact component={VaultsProcessesVaults} permission={PermissionType.VAULTS} />
									<PermissionRoute path='/vaults/list/:proc/liabilities' exact component={VaultsLiabilities} permission={PermissionType.VAULTS} />
									<PermissionRoute path='/vaults/accounts' key={window.location.pathname} component={Accounts} type='VAULT' permission={PermissionType.VAULTS} />
									<PermissionRoute path='/vaults/planned-transfers' key={window.location.pathname} component={PlannedTransfers} permission={PermissionType.VAULTS} />
									<PermissionRoute path='/vaults/pending-requests' key={window.location.pathname} component={PendingWithdrawalRequests} permission={PermissionType.VAULTS} />

									<PermissionRoute path='/fireblocks/vault/:vaultId/:assetId' key={window.location.pathname} component={VaultDetails} permission={PermissionType.VAULTS} />
									<PermissionRoute path='/fireblocks/vault/:vaultId' key={window.location.pathname} component={VaultDetails} permission={PermissionType.VAULTS} />

									<PermissionRoute path='/trust/payments' key={window.location.pathname} component={TrustPayments} permission={PermissionType.TRUST} />

									<PermissionRoute path='/user-groups' exact component={UserGroups} permission={PermissionType.USERS} />
									<PermissionRoute path='/user-groups/:userGroupId' component={UserGroupUsers} permission={PermissionType.USERS} />

									<PermissionRoute path='/compliance' exact component={Compliance} permission={PermissionType.POA_COMPLIANCE} />
									<PermissionRoute path='/compliance/:applicantId' component={ComplianceDetails} permission={PermissionType.POA_COMPLIANCE} />
									<PermissionRoute path='/new-compliance/:applicantId' component={V3ComplianceDetails} permission={PermissionType.POA_COMPLIANCE} />
									<PermissionRoute path='/kyc-issues' exact component={KYCIssues} permission={PermissionType.POA_COMPLIANCE} />
									<PermissionRoute path='/kyc-calendar' exact component={KYCCalendar} permission={PermissionType.POA_COMPLIANCE} />
									<PermissionRoute path='/tax-form' exact component={TaxForm} permission={PermissionType.POA_COMPLIANCE} />

									<PermissionRoute path='/client/:userId' component={ClientDetails} permission={PermissionType.USERS} />
									<PermissionRoute path='/account/:accountId' component={AccountDetails} permission={PermissionType.ACCOUNTS} />
									<PermissionRoute path='/card/:cardId' component={CardDetails} permission={PermissionType.CARDS} />
									<Route path='/document/:documentNumber' component={DocumentDetails} />
									<PermissionRoute path='/faq/:faqId' component={FaqHeaderDetails} permission={PermissionType.FAQ} />
									<PermissionRoute path='/ticket/:ticketId' component={TicketDetails} permission={PermissionType.SUPPORT} />
									<PermissionRoute path='/process/:processName' component={ProcessDetails} key={window.location.pathname} permission={PermissionType.PROCESSES} />
									{/*	<PermissionRoute path='/statements/:transactionId' component={StatementTransactionDetails} permission={PermissionType.RECONCILIATIONS} />
							<PermissionRoute path='/statement-balancies/:transactionId' exact component={StatementTransactionDetails} permission={PermissionType.RECONCILIATIONS} /> 
	*/}
									{/* bank statements */}
									<PermissionRoute path='/reconciliations' exact component={ReconciliationSummary} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/reconciliations/reconcile' exact component={Reconcile} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/statements' exact component={StatementBatches} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/statements/summaries/:batchId' component={StatementSummaries} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/statements/transactions/:batchId' component={StatementTransactions} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/statements-transactions' component={StatementTransactions} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/statement-balancies' exact component={BankAccountBalances} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/system-documents' component={SystemDocuments} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/reports/aml' component={AMLReports} permission={PermissionType.REPORTS} />
									<PermissionRoute path='/reports/system-documents' component={SystemDocuments} permission={PermissionType.REPORTS} />
									<PermissionRoute path='/reports/aml-vasp' component={AMLVsapReports} permission={PermissionType.REPORTS} />


									{/* end bank statements */}
									{/* Payments*/}
									<PermissionRoute path='/payment/accounts' exact component={SystemAccountSummary} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/payment/account-details/:bankAccountId' exact component={SystemAccountDetails} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/payment/config' exact component={PaymentConfig} permission={PermissionType.RECONCILIATIONS} />
									<PermissionRoute path='/payment/history/:bankAccountId' component={PaymentHistory} permission={PermissionType.RECONCILIATIONS} />
									{/* end Payments */}
									<PermissionRoute path='/exchange' exact component={ExchangeOrders} permission={PermissionType.CURRENCY_EXCHANGE} />
									<PermissionRoute path='/admins' component={Admins} permission={PermissionType.SYSTEM_ADMINS} />

									{/* Referrals */}
									<PermissionRoute path='/referrals/programs' component={ReferralPrograms} permission={PermissionType.REFERRALS} />
									<PermissionRoute path='/referrals/payments' component={ReferralPayments} permission={PermissionType.REFERRALS} />
									{/* End referrals */}

									{/* Maintenance */}
									<PermissionRoute path='/maintenance' component={Maintenance} permission={PermissionType.MAINTENANCE} />
									{/* Maintenance */}  
									<Route exact path="/not-permitted">
										<NotPermitted />
									</Route>
									<Redirect exact from='/' to='/dashboard' />
									<Route component={NotFound} />
								</Switch>
							</Sidebar.Pusher>
						</Sidebar.Pushable>
					</Grid.Column>
				</Grid>
			</Container >
		</div >);
};

export default Dashboard;
