import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { deleteFaq, getFaqHeaders, postFaq, putFaq } from '@/features/faq/faqSlice';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { FaqHeader, FaqHeaderPayload } from '@/features/faq/faqTypes';
import FaqHeaderModal from './FaqHeaderModal';
import { useHistory } from 'react-router';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	writePermission: boolean,
}

const FaqHeaderPage = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.faq
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams} = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const [openFaqModal, setOpenFaqModal] = useState<boolean>(false);
	const [editFaq, setEditFaq] = useState<FaqHeader>(null);

	const filter: FilterParam[] = [
		{
			field: 'name',
			label: 'Name',
			type: 'input'
		},
		{
			field: 'countryCode',
			label: 'Country',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'name',
			name: 'Name',
			position: 1,
			sortable: true
		},
		{
			field: 'countryCode',
			name: 'Country',
			position: 2,
			sortable: true
		},
		{
			field: 'languageCode',
			name: 'Language',
			position: 3,
			sortable: true
		},
		{
			field: 'disabled',
			name: 'Enabled',
			position: 4,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 5,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getFaqHeaders(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);


	const removeFaq = async (id: number) => {
		try {
			await deleteFaq(id);
			showSuccessNotification('FAQ header removed');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const handleCloseFaqModal = async (language?: string, name?: string, disabled?: boolean, country?: string, whitelabel?: string) => {
		setOpenFaqModal(false);
		if (language && name && whitelabel) {
			const payload: FaqHeaderPayload = {
				languageCode: language,
				name,
				disabled,
				countryCode: country,
				agentCode: whitelabel
			};
			try {
				if (editFaq) {
					payload.id = editFaq.id;
					await putFaq(payload);
				} else {
					await postFaq(payload);
				}
				showSuccessNotification(editFaq ? 'FAQ header updated' : 'FAQ header added');
				fetchData();
			} catch (e) {
				showErrorNotification(e);
			}
		}
		setEditFaq(null);

	};

	const history = useHistory();
	const openFaqDetails = (id: number) => {
		history.push(`/faq/${id}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.name}</Table.Cell>
						<Table.Cell width={2}>{entry.countryCode}</Table.Cell>
						<Table.Cell width={2}>{entry.languageCode}</Table.Cell>
						<Table.Cell width={2}>
							{!entry.disabled && <Icon color='green' size='large' name="check circle" />}
							{entry.disabled && <Icon color='red' size='large' name="times circle" />}
						</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Grid>
								<Grid.Row textAlign='center' centered>
									<Popup content='Edit FAQ header' trigger={<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="edit outline" onClick={() => { setEditFaq(entry); setOpenFaqModal(true); }} />} />
									<ComponentWithConfirmation popup={'Remove FAQ header'} onConfirm={() => removeFaq(entry.id)} confirmContent={'Are you sure you want delete FAQ?'}>
										<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="trash" />
									</ComponentWithConfirmation>
									<Popup content='Open FAQ header' trigger={<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="question circle outline" onClick={() => openFaqDetails(entry.id)} />} />
								</Grid.Row>
							</Grid>
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>Not faq yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<FaqHeaderModal
					open={openFaqModal}
					onClose={handleCloseFaqModal}
					faq={editFaq}
				/>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>FAQ</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button
								floated='right'
								primary
								disabled={!writePermission}
								onClick={() => setOpenFaqModal(true)}
							>
								Add FAQ</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default FaqHeaderPage;
