import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';

const useWhiteLabelLov = () => {

	const { list } = useSelector((state: RootState) => state.whitelabel);

	const whitelabelOptions = list.map(item => {
		return { value: item.value, key: item.value, text: item.label };
	});

	const whitelabelEmiFlagged = list.map(item => {
		return { value: item.value, key: item.value, text: `${item.label}${item?.isEmi ? ' (EMI)' : ''} ` };
	});

	return { list, whitelabelOptions, whitelabelEmiFlagged };
};

export default useWhiteLabelLov;
