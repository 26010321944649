/* eslint-disable react/prop-types */
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Dimmer, Divider, Header, Loader, Segment} from 'semantic-ui-react';
import {showErrorNotification, showSuccessNotification} from '@/features/swal/slice';
import '../Clients/ClientDetails/Profile.css';
import {getVault, vaultToOmnibus} from '@features/fireblocks/slice';
import './Assets.css';
import {Vault, VaultAsset} from '@features/fireblocks/types';
import {useHistory} from 'react-router-dom';
import Assets from './Assets';

interface Props {
	assetOnClick?: (asset: VaultAsset) => void,
}

const Omnibus = ({assetOnClick}: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [vault, setVault] = useState<Vault>(null);

	const fetchData = useCallback(() => {
		const get = async () => {
			setLoading(true);
			try {
				const data = await getVault('OMNIBUS');
				setVault(data);
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setLoading(false);
			}
		};
		get();
	}, []);

	useEffect(()=>{
		fetchData();
	}, [fetchData, dispatch]);

	const history = useHistory();
	const openVaultAsset = (asset: VaultAsset) => {
		history.push(`/fireblocks/vault/OMNIBUS/${asset.id}`);
	};
	const vaultToOmnibusClick = async (asset: VaultAsset) => {
		try {
			await vaultToOmnibus(asset.id);
			showSuccessNotification('Request sent');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	return (
		<div>
			<Segment>
				<Dimmer active={loading} inverted>
					<Loader />
				</Dimmer>
				{!loading && vault && <div>
					<Header as='h2'>{vault.name.replaceAll('-', ' ')}</Header>
					<Divider hidden />
					<Assets
						assets={vault.assets}
						onClick={openVaultAsset}
						onClickText="Open Vault Asset" />
					<Divider />
					<Assets
						assets={vault.assets}
						onClick={assetOnClick}
						onClickText="Filter account by asset"
						buttonIcon="arrow up"
						buttonOnClick={vaultToOmnibusClick}
						buttonOnClickText="Transfer asset to omnibus vault"
						assetValue="totalUsersBalance"
					/>
				</div>}
			</Segment>
		</div>
	);
};

export default Omnibus;
