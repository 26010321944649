import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { Button, Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { executeTransfer, getTokenTransfers, rejectTransfer } from '@/features/tokenTransfers/slice';
import { formatDateWithPattern } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { TokenTransfer, TokenTransferExecutePayload } from '@/features/tokenTransfers/types';
import ExecuteTokenTransferModal from './ExecuteTokenTransferModal';
import { FilterParam } from '@/components/Shared/Table/TableFilters';



interface Props {
	writePermission: boolean,
}

const TokenTrades = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.tokenTransfers
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);
	const [openExecuteModal, setOpenExecuteModal] = useState<boolean>(false);
	const [executeTokenTransfer, setExecuteTokenTransfer] = useState<TokenTransfer>(null);

	const filter: FilterParam[] = [
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: [{ key: 'NEW', value: 'NEW', text: 'New' }, { key: 'EXECUTED', value: 'EXECUTED', text: 'Executed' }, { key: 'REJECTED', value: 'REJECTED', text: 'Rejected' }]
		}
	];


	const headers: Array<Headers> = [
		{
			field: 'createdDate',
			name: 'Date',
			position: 1,
			sortable: true
		},
		{
			field: '',
			name: 'Name',
			position: 2,
			sortable: false
		},
		{
			field: 'requestedToAmount',
			name: 'To Amount',
			position: 3,
			sortable: true
		},
		{
			field: 'requestedFromAmount',
			name: 'From Amount',
			position: 4,
			sortable: true
		},
		{
			field: 'executedToAmount',
			name: 'Executed To Amount',
			position: 5,
			sortable: true
		},
		{
			field: 'executedFromAmount',
			name: 'Executed From Amount',
			position: 6,
			sortable: true
		},
		{
			field: 'executedFee',
			name: 'Executed fee',
			position: 7,
			sortable: true
		},
		{
			field: 'externalIds',
			name: 'External ID',
			position: 8,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 9,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 10,
			sortable: false
		}
	];

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getTokenTransfers(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const reject = async (id: number) => {
		try {
			await rejectTransfer(id);
			showSuccessNotification('Token transfer rejected');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};


	const handleCloseExecuteModal = async (executedFromAmount?: number, executedToAmount?: number, executedFee?: number, externalIds?: string, tokenTransfer?: TokenTransfer) => {
		setOpenExecuteModal(false);
		if (tokenTransfer) {
			try {

				const payload: TokenTransferExecutePayload = {
					tokenTransferId: tokenTransfer.id,
					executedFee,
					executedFromAmount,
					executedToAmount,
					externalIds
				};

				await executeTransfer(payload);
				showSuccessNotification('Token transfer executed');
				fetchData();
			} catch (e) {
				showErrorNotification(e);
			}
		}

		setExecuteTokenTransfer(null);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			<ExecuteTokenTransferModal
				open={openExecuteModal}
				onClose={handleCloseExecuteModal}
				tokenTransfer={executeTokenTransfer}
			/>
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.documentNumber}${entry.id}${index}`} >
						<Table.Cell width={2}>{formatDateWithPattern(entry.createdDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={1}>{entry.profileType === 'INDIVIDUAL' ? `${entry.firstName} ${entry.lastName}` : entry.companyName}</Table.Cell>
						<Table.Cell width={1}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={` ${entry.toCcy}`} value={entry.requestedToAmount} /></Table.Cell>
						<Table.Cell width={1}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={` ${entry.fromCcy}`} value={entry.requestedFromAmount} /></Table.Cell>
						<Table.Cell width={1}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={` ${entry.toCcy}`} value={entry.executedToAmount} /></Table.Cell>
						<Table.Cell width={1}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={` ${entry.fromCcy}`} value={entry.executedFromAmount} /></Table.Cell>
						<Table.Cell width={1}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.executedFee} /></Table.Cell>
						<Table.Cell width={1}>{entry.externalIds}</Table.Cell>
						<Table.Cell width={1}>{entry.status}</Table.Cell>
						<Table.Cell width={4}>
							<Button primary disabled={!writePermission || entry.status !== 'NEW'} content='Execute' onClick={() => { setExecuteTokenTransfer(entry); setOpenExecuteModal(true); }} />
							<ComponentWithConfirmation onConfirm={() => reject(entry.id)} confirmContent={'Are you sure you want reject token transfer execution?'}>
								<Button primary disabled={!writePermission || entry.status !== 'NEW'} content='Reject' />
							</ComponentWithConfirmation>
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={9}>No token transfers yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Token Trades</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					hideItemsPerPage={true}
					filter={filter}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};

export default TokenTrades;
