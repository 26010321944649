import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getErrorWithParams, showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Confirm, Container, Divider, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { formatDateWithPattern } from '@/helpers/date';
import { addRole, editRoleCall, getAvailablePermissions, getRoleDetails, getRoleDetailsByCode, getRoles, toogleRole } from '@/features/roles/rolesSlice';
import { RoleDetails, RolePayload, RolePermissions } from '@/features/roles/rolesTypes';
import RoleModal from './RoleModal';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	writePermission: boolean,
}

const Roles = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.roles
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [openRoleModal, setOpenRoleModal] = useState<boolean>(false);
	const [editRole, setEditRole] = useState<RoleDetails>(null);
	const [availablePermissions, setAvailablePermissions] = useState<Array<RolePermissions>>(null);
	const [replaceRole, setReplaceRole] = useState<RolePayload>(null);

	const options = [
		{
			key: '0',
			text: 'Disabled',
			value: true,
		},
		{
			key: '1',
			text: 'Enabled',
			value: false,
		}
	];

	const filter: FilterParam[] = [
		{
			field: 'code',
			label: 'Code',
			type: 'input'
		},
		{
			field: 'label',
			label: 'Name',
			type: 'input'
		},
		{
			field: 'disabled',
			label: 'Status',
			type: 'select',
			options: options
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'code',
			name: 'Code',
			position: 1,
			sortable: true
		},
		{
			field: 'label',
			name: 'Name',
			position: 2,
			sortable: true
		},
		{
			field: 'disabled',
			name: 'Status',
			position: 3,
			sortable: true
		},
		{
			field: 'createdDate',
			name: 'Date',
			position: 4,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 5,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getRoles(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const toogle = async (id: number, disabled: boolean) => {
		try {
			await toogleRole(id);
			showSuccessNotification(disabled ? 'Role enabled' : 'Role disabled');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const handleCloseRoleModal = async (code?: string, name?: string, disabled?: boolean, rolePermissions?: Array<RolePermissions>, edit?: number) => {
		setOpenRoleModal(false);

		const payload: RolePayload = {
			code,
			label: name,
			disabled,
			permissions: rolePermissions
		};

		if (code && name) {
			try {
				if (edit === null) {
					await addRole(payload);
				} else {
					await editRoleCall(edit, payload);
				}
				showSuccessNotification(edit ? 'Role updated' : 'Role added');
			} catch (e) {

				const err = getErrorWithParams(e);
				if (err.error === 'Role already exist') {
					setReplaceRole(payload);
				} else {
					showErrorNotification(e);
				}
			}
			fetchData();
		}
	};

	const handlEditRole = async (id: number) => {
		try {
			const data = await getRoleDetails(id);
			getAvailablePerm();
			setEditRole(data);
			setOpenRoleModal(true);
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const getAvailablePerm = async () => {
		const data = await getAvailablePermissions();
		setAvailablePermissions(data);
	};

	const handleAddRole = async () => {
		setEditRole(null);
		getAvailablePerm();
		setOpenRoleModal(true);
	};

    const confirmRoleReplace = async () => {
        try {
            const data = await getRoleDetailsByCode(replaceRole.code);
            await editRoleCall(data.id, replaceRole);
            showSuccessNotification('Role updated');
        }
        catch (e) {
            showErrorNotification(e);
        }
        finally { setReplaceRole(null); }
    };


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.code}</Table.Cell>
						<Table.Cell width={2}>{entry.label}</Table.Cell>
						<Table.Cell width={2}>
							{!entry.disabled &&
								<ComponentWithConfirmation popup='Disable role' onConfirm={() => toogle(entry.id, entry.disabled)} confirmContent={'Are you sure you want disable role?'}>
									<Icon disabled={!writePermission} className='tableActionIcon' color='green' size='large' name="check circle" />
								</ComponentWithConfirmation>}
							{entry.disabled &&
								<ComponentWithConfirmation popup='Enable role' onConfirm={() => toogle(entry.id, entry.disabled)} confirmContent={'Are you sure you want enable role?'}>
									<Icon disabled={!writePermission} className='tableActionIcon' color='red' size='large' name="times circle" />
								</ComponentWithConfirmation>}
						</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.createdDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='Edit role' trigger={<Icon className='tableActionIcon' size='large' name="edit outline" onClick={() => handlEditRole(entry.id)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>No roles yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Confirm
				open={!!(replaceRole?.code)}
				onCancel={() => setReplaceRole(null)}
				onConfirm={confirmRoleReplace}
				content='Role with such code already exist. Do you want to replace it?'
				size='mini'
				cancelButton='No'
				confirmButton='Yes'
			/>
			<Segment>
				<RoleModal
					open={openRoleModal}
					roleDetails={editRole}
					onClose={handleCloseRoleModal}
					availablePermissions={availablePermissions}
					writePermission={writePermission}
				/>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Roles</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button
								floated='right'
								primary
								disabled={!writePermission}
								onClick={handleAddRole}
							>
								Add Role</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default Roles;
