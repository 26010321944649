import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Header, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getUnassignedPayments, postMarkAsMatched } from '@/features/unassignedPayments/unassignedPaymentsSlice';
import NumberFormat from 'react-number-format';
import UnassignedPaymentsModal from './UnassignedPaymentsModal';
import { UnassignedPayment } from '@/features/unassignedPayments/unassignedPaymentsTypes';
import { formatDateWithPattern } from '@/helpers/date';

interface Props {
	writePermission: boolean,
}

const UnassignedPayments = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.unassignedPayments
	);

	const paginateInitialState: PaginateParams = { orderBy: 'entryDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);
	const [open, setOpen] = useState<boolean>(false);
	const [details, setDetails] = useState<UnassignedPayment>(null);

	const filter: FilterParam[] = [
		{
			field: 'amount',
			label: 'Amount',
			type: 'input',
			inputType: 'number'
		},
		{
			field: 'fileName',
			label: 'File Name',
			type: 'input'
		},
		{
			field: 'paymentDetails',
			label: 'Payment Details',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'fileName',
			name: 'File Name',
			position: 1,
			sortable: true
		},
		{
			field: 'entryDate',
			name: 'Date',
			position: 2,
			sortable: true
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 3,
			sortable: true
		},
		{
			field: 'paymentDetails',
			name: 'Payment Details',
			position: 4,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 5,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getUnassignedPayments(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const handleOnCloseMatch = async (markAsMatched?: boolean) => {
		setOpen(false);
		if (markAsMatched) {
			try {
				await postMarkAsMatched(details.id);
				fetchData();
			} catch (e) {
				showErrorNotification(e);
			}
		}
		setDetails(null);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.fileName}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.entryDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>
							{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} />
						</Table.Cell>
						<Table.Cell width={8}>
							<Popup
								trigger={<div>{entry.paymentDetails.length > 50 ? entry.paymentDetails.slice(0, 50).concat('...') : entry.paymentDetails}</div>}
								content={entry.paymentDetails}
								position='top center'
								wide='very'
							/>
						</Table.Cell>
						<Table.Cell width={2}>
							<Button primary disabled={!writePermission} content='MATCH' onClick={() => { setDetails(entry); setOpen(true); }} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>Not unassigned payments yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<UnassignedPaymentsModal
					open={open}
					onClose={handleOnCloseMatch}
					details={details}
				/>
				<Header as='h2'>Files Unassigned Payments</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Segment>
		</Container>
	);
};


export default UnassignedPayments;
