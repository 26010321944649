export interface AdminType {
    id: number,
    email: string,
    createdDate: string,
    lastLoginDate: string,
    disabled: string,
    confirmedMobileNumber: string,
    firstName: string,
    lastName: string,
    country: string,
    type: string,
}

export interface CreateAdminPayload {
    type: string,
    email: string,
    firstName: string,
    lastName: string,
    mobileNumber: string,
    country: string,
    roleId: number,
    wlpAdminWlpId: number,
}

export interface AdminsStore {
    loading: boolean,
    list: Array<AdminType>,
    error: string,
    count: number,
    totalPages: number,
    pageSize: number
}


export interface EmiListType {
    emiWlpList: Array<{ name: string, wlpId: string }>,
    isEmi: boolean
}

export interface AdminRolesResposeTypes {
    id: number,
    code: string,
    label: string
}  

export enum AdminsRoleType {
    EMI_ADMIN = 'EMI_ADMIN',
    WLP_ADMIN = 'WLP_ADMIN'
}
