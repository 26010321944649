import React, { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, SubmitButton, Select } from 'formik-semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { downloadBalance } from '@/features/statements/accountBalanceSlice';
import { PaginateParams } from '@/helpers/globalTypes';
import { getBankAccountList } from '@/features/statements/statementsSlice';
import { DownloadBalanceProps } from '@/features/statements/statementsTypes';

const BalanceDownload = () => {

	const dispatch = useDispatch();
	const periodList = useSelector((state: RootState) => state.periods.list);
	const isDownloading = useSelector((state: RootState) => state.accountBalance.isDownloading);
	const periodLov = periodList.map((p) => { return { key: p.periodName, text: p.periodName, value: p.periodName }; });

	const [bankAccountLov, setBankAccountLov] = useState<any>();

	useEffect(() => {
		const fetch = async () => {
			const params: PaginateParams = { orderBy: 'treeAccountPath', skip: 0, sort: 'ASC', filter: 'accountType=ROOT_ACCOUNT', take: 100 };
			const list = await getBankAccountList(params);
			const result = list.map((p) => { return { key: p.bankAccountIdLov.toString(), text: p.description, value: p.bankAccountIdLov.toString() }; });
			setBankAccountLov(result);
		};
		fetch();
	}, []);


	const formatOptions = [
		{
			key: 'SHORT',
			text: 'Short',
			value: 'SHORT',
		},
		{
			key: 'LONG',
			text: 'Long',
			value: 'LONG',
		}
	];

	const validationSchema = Yup.object({
		period: Yup.string().required(),
		rootAccountId: Yup.string().required(),
		format: Yup.string().required()
	});

	const initialValues = {
		period: '',
		rootBankAccountId: '',
		format: 'SHORT'
	};


	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);
		try {
			const payload: DownloadBalanceProps = {
				rootBankAccountId: formData.rootAccountId,
				period: formData.period,
				format: formData.format
			};
			dispatch(
				downloadBalance(payload));
			setSubmitting(false);
		}
		finally {
			setSubmitting(false);
		}

	};

	return (
		<div id='table-layout' >
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>{({ isSubmitting, isValid, dirty }) => {
				return (
					<Form >
						<Grid id='filters'>
							<Grid.Row>
								<Select
									key='period'
									name='period'
									label='Period'
									className='field'
									options={periodLov}
									selection
								/>
								<Select
									key='rootAccountId'
									name='rootAccountId'
									label='Root Account'
									className='field'
									options={bankAccountLov}
									selection
								/>
								<Select
									key='format'
									name='format'
									label='Format'
									className='field'
									options={formatOptions}
									selection
								/></Grid.Row>
							<Grid.Row id='buttons'>
								<SubmitButton type='submit'
									disabled={isSubmitting || !isValid || !dirty || isDownloading}
									primary basic>Download</SubmitButton>
							</Grid.Row>
						</Grid>
					</Form>);
			}}
			</Formik>
		</div>
	);
};

export default (BalanceDownload);
