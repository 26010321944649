import React, { useEffect, useState } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Checkbox, Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { RoleDetails, RolePermissions } from '@/features/roles/rolesTypes';


interface Props {
	open: boolean;
	onClose: (code?: string, name?: string, disabled?: boolean, rolePermissions?: Array<RolePermissions>, edit?: number) => void,
	roleDetails: RoleDetails,
	availablePermissions: Array<RolePermissions>,
	writePermission: boolean
}

const RoleModal = ({ open, onClose, roleDetails, availablePermissions, writePermission }: Props): React.ReactElement => {

	
	const prepareInitValues = () => {
		const initValues = {
			code: roleDetails ? roleDetails.code : '',
			name: roleDetails ? roleDetails.label : '',
			disabled: roleDetails ? roleDetails.disabled : false,
		};
		availablePermissions && availablePermissions.map(entry => {
			initValues[entry.permissionCode] = roleDetails && roleDetails.permissions.find(p => p.permissionCode === entry.permissionCode) ? true : false;
			initValues[entry.permissionCode + '_write'] = roleDetails && roleDetails.permissions.find(p => p.permissionCode === entry.permissionCode && p.writeable === true) ? true : false;
		});
		return initValues;
	};
	const [initialValues, setInitialValues] = useState<any>(prepareInitValues);

	useEffect(() => {
		if (!availablePermissions) {
			return;
		}
		const initValues = {
			code: roleDetails ? roleDetails.code : '',
			name: roleDetails ? roleDetails.label : '',
			disabled: roleDetails ? roleDetails.disabled : false,
		};
		availablePermissions.map(entry => {
			initValues[entry.permissionCode] = roleDetails && roleDetails.permissions.find(p => p.permissionCode === entry.permissionCode) ? true : false;
			initValues[entry.permissionCode + '_write'] = roleDetails && roleDetails.permissions.find(p => p.permissionCode === entry.permissionCode && p.writeable === true) ? true : false;
		});
		setInitialValues(initValues);
	}, [roleDetails, availablePermissions]);

	const validationSchema = Yup.object({
		code: Yup.string()
			.required('You must enter an code'),
		name: Yup.string()
			.required('You must enter a name')

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { code, name, disabled } = formData;

		const permissions: Array<RolePermissions> = [];
		availablePermissions.map(entry => {
			if (formData[entry.permissionCode]) {
				permissions.push({
					permissionCode: entry.permissionCode,
					name: entry.name,
					writeable: formData[entry.permissionCode + '_write'] ? true : false
				});
			}
		});
		setInitialValues(null);
		onClose(code, name, disabled, permissions, roleDetails ? roleDetails.id : null);
	};


	const handlePermissionsField = (entry, setFieldValue) => {
		return (
			<Grid key={entry.permissionCode}>
				<Grid.Row columns={3} style={{ paddingBottom: 0 }}>
					<Grid.Column width={8}>
						<Header as='h4' content={entry.name} />
					</Grid.Column>
					<Grid.Column width={4}>
						<Checkbox fitted
							name={entry.permissionCode}
							label='Read'
							onChange={(e) => {
								if (e.target) {
									setFieldValue(entry.permissionCode + '_write', false);
								}
							}}
							disabled={!writePermission}
						/>
					</Grid.Column>
					<Grid.Column width={4}>
						<Checkbox fitted
							name={entry.permissionCode + '_write'}
							label='Write'
							onChange={(e) => {
								if (e.target) {
									setFieldValue(entry.permissionCode, true);
								}
							}}
							disabled={!writePermission}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
				enableReinitialize={true}
			>
				{({ setFieldValue, errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content={roleDetails ? 'Edit role' : 'Add new role'} />

						<Input
							label='Code'
							fluid
							name="code"
							disabled={!writePermission}
						/>
						<Input
							label='Name'
							fluid
							name="name"
							disabled={!writePermission}
						/>
						<Checkbox fitted
							name="disabled"
							label='Disabled'
							disabled={!writePermission}
						/>
						<Divider />

						{availablePermissions && availablePermissions.map(entry =>
							handlePermissionsField(entry, setFieldValue)
						)}

						<Divider hidden />
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty || !writePermission}
										primary size="large" type="submit"
										floated='right' >
										{roleDetails ? 'Edit' : 'Add'}
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default RoleModal;
