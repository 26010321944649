export interface Status {
	loggedIn: boolean,
	source: string,
	permissions: Array<Permission>,
	restricted: string,
	adminUserId: number,
	userRole: UserRole
}

export interface Permission {
	permission: string,
	writePermission: boolean
}

export interface UserRole {
	agentCode: string,
	assignable: boolean,
	code: string,
	disabled: boolean,
	editable: boolean,
	id: number,
	label: string
}

export interface MaintenanceMode {
	mode: boolean, reason: string
}

export enum UserType {
	INDIVIDUAL = 'INDIVIDUAL',
	BUSINESS = 'BUSINESS',
	EMPLOYEE = 'EMPLOYEE'
}

export interface UserError {
	error: string,
	error_param: string
}

export interface UserStore {
	status: Status,
	error: UserError,
	maintenanceMode: MaintenanceMode,
	permissions: Array<Permission>,
	accrossAllWhitelabels: boolean
}

export interface DeviceInfo {
	browser: string,
	platform: string,
	hash: string
}

export interface LoginBody {
	email: string,
	password: string,
	deviceInfo: DeviceInfo
}

export interface Credentials {
	email: string,
	token: string
}

export interface UserLevel {
	userLevelId: number,
	name: string,
	description: string,
	priority: number,
	defaultLevel: boolean,
}

export enum PermissionType {
	DASHBOARD = 'DASHBOARD',
	UNASSIGNED_PAYMENTS = 'UNASSIGNED_PAYMENTS',
	FILE_UPLOAD = 'FILE_UPLOAD',
	SYSTEM_ERRORS = 'SYSTEM_ERRORS',
	SETTINGS = 'SETTINGS',
	ALL_TRANSACTIONS = 'ALL_TRANSACTIONS',
	BANK_TRANSACTIONS = 'BANK_TRANSACTIONS',
	CARD_TRANSACTIONS = 'CARD_TRANSACTIONS',
	ACCOUNTS = 'ACCOUNTS',
	COMPLIANCE = 'COMPLIANCE',
	SUPPORT = 'SUPPORT',
	//SYSTEM_USERS = 'SYSTEM_USERS',
	FAQ = 'FAQ',
	NOTIFICATIONS_TRIGGERS = 'NOTIFICATIONS_TRIGGERS',
	ACTION_CENTER = 'ACTION_CENTER',
	PENDING_TRANSACTIONS = 'PENDING_TRANSACTIONS',
	PENDING_CARDS = 'PENDING_CARDS',
	PENDING_KYC = 'PENDING_KYC',
	PENDING_TICKETS = 'PENDING_TICKETS',
	//CLIENTS = 'CLIENTS',
	LANGUAGES = 'LANGUAGES',
	ROLES = 'ROLES',
	COUNTRY_LIMITS = 'COUNTRY_LIMITS',
	ACTIVITY_LOGS = 'ACTIVITY_LOGS',
	PROCESSES = 'PROCESSES',
	REPORTS = 'REPORTS',
	CARDS = 'CARDS',
	STOCK = 'STOCK',
	USER_LEVEL = 'USER_LEVEL',
	USERS = 'USERS',
	BANK_DOCUMENTS = 'BANK_DOCUMENTS',
	CARD_DOCUMENTS = 'CARD_DOCUMENTS',
	CRYPTO_DOCUMENTS = 'CRYPTO_DOCUMENTS',
	TRUST_DOCUMENTS = 'TRUST_DOCUMENTS',
	POA_COMPLIANCE = 'POA_COMPLIANCE',
	TOKENS = 'TOKENS',
	VAULTS = 'TOKENS',
	RECONCILIATIONS='RECONCILIATIONS',
	TRUST = 'TRUST',
	CURRENCY_EXCHANGE = 'CURRENCY_EXCHANGE',
	CHANGE_PHONE = 'CHANGE_PHONE',
	RESET_PASSCODE = 'RESET_PASSCODE',
	BLOCK_CARD = 'BLOCK_CARD',
	UPDATE_KYC = 'UPDATE_KYC',
	SYSTEM_ADMINS = 'SYSTEM_ADMINS',
	REFERRALS = 'REFERRALS',
	INTERNAL_SYSTEM_TRANSFERS = 'INTERNAL_SYSTEM_TRANSFERS',
	MAINTENANCE = 'MAINTENANCE',
}
