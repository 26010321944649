import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { showErrorNotification } from '../swal/slice';
import { KycAddress, KycIssue, KycIssuesStore } from './types';

const initialState: KycIssuesStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'kycIssues',
	initialState,
	reducers: {
		setKycIssues(state, action: PayloadAction<Array<KycIssue>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setKycIssues, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getKycIssuesList = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/compliance/issues/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setKycIssues(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getKycAddressList = async (userId: number): Promise<{ count: number, list: KycAddress[] }> => {
	const response = await request.get(`/api/admin/kyc-address/${userId}`);
	const { data } = response;
	return data;
};


export const createKycAddress = async (payload: KycAddress) => {
	const response = await request.post('/api/admin/kyc-address', payload);
	const { data } = response;
	return data;
};


export const updateKycAddress = async (payload: KycAddress) => {
	const response = await request.post('/api/admin/kyc-address/update', payload);
	const { data } = response;
	return data;
};
export default slice.reducer;
