/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, List, Popup, Segment } from 'semantic-ui-react';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import Loading from '@/components/Shared/Loading';
import '../Clients/ClientDetails/Profile.css';
import { RootState } from '@/rootReducer';
import { PermissionType } from '@/features/user/types';
import { checkPermission, checkWritePermission } from '@/helpers/permissions';
import { useHistory } from 'react-router-dom';
import Transactions from '../Transactions/Transactions';
import { blockCard, getCard, pullOutCard, topUpAndCloseCard, topUpCard } from '@/features/cards/slice';
import { Card } from '@/features/cards/types';
import { approveDocument, rejectDocument } from '@/features/documents/slice';
import { ApprovedBy, ApprovedStatus } from '@/features/documents/types';
import NumberFormat from 'react-number-format';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import BlockCardModal from './BlockCardModal';
import TopupAndCloseCardModal from './TopupAndCloseCardModal';
import TopupCardModal from './TopupCardModal';

const CardDetails = (props): React.ReactElement => {

	//	const dispatch = useDispatch();
	const [cardId] = useState<number>(props.match.params.cardId);
	const [card, setCard] = useState<Card>(null);
	const [approvedOrRejectedByYou, setApprovedOrRejectedByYou] = useState<boolean>(false);
	const [openBlockCardModal, setOpenBlockCardModal] = useState<boolean>(false);
	const [openTopupAndCloseCardModal, setOpenTopupAndCloseCardModal] = useState<boolean>(false);
	const [openTopupCardModal, setOpenTopupCardModal] = useState<boolean>(false);

	const { permissions, status } = useSelector(
		(state: RootState) => state.user
	);


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getCard(cardId);
				setCard(data);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [cardId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (card && card.approvedBy.find(element => element.userId === status.adminUserId)) {
			setApprovedOrRejectedByYou(true);
		}
	}, [card, status.adminUserId]);

	const isBlockAvailable = ['ACTIVE', 'SUSPENDED', 'INACTIVE', 'ORDERED', 'ORDER_COMPLETED'].includes(card?.status);
	const isTopUpAndCloseAvailable = card?.balance < 0;

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const approveCard = async () => {
		try {
			await approveDocument(card.documentNumber, 'CARD');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const rejectCard = async () => {
		try {
			await rejectDocument(card.documentNumber, 'CARD');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const showApprovedBy = (entry: ApprovedBy) => {
		if (entry.status === ApprovedStatus.APPROVED) {
			return (
				<List.Description key={entry.userId}>
					{`${entry.userId} - ${entry.user}`}
					<Popup content='View system user' trigger={<Icon className='actionIcon' name="user" onClick={() => openUser(entry.userId)} />} />
				</List.Description>
			);
		}
	};

	const showRejectedBy = (entry: ApprovedBy) => {
		if (entry.status === ApprovedStatus.REJECTED) {
			return (
				<List.Description key={entry.userId}>
					{`${entry.userId} - ${entry.user}`}
					<Popup content='View system user' trigger={<Icon className='actionIcon' name="user" onClick={() => openUser(entry.userId)} />} />
				</List.Description>
			);
		}
	};

	const handleBlockCardModal = async (account?: number, pulloutOnly?: boolean, amount?: number) => {
		setOpenBlockCardModal(false);
		if(account) {
			try {
				if(pulloutOnly) {
					if(!amount) {
						return;
					}
					await pullOutCard(card.id, account, card.accountId, amount, card.userId);
					showSuccessNotification('Card pulled out');
				} else {
					await blockCard(card.id, account, card.userId);
					showSuccessNotification('Card blocked');
				}
			} catch (err) {
				showErrorNotification(err);
			}
			fetchData();
		}
	};
	
	const handleTopupAndCloseCard = async (walletId?: string) => {
		setOpenTopupAndCloseCardModal(false);
		if(walletId) {
			try {
				await topUpAndCloseCard(card.id, walletId);
				showSuccessNotification('Card closed');
			} catch (err) {
				showErrorNotification(err);
			}
			fetchData();
		}
	};
	
	const handleTopupCard = async (walletId?: string, amount?: number) => {
		setOpenTopupCardModal(false);
		if(walletId && amount) {
			try {
				await topUpCard(card.id, walletId, amount);
				showSuccessNotification('Card topped up');
			} catch (err) {
				showErrorNotification(err);
			}
			fetchData();
		}
	};

	if (!card) {
		return <Loading />;
	}

	return (
		<Container fluid>
			<BlockCardModal 
				open={openBlockCardModal}
				card={card}
				onClose={handleBlockCardModal}
			/>
			<TopupAndCloseCardModal 
				open={openTopupAndCloseCardModal}
				onClose={handleTopupAndCloseCard}
			/>
			<TopupCardModal 
			open={openTopupCardModal}
			card={card}
			onClose={handleTopupCard}
			/>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Card</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
							<Button
								style={{ marginLeft: 10 }}
								secondary
								content='View user'
								disabled={!checkPermission(permissions, PermissionType.USERS)}
								onClick={() => openUser(card.userId)}
								floated='right'
							/>
							{isBlockAvailable && <Button
								style={{ marginLeft: 10 }}
								color='red'
								content='Block card'
								disabled={!checkPermission(permissions, PermissionType.BLOCK_CARD)}
								onClick={() => setOpenBlockCardModal(true)}
								floated='right'
							/>}
							{isTopUpAndCloseAvailable && <Button
								style={{ marginLeft: 10 }}
								color='red'
								content='Topup and close'
								disabled={!checkPermission(permissions, PermissionType.BLOCK_CARD)}
								onClick={() => setOpenTopupAndCloseCardModal(true)}
								floated='right'
							/>}
							<Button
								style={{ marginLeft: 10 }}
								color='green'
								content='Topup'
								disabled={!checkPermission(permissions, PermissionType.BLOCK_CARD)}
								onClick={() => setOpenTopupCardModal(true)}
								floated='right'
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable columns={2}>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Card name</List.Header>
										<List.Description>{card.name}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Type</List.Header>
										<List.Description>{card.virtual ? 'Virtual' : 'Physical'}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Status</List.Header>
										<List.Description>{card.status}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Tracking Code</List.Header>
										<List.Description>{card.trackingNumber ?? 'No Tracking Code'}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Balance</List.Header>
										<List.Description>{card.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={card.balance} /></List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Balance on hold</List.Header>
										<List.Description>{card.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={card.balanceOnHold} /></List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Balance frozen</List.Header>
										<List.Description>{card.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={card.balanceFrozen} /></List.Description>
									</List.Content>
								</List.Item>
							</List>
						</Segment>
					</Grid.Column>


					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								{card.documentStatus === 'APPROVAL' && !approvedOrRejectedByYou && <Grid columns={2}>
									<Grid.Row>
										<Grid.Column>
											<ComponentWithConfirmation onConfirm={approveCard} confirmContent={'Are you sure you want approve card?'}>
												<Button
													color='green'
													content='Approve'
													disabled={!checkWritePermission(permissions, PermissionType.CARDS)}
													floated='right'
												/>
											</ComponentWithConfirmation>
										</Grid.Column>
										<Grid.Column>
											<ComponentWithConfirmation onConfirm={rejectCard} confirmContent={'Are you sure you want reject card?'}>
												<Button
													color='red'
													content='Reject'
													disabled={!checkWritePermission(permissions, PermissionType.CARDS)}
													floated='left'
												/>
											</ComponentWithConfirmation>
										</Grid.Column>
									</Grid.Row>
								</Grid>}
								<Divider hidden />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Approved by:</List.Header>
										{card.approvedBy && card.approvedBy.map(entry =>
											showApprovedBy(entry)
										)}
									</List.Content>
								</List.Item>
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Rejected by:</List.Header>
										{card.approvedBy && card.approvedBy.map(entry =>
											showRejectedBy(entry)
										)}
									</List.Content>
								</List.Item>
								{card.deliveryAddress && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Delivery country</List.Header>
											<List.Description>{card.deliveryAddress.country}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Delivery city</List.Header>
											<List.Description>{card.deliveryAddress.city}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Delivery address</List.Header>
											<List.Description>{card.deliveryAddress.address}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Delivery zip</List.Header>
											<List.Description>{card.deliveryAddress.zip}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Delivery phone</List.Header>
											<List.Description>{card.deliveryAddress.phone}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
								</div>}
							</List>
						</Segment>
					</Grid.Column>

				</Grid>
			</Segment>
			<Segment>
				<Header as='h2'>Transactions</Header>
				<Transactions accountId={card.accountId} />
			</Segment>
		</Container>
	);
};

export default CardDetails;
