/* eslint-disable */
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory, useLocation } from 'react-router-dom';
import { getVaultPlannedTransactions } from '@/features/vaultPlannedTransactions/slice';
import { formatTransactionType, transactionTypeOptions } from './helpers';

const PlannedTransfers = (): React.ReactElement => {

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);
	const [fromWalletId] = useState<string>(searchParams.get('sysWalletId'));
	const [transferDate] = useState<string>(searchParams.get('transferDate'));
	const [transactionType] = useState<string>(searchParams.get('transactionType'));
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.vaultPlannedTransactions
	);

	const searchFilter = `sysWalletId=${fromWalletId};transferDate=${transferDate};transactionType=${transactionType}`

	const paginateInitialState: PaginateParams = { orderBy: 'transferDate', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const onloadPagination = { ...paginateInitialState, filter: searchFilter };

	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);
	const [takeOnloadParams, setTakeOnloadParams] = useState<boolean>(true);

	useEffect(() => {
		setTakeOnloadParams(false);
	}, []);

	useEffect(() => {
		dispatch(getVaultPlannedTransactions(takeOnloadParams ? onloadPagination : pagination));
	}, [dispatch, pagination]);

	const individualFilter: FilterParam[] = [
		{
			field: 'sysWalletId',
			label: 'From Wallet',
			type: 'input',
		},
		{
			field: 'toWalletId',
			label: 'To Wallet',
			type: 'input',
		},
		{
			field: 'transferDate',
			label: 'Transfer Date',
			type: 'input',
		},
		{
			field: 'transactionType',
			label: 'Transaction Type',
			type: 'select',
			options: transactionTypeOptions,
		}
	];


	const headers: Array<Headers> = [
		{
			field: 'sysWalletId',
			name: 'From Wallet',
			position: 1,
			sortable: true
		},
		{
			field: 'toWalletId',
			name: 'To Wallet',
			position: 2,
			sortable: true
		},
		{
			field: 'transferDate',
			name: 'Transfer Date',
			position: 3,
			sortable: true
		},
		{
			field: 'transactionType',
			name: 'Transaction Type',
			position: 4,
			sortable: true
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 5,
			sortable: false
		},
		{
			field: 'feeType',
			name: 'Fee Type',
			position: 6,
			sortable: false
		}
	];

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.fromWalletId}</Table.Cell>
						<Table.Cell width={2}>{entry.toWalletId}</Table.Cell>
						<Table.Cell width={2}>{entry.transferDate}</Table.Cell>
						<Table.Cell width={2}>{formatTransactionType(entry.transactionType)}</Table.Cell>
						<Table.Cell width={1}>{entry.amount}</Table.Cell>
						<Table.Cell width={1}>{entry.feeType}</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>You dont have planned transfers yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Planned Transfers</Header></Grid.Column>
						<Grid.Column floated='right'><Button floated='right' primary onClick={() => history.goBack()}>Go back</Button></Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					filter={individualFilter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Segment>
		</Container>
	);
};

export default PlannedTransfers;
