/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Header, Segment, Comment, Message, Icon, Divider, Grid, Button } from 'semantic-ui-react';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import Loading from '@/components/Shared/Loading';
import '../Clients/ClientDetails/Profile.css';
import { RootState } from '@/rootReducer';
import { useHistory } from 'react-router-dom';
import { downloadAttachment, getAuthorInitials, getTicket, replyOnTicket, updateTicketStatus } from '@/features/tickets/ticketsSlice';
import { Ticket, TicketMessage, TicketMessageAttachment } from '@/features/tickets/ticketsType';
import { PermissionType, UserType } from '@/features/user/types';
import { formatDateWithPattern } from '@/helpers/date';
import './Tickets.css';
import { capitalizeFirstLetter } from '@/helpers/string';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, Form, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import { isEmpty } from 'lodash';
import { checkWritePermission } from '@/helpers/permissions';
import { useTranslation } from 'react-i18next';

const TicketDetails = (props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const [ticketId] = useState<any>(props.match.params.ticketId);
	const [ticket, setTicket] = useState<Ticket>(null);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const hiddenFileInput = React.useRef(null);

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const fileTypes = [
		'image/jpg',
		'image/jpeg',
		'image/png',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	];

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getTicket(ticketId);
				setTicket(data);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [ticketId]);

	useEffect(() => {
		fetchData();
	}, [ticketId, dispatch, fetchData]);

	if (!ticket) {
		return <Loading />;
	}


	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const validationSchema = Yup.object({
		message: Yup.string().required(t('form.validator.required')),
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > 5) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (files[i].size > 5000000) {
				showErrorNotification(t('swal.FILE_SIZE_TOO_LARGE', { fileName: files[i].name }));
				continue;
			}
			if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < 5) {
				selectedFiles.push(files[i]);
				setSelectedFiles(selectedFiles);
			}
		}
	};

	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
	};

	const onSend = (value, { setSubmitting, setErrors, resetForm }) => {
		const get = async () => {
			try {
				if (value.message === undefined) {
					return;
				}
				const formData = new FormData();
				formData.append('ticketId', ticketId);
				formData.append('message', value.message);
				formData.append('sendByEmail', value.sendByEmail);


				if (value.files) {
					value.files.map(file => {
						formData.append('files', file);
					});
				}

				await replyOnTicket(formData);
				showSuccessNotification('Reply successfully sent');
				resetForm({});
				setSelectedFiles([]);
				fetchData();
			} catch (e) {
				showErrorNotification(e);
				setErrors({ submit: e.message });
				setSubmitting(false);
			}
		};
		get();
	};

	const updateStatus = async (status: 'solved' | 'rejected') => {
		try {
			await updateTicketStatus(ticket.id, status);
			showSuccessNotification(`Ticket successfully ${status}.`);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const userEmail = JSON.parse(ticket?.requestMeta)?.email || null;
	
	return (
		<Container fluid className='ticketContainer'>
			<Segment style={{ width: '80%' }}>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Ticket ({ticket.status})</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button floated='right'
								style={{ marginLeft: 10 }}
								primary
								onClick={() => props.history.goBack()}
								content='Go back'
							/>
							{ticket && ticket.status !== 'REJECTED' && ticket.status !== 'SOLVED' && <Button
								style={{ marginLeft: 10 }}
								color='green'
								content='Solve'
								floated='right'
								disabled={!checkWritePermission(permissions, PermissionType.SUPPORT)}
								onClick={() => updateStatus('solved')}
							/>}
							{ticket && ticket.status !== 'REJECTED' && ticket.status !== 'SOLVED' && <Button
								style={{ marginLeft: 10 }}
								color='red'
								content='Reject'
								floated='right'
								disabled={!checkWritePermission(permissions, PermissionType.SUPPORT)}
								onClick={() => updateStatus('rejected')}
							/>}
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Comment.Group size='large' style={{ maxWidth: 'unset' }}>
					<Header as='h3' dividing>
						<Grid>
							<Grid.Row columns={2}>
								<Grid.Column>
									Subject: {ticket.title}
								</Grid.Column>
								{userEmail &&
									<Grid.Column style={{ textAlign: 'right' }}>
										Client email: {userEmail}
									</Grid.Column>
								}
							</Grid.Row>
						</Grid>
					</Header>
					<div className='messages' >
						{ticket && ticket.ticketMessages.map((entry, index) => (
							<ChatMessage message={entry} openUser={openUser} key={index + entry.createdDate} ticketId={ticket.id} />
						))}
					</div>
				</Comment.Group>
				<Divider />



				<Formik
					initialValues={{
						message: '',
						sendByEmail: ticket.userId === -1 ? true : false
					}}
					enableReinitialize
					onSubmit={onSend}
					validationSchema={validationSchema}
					render={({ setFieldValue, isSubmitting, errors, dirty }) => {
						return (
							<Form>
								<TextArea
									label='Message'
									name="message"
									style={{ minHeight: 100 }}
									errorPrompt
									disabled={!checkWritePermission(permissions, PermissionType.SUPPORT) || ticket.status === 'REJECTED' || ticket.status === 'SOLVED'}
								/>
								{userEmail &&
									<Checkbox fitted
										name="sendByEmail"
										label='Send by email'
										disabled={ticket.userId === -1}
									/>}
								<Divider hidden />

								<input type="file"
									multiple
									accept="image/jpg, image/png, image/jpeg, .pdf, .doc, .docx"
									name="files"
									ref={hiddenFileInput}
									onChange={e => {
										uploadFiles(e.currentTarget.files);
										setFieldValue('files', selectedFiles);
									}}
									style={{ display: 'none' }}
								/>
								<Grid columns={2} style={{ paddingBottom: '1rem' }}>
									<Grid.Column>
										{selectedFiles.length > 0 && (
											<Grid className="uploadedFiles" style={{ justifyContent: 'left' }} >
												{selectedFiles.map((file, index) =>
													<Grid.Row className="uploadedFile" key={file.name + index} style={{ paddingTop: 0 }}>
														<Icon size="large" name="file outline" />
														<div className="fileName">{file.name}</div>
														<Icon className="deleteAttachment"
															onClick={() => {
																deleteAttachment(index);
																setFieldValue('files', selectedFiles);
															}}
															size="large"
															name="times" />
													</Grid.Row>
												)}
											</Grid>
										)}
									</Grid.Column>

									<Grid.Column>
										<Grid style={{ justifyContent: 'right' }}>
											<Button type="button"
												onClick={handleUploadClick}
												content='Attach files'
												icon="upload"
												disabled={!checkWritePermission(permissions, PermissionType.SUPPORT) || ticket.status === 'REJECTED' || ticket.status === 'SOLVED'}
											/>
											<SubmitButton primary
												type="submit"
												disabled={!checkWritePermission(permissions, PermissionType.SUPPORT) || isSubmitting || !isEmpty(errors) || !dirty || ticket.status === 'REJECTED' || ticket.status === 'SOLVED'}>
												Send
											</SubmitButton>
										</Grid>
									</Grid.Column>
								</Grid>
							</Form>);
					}}
				/>
			</Segment>
		</Container>
	);
};

export interface Props {
	message: TicketMessage,
	ticketId: number,
	openUser: (userId: number) => void
}

const ChatMessage = ({ message, openUser, ticketId }: Props): React.ReactElement => {

	const download = (id: number, attachment: TicketMessageAttachment) => {
		const get = async () => {
			try {
				downloadAttachment(id, attachment);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	};

	return (
		<Message style={{ width: '45%', marginLeft: message.author === 'USER' ? 'auto' : 'unset' }}>
			<Comment>
				{message?.authorData &&
					<Comment.Avatar as='div' onClick={() => openUser(message.authorData.userId)} data-letters={getAuthorInitials(message.authorData)} />
				}
				<Comment.Content>
					{message?.authorData &&
						<Comment.Author as='a' onClick={() => openUser(message.authorData.userId)}>
							{message.authorData.type === UserType.BUSINESS ? message.authorData.companyName??'' : `${capitalizeFirstLetter(message.authorData.firstName??'')} ${capitalizeFirstLetter(message.authorData.lastName??'')}`}
						</Comment.Author>
					}
					<Comment.Metadata>
						<span>{formatDateWithPattern(message.createdDate, 'dd/MM/yyyy HH:mm:ss')}</span>
					</Comment.Metadata>
					<Comment.Text dangerouslySetInnerHTML={{ __html: message.message }} />
				</Comment.Content>
				{message.ticketAttachments.length > 0 && <Comment.Actions>
					<br />
					{message.ticketAttachments.map(attachment =>
						<>
							<Comment.Action
								onClick={() => download(ticketId, attachment)}
								key={attachment.key}
							>
								<Icon size="small" name="file outline" />{attachment.name}
							</Comment.Action>
						</>
					)}
				</Comment.Actions>}
			</Comment>
		</Message>
	);
};

export default TicketDetails;
