/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, Label, List, Segment } from 'semantic-ui-react';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import '../Clients/ClientDetails/Profile.css';
import { RootState } from '@/rootReducer';
import { PermissionType } from '@/features/user/types';
import { checkPermission, checkWritePermission } from '@/helpers/permissions';
import { useHistory } from 'react-router-dom';
import { AddValuePayload, Compliance, Field, Form, Group, Section } from '@/features/compliance/types';
import { addValue, approveKyc, downloadAttachment, editValue, getForm, rejectKyc } from '@/features/compliance/slice';
import Loading from '@/components/Shared/Loading';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import './Compliance.css';
import ComplianceRejectionModal from './ComplianceRejectionModal';
import ComplianceEditValueModal from './ComplianceEditValueModal';
import ComplianceUploadValueModal from './ComplianceUploadValueModal';

const ComplianceDetails = (props): React.ReactElement => {

    const dispatch = useDispatch();
    const [applicantId] = useState<string>(props.match.params.applicantId);
    const [compliance, setCompliance] = useState<Compliance>(null);
    const [form, setForm] = useState<Form>(null);
    const [sortedSections, setSortedSections] = useState<Section[]>(null);
    const [openRejectionModal, setOpenRejectionModal] = useState<boolean>(false);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
    const [editField, setEditField] = useState<Field>(null);

    const history = useHistory();


    const { permissions } = useSelector(
        (state: RootState) => state.user
    );

    const fetchData = useCallback(() => {
        const get = async () => {
            try {
                const data = await getForm(applicantId);
                setForm(data.form);
                setCompliance(data.kyc);
            } catch (e) {
                showErrorNotification(e);
            }
        };
        get();
    }, [applicantId, dispatch]);

    useEffect(() => {
        fetchData();
    }, [applicantId, dispatch]);

    useEffect(() => {
        if (!form) return;
        setSortedSections(getSortedSections(form.sections));
    }, [form]);

    const openUser = (userId: number) => {
        history.push(`/client/${userId}`);
    };

    const getSortedSections = (sections: Section[]) => {
        const arrayForSort = [...sections];
        return arrayForSort.sort((a, b) => a.order - b.order);
    };

    const getSortedGroups = (groups: Group[]) => {
        const arrayForSort = [...groups];
        return arrayForSort.sort((a, b) => a.order - b.order);
    };

    const getSortedFields = (fields: Field[]) => {
        const arrayForSort = [...fields];
        return arrayForSort.sort((a, b) => a.order - b.order);
    };

    const download = (key: string, name: string) => {
        const get = async () => {
            try {
                downloadAttachment(key, name);
            } catch (e) {
                showErrorNotification(e);
            }
        };
        get();
    };

    const approve = async () => {
        try {
            await approveKyc(applicantId);
            showSuccessNotification('KYB approved');
            fetchData();
        } catch (e) {
            showErrorNotification(e);
        }
    };

    const handleComplianceRejection = async (reason?: string, final?: boolean) => {
        setOpenRejectionModal(false);
        if (reason) {
            try {
                await rejectKyc(applicantId, reason, final);
                showSuccessNotification('KYB rejected');
                fetchData();
            } catch (e) {
                showErrorNotification(e);
            }
        }
    };

    const searchSectionIdByGroupId = (groupId: number) => {
        return form.sections.find(s => s.groups.find(g => g.id === groupId)).id;
    };

    const handleEditModalClose = async (newValue?: string, field?: Field, add?: boolean, file?: boolean) => {
        setOpenEditModal(false);
        setOpenUploadModal(false);
        if (newValue && !add) {
            try {
                await editValue(field.value.id, newValue);
                showSuccessNotification('KYB field updated');
                fetchData();
            } catch (e) {
                showErrorNotification(e);
            }
        } else if (newValue && add) {
            const payload: AddValuePayload = {
                name: field.name,
                applicantId: applicantId,
                value: newValue,
                formId: form.id,
                fieldId: field.fieldId,
                groupId: field.groupId,
                sectionId: searchSectionIdByGroupId(field.groupId),
                checkCount: compliance.checkCount,
                file
            };

            try {
                await addValue(payload);
                showSuccessNotification('KYB field added');
                fetchData();
            } catch (e) {
                showErrorNotification(e);
            }
        }
        setEditField(null);
    };

    const handleEditClick = (field: Field) => {
        setEditField(field);
        setOpenEditModal(true);
    };

    const handleUploadClick = (field: Field) => {
        setEditField(field);
        setOpenUploadModal(true);
    };

    if (!form || !compliance) return <Loading />;

    return (
        <Container fluid id='complianceDetails'>
            <ComplianceRejectionModal
                open={openRejectionModal}
                onClose={handleComplianceRejection}
            />
            <ComplianceEditValueModal
                open={openEditModal}
                onClose={handleEditModalClose}
                field={editField}
            />
            <ComplianceUploadValueModal
                open={openUploadModal}
                onClose={handleEditModalClose}
                field={editField}
            />
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column><Header as='h2'>Compliance</Header></Grid.Column>
                        <Grid.Column floated='right'>
                            <Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
                            <Button
                                style={{ marginLeft: 10 }}
                                secondary
                                content='View user'
                                disabled={!checkPermission(permissions, PermissionType.USERS)}
                                onClick={() => openUser(compliance.userId)}
                                floated='right'
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid stackable columns={2}>
                    <Grid.Column className="profiledatacolumn">
                        <Segment className="usersegment" basic>
                            <List id="profile-data">
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Name</List.Header>
                                        <List.Description>{compliance.profileType === 'BUSINESS' ? compliance.businessName : compliance.name}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <Divider />
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Applicant ID</List.Header>
                                        <List.Description>{compliance.serviceId}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <Divider />
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Provider</List.Header>
                                        <List.Description>{compliance.service}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <Divider />
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Status</List.Header>
                                        <List.Description>{compliance.status}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <Divider />
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Result</List.Header>
                                        <List.Description>{compliance.result ?? '⠀'}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <Divider />
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Rejection reason</List.Header>
                                        <List.Description>{compliance.rejectionReason ?? '⠀'}</List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column className="profiledatacolumn">
                        <Segment className="usersegment" basic>
                            <List id="profile-data">

                                <Grid.Row className='buttons'>
                                    <Grid.Column>
                                        <ComponentWithConfirmation onConfirm={approve} confirmContent={'Are you sure you want approve KYC/KYB?'}>
                                            <Button
                                                color='green'
                                                content='Approve'
                                                disabled={!checkWritePermission(permissions, PermissionType.POA_COMPLIANCE) || (compliance.status !== 'CREATED' && compliance.status !== 'REJECTED')}
                                                floated='right'
                                            />
                                        </ComponentWithConfirmation>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button
                                            color='red'
                                            content='Reject'
                                            disabled={!checkWritePermission(permissions, PermissionType.POA_COMPLIANCE) || compliance.status === 'REJECTED'}
                                            floated='left'
                                            onClick={() => setOpenRejectionModal(true)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </List>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Segment>


            <Segment id='form'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column><Header as='h2'>Compliance Form</Header></Grid.Column>
                    </Grid.Row>
                </Grid>

                {form && sortedSections && sortedSections.map(section => (
                    <div key={section.name}>
                        <Header as='h3' className='sectionHeader'>{section.translation[0].label}</Header>
                        {getSortedGroups(section.groups).map(group => (
                            <div key={group.name}>
                                <Header as='h4' className='groupHeader'>{group.translation[0].label}</Header>
                                <List id="profile-data">
                                    {getSortedFields(group.fields).map(field => (
                                        <div key={field.fieldId}>
                                            <List.Item >
                                                <List.Content>
                                                    <List.Header className="color-grey">{field.translation[0].label}</List.Header>
                                                    <List.Description>
                                                        {field.value?.editedByAdmin && <Label color='red' ribbon>
                                                            Edited by admin
                                                        </Label>}
                                                        {field.value?.file || field.type === 'upload' ?
                                                            <>
                                                                {field.value ? <>{field.value?.fileDetails.name} <Icon size="large" style={{ cursor: 'pointer' }} name="download" onClick={() => download(field.value.value, field.value.fileDetails.name)} /> </> : <i>⠀</i>}
                                                            </>
                                                            : field.value?.value === '' ? <i>⠀</i> : field.value?.value}
                                                        {!field.value?.file && field.type !== 'upload' && <Icon style={{ marginRight: '1em', float: 'right', cursor: 'pointer' }} onClick={() => handleEditClick(field)} name="edit outline" />}
                                                        {(field.value?.file || field.type === 'upload') && <Icon style={{ marginRight: '1em', float: 'right', cursor: 'pointer' }} onClick={() => handleUploadClick(field)} name="edit outline" />}
                                                    </List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                        </div>

                                    ))}
                                </List>

                            </div>
                        ))}
                    </div>
                ))}
            </Segment>
        </Container>
    );
};

export default ComplianceDetails;
