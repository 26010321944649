import React from 'react';

import { Grid, List } from 'semantic-ui-react';
import { SystemAccountSummaryType } from '@/features/systemAccountDetails/types';

interface Props {
	systemAccount: SystemAccountSummaryType
}

const SystemAccountInfo = ({ systemAccount }: Props) => {
	return (
		<Grid columns={3} >
			<Grid.Row>
				<Grid.Column>
					<List >
						<List.Item>
							<List.Content>
								<List.Header className="color-grey">Account</List.Header>
								<List.Description>{systemAccount?.walletId}</List.Description>
							</List.Content>
						</List.Item>
					</List>
				</Grid.Column>
				<Grid.Column>
					<List >
						<List.Item>
							<List.Content>
								<List.Header className="color-grey">Name</List.Header>
								<List.Description>{systemAccount?.description}</List.Description>
							</List.Content>
						</List.Item>
					</List>
				</Grid.Column>
				<Grid.Column>
					<List>
						<List.Item>
							<List.Content>
								<List.Header className="color-grey">Balance</List.Header>
								<List.Description> {systemAccount?.currencyCode} {systemAccount?.accountBalance}</List.Description>
							</List.Content>
						</List.Item>
					</List>
				</Grid.Column>
			</Grid.Row>
		</Grid>);
};

export default SystemAccountInfo;
