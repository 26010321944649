import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Divider,
	Grid, Header,
	Modal
} from 'semantic-ui-react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { getErrorMessage, showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import { getUserVaultsCcy, transferVault } from '@features/vaults/vaultsSlice';

interface Props {
	open: boolean;
	userId: number,
	accountId?: number,
	currency?: string,
	onClose: () => void,
	onSubmit?: () => void,
}

const VaultTransferModal = ({ open, userId, accountId, currency, onClose, onSubmit }: Props): React.ReactElement => {
	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const [procListOptions, setProcListOptions] = useState<any>(null);
	const [vaultsLoading, setVaultsLoading] = useState<any>(false);


	const disabled = !accountId && !currency;

	useEffect(() => {
		const get = async () => {
			setProcListOptions([]);
			try {
				setVaultsLoading(true);
				if (currency) {
					if (currency) {
						const vaults = await getUserVaultsCcy(userId, currency);
						if (vaults?.list?.length > 0) {
							const vaultOptions = [];
							vaults.list.map(entry => {
								vaultOptions.push({ key: entry.proc, value: entry.proc, text: entry.vaultName });
							});
							setProcListOptions(vaultOptions);
						} else {
							throw `No vaults found for currency ${currency}`;
						}
					} else {
						throw 'No currency found';
					}
				}
			} catch (e) {
				showErrorNotification(e, e);
			} finally {
				setVaultsLoading(false);
			}
		};
		get();
	}, [currency]);



	const validationSchema = Yup.object({
		vault: Yup.string().required('Vault is required'),
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const {
			vault,
		} = formData;

		try {
			await transferVault(accountId, vault);
			onClose();
			showSuccessNotification('Transfer done successfully');
		} catch (e) {
			const msg = getErrorMessage(e);
			showErrorNotification(null, msg);
		} finally {
			setSubmitting(false);
			onSubmit && onSubmit();
			onClose();
		}
	};


	return (<Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={{
					proc: '',
				}}
				onSubmit={submit}
				validationSchema={validationSchema}
				render={({ setFieldValue, isSubmitting, errors }) => {
					return (
						<Form>
							<Header content="Select new plan" />
							<Select
								label='Vault'
								fluid
								clearable
								errorPrompt
								name="vault"
								loading={vaultsLoading}
								options={procListOptions}
								onChange={(element, { value }) => setFieldValue('vault', value)}
							/>
							<Divider hidden />
							<Grid columns={2}>
								<Grid.Row centered>
									<Grid.Column>
										<SubmitButton primary
											type="submit"
											loading={isSubmitting}
											disabled={!checkWritePermission(permissions, PermissionType.SUPPORT) || isSubmitting || !isEmpty(errors) || disabled}
											floated='right'>
											Transfer
										</SubmitButton>
									</Grid.Column>
									<Grid.Column>
										<Button floated='left' secondary content='Discard' onClick={() => onClose()} />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					);
				}}
			/>

		</Modal.Content>
	</Modal>);
};

export default VaultTransferModal;
