
export interface ExchangeOrderStore {
	loading: boolean,
	list: Array<ExchangeOrder>,
	error: string,
	count: number,
	totalPages: number,
	pageSize: number
}

export interface ExchangeOrder {
	id: number,
	documentId: number,
	fxProcName?: string,
	wlpId: string,
	userId: number,
	clientId: string,
	clientName?: string,
	firstName?: string,
	lastName?: string,
	companyName?: string,
	changeAmount: number,
	note?: string,
	docNumber: string,
	transactionNr?: string,
	//
	fromAccountId: number,
	fromWalletId: string,
	fromCurrencyCode: string,
	fromAvailable: string,
	//
	toAccountId: number,
	toWalletId: string,
	toCurrencyCode: string,
	toAvailable: string,
	//
	rate?: number,
	details?: string,
	feeFromAccountId?: number,
	feeAmount?: number,
	cjOrderDate?: string,
	cjFromReference?: string,
	cjToReference?: string,
	status: string,
	fromIntegration: string,
}


export interface ExchangeOrderCompletePayload {
	documentId: number,
	docStatus: string,
	fromAccountId: number,
	toAccountId: number,
	changeAmount: number,
	fxRate: number,
	details: string,
	feeFromAccountId?: string,
	feeAmount?: number,
	cjOrderDate: string,
	bankRefFrom: string,
	bankRefTo: string,
	updatedBy: number
}

export interface ExchangeOrderStartPayload {
	docStatus: string,
	documentId: number,
	fromAccountId: number,
	toAccountId: number,
	changeAmount: number
}


export enum ExchangeOrderStatus {
	'PENDING' = 'PENDING',
	'PENDING_FX' = 'PENDING_FX',
	'REJECTED' = 'REJECTED',
	'COMPLETED' = 'COMPLETED'
}

