import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Divider,
	Grid, Header,
	Modal
} from 'semantic-ui-react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import {  showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import { terminateVault } from '@features/vaults/vaultsSlice';

interface Props {
	accountId: number,
	open: boolean;
	onClose: () => void,
	onSubmit?: () => void,
}

const VaultTerminateModal = ({ open, onClose, accountId, onSubmit }: Props): React.ReactElement => {
	const { permissions } = useSelector(
		(state: RootState) => state.user
	);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [fee, setFee] = useState<any>('');
	const [loading, setLoading] = useState<any>(false);

	const validationSchema = Yup.object({
		feeAmount: Yup.number().required('Fee Amount is required').min(0, 'Fee min value is 0'),
	});

	useEffect(() => {
		setShowConfirmation(false);
	}, [open]);

	const submit = async (formData) => {
		const {
			feeAmount,
		} = formData;
		setFee(feeAmount);
		setShowConfirmation(true);
	};

	const terminateVaultEvent = async () => {
		setLoading(true);
		try {
			await terminateVault(accountId, {
				fee
			});
			showSuccessNotification('Vault successfully terminated');
		} catch (e) {
			if (e?.data?.errors[0]?.error?.error?.errorMessage) {
				showErrorNotification(e, e.data.errors[0].error.error.errorMessage);
			} else {
				showErrorNotification(e);
			}
		} finally {
			onSubmit();
			setLoading(false);
			onClose();
		}
	};




	return (
		<Modal
			size="tiny"
			open={open}
			onClose={() => onClose()}>
			<Modal.Content>
				{!showConfirmation && <Formik
					initialValues={{
						feeAmount: 0,
					}}
					onSubmit={submit}
					validationSchema={validationSchema}
					render={({ isSubmitting, errors }) => {
						return (
							<Form>
								<Header content={`Vault ID: ${accountId}`} />
								<Input
									label='Fee Amount'
									fluid
									errorPrompt
									name="feeAmount"
									type='number'
								/>
								<Divider hidden />
								<Grid columns={2}>
									<Grid.Row centered>
										<Grid.Column>
											<SubmitButton primary
												type="submit"
												loading={isSubmitting}
												disabled={!checkWritePermission(permissions, PermissionType.SUPPORT) || isSubmitting || !isEmpty(errors)}
												floated='right'
											>
												Execute
											</SubmitButton>
										</Grid.Column>
										<Grid.Column>
											<Button floated='left' secondary content='Discard' onClick={() => onClose()} />
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Form>
						);
					}}
				/>}
				{showConfirmation && <div>
					<Header textAlign='center' content={`Are you sure you want terminate vault ${accountId}?`} />
					<Divider hidden />
					<Grid columns={2}>
						<Grid.Row centered>
							<Grid.Column>
								<Button loading={loading} floated='right' primary content='Terminate' onClick={() => terminateVaultEvent()} />
							</Grid.Column>
							<Grid.Column>
								<Button floated='left' secondary content='Discard' onClick={() => onClose()} />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>}
			</Modal.Content>
		</Modal>
	);
};

export default VaultTerminateModal;
