import { getBanks } from '@/features/banks/banksSlice';
import { RootState } from '@/rootReducer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useBankLov = () => {

	const dispatch = useDispatch();
	const { list: bankList, status: bankStatus } = useSelector((state: RootState) => state.banks);

	useEffect(() => {
		bankStatus === 'idle' && dispatch(getBanks());
	}, [dispatch, bankStatus]); 

	const bankLov = bankList.map((p) => { return { key: p.bankId.toString(), text: p.bankName, value: p.bankId.toString() }; });
	const bankNameLov = bankList.map((p) => { return { key: p.bankId.toString(), text: p.bankName, value: p.bankName}; });

	return { bankList, bankStatus, bankLov, bankNameLov };
};

export default useBankLov;
