import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { showErrorNotification } from '../swal/slice';
import { POA, PoaStore } from './types';

const initialState: PoaStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'poa',
	initialState,
	reducers: {
		setPoa(state, action: PayloadAction<Array<POA>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setPoa, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getPoaList = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/poa/compliance', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setPoa(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getUserPoa = async (userId: number) => {
	const response = await request.get(`/api/admin/poa/${userId}`);
	const { data } = response;
	return data;
};

export const toogleDocument = async (poaId: number, status: string, rejectionReason?: string ) => {
	const response = await request.post('/api/admin/poa/toogle', { poaId, status, rejectionReason });
	const { data } = response;
	return data;
};

export const markAsInProgress = async (poaId: number ) => {
	const response = await request.post('/api/admin/poa/watching', { poaId });
	const { data } = response;
	return data;
};

export const updateAddress = async (userId: number, country: string, street: string, town: string, postalCode: string, state?: string ) => {
	const response = await request.put('/api/admin/poa/change-address', { userId, country, street, town, postalCode, state });
	const { data } = response;
	return data;
};

export const accountsAutoCreation = async (userId: number ) => {
	const response = await request.post('/api/admin/accounts/create/auto', { userId });
	const { data } = response;
	return data;
};

export const downloadAttachment = async (key: string, name: string) => {
	const response = await request.get(`/api/admin/poa/document/${key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};



export default slice.reducer;
