import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';


interface Props {
	open: boolean;
	onClose: (from?: string, to?: string) => void
}

const RefreshParameters = ({ open, onClose }: Props): React.ReactElement => {

	const initialValues = {
		from: '',
		to: ''
	};

	const validationSchema = Yup.object({
		from: Yup.date()
			.required('You must enter from Date'),
		to: Yup.date()
			.required('You must enter to Date')

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { from, to } = formData;
		onClose(from, to);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content={'Balance refresh parameters'} />

						<Input
							label='From Date'
							fluid
							name="from"
							type='date'
							errorPrompt
						/>
						<Input
							label='To Date'
							fluid
							name="to"
							type='date'
							errorPrompt
						/>
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										Refresh
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default RefreshParameters;
