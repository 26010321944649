import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import request from '../../services/request';
import { MaintenanceType, MaintenanceStore, MaintenancePostType } from './maintenanceType';


const initialState: MaintenanceStore = {
	loading: true,
	list: [],
	error: null,
};

const slice = createSlice({
	name: 'maintenance',
	initialState,
	reducers: {
		setMaintenanceList(state, action: PayloadAction<MaintenanceType[]>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
	}
});

export const { setMaintenanceList, setLoading, setError } = slice.actions;

export const getMaintenanceMode = (status?: any): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/maintenance', { params: { status: status } }
			);
			if (response?.data) {
				dispatch(setMaintenanceList(response.data));
			}
		} catch (e) {
			dispatch(setError('Failed to get maintenance status'));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const updateMaintenanceMode = async (payload: MaintenancePostType) => {
	const response = await request.post('/api/admin/maintenance/create', { data: payload} );
	const { data } = response;
	return data;
};

export const applyMaintenanceMode = async () => {
	const response = await request.post('/api/admin/maintenance/apply');
	const { data } = response;
	return data;
};


export default slice.reducer;
