import { showErrorNotification } from '@/features/swal/slice';
import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
	Button,
	Divider,
	Grid,
	Icon,
	Modal
} from 'semantic-ui-react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

interface Props {
	open: boolean;
	onClose: (message?: string, subject?: string, selectedFiles?: any[]) => void,
}

const CreateTicketForUserModal = ({ open, onClose }: Props): React.ReactElement => {
	const [selectedFiles, setSelectedFiles] = useState([]);
	const hiddenFileInput = React.useRef(null);
	const { t } = useTranslation('translations');

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const validationSchema = Yup.object({
		message: Yup.string().required('Message reuired'),
		subject: Yup.string().required('Message reuired'),
	});

	const fileTypes = [
		'image/jpg',
		'image/jpeg',
		'image/png',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	];

	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > 5) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (files[i].size > 5000000) {
				showErrorNotification(t('swal.FILE_SIZE_TOO_LARGE', { fileName: files[i].name }));
				continue;
			}
			if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < 5) {
				selectedFiles.push(files[i]);
				setSelectedFiles(selectedFiles);
			}
		}
	};

	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
	};

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { message, subject } = formData;

		onClose(message, subject, selectedFiles);
	};


	return (<Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>

			<Formik
				initialValues={{
					message: '',
					subject: ''
				}}
				onSubmit={submit}
				validationSchema={validationSchema}
				render={({ setFieldValue, isSubmitting, errors, dirty }) => {
					return (
						<Form>
							<Input
								label='Subject'
								name="subject"
								errorPrompt
								disabled={!checkWritePermission(permissions, PermissionType.SUPPORT)}
							/>
							<TextArea
								label='Message'
								name="message"
								style={{ minHeight: 100 }}
								errorPrompt
								disabled={!checkWritePermission(permissions, PermissionType.SUPPORT)}
							/>
							<Divider hidden />

							<input type="file"
								multiple
								accept="image/jpg, image/png, image/jpeg, .pdf, .doc, .docx"
								name="files"
								ref={hiddenFileInput}
								onChange={e => {
									uploadFiles(e.currentTarget.files);
									setFieldValue('files', selectedFiles);
								}}
								style={{ display: 'none' }}
							/>
							<Grid style={{ paddingBottom: '1rem' }} columns={2}>
								<Grid.Row>
									<Grid.Column>
										{selectedFiles.length > 0 && (
											<Grid className="uploadedFiles" style={{ justifyContent: 'left' }} >
												{selectedFiles.map((file, index) =>
													<Grid.Row className="uploadedFile" key={file.name + index} style={{ paddingTop: 0, fontSize: '0.85em' }}>
														<Icon size="large" name="file outline" />
														<div className="fileName">{file.name}</div>
														<Icon className="deleteAttachment"
															onClick={() => {
																deleteAttachment(index);
																setFieldValue('files', selectedFiles);
															}}
															size="large"
															name="times" />
													</Grid.Row>
												)}
											</Grid>
										)}
									</Grid.Column>
									<Grid.Column floated='right'>
										<Button type="button"
											onClick={handleUploadClick}
											content='Attcah files'
											icon="upload"
											disabled={!checkWritePermission(permissions, PermissionType.SUPPORT)}
											floated='right'
										/>
									</Grid.Column>
								</Grid.Row>

							</Grid>
							<Grid columns={2}>
								<Grid.Row centered>
									<Grid.Column>
										<SubmitButton primary
											type="submit"
											disabled={!checkWritePermission(permissions, PermissionType.SUPPORT) || isSubmitting || !isEmpty(errors) || !dirty}
											floated='right' >
											Send		
										</SubmitButton>
									</Grid.Column>
									<Grid.Column>
										<Button floated='left' secondary content='Discard' onClick={() => onClose()} />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					);
				}}
			/>

		</Modal.Content>
	</Modal>);
};

export default CreateTicketForUserModal;
