import React, { useEffect, useState } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { FaqHeader } from '@/features/faq/faqTypes';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getAvailableLanguages } from '@/features/languages/languagesSlice';
import { showErrorNotification } from '@/features/swal/slice';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';


interface Props {
	open: boolean;
	onClose: (language?: string, name?: string, disabled?: boolean, country?: string, whitelabel?: string) => void,
	faq: FaqHeader,
}

const FaqHeaderModal = ({ open, onClose, faq }: Props): React.ReactElement => {

	const { whitelabel } = useSelector(
		(state: RootState) => state.whitelabel
	);
	const [languageOptions, setLanguageOptions] = useState<any>(null);
	const [countriesOptions, setCountriesOptions] = useState<any>(null);
	const countries = getNames('en');

	useEffect(() => {
		const get = async () => {
			try {
				const data = await getAvailableLanguages(whitelabel);
				const langOptions = [];
				data.map(entry => {
					langOptions.push({ key: entry.value, value: entry.value, text: entry.label });
				});
				setLanguageOptions(langOptions);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [whitelabel ]);

	useEffect(() => {
		const options = [];
		Object.keys(countries)
			.map($code => {
				options.push({ key: alpha2ToAlpha3($code), text: countries[$code], value: alpha2ToAlpha3($code) });
			});
		setCountriesOptions(options);
	}, []);

	const initialValues = {
		language: faq ? faq.languageCode : '',
		name: faq ? faq.name : '',
		disabled: faq ? faq.disabled : false,
		country: faq && faq.countryCode ? faq.countryCode : ''
	};

	const validationSchema = Yup.object({
		name: Yup.string()
			.required('You must enter a name'),
		language: Yup.string()
			.required('You must select a language')
	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { language, name, disabled, country } = formData;
		onClose(language, name, disabled, country === 'none' ? null : country, whitelabel);
	};



	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
				enableReinitialize={true}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content={faq ? 'Edit FAQ' : 'Add new FAQ'} />

						<Input
							label='Name'
							fluid
							name="name"
						/>
						<Select
							label='Language'
							fluid
							name="language"
							options={languageOptions}
							
						/>
						<Select
							label='Country'
							fluid
							name="country"
							options={countriesOptions}
							search
							clearable
							selection
						/>
						<Checkbox fitted
							name="disabled"
							label='Disabled'
						/>

						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										{faq ? 'Edit' : 'Add'}
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default FaqHeaderModal;
