import React from 'react';
import {
	Divider,
	Grid,
	Card
} from 'semantic-ui-react';
import { ExchangeOrder } from '@/features/exchangeOrder/types';
import './OrderDetails.css';
import NumberFormat from 'react-number-format';

interface Props {
	order: ExchangeOrder
}

const OrderDetails = ({ order }: Props): React.ReactElement => {
	const labelWidth = 4;
	const valueWidth = 8;
	return (
		<Card id="profile-card" >
			<Card.Content className='exchange-order-details'>
				<Grid>
					<Grid.Row>
						<Grid.Column width={4} className='field-label'>
							From Account
							{!order?.fromAccountId &&
								<div className='data-warning'>
									Required
								</div>}
						</Grid.Column>
						<Grid.Column width={4} textAlign='right'>
							{order?.fromWalletId}
						</Grid.Column>
						<Grid.Column width={4} className='field-label'>
							To Account
							{!order?.toAccountId &&
								<div className='data-warning'>
									Required
								</div>}
						</Grid.Column>
						<Grid.Column width={4} textAlign='right'>
							{order?.toWalletId}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={4} className='field-label'>
							{`Available Balance ${order.fromCurrencyCode}:`}
						</Grid.Column>
						<Grid.Column width={4} textAlign='right'>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={order.fromAvailable} />
						</Grid.Column>
						<Grid.Column width={6} className='field-label'>
							{`Available Balance ${order.toCurrencyCode}:`}
						</Grid.Column>
						<Grid.Column width={2} textAlign='right'>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={order.toAvailable} />
						</Grid.Column>
					</Grid.Row>
					<Divider />
					<Grid.Row>
						<Grid.Column width={labelWidth} className='field-label'>
							Client Id
						</Grid.Column>
						<Grid.Column width={valueWidth}>
							{order?.clientId}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={labelWidth} className='field-label'>
							Name
						</Grid.Column>
						<Grid.Column width={valueWidth}>
							{order?.companyName ? order?.companyName : `${order?.firstName}  ${order?.lastName}`}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={labelWidth} className='field-label'>
							Document Nr
							{!order?.documentId &&
								<div className='data-warning'>
									Required
								</div>}
						</Grid.Column>
						<Grid.Column>
							{order?.docNumber}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={labelWidth} className='field-label'>
							Transaction Nr
						</Grid.Column>
						<Grid.Column width={valueWidth}>
							{order?.transactionNr}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={labelWidth} className='field-label'>
							Change Amount
							{!order?.changeAmount &&
								<div className='data-warning'>
									Required
								</div>}
						</Grid.Column>
						<Grid.Column width={valueWidth}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								prefix={`${order?.fromCurrencyCode ?? ''} `}
								value={order.changeAmount * -1} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={labelWidth} className='field-label'>
							Notes
						</Grid.Column>
						<Grid.Column width={valueWidth}>
							{order?.note}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Card.Content>
		</Card>);
};


export default OrderDetails;


