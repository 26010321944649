import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';


interface Props {
	open: boolean;
	onClose: (status?: 'REJECTED' | 'APPROVED' | 'WAITING_FOR_PAYMENT', final?: boolean, reason?: string, amount?: number, ccy?: string) => void,
}

const KybStatusModal = ({ open, onClose }: Props): React.ReactElement => {

	const statusOptions = [
        { key: 'APPROVED', text: 'Approved', value: 'APPROVED' },
        { key: 'REJECTED', text: 'Rejected', value: 'REJECTED' },
        { key: 'WAITING_FOR_PAYMENT', text: 'Waiting for payment', value: 'WAITING_FOR_PAYMENT' }
    ];

	const ccyOptions = [
        { key: 'EUR', text: 'EUR', value: 'EUR' },
        { key: 'USD', text: 'USD', value: 'USD' },
        { key: 'GBP', text: 'GBP', value: 'GBP' }
    ];

	const initialValues = {
		status: '',
		reason: '',
		final: false,
		amount: '',
		ccy: ''
	};

	const validationSchema = Yup.object({
		status: Yup.string()
			.required('You must choose a status'),
		reason: Yup.string().when('status', {
			is: 'REJECTED',
			then: Yup.string().required('You must enter a rejection reason'),
			otherwise: Yup.string().notRequired(),
		}),
		ccy: Yup.string().when('status', {
			is: 'WAITING_FOR_PAYMENT',
			then: Yup.string().required('You must choose a currency'),
			otherwise: Yup.string().notRequired(),
		}),
		amount: Yup.string().when('status', {
			is: 'WAITING_FOR_PAYMENT',
			then: Yup.string().required('You must enter an amount'),
			otherwise: Yup.string().notRequired(),
		})
	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { status, reason, ccy, amount, final } = formData;
		onClose(status, final, reason, amount, ccy);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		< Modal.Content >
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty, values }) => (
					<Form
						size="large">
						<Header content="Specify rejection reason" />
						
						<Select
                            label='Status'
                            fluid
                            name="status"
                            errorPrompt
                            options={statusOptions}
                        />
						{values.status === 'REJECTED' && <TextArea
							label='Reason'
							name="reason"
						/>}
						{values.status === 'REJECTED' && <Checkbox fitted
                            name="final"
                            label='Final'
                        />}
						{values.status === 'WAITING_FOR_PAYMENT' &&  <Input
                            label='Amount'
                            type='number'
                            fluid
                            name="amount"
                            errorPrompt
                        />}
						{values.status === 'WAITING_FOR_PAYMENT' &&  <Select
                            label='Currency'
                            fluid
                            name="ccy"
                            errorPrompt
                            options={ccyOptions}
                        />}

						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										Change
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content >
	</Modal >;
};

export default KybStatusModal;
