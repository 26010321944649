/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { getVaultProcessesVaults } from '@features/vaultProcessesVaults/slice';
import UpcomingLiabilities from './UpcomingLiabilities';
import VaultTerminateModal from './VaultTerminateModal';
import VaultTransferModal from './VaultTransferModal';
import { VaultProcessVault } from '@/features/vaultProcessesVaults/types';


const ProcessVaults = (props): React.ReactElement => {


	const [proc] = useState<string>(props.match.params.proc);
	const [showVaultTerminateModal, setShowVaultTerminateModal] = useState<boolean>(false);
	const [terminateAccountId, setTerminateAccountId] = useState<number>(undefined);
	const [activeVault, setActiveVault] = useState<VaultProcessVault>(undefined);
	const [showVaultTransferModal, setShowVaultTransferModal] = useState<boolean>(false);

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.vaultProcessesVaults
	);

	const paginateInitialState: PaginateParams = { orderBy: 'ccy', sort: 'DESC', skip: 0, filter: '', take: 25 };

	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const accountStatusOptions = [
		{
			key: 'ORDERED',
			text: 'ORDERED',
			value: 'ORDERED',
		},
		{
			key: 'ACTIVE',
			text: 'ACTIVE',
			value: 'ACTIVE',
		},
		{
			key: 'DELETED',
			text: 'DELETED',
			value: 'DELETED',
		},
		{
			key: 'TERMINATED',
			text: 'TERMINATED',
			value: 'TERMINATED',
		},
		{
			key: 'BLOCKED',
			text: 'BLOCKED',
			value: 'BLOCKED',
		},
	];

	const filter: FilterParam[] = [
		{
			field: 'email',
			label: 'Email',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'walletId',
			label: 'Wallet ID',
			type: 'input'
		},
		{
			field: 'accountStatus',
			label: 'Account Status',
			type: 'select',
			options: accountStatusOptions
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Wallet ID',
			position: 1,
			sortable: false
		},
		{
			field: 'clientId',
			name: 'Client ID',
			position: 2,
			sortable: true
		},
		{
			field: 'email',
			name: 'Email',
			position: 3,
			sortable: true
		},
		{
			field: 'accountStatus',
			name: 'Account Status',
			position: 4,
			sortable: true
		},
		{
			field: 'interestRate',
			name: 'Interest Rate',
			position: 5,
			sortable: true
		},
		{
			field: 'depositPeriod',
			name: 'Deposit Period',
			position: 6,
			sortable: true
		},
		{
			field: 'vaultHolding',
			name: 'Holding',
			position: 7,
			sortable: true
		},
		{
			field: 'vaultDepositPaid',
			name: 'Deposit Paid',
			position: 8,
			sortable: true
		},
		{
			field: 'vaultInterestPaid',
			name: 'Interest Paid',
			position: 9,
			sortable: true
		},
		{
			field: 'vaultDepositLiabilities',
			name: 'Deposit Liabilities',
			position: 10,
			sortable: true
		},
		{
			field: 'vaultInterestLiabilities',
			name: 'Interest Liabilities',
			position: 11,
			sortable: true
		},
		{
			field: 'vaultDepositAutoReturns',
			name: 'Total Auto Extend',
			position: 12,
			sortable: true
		},
		{
			field: 'autoExtendCount',
			name: 'Auto Extend Count',
			position: 13,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 14,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getVaultProcessesVaults(proc, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const history = useHistory();

	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const openAccount = (accountId: number) => {
		history.push(`/account/${accountId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.walletId}${index}`} >
						<Table.Cell>{entry.walletId}</Table.Cell>
						<Table.Cell>{entry.clientId}</Table.Cell>
						<Table.Cell>{entry.email}</Table.Cell>
						<Table.Cell>{entry.accountStatus}</Table.Cell>
						<Table.Cell>{entry.interestRate}</Table.Cell>
						<Table.Cell>{entry.depositPeriod}</Table.Cell>
						<Table.Cell>
							{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.vaultHolding} />
						</Table.Cell>
						<Table.Cell>
							{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.vaultDepositPaid} />
						</Table.Cell>
						<Table.Cell>
							{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.vaultInterestPaid} />
						</Table.Cell>
						<Table.Cell>
							{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.vaultDepositLiabilities} />
						</Table.Cell>
						<Table.Cell>
							{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.vaultInterestLiabilities} />
						</Table.Cell>
						<Table.Cell>
							{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.vaultDepositAutoReturns} />
						</Table.Cell>
						<Table.Cell>
							{entry.autoExtendCount}
						</Table.Cell>
						<Table.Cell selectable={false}>
							<Popup content='Open account details' trigger={<Icon className='tableActionIcon' size='large' name="money bill alternate outline" onClick={() => openAccount(entry.accountId)} />} />
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
							{entry.accountStatus !== 'TERMINATED' && <Popup content='Transfer to another Plan' trigger={<Icon className='tableActionIcon' size='large' name="arrows alternate horizontal" onClick={() => { setActiveVault(entry); setShowVaultTransferModal(true); }} />} />}
							{entry.accountStatus !== 'TERMINATED' && <Popup content='Terminate vault' trigger={<Icon className='tableActionIcon' size='large' name="trash" onClick={() => { setTerminateAccountId(entry.accountId); setShowVaultTerminateModal(true); }} />} />}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={9}>No data yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<VaultTerminateModal
				accountId={terminateAccountId}
				onClose={() => setShowVaultTerminateModal(false)}
				onSubmit={() => fetchData()}
				open={showVaultTerminateModal}
			/>
			<VaultTransferModal
				userId={activeVault?.userId}
				accountId={activeVault?.accountId}
				currency={activeVault?.ccy}
				onClose={() => setShowVaultTransferModal(false)}
				onSubmit={() => fetchData()}
				open={showVaultTransferModal}
			/>
			<UpcomingLiabilities proc={proc} />
			<Segment>
				<Grid.Row columns={2}>
					<Grid.Column><Header as='h2'>Vault List</Header></Grid.Column>
					<Grid.Column floated='right'>
						<Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
					</Grid.Column>
				</Grid.Row>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default ProcessVaults;
