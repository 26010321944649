/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, Label, List, Message, Segment, Table } from 'semantic-ui-react';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import '../Clients/ClientDetails/Profile.css';
import { RootState } from '@/rootReducer';
import { PermissionType } from '@/features/user/types';
import { checkPermission, checkWritePermission } from '@/helpers/permissions';
import { useHistory } from 'react-router-dom';
import { ChangeKYBStatusPayload, Compliance, KycPayment, SectionType, V3AdvisionaryFormSections } from '@/features/compliance/types';
import { changeKYBPaymentStatus, changeKYBStatus, downloadAttachment, getComplianceDetails, postApproveSection, postRejectSection, postUpdateProfile } from '@/features/compliance/slice';
import Loading from '@/components/Shared/Loading';
import './Compliance.css';
import * as Yup from 'yup';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { alpha2ToAlpha3, alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import SectionRejectionModal from './components/SectionRejectionModal';
import { formatDateWithPattern } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import Directors from './components/Directors';
import Shareholders from './components/Shareholders';
import Partners from './components/Partners';
import KybStatusModal from './components/KybStatusModal';
import ChangePaymentStatusModal from './components/ChangePaymentStatusModal';

const V3ComplianceDetails = (props): React.ReactElement => {

    const dispatch = useDispatch();
    const [applicantId] = useState<string>(props.match.params.applicantId);
    const [compliance, setCompliance] = useState<Compliance>(null);
    const [form, setForm] = useState<V3AdvisionaryFormSections[]>(null);
    const [payment, setPayment] = useState<KycPayment>(null);

    const countries = getNames('en');
    const [countriesOptions, setCountriesOptions] = useState<any>(null);
    const [openSectionRejectionModal, setOpenSectionRejectionModal] = useState<boolean>(false);
    const [currentSection, setCurrentSection] = useState<string>(null);
    const [openChangeStatus, setOpenChangeStatus] = useState<boolean>(false);
    const [openChangePaymentStatus, setOpenChangePaymentStatus] = useState<boolean>(false);

    useEffect(() => {
        const options = [];
        Object.keys(countries)
            .map($code => {
                options.push({ key: alpha2ToAlpha3($code), text: countries[$code], value: alpha2ToAlpha3($code) });
            });
        setCountriesOptions(options);
    }, []);

    const history = useHistory();


    const { permissions } = useSelector(
        (state: RootState) => state.user
    );

    const fetchData = useCallback(() => {
        const get = async () => {
            try {
                const data = await getComplianceDetails(applicantId);
                setForm(data.form);
                setCompliance(data.kyc);
                setPayment(data.kycPayment);
            } catch (e) {
                showErrorNotification(e);
            }
        };
        get();
    }, [applicantId, dispatch]);

    useEffect(() => {
        fetchData();
    }, [applicantId, dispatch]);


    const openUser = (userId: number) => {
        history.push(`/client/${userId}`);
    };

    const initialValues = {
        businessName: compliance?.businessName ?? '',
        address: compliance?.companyAddress ?? '',
        regNumber: compliance?.companyRegNumber ?? '',
        country: compliance?.companyCountry ?? '',
        companyCity: compliance?.companyCity ?? '',
        companyPostalCode: compliance?.companyPostalCode ?? '',
    };

    const validationSchema = Yup.object({
        businessName: Yup.string()
            .required('You must enter a business name'),
        address: Yup.string()
            .required('You must enter a address'),
        regNumber: Yup.string()
            .required('You must enter a reg number'),
        country: Yup.string()
            .required('You must choose a country'),
    });

    const download = (key: string, name: string) => {
        name = compliance.businessName.replaceAll(' ', '') + '_' + name;
        const get = async () => {
            try {
                downloadAttachment(key, name);
            } catch (e) {
                showErrorNotification(e);
            }
        };
        get();
    };

    const approveSection = (sectionType: string) => {
        const get = async () => {
            try {
                await postApproveSection(compliance.serviceId, sectionType);
                showSuccessNotification('Section approved');
                fetchData();
            } catch (e) {
                showErrorNotification(e);
            }
        };
        get();
    };

    const isSectionExist = (name: string) => {
        return form.filter(s => s.name === name).length;
    };

    const getSection = (name: string) => {
        return form.filter(s => s.name === name)[0];
    };

    const getSectionStatusIcon = (name: string) => {
        if (isSectionExist(name)) {
            switch (getSection(name).status) {
                case 'PENDING':
                    return <Icon name="history" />;
                case 'APPROVED':
                    return <Icon name="check circle outline" color='green' />;
                case 'REJECTED':
                    return <Icon name="ban" color='red' />;
            }
        } else {
            return <Icon name="history" />;
        }
    };

    const getSectionHeader = (name: string, sectionType: string) => {
        const sectionExist = isSectionExist(sectionType);
        return (
            <Grid.Row className='withoutPadding'>
                <Grid.Column>
                    <Header as='h3' className='sectionHeader'>
                        <Grid.Row>
                            {sectionExist ? getSection(sectionType)?.updatedByAdmin && <Label color='red' ribbon>
                                Edited by admin
                            </Label> : ''}
                            {name}
                            {getSectionStatusIcon(sectionType)}
                            {sectionExist ? <>
                                <Button
                                    color='red'
                                    content='Reject'
                                    disabled={!checkWritePermission(permissions, PermissionType.POA_COMPLIANCE) || getSection(sectionType).status === 'REJECTED'}
                                    floated='right'
                                    onClick={() => handleSectionReject(sectionType)}
                                />
                                <ComponentWithConfirmation onConfirm={() => approveSection(sectionType)} confirmContent={'Are you sure you want approve this section?'}>
                                    <Button
                                        color='green'
                                        content='Approve'
                                        disabled={!checkWritePermission(permissions, PermissionType.POA_COMPLIANCE) || (compliance.status !== 'SUBMITTED' && compliance.status !== 'REJECTED') || getSection(sectionType).status === 'APPROVED'}
                                        floated='right'
                                    />
                                </ComponentWithConfirmation>
                                <Button
                                    secondary
                                    content='Edit'
                                    disabled={!checkWritePermission(permissions, PermissionType.POA_COMPLIANCE)}
                                    floated='right'
                                    onClick={() => console.log('edit')}
                                />
                            </> : ''}
                        </Grid.Row>
                    </Header>
                </Grid.Column>
            </Grid.Row>
        );
    };

    const handleSectionReject = (sectionType: string) => {
        setCurrentSection(sectionType);
        setOpenSectionRejectionModal(true);
    };

    const handleSectonRejectionClose = async (reason?: string) => {
        setOpenSectionRejectionModal(false);
        if (reason) {
            try {
                await postRejectSection(compliance.serviceId, currentSection, reason);
                showSuccessNotification('Section rejected');
                fetchData();
            } catch (e) {
                showErrorNotification(e);
            }
        }
        setCurrentSection(null);
    };

    const getPaymentStatus = (payment: KycPayment) => {
        if (!payment.paid && !payment.cancelled) {
            return 'Waiting for payment';
        } else if (payment.cancelled) {
            return 'Cancelled';
        } else if (payment.paid) {
            return 'Paid';
        }
    };

    const handleUserProfileUpdate = async (formData, formikProps) => {
        const { setSubmitting } = formikProps;
        setSubmitting(false);
        const { country, address, businessName, regNumber, companyCity, companyPostalCode } = formData;
        try {

            await postUpdateProfile(compliance.userId, businessName, regNumber, address, country, companyCity === '' ? null : companyCity, companyPostalCode === '' ? null : companyPostalCode);
            fetchData();
            showSuccessNotification('Company data updated');
        } catch (e) {
            showErrorNotification(e);
        }

    };

    const handleChangeStatusClose = async (status?: 'REJECTED' | 'APPROVED' | 'WAITING_FOR_PAYMENT', final?: boolean, reason?: string, amount?: number, ccy?: string) => {
        setOpenChangeStatus(false);
        if (status) {
            const payload: ChangeKYBStatusPayload = {
                applicantId: compliance.serviceId,
                status
            };

            if (status === 'REJECTED') {
                payload.final = final;
                payload.rejectionReason = reason;
            } else if (status === 'WAITING_FOR_PAYMENT') {
                payload.amount = amount;
                payload.ccy = ccy;
            }

            try {
                await changeKYBStatus(payload);
                fetchData();
                showSuccessNotification('Status changed');
            } catch (e) {
                showErrorNotification(e);
            }
        }
    };

    const handleChangePaymentStatusClose = async (status?: string) => {
        setOpenChangePaymentStatus(false);
        if (status) {
            try {
                await changeKYBPaymentStatus(parseInt(payment.id), status);
                fetchData();
                showSuccessNotification('Payment status changed');
            } catch (e) {
                showErrorNotification(e);
            }
        }
    };

    if (!form || !compliance) return <Loading />;

    return (
        <Container fluid id='complianceDetails'>
            <SectionRejectionModal
                open={openSectionRejectionModal}
                onClose={handleSectonRejectionClose}
            />
            <KybStatusModal
                open={openChangeStatus}
                onClose={handleChangeStatusClose}
            />
            <ChangePaymentStatusModal
                open={openChangePaymentStatus}
                onClose={handleChangePaymentStatusClose}
            />
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column><Header as='h2'>Compliance</Header></Grid.Column>
                        <Grid.Column floated='right'>
                            <Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
                            <Button
                                style={{ marginLeft: 10 }}
                                secondary
                                content='View user'
                                disabled={!checkPermission(permissions, PermissionType.USERS)}
                                onClick={() => openUser(compliance.userId)}
                                floated='right'
                            />
                            <Button
                                style={{ marginLeft: 10 }}
                                content='Change status'
                                color='green'
                                disabled={!checkPermission(permissions, PermissionType.POA_COMPLIANCE) || compliance?.status === 'CREATED'}
                                onClick={() => setOpenChangeStatus(true)}
                                floated='right'
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {compliance?.status === 'CREATED' && <Grid.Row>
                        <Grid.Column>
                            <Message info>Form not submitted yet, you can not modify it or change status </Message>
                        </Grid.Column>
                    </Grid.Row>}
                </Grid>
                <Grid stackable columns={2}>
                    <Grid.Column className="profiledatacolumn">
                        <Segment className="usersegment" basic>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleUserProfileUpdate}
                                enableReinitialize={true}
                            >
                                {({ errors, isSubmitting, dirty }) => (
                                    <Form
                                        size="large">
                                        <Input
                                            label='Name'
                                            fluid
                                            name="businessName"
                                            errorPrompt
                                        />
                                        <Input
                                            label='Registration number'
                                            fluid
                                            name="regNumber"
                                            errorPrompt
                                        />
                                        <Input
                                            label='Address'
                                            fluid
                                            name="address"
                                            errorPrompt
                                        />
                                        <Input
                                            label='City'
                                            fluid
                                            name="companyCity"
                                            errorPrompt
                                        />
                                        <Input
                                            label='Postal code'
                                            fluid
                                            name="companyPostalCode"
                                            errorPrompt
                                        />
                                        <Select
                                            label='Country'
                                            fluid
                                            name="country"
                                            options={countriesOptions}
                                            search
                                            clearable
                                            selection
                                            errorPrompt
                                        />


                                        <Divider />
                                        <Grid columns={2}>
                                            <Grid.Row>
                                                <Grid.Column >
                                                    <SubmitButton
                                                        disabled={isSubmitting || !isEmpty(errors) || !dirty}
                                                        primary size="large" type="submit"
                                                    >
                                                        Save changes
                                                    </SubmitButton>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Form>)}
                            </Formik>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column className="profiledatacolumn">
                        <Segment className="usersegment" basic>
                            <List id="profile-data">
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Applicant ID</List.Header>
                                        <List.Description>{compliance.serviceId}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <Divider />
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Provider</List.Header>
                                        <List.Description>{compliance.service}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <Divider />
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Status</List.Header>
                                        <List.Description>{compliance.status}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <Divider />
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Result</List.Header>
                                        <List.Description>{compliance.result ?? '⠀'}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <Divider />
                                <List.Item>
                                    <List.Content>
                                        <List.Header className="color-grey">Rejection reason</List.Header>
                                        <List.Description>{compliance.rejectionReason ?? '⠀'}</List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Segment>


            {payment && <Segment id='paymnet'>
                <Table celled textAlign='center' padded='very' striped>
                    <Table.Header fullWidth className='tableHeader'>
                        <Table.Row>
                            <Table.HeaderCell className="tableHeaders">Date</Table.HeaderCell>
                            <Table.HeaderCell className="tableHeaders">Amount</Table.HeaderCell>
                            <Table.HeaderCell className="tableHeaders">Reference</Table.HeaderCell>
                            <Table.HeaderCell className="tableHeaders">Status</Table.HeaderCell>
                            <Table.HeaderCell className="tableHeaders">Proof of payment</Table.HeaderCell>
                            <Table.HeaderCell className="tableHeaders"></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body className="tableBody">
                        <Table.Row>
                            <Table.Cell>{formatDateWithPattern(payment.createdDate, 'dd/MM/yyyy HH:mm')}</Table.Cell>
                            <Table.Cell><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={payment.amount} prefix={`${payment.ccy} `} /></Table.Cell>
                            <Table.Cell>{payment.reference}</Table.Cell>
                            <Table.Cell>{getPaymentStatus(payment)}</Table.Cell>
                            <Table.Cell>{payment.proofOfPayment ? <a onClick={() => download(payment.proofOfPayment, 'proofOfPayment')}> Download</a> : ''}</Table.Cell>
                            <Table.Cell> <Button primary onClick={() => setOpenChangePaymentStatus(true)} >Change status</Button></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>}


            <Segment id='form'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column><Header as='h2'>Compliance Form</Header></Grid.Column>
                    </Grid.Row>




                    {getSectionHeader('Business information', SectionType.BUSINESS_INFORMATION)}
                    {isSectionExist(SectionType.BUSINESS_INFORMATION) ?
                        <Grid.Row className='withoutPadding'>
                            <Grid stackable columns={2}>
                                <Grid.Column className="profiledatacolumn">
                                    <Segment className="usersegment" basic>
                                        <List id="profile-data">
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Company name</List.Header>
                                                    <List.Description>{getSection(SectionType.BUSINESS_INFORMATION).values.companyName}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Company registration number</List.Header>
                                                    <List.Description>{getSection(SectionType.BUSINESS_INFORMATION).values.companyRegNumber}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Company country</List.Header>
                                                    <List.Description>{countries[alpha3ToAlpha2(getSection(SectionType.BUSINESS_INFORMATION).values.companyCountry)]}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Company address</List.Header>
                                                    <List.Description>{getSection(SectionType.BUSINESS_INFORMATION).values.companyAddress}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                        </List>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column className="profiledatacolumn">
                                    <Segment className="usersegment" basic>
                                        <List id="profile-data">
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Incorporation date</List.Header>
                                                    <List.Description>{getSection(SectionType.BUSINESS_INFORMATION).values.incorporationDate}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Company phone number</List.Header>
                                                    <List.Description>{getSection(SectionType.BUSINESS_INFORMATION).values.companyPhoneNumber}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Company e-mail address</List.Header>
                                                    <List.Description>{getSection(SectionType.BUSINESS_INFORMATION).values.companyEmailAddress}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Company description</List.Header>
                                                    <List.Description>{getSection(SectionType.BUSINESS_INFORMATION).values.companyDescription}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                        </List>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Grid.Row>
                        : <Grid.Row><Grid.Column>Business information data not provided yet</Grid.Column></Grid.Row>}

                    <Divider />
                    {getSectionHeader('Documents', SectionType.DOCUMENTS)}
                    {isSectionExist(SectionType.DOCUMENTS) ?
                        <Grid.Row className='withoutPadding'>
                            <Grid stackable columns={2}>
                                <Grid.Column className="profiledatacolumn">
                                    <Segment className="usersegment" basic>
                                        <List id="profile-data">
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Certificate of incorporation</List.Header>
                                                    <List.Description><a onClick={() => download(getSection(SectionType.DOCUMENTS).values.certificateOfIncorporation, 'certificateOfIncorporation')}> Download</a></List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">UBOs</List.Header>
                                                    <List.Description><a onClick={() => download(getSection(SectionType.DOCUMENTS).values.ubo, 'ubo')}> Download</a></List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                        </List>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column className="profiledatacolumn">
                                    <Segment className="usersegment" basic>
                                        <List id="profile-data">
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Directors</List.Header>
                                                    <List.Description><a onClick={() => download(getSection(SectionType.DOCUMENTS).values.directors, 'directors')}> Download</a></List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Business POA</List.Header>
                                                    <List.Description><a onClick={() => download(getSection(SectionType.DOCUMENTS).values.poaBusiness, 'poaBusiness')}> Download</a></List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                        </List>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Grid.Row>
                        : <Grid.Row><Grid.Column>Documents data not provided yet</Grid.Column></Grid.Row>}
                    <Divider />


                    {getSectionHeader('Directors', SectionType.DIRECTORS)}
                    {isSectionExist(SectionType.DIRECTORS) ?
                        <Grid.Row><Grid.Column><Directors directors={getSection(SectionType.DIRECTORS).values.directors} /></Grid.Column></Grid.Row>
                        : <Grid.Row><Grid.Column>Directors information data not provided yet</Grid.Column></Grid.Row>}
                    <Divider />
                    {getSectionHeader('Shareholders', SectionType.SHAREHOLDERS)}
                    {isSectionExist(SectionType.SHAREHOLDERS) ?
                        <Grid.Row><Grid.Column><Shareholders shareholders={getSection(SectionType.SHAREHOLDERS).values.shareholders} /></Grid.Column></Grid.Row>
                        : <Grid.Row><Grid.Column>Shareholders information data not provided yet</Grid.Column></Grid.Row>}
                    <Divider />
                    {getSectionHeader('Partners', SectionType.PARTNERS)}
                    {isSectionExist(SectionType.PARTNERS) ?
                        <Grid.Row><Grid.Column><Partners partners={getSection(SectionType.PARTNERS).values.partners} /></Grid.Column></Grid.Row>
                        : <Grid.Row><Grid.Column>Partners information data not provided yet</Grid.Column></Grid.Row>}
                    <Divider />

                    {getSectionHeader('Transfers', SectionType.TRANSFERS)}
                    {isSectionExist(SectionType.TRANSFERS) ?
                        <Grid.Row className='withoutPadding'>
                            <Grid stackable columns={2}>
                                <Grid.Column className="profiledatacolumn">
                                    <Segment className="usersegment" basic>
                                        <List id="profile-data">
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Planned number of incoming transactions</List.Header>
                                                    <List.Description>{getSection(SectionType.TRANSFERS).values.plannedNumberOfIncomingTransactions ?? '⠀'}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Planned turnover of incoming transactions</List.Header>
                                                    <List.Description>{getSection(SectionType.TRANSFERS).values.plannedTurnoverOfIncomingTransactions ?? '⠀'}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Additional information</List.Header>
                                                    <List.Description>{getSection(SectionType.TRANSFERS).values.additionalInformation ?? '⠀'}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                        </List>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column className="profiledatacolumn">
                                    <Segment className="usersegment" basic>
                                        <List id="profile-data">
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Planned number of outgoing transactions</List.Header>
                                                    <List.Description>{getSection(SectionType.TRANSFERS).values.plannedNumberOfOutgoingTransactions ?? '⠀'}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                            <List.Item>
                                                <List.Content>
                                                    <List.Header className="color-grey">Planned turnover of outgoing transactions</List.Header>
                                                    <List.Description>{getSection(SectionType.TRANSFERS).values.plannedTurnoverOfOutgoingTransactions ?? '⠀'}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                            <Divider />
                                        </List>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Grid.Row>
                        : <Grid.Row><Grid.Column>Transfers information data not provided yet</Grid.Column></Grid.Row>}

                </Grid>
            </Segment>
        </Container>
    );
};

export default V3ComplianceDetails;
