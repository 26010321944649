/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '../../store';
import { UserStore, Status, LoginBody, UserError, MaintenanceMode, Permission } from './types';
import { setCreditials, clearCreditials } from './credentialsSice';
import { postWhitelabel } from '../whitelabel/whitelabelSlice';

const initialState: UserStore = {
	maintenanceMode: { mode: false, reason: null },
	status: null,
	error: null,
	permissions: null,
	accrossAllWhitelabels: false,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setStatus(state, action: PayloadAction<Status>) {
			state.status = action.payload;
		},
		setPermissions(state, action: PayloadAction<Array<Permission>>) {
			state.permissions = action.payload;
		},
		setAccrossAllWhitelabels(state, action: PayloadAction<boolean>) {
			state.accrossAllWhitelabels = action.payload;
		},
		updateMaintenance(state, action: PayloadAction<MaintenanceMode>) {
			state.maintenanceMode = action.payload;
		},
		clearPermissions(state) {
			state.permissions = null;
		},
		clearStatus(state) {
			state.status = null;
		},
		clearAccrossAllWhitelabels(state) {
			state.accrossAllWhitelabels = false;
		},
		setError(state, action: PayloadAction<UserError>) {
			state.error = action.payload;
		}
	}
});

export const { updateMaintenance, setStatus, clearStatus, setError, setPermissions, clearPermissions, setAccrossAllWhitelabels, clearAccrossAllWhitelabels } = userSlice.actions;

export const userSelector = (state: { userStore: UserStore }) =>
	state.userStore;


export const status = (): AppThunk => {
	return async dispatch => {
		const response = await request.get('/api/auth/status');
		const { data } = response;
		dispatch(setStatus(data));
		dispatch(setPermissions(data.permissions));
		dispatch(setAccrossAllWhitelabels(data.userRole.agentCode === null ? true : false));
		const savedWlp = sessionStorage.getItem('wlp');
		const newWhiteLabel = data.userRole.agentCode === null ? data.source : data.userRole.agentCode;
		await dispatch(postWhitelabel(savedWlp ?? newWhiteLabel));
		dispatch(setError(null));
		return data;
	};
};

export const login = (payload: LoginBody): AppThunk => {
	return async dispatch => {
		const response = request.post('/api/admin/auth/login',
			payload
		);
		const { data } = await response;
		dispatch(setCreditials(data));
		dispatch(setError(null));
	};
};


export const logout = (): AppThunk => {
	return async dispatch => {
		try {
			// await request.get('/api/admin/auth/logout');
			dispatch(clearCreditials());
			dispatch(clearPermissions());
			dispatch(clearAccrossAllWhitelabels());
			dispatch(clearStatus());
			sessionStorage.removeItem('wlp');
		} catch (error) {
			//TODO handle error
			console.log(error);
		}
	};
};

export const getUserLevels = async () => {
	const response = await request.get('/api/admin/user-level');
	const { data } = response;
	return data;
};

export default userSlice.reducer;
