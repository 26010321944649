import React, { useCallback, useEffect, useState } from 'react';

import { Divider, Grid, Header, List, Segment } from 'semantic-ui-react';
import { Input, Select } from 'formik-semantic-ui-react';
import { PaymentConfigurationType } from '@/features/payments/paymentTypes';
import { SystemAccountSummaryType } from '@/features/systemAccountDetails/types';
import { ErrorMessage } from 'formik';
import { showErrorNotification } from '@/features/swal/slice';
import { getPaymentSysAccounts, getPaymentSysMainAccounts } from '@/features/payments/configSlice';

interface Props {
	selectedConfig: PaymentConfigurationType,
	systemAccount: SystemAccountSummaryType,
	editDisabled: boolean
}

const Payment = ({ selectedConfig, systemAccount, editDisabled }: Props) => {

	const [toAccounts, setToAccounts] = useState([]);

	const toAccountLov = toAccounts?.map((p) => {
		return {
			key: p.accountId.toString(),
			text: `${p.walletId} | ${p.sysAcctType} | ${p.description}`,
			value: p.accountId.toString()
		};
	});

	const toAccountMandatory = selectedConfig?.operationCode === 'TRANSFER_TO_SYS_ACCT' || selectedConfig?.operationCode === 'MAIN_ACCOUNT_TRANSFERS';

	const fetchData = useCallback(() => {
		const get = async () => {
			try {

				if (selectedConfig?.operationCode === 'TRANSFER_TO_SYS_ACCT' && systemAccount?.integration && selectedConfig?.wlpId) {
					const result = await getPaymentSysAccounts(systemAccount?.integration, selectedConfig.wlpId, systemAccount?.currencyCode);
					setToAccounts(result?.list || []);
				}
				if (selectedConfig?.operationCode === 'MAIN_ACCOUNT_TRANSFERS' && systemAccount?.integration) {
					const result = await getPaymentSysMainAccounts(systemAccount?.integration, systemAccount?.currencyCode);
					setToAccounts(result?.list || []);
				}
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [selectedConfig?.operationCode]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);


	return (
		<Segment>
			<Grid columns={toAccountMandatory ? 2 : 4} >
				<Grid.Row>
					<Grid.Column width={toAccountMandatory ? 12 : 4}>
						<Header as='h5' >Chosen Payment</Header>
						{selectedConfig ?
							<List>
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Operation</List.Header>
										<List.Description>{selectedConfig?.operationName}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">From Wallet</List.Header>
										<List.Description>{selectedConfig?.fromWalletId}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								{!toAccountMandatory && <>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">To Wallet</List.Header>
											<List.Description>{selectedConfig?.toWalletId}</List.Description>
										</List.Content>
									</List.Item>
									<Divider /></>
								}
								{toAccountMandatory &&
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">To Account</List.Header>
											<Select
												fluid
												name='toAccountId'
												options={toAccountLov}
												disabled={editDisabled}
												errorPrompt
												search
											/>
										</List.Content>
									</List.Item>}
								{selectedConfig?.notes &&
									<>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Notes</List.Header>
												<List.Description>{selectedConfig?.notes}</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</>
								}
							</List>
							: <div>Please Choose Payment</div>
						}
					</Grid.Column >
					<Grid.Column width={4}>
						<Header as='h5'>Summary</Header>
						<Input
							label={`Amount ${systemAccount?.currencyCode}`}
							name='amount'
							type='number'
							disabled={editDisabled}
						/>
						<ErrorMessage name="amount" />
						<ErrorMessage name="currency" />
						<Input
							label='Details'
							name='details'
							disabled={editDisabled}
						/>
						<Input
							label='Date'
							name='transactionDate'
							type='datetime-local'
							disabled={editDisabled}
						/>
						<Input
							label='Bank Reconciliation Reference'
							fluid
							name='reconciliationRef'
							disabled={editDisabled}
						/>
					</Grid.Column>
					{!toAccountMandatory &&
						<Grid.Column >
							<Header as='h5' content='Remitter (From)' />
							<Input
								label='Bank'
								name='fromBank'
								disabled
							/>
							<Input fluid
								label='Bank Address'
								name='fromAddress'
								disabled
							/>
							<Input fluid
								label='Customer Name'
								name='fromCustomerName'
								disabled
							/>
							<Input fluid
								label='Customer ID'
								name='fromCustomerId'
								disabled
							/>
							<Input fluid
								label='IBAN'
								name='fromIban'
								disabled
							/>
						</Grid.Column>}
					{!toAccountMandatory &&
						<Grid.Column>
							<Header as='h5' content='Beneficiary (To)' />
							<Input fluid
								label='Bank'
								name='toBank'
								disabled={editDisabled}
							/>
							<Input
								fluid
								label='Bank Address'
								name='toAddress'
								disabled={editDisabled}
							/>
							<Input fluid
								label='Customer Name'
								name='toCustomerName'
								disabled={editDisabled}
							/>
							<Input
								fluid
								label='Customer ID'
								name='toCustomerId'
								disabled={editDisabled}
							/>
							<Input fluid
								label='IBAN'
								name='toIban'
								disabled={editDisabled}
							/>
						</Grid.Column>
					}
				</Grid.Row>
			</Grid>
		</Segment>);
};

export default Payment;
