import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';


interface Props {
	open: boolean;
	onClose: (sourceWalletId?: string) => void,
}

const TopupAndCloseCardModal = ({ open, onClose }: Props): React.ReactElement => {

	const initialValues = {
		walletId: ''
	};

	const validationSchema = Yup.object({
		walletId: Yup.string()
			.required('Wallet ID is required')

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { walletId } = formData;
		onClose(walletId);
	};

	return <Modal
		size="small"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content="Top-up and close" />
						<Input
							label='Wallet ID'
							fluid
							name="walletId"
							errorPrompt
						/>

						<Divider style={{ marginBottom: '2rem', marginTop: '2rem' }} />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										Topup and close
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default TopupAndCloseCardModal;
