/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, List, Segment, } from 'semantic-ui-react';
import { getErrorWithParams, showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import Loading from '@/components/Shared/Loading';
import '../Clients/ClientDetails/Profile.css';
import { RootState } from '@/rootReducer';
import { PermissionType } from '@/features/user/types';
import { checkPermission } from '@/helpers/permissions';
import { useHistory } from 'react-router-dom';
import { accountsAutoCreation, getUserPoa, updateAddress } from '@/features/poa/slice';
import { KycIdentity, UserPOA } from '@/features/poa/types';
import UpdateKycFieldModal from './UpdateKycFieldModal';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import isEmpty from 'is-empty';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';
import {
	exportApplicant,
	postSyncOnfido,
	postSyncSumSub,
	updateKycData
} from '@/features/compliance/slice';
import { UpdateKycDataPayload } from '@/features/compliance/types';
import usStates from './usstates.json';
import _ from 'lodash';
import { createKyc, updateKyc } from '@features/clients/slice';
import RiskEvaluation from '../Compliance/RiskEvaluation';
import PoaDocuments from '../Compliance/PoaDocuments';
import KycAddresses from '../Compliance/KycAddresses';

const UserPoa = (props): React.ReactElement => {

	const dispatch = useDispatch();
	const [userId] = useState<number>(props.match.params.userId);
	const [userPoa, setUserPoa] = useState<UserPOA>(null);

	const [countriesOptions, setCountriesOptions] = useState<any>(null);
	const countries = getNames('en');

	const [createAccountsLoading, setCreateAccountsLoading] = useState<boolean>(false);
	const [openUpdateKycFieldModal, setOpenUpdateKycFieldModal] = useState<boolean>(false);

	const history = useHistory();
	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		const options = [];
		Object.keys(countries)
			.map($code => {
				options.push({ key: alpha2ToAlpha3($code), text: countries[$code], value: alpha2ToAlpha3($code) });
			});
		setCountriesOptions(options);
	}, []);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getUserPoa(userId);
				setUserPoa(data);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [userId, dispatch]);

	useEffect(() => {
		fetchData();
	}, [userId, dispatch]);



	if (!userPoa) {
		return <Loading />;
	}


	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};


	const handleUpdateKycFieldClose = async (service?: string, serviceId?: string, checkId?: string) => {
		setOpenUpdateKycFieldModal(false);
		if (service) {
			if (userPoa?.kycIdentity?.kycId) {
				try {
					await updateKyc(userPoa.kycIdentity.kycId, service, serviceId, checkId);
					showSuccessNotification('Provider changed');
				} catch (e) {
					const err = getErrorWithParams(e);
					if (e && err.error === 'duplicate' && err.error_param === 'email') {
						showErrorNotification(err, 'User with this email already exists');
					} else {
						showErrorNotification(e);
					}
				}
			} else {
				try {
					await createKyc(userId, service, serviceId, checkId);
					showSuccessNotification('KYC created');
				} catch (e) {
					const err = getErrorWithParams(e);
					if (e && err.error === 'duplicate' && err.error_param === 'email') {
						showErrorNotification(err, 'User with this email already exists');
					} else {
						showErrorNotification(e);
					}
				}
			}
			fetchData();
		}
	};

	const initialValues = {
		country: userPoa.profile.addressCountry ?? '',
		address: userPoa.profile.address ?? '',
		town: userPoa.profile.city ?? '',
		postalCode: userPoa.profile.postalCode ?? '',
		state: userPoa.profile.state ?? '',
	};

	const validationSchema = Yup.object({
		country: Yup.string()
			.required('You must choose a country'),
		address: Yup.string()
			.required('You must enter a address'),
		town: Yup.string()
			.required('You must enter a town'),
		postalCode: Yup.string()
			.required('You must enter a postalCode'),
		state: Yup.string().when('country', {
			is: 'USA',
			then: Yup.string().required('You must enter a state'),
			otherwise: Yup.string().notRequired(),
		})
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { country, address, town, postalCode, state } = formData;
		try {
			await updateAddress(userPoa.id, country, address, town, postalCode, state);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const getDocumentType = (kycIdentity: KycIdentity) => {
		if (!kycIdentity) return;

		if (kycIdentity.service === 'ONFIDO') {
			switch (kycIdentity.documentType) {
				case 'national_identity_card':
					return 'ID_CARD';
				case 'passport':
					return 'PASSPORT';
				default:
					return '';
			}
		} else if (kycIdentity.service === 'SUMSUB') {
			switch (kycIdentity.documentType) {
				case 'ID_CARD':
					return 'ID_CARD';
				case 'PASSPORT':
					return 'PASSPORT';
				case 'RESIDENCE_PERMIT':
					return 'RESIDENCE_PERMIT';
				default:
					return '';
			}
		} else {
			return '';
		}

	};

	const isKycRequiredDataPresent = !_.isEmpty(userPoa.kycIdentity?.firstName) &&
		!_.isEmpty(userPoa.kycIdentity?.lastName) &&
		!_.isEmpty(userPoa.kycIdentity?.birthDate) &&
		!_.isEmpty(userPoa.kycIdentity?.documentNumber) &&
		!_.isEmpty(userPoa.kycIdentity?.issuingCountry) &&
		!_.isEmpty(userPoa.kycIdentity?.issueDate) &&
		!_.isEmpty(userPoa.kycIdentity?.expiryDate) &&
		!_.isEmpty(userPoa.kycIdentity?.documentType);

	const kycInitialValues = {
		firstName: userPoa.kycIdentity?.firstName ?? '',
		lastName: userPoa.kycIdentity?.lastName ?? '',
		dob: userPoa.kycIdentity?.birthDate ?? '',
		docType: getDocumentType(userPoa.kycIdentity),
		docNumber: userPoa.kycIdentity?.documentNumber ?? '',
		issuingCountry: userPoa.kycIdentity?.issuingCountry ?? '',
		issueDate: userPoa.kycIdentity?.issueDate ?? '',
		expiryDate: userPoa.kycIdentity?.expiryDate ?? '',
		placeOfBirth: userPoa.kycIdentity?.placeOfBirth ?? '',
		gender: userPoa.kycIdentity?.gender ?? '',
		identificationNumber: userPoa.kycIdentity?.identificationNumber ?? '',
	};

	const kycValidationSchema = Yup.object({
		firstName: Yup.string()
			.required('You must enter a first name'),
		lastName: Yup.string()
			.required('You must enter a last name'),
		dob: Yup.string()
			.required('You must enter a date of birth'),
		issueDate: Yup.date()
			.required('You must enter a issue date')
			.test('issueDate', 'Issue date should be before current time', (issueDate) => (issueDate < new Date(Date.now() + (60 * 30 * 1000)))),
		expiryDate: Yup.date()
			.required('You must enter a expiry date')
			.test('expiryDate', 'Expiry date should be after current time', (expiryDate) => (expiryDate > new Date(Date.now() + (60 * 30 * 1000)))),
		docNumber: Yup.string()
			.required('You must enter a document number'),
	});

	const submitKyc = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { firstName, lastName, dob, docType, docNumber, issuingCountry, issueDate, expiryDate, placeOfBirth, gender, identificationNumber } = formData;

		const payload: UpdateKycDataPayload = {
			applicantId: userPoa.kycIdentity.serviceId,
			firstName,
			lastName,
			dateOfBirth: dob,
			documentType: docType,
			documentNumber: docNumber === '' ? null : docNumber,
			issuingCountry: issuingCountry,
			issueDate: issueDate === '' ? null : issueDate,
			expiryDate: expiryDate === '' ? null : expiryDate,
			placeOfBirth: placeOfBirth === '' ? null : placeOfBirth,
			identificationNumber: identificationNumber === '' ? null : identificationNumber,
			gender: gender === '' ? null : gender
		};

		try {
			await updateKycData(payload);
			fetchData();
			showSuccessNotification('Data changes saved');
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const docTypeOptions = [
		{
			key: 'EMPTY',
			value: null,
			text: ''
		},
		{
			key: 'ID_CARD',
			value: 'ID_CARD',
			text: 'ID card'
		},
		{
			key: 'PASSPORT',
			value: 'PASSPORT',
			text: 'Passport'
		},
		{
			key: 'RESIDENCE_PERMIT',
			value: 'RESIDENCE_PERMIT',
			text: 'Residence permit'
		}
	];


	const genderOptions = [
		{
			key: 'EMPTY',
			value: null,
			text: ''
		},
		{
			key: 'Male',
			value: 'Male',
			text: 'Male'
		},
		{
			key: 'Female',
			value: 'Female',
			text: 'Female'
		},
	];


	const syncOnfido = async (applicantId: string, userId: number) => {
		try {
			await postSyncOnfido(applicantId, userId);
			fetchData();
			showSuccessNotification('Data synced with KYC provider');
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const syncSumSub = async (applicantId: string, userId: number) => {
		try {
			await postSyncSumSub(applicantId, userId);
			fetchData();
			showSuccessNotification('Data synced with KYC provider');
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const actionExportApplicant = async () => {
		try {
			await exportApplicant(userId);
			fetchData();
			showSuccessNotification('Applicant exported');
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const syncWithKYCProvider = () => {
		if (userPoa.kycIdentity.service === 'ONFIDO') {
			syncOnfido(userPoa.kycIdentity.serviceId, userId);
		} else if (userPoa.kycIdentity.service === 'SUMSUB') {
			syncSumSub(userPoa.kycIdentity.serviceId, userId);
		} else {
			return;
		}
	};

	const createAccounts = async (userId: number) => {
		try {
			setCreateAccountsLoading(true);
			await accountsAutoCreation(userId);
			fetchData();
			setCreateAccountsLoading(false);
			showSuccessNotification('Accounts auto creation process initiated');
		} catch (e) {
			setCreateAccountsLoading(false);
			showErrorNotification(e);
		}
	};

	return (
		<Container fluid>

			<UpdateKycFieldModal
				onClose={handleUpdateKycFieldClose}
				open={openUpdateKycFieldModal}
				initialService={userPoa.kycIdentity?.service}
				initialServiceId={userPoa.kycIdentity?.serviceId}
				initialCheckId={userPoa.kycIdentity?.checkId}
			/>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Internal compliance</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
							<Button
								style={{ marginLeft: 10 }}
								secondary
								content='View user'
								disabled={!checkPermission(permissions, PermissionType.USERS)}
								onClick={() => openUser(userPoa.id)}
								floated='right'
							/>
							<Button
								style={{ marginLeft: 10 }}
								color='green'
								content='Create accounts'
								loading={createAccountsLoading}
								disabled={!checkPermission(permissions, PermissionType.POA_COMPLIANCE)}
								onClick={() => createAccounts(userPoa.id)}
								floated='right'
							/>
						</Grid.Column>
						<Grid.Column floated='right' style={{ paddingTop: 10 }}>
							<Button
								basic
								color='green'
								style={{ marginLeft: 10 }}
								content='Export Applicant'
								loading={createAccountsLoading}
								disabled={!checkPermission(permissions, PermissionType.POA_COMPLIANCE)}
								onClick={actionExportApplicant}
								floated='right'
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable columns={2}>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Client ID</List.Header>
										<List.Description>{userPoa.clientId}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Name</List.Header>
										<List.Description>{`${userPoa.profile.firstName} ${userPoa.profile.lastName}`}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Mobile number/Email</List.Header>
										<List.Description>{userPoa.mobileNumber ?? userPoa.email}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">KYC provider</List.Header>
										<List.Description>{userPoa.kycIdentity?.service ? <div>
											{userPoa.kycIdentity?.service} <Icon onClick={() => setOpenUpdateKycFieldModal(true)} disabled={!checkPermission(permissions, PermissionType.UPDATE_KYC)} style={{ marginLeft: 4, cursor: 'pointer' }} name='edit' />
										</div> : <Button
											primary
											content='Add provider'
											onClick={() => setOpenUpdateKycFieldModal(true)}
											disabled={!checkPermission(permissions, PermissionType.POA_COMPLIANCE)}
											size='mini'
										/>}</List.Description>
									</List.Content>
								</List.Item>
								{userPoa.kycIdentity?.service &&
									<>
										<Divider />
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Applicant ID</List.Header>
												<List.Description>{userPoa.kycIdentity?.serviceId ? <div>
													{userPoa.kycIdentity?.serviceId}
												</div> : <Button
													primary
													content='Add applicant'
													onClick={() => setOpenUpdateKycFieldModal(true)}
													disabled={!checkPermission(permissions, PermissionType.POA_COMPLIANCE)}
													size='mini'
												/>}</List.Description>
											</List.Content>
										</List.Item>
									</>
								}
								{userPoa.kycIdentity?.service === 'ONFIDO' &&
									<>
										<Divider />
										<List.Item>
											<List.Content>
												<List.Header className="color-grey">Check ID</List.Header>
												<List.Description>{userPoa.kycIdentity?.checkId ? <div>
													{userPoa.kycIdentity?.checkId}
												</div> : <Button
													primary
													content='Add check ID'
													onClick={() => setOpenUpdateKycFieldModal(true)}
													disabled={!checkPermission(permissions, PermissionType.POA_COMPLIANCE)}
													size='mini'
												/>}</List.Description>
											</List.Content>
										</List.Item>
									</>
								}
								<Divider />
							</List>
						</Segment>
					</Grid.Column>

					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<Header as='h4'>Address info</Header>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={submit}
								enableReinitialize={true}
							>
								{({ errors, isSubmitting, dirty, values }) => (
									<Form
										size="large">
										<Select
											label='Country'
											fluid
											name="country"
											options={countriesOptions}
											search
											clearable
											selection
											errorPrompt
										/>
										{values.country === 'USA' && <Select
											label='State'
											fluid
											name="state"
											options={usStates.map(s => ({ key: s.abbreviation, value: s.abbreviation, text: s.name }))}
											clearable
											errorPrompt
										/>}
										<Input
											label='Address'
											fluid
											name="address"
											errorPrompt
										/>
										<Input
											label='Town'
											fluid
											name="town"
											errorPrompt
										/>
										<Input
											label='Postal Code'
											fluid
											name="postalCode"
											errorPrompt
										/>

										<Divider />
										<Grid columns={2}>
											<Grid.Row>
												<Grid.Column >
													<SubmitButton
														disabled={isSubmitting || !isEmpty(errors) || !dirty}
														primary size="large" type="submit"
													>
														Save changes
													</SubmitButton>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Form>)}
							</Formik>
						</Segment>
					</Grid.Column>

				</Grid>

				{userPoa && userPoa.kycIdentity && <Grid stackable >
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<Grid>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Header as='h4'>Compliance data</Header>
									</Grid.Column>
									<Grid.Column>
										{(userPoa.kycIdentity.service === 'ONFIDO' || userPoa.kycIdentity.service === 'SUMSUB') && <Button
											style={{ marginLeft: 10 }}
											secondary
											content='Sync with KYC provider'
											disabled={!checkPermission(permissions, PermissionType.POA_COMPLIANCE)}
											onClick={syncWithKYCProvider}
											floated='right'
											type='button'
										/>}
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Formik
								initialValues={kycInitialValues}
								validationSchema={kycValidationSchema}
								onSubmit={submitKyc}
								enableReinitialize={true}
							>
								{({ errors, isSubmitting, dirty }) => (
									<Form
										size="large">
										<Input
											label='First Name'
											fluid
											name="firstName"
										/>
										<Input
											label='Last Name'
											fluid
											name="lastName"
										/>
										<Input
											name="dob"
											label='Date of Birth'
											type="date"
											placeholder='YYYY/MM/DD'
											max="2999-12-31"
											errorPrompt
										/>
										<Select
											label='Gender'
											fluid
											name="gender"
											options={genderOptions}
										/>

										<Select
											label='Document type'
											fluid
											name="docType"
											options={docTypeOptions}
										/>
										<Input
											label='Document Number'
											fluid
											name="docNumber"
										/>
										<Input
											label='Identification number'
											fluid
											name="identificationNumber"
										/>
										<Select
											label='Issuing Country'
											fluid
											name="issuingCountry"
											options={[{ key: 'EMPTY', value: null, text: '' }, ...countriesOptions]}
											search
											clearable
											selection
										/>
										<Input
											name="issueDate"
											label='Issue date'
											type="date"
											placeholder='YYYY/MM/DD'
											max="2999-12-31"
											errorPrompt
										/>
										<Input
											name="expiryDate"
											label='Expiry Date'
											type="date"
											placeholder='YYYY/MM/DD'
											max="2999-12-31"
											errorPrompt
										/>
										<Input
											label='Place Of Birth'
											fluid
											name="placeOfBirth"
										/>

										<Divider />
										<Grid columns={2}>
											<Grid.Row>
												<Grid.Column >
													<SubmitButton
														disabled={isSubmitting || !isEmpty(errors) || !dirty}
														primary size="large" type="submit"
													>
														Save changes
													</SubmitButton>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Form>)}
							</Formik>
							{!isKycRequiredDataPresent && <div style={{ color: 'red' }}>Please fill mandatory fields in compliance data form</div>}
						</Segment>
					</Grid.Column>
				</Grid>}

			</Segment>
			<RiskEvaluation userId={userId}
				risksVerified={userPoa.risksVerified}
				risksVerifiedByUser={userPoa?.risksVerifiedByUser}
				fetchUser={fetchData} />
			<PoaDocuments userPoa={userPoa} fetchData={fetchData} isKycRequiredDataPresent={isKycRequiredDataPresent} />
			<KycAddresses userId={userId} countriesOptions={countriesOptions} />
		</Container>
	);
};

export default UserPoa;
