import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers, transformFilterToObject } from '@/components/Shared/Table/Table';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import NumberFormat from 'react-number-format';
import { formatDateWithPattern } from '@/helpers/date';
import { Link } from 'react-router-dom';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { PaginateParams } from '@/helpers/globalTypes';
import { showException } from '@/features/swal/slice';
import useGLLov from '@helpers/customHook/useGLLov';
import { GlLookupTypes } from '@features/statements/statementsTypes';
import { getGlLookup } from '@features/statements/statementsSlice';
import {
	downloadLargeTransactionExport,
	getLargeTransactionExport,
	setAmlReports
} from '@features/amlReports/amlReportsSlice';

const SystemDocuments = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading, isDownloading } = useSelector((state: RootState) => state.amlReports);
	const paginateInitialState: PaginateParams = { orderBy: 'clientId', skip: 0, sort: 'ASC', filter: 'repGreaterThan=10000;cpInclude=Y', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [emiCcyLov, setEmiCcyLov] = useState<any>({});
	const [ccy, setCcy] = useState<any>('');
	const { options: emiLov } = useGLLov(GlLookupTypes.AML_LARGE_TRX_EMI);

	const handleEmiChange = (newEmi) => {
		if (newEmi) {
			setCcy(emiCcyLov[newEmi]);
		} else {
			setCcy('');
		}
	};

	const filter: FilterParam[] = [
		{
			field: 'dateFrom',
			label: 'Report Date From',
			type: 'input',
			inputType: 'date',
			required: true
		},
		{
			field: 'dateTo',
			label: 'Report Date To',
			type: 'input',
			inputType: 'date',
			required: true
		},
		{
			field: 'repGreaterThan',
			label: `Greater Than ${ccy}`,
			type: 'input',
			inputType: 'number',
			required: true,
		},
		{
			field: 'emi',
			label: 'EMI',
			type: 'select',
			// options: emiLovOptions
			options: emiLov,
			onChange: handleEmiChange,
		},
		{
			field: 'cpInclude',
			label: 'Included in report',
			type: 'select',
			options: [
				{
					key: 'yes',
					text: 'Yes',
					value: 'Y',
				},
				{
					key: 'no',
					text: 'No',
					value: 'N',
				},
			],
		},
		{
			field: 'integration',
			label: 'Integration',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'wlpId',
			name: <div>Wallet ID<br />Client ID</div>,
			position: 1,
			sortable: false
		},
		{
			field: 'integration',
			name: <div>Email<br />Client Name</div>,
			position: 2,
			sortable: false
		},
		{
			field: 'transactionDate',
			name: 'Transaction Date',
			position: 3,
			sortable: false
		},
		{
			field: 'amount',
			name: <div>Amount<br />Reporting Amount</div>,
			position: 4,
			sortable: false
		},
		{
			field: 'total',
			name: 'Daily Turnover',
			position: 5,
			sortable: false
		},
		{
			field: 'wbhProcName',
			name: <div>Document Nr<br />Transaction Nr</div>,
			position: 6,
			sortable: false
		},
		{
			field: 'changeAmount',
			name: <div>Integration<br />WLP_ID</div>,
			position: 7,
			sortable: false
		},
		{
			field: 'cpName',
			name: 'Counter Party',
			position: 8,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 9,
			sortable: false
		}
	];

	const fetchData = useCallback(() => {
		const get = async () => {
			const list = await getGlLookup(GlLookupTypes.AML_LARGE_TRX_EMI);

			const emiCcy = {};
			list.forEach(item => {
				emiCcy[item.code] = item.description;
			});
			setEmiCcyLov(emiCcy);
		};
		get();
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		const filterKeys = Object.keys(transformFilterToObject(pagination.filter)) || [];
		if (filterKeys.includes('dateFrom') && filterKeys.includes('dateTo')) {
			dispatch(getLargeTransactionExport(pagination));
		} else if (list.length > 0) {
			dispatch(setAmlReports([]));
		}
	}, [pagination, dispatch]);


	const download = async () => {
		try {
			dispatch(downloadLargeTransactionExport(pagination));
		}
		catch (e) {
			showException(e);
		}
	};

	const downloadBtn = (
		<>
			<Divider hidden />
			<Button secondary basic
					content='Download Results'
					onClick={download}
					disabled={isDownloading || list.length < 1} />
		</>);

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry,idx) => {
				return (
					<Table.Row key={`${entry.wbhId} ${idx}`} >
						<Table.Cell>{entry.walletId}<br/>{entry.clientId}</Table.Cell>
						<Table.Cell>{entry.email}<br/>{entry.clientName}</Table.Cell>
						<Table.Cell>{formatDateWithPattern(entry.transactionDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell>
							{entry.currencyCode} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.changeAmount} />
							<br/>
							{entry.repCC} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.repChangeAmountCc} />
						</Table.Cell>
						<Table.Cell>
							DR {entry.repCC} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.totalRepCcDr} />
							<br/>
							CR {entry.repCC} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.totalRepCcCr} />
						</Table.Cell>
						<Table.Cell>{entry.docNumber}<br/>{entry.transactionNumber}</Table.Cell>
						<Table.Cell>{entry.integration}<br/>{entry.wlpId}</Table.Cell>
						<Table.Cell>{entry.cpName}<br/>{entry.cpAccount}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Link to={`/document/${entry.docNumber ?? ''}`}>View Document</Link>
							<br />
							<Link to={`/client/${entry.userId ?? ''}`}>View Client</Link>
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
                <Table.Cell textAlign='center' colSpan={9}>No documents yet.</Table.Cell>
            </Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid id='all-documents'>
			<Segment>
				<Header as='h2'>AML 24h Large Transactions</Header>
				<Divider hidden />
				<CustomTable
					filter={filter}
					loading={loading}
					paginateInitialState={pagination}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
					contentAfterFilter={downloadBtn}
				/>
			</Segment>
		</Container>
	);
};

export default SystemDocuments;
