import React, { useState } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
	Icon,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { showErrorNotification } from '@/features/swal/slice';
import { addAttachments, createSecurityReport, downloadAttachment, updateSecurityReport } from '@/features/userSecurityReport/slice';
import { CreateUserSecurityReportPayload, UpdateUserSecurityReportPayload, UserSecurityReport } from '@/features/userSecurityReport/types';
import { convertDate } from '@/helpers/date';


interface Props {
	userId: number,
	open: boolean;
	writeAllowed: boolean,
	report?: UserSecurityReport,
	onClose: () => void,
	onSuccess: () => void,
}

const addFiles = (formData: FormData, files: File[]) => {
	if (files?.length > 0) {
		files.map(file => {
			formData.append('files', file);
		});
	}
};

const ChangeReportModal = ({ report, writeAllowed, userId, open, onClose, onSuccess }: Props): React.ReactElement => {

	const [selectedFiles, setSelectedFiles] = useState<any>([]);
	const maxFileSizeKb = 5000;

	const onDrop = (acceptedFiles: File[]) => {
		if (acceptedFiles?.length < 1) {
			setSelectedFiles([]);
		}
		else {
			const allowedFiles = acceptedFiles.filter(p => p.size <= (maxFileSizeKb * 1000)) || [];
			setSelectedFiles([...selectedFiles, ...allowedFiles]);
		}
	};


	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: 'image/png,image/jpg,image/jpeg,application/pdf',
		maxFiles: 4,
	});

	const deleteAttachment = (index: number) => {
		setSelectedFiles(prev => prev?.filter((_, i) => i !== index));
	};

	const initialValues = {
		fullName: report?.fullName ?? '',
		accountNumber: report?.accountNumber ?? '',
		suspectDetails: report?.suspectDetails ?? '',
		trxDate: report?.trxDate ? convertDate(report.trxDate, 'yyyy-MM-dd') : '',
		trxAmount: report?.trxAmount ?? '',
		details: report?.details ?? '',
		trxSarsList: report?.trxSarsList ?? '',
	};


	const validationSchema = Yup.object({
		fullName: Yup.string().required('Name is required'),
		suspectDetails: Yup.string().required('Details are required'),
		trxAmount: Yup.number().required('Transaction amount is required'),
		trxDate: Yup.date().required('Transaction date is required'),

	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);

		let reportId: number;
		try {
			const { fullName,
				accountNumber,
				suspectDetails,
				trxDate,
				trxAmount,
				details,
				trxSarsList } = formData;


			if (report) {
				const payload: UpdateUserSecurityReportPayload = {
					reportId: report.id,
					fullName,
					accountNumber,
					suspectDetails,
					trxDate,
					trxAmount,
					details,
					trxSarsList
				};

				await updateSecurityReport(payload);
				reportId = report.id;

			}
			else {
				const payload: CreateUserSecurityReportPayload = {
					userId: userId,
					fullName,
					accountNumber,
					suspectDetails,
					trxDate,
					trxAmount,
					details,
					trxSarsList
				};

				const result = await createSecurityReport(payload);
				reportId = result.id;

			}

			if (selectedFiles?.length > 0) {
				const fileData = new FormData();
				fileData.append('reportId', `${reportId}`);
				addFiles(fileData, selectedFiles);
				await addAttachments(fileData);

			}
			onSuccess();
		}
		catch (e) {
			showErrorNotification(e);
		}
		finally {
			setSubmitting(false);
			handleClose();
		}
	};

	const handleClose = () => {
		setSelectedFiles([]);
		acceptedFiles.splice(0);
		onClose();

	};

	const download = (key: string, name: string, type: 'attachment' | 'finding') => {
		const get = async () => {
			try {
				downloadAttachment(key, name, type);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	};


	return <Modal
		open={open}
		onClose={() => handleClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => {
					return (
						<Form
							size='large'>
							<Header content={report ? 'Edit Security Assessment Report ' : 'New Security Assessment Report'} />
							<Input
								label='Full name of customer'
								name='fullName'
								disabled={!writeAllowed}
								errorPrompt />
							<Input
								label='Account number'
								name='accountNumber'
								disabled={!writeAllowed}
								errorPrompt />
							<TextArea
								label='Name and account details of suspect(s)'
								name='suspectDetails'
								disabled={!writeAllowed}
								errorPrompt />
							<Input
								label='Transaction Date'
								name='trxDate'
								type='date'
								disabled={!writeAllowed}
								errorPrompt />
							<Input
								label='Transaction value in EUR'
								name='trxAmount'
								type='number'
								max={9999999999}
								disabled={!writeAllowed}
								errorPrompt />
							<TextArea
								label='Describe in as much detail as possible as to why you are suspicious, or what you know to be money laundering, fraud or terrorist property'
								name='details'
								disabled={!writeAllowed}
								errorPrompt />
							<TextArea
								label='List any transactions or SARs which are related to this report, or any transactions which are currently pending'
								name='trxSarsList'
								disabled={!writeAllowed}
								errorPrompt />

							{report?.attachments?.length > 0 &&
								<>
									<Divider />
									<Grid>
										{report.attachments.map((file, idx) => {
											return (
												<Grid.Row key={idx}>
													<div style={{ cursor: 'pointer', paddingLeft: '15px' }}
														className='fileName'
														onClick={() => download(file.fileKey, file.fileName, 'attachment')}
													>{file.fileName}</div>
												</Grid.Row>);
										})
										}
									</Grid>
								</>}
							<Grid className='uploadFile' style={{ marginLeft: '0', marginRight: '0', marginBottom: '1em', marginTop: '1em' }}>
								{writeAllowed && <Grid.Row>
									<div {...getRootProps()} id='file-container'>
										<input {...getInputProps()} id='file-drop' />
										<span><Icon name='upload' /> Upload supporting documents...</span>
									</div>
								</Grid.Row>
								}
								{selectedFiles?.length > 0 &&
									selectedFiles.map((file, idx) => {
										return (
											<Grid.Row key={idx}>
												<Icon size='large' name='file outline' />
												<div className='fileName'>{file.name}</div>
												<Icon className='fileName' size='large' name='trash' link onClick={() => deleteAttachment(idx)} />
											</Grid.Row>);
									})
								}
							</Grid>
							<Grid columns={2}>
								<Grid.Row>
									<Grid.Column>
										<SubmitButton
											disabled={isSubmitting || !isEmpty(errors) || !dirty}
											primary size='large' type='submit'
											floated='right'>
											Save
										</SubmitButton>
									</Grid.Column>
									<Grid.Column>
										<Button floated='left' secondary size='large' content='Discard' onClick={() => handleClose()} />
									</Grid.Column>
								</Grid.Row>
							</Grid>
							{report?.completionDate &&
								<>
									<Header content='Completion Details' />
									<Divider />
									<Grid columns={4}>
										<Grid.Row>
											<Grid.Column>
												Completion Data
											</Grid.Column>
											<Grid.Column>
												{convertDate(report.completionDate, 'dd/MM/yyyy')}
											</Grid.Column>
											<Grid.Column>
												Explanation
											</Grid.Column>
											<Grid.Column>
												{report.explanation}
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column>
												Reported To Authority?
											</Grid.Column>
											<Grid.Column>
												{report.reportedToAuthority ? 'Yes' : 'No'}
											</Grid.Column>

											<Grid.Column>
												Finding File
											</Grid.Column>
											<Grid.Column>
												<div style={{ cursor: 'pointer' }}
													onClick={() => download(report.fileKey, report.fileName, 'finding')}
												>{report.fileName}</div>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</>
							}
						</Form>);
				}}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default ChangeReportModal;
