/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PaginateParams } from '@/helpers/globalTypes';
import request from '@/services/request';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showException } from '../swal/slice';
import { PaymentConfigurationType, PaymentConfigStore, MakePaymentType } from './paymentTypes';

const initialState: PaymentConfigStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0
};

const slice = createSlice({
	name: 'paymentConfig',
	initialState,
	reducers: {
		setPaymentConfig(state, action: PayloadAction<Array<PaymentConfigurationType>>) {
			state.list = action.payload || [];
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const {
	setPaymentConfig, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getPaymentConfig = (payload?: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const filterParams = {
				filter: payload?.filter,
				orderBy: payload?.orderBy,
				sort: payload?.sort.toUpperCase(),
				skip: payload?.skip.toString(),
				take: payload?.take ?? 1000
			};
			const response = await request.get('/api/admin/payments/config', { params: filterParams });
			const { data } = response;
			dispatch(setPaymentConfig(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			dispatch(setLoading(false));
			showException(e);
		}
	};
};

export const postPaymentConfig = async (payload: PaymentConfigurationType) => {
	const response = await request.post('/api/admin/payments/config', payload);
	const { data } = response;
	return data;
};

export const putPaymentConfig = async (paymentConfigId: number, payload: PaymentConfigurationType) => {
	const response = await request.put(`/api/admin/payments/config/${paymentConfigId}`, payload);
	const { data } = response;
	return data;
};


export const postPayment = async (userId: number, operation: string, payload: MakePaymentType) => {
	const response = await request.post('/api/admin/payments/', { userId, operation, data: payload });
	const { data } = response;
	return data;
};

export const getPaymentSysAccounts = async (integration: string, wlpId: string, currencyCode: string) => {
	const response = await request.get(`/api/admin/payments/sys-accounts/${integration}/${wlpId}`, { params: { filter: `currencyCode=${currencyCode}`, skip: 0, take: 200, orderBy: 'description', sort: 'ASC' } });
	const { data } = response;
	return data;
};


export const getPaymentSysMainAccounts = async (integration: string, currencyCode: string) => {
	const response = await request.get(`/api/admin/payments/sys-main-accounts/${integration}`, { params: { filter: `currencyCode=${currencyCode}`, skip: 0, take: 200, orderBy: 'description', sort: 'ASC' } });
	const { data } = response;
	return data;
};

export default slice.reducer;
