/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '../swal/slice';
import { ToogleVaultPayload, Vault, VaultsStore, VaultDepositPayload, VaultProviders } from './vaultsTypes';
import { PaginateParams } from '@/helpers/globalTypes';

const initialState: VaultsStore = {
	loading: false,
	list: [],
	error: null
};


const slice = createSlice({
	name: 'vaults',
	initialState,
	reducers: {
		setVault(state, action: PayloadAction<Array<Vault>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
	}
});

export const { setVault, setLoading, setError } = slice.actions;


export const getVaults = (): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/vaults/list');
			const { data } = response;
			dispatch(setVault(data));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getVaultsCurrencies = async () => {
	const response = await request.get('/api/admin/vaults/currencies');
	const { data } = response;
	return data;
};

export const postVaults = async (payload: Vault) => {
	const response = await request.post('/api/admin/vaults', payload);
	const { data } = response;
	return data;
};

export const postLogoUpload = async (payload: FormData): Promise<any> => {
	const response = await request.post('/api/admin/vaults/logo', payload);
	const { data } = response;
	return data;
};

export const postOperations = async (userId: number, operation: string, payload: VaultDepositPayload): Promise<any> => {
	const response = await request.post('/api/admin/vaults/operations', { userId, operation, data: payload });
	const { data } = response;
	return data;
};

export const toogleVault = async (payload: ToogleVaultPayload) => {
	const response = await request.post('/api/admin/vaults/toggle', payload);
	const { data } = response;
	return data;
};

export const terminateVault = async (accountId: number, payload: {
	fee: number,
}) => {
	const response = await request.post(`/api/admin/vaults/${accountId}/terminate`, payload);
	const { data } = response;
	return data;
};

export const transferVault = async (accountId: number, newVaultProc: string) => {
	const response = await request.post(`/api/admin/vaults/transfer/${accountId}`, { proc: newVaultProc });
	const { data } = response;
	return data;
};


export const getUserVaultsCcy = async (userId: number, ccy: string) => {
	const pagination: PaginateParams = { orderBy: 'ccy', sort: 'DESC', skip: 0, filter: '', take: 25 };
	pagination.filter = `ccy=${ccy}`;

	const response = await request.get('/api/admin/vaults/user/list', {
		params: {
			filter: pagination.filter,
			orderBy: pagination.orderBy,
			sort: pagination.sort.toUpperCase(),
			skip: pagination.skip.toString(),
			take: pagination.take,
			userId: userId
		}
	});
	const { data } = response;
	return data;
};

export const getVaultsProviders = async (): Promise<VaultProviders[]> => {
	const response = await request.get('/api/admin/vaults/service-provider');
	const { data } = response;
	return data;
};

export default slice.reducer;
