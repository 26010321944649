import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table, Image } from 'semantic-ui-react';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { Permission, PermissionType } from '@/features/user/types';
import { getUserAvailableCustomTokens, postCreateTokenyAccount } from '@/features/tokens/slice';
import { AvailableTokenType } from '@/features/tokens/types';
import { checkWritePermission } from '@/helpers/permissions';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { useDispatch } from 'react-redux';
import { PaginateParams } from '@/helpers/globalTypes';
import { getClientAccountInitPagination } from './ClientAccounts';
import { getAccounts } from '@/features/accounts/slice';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	userId: number,
	permissions?: Array<Permission>
}

const TokenAccounts = ({ userId, permissions }: Props): React.ReactElement => {
	const [tokens, setTokens] = useState<AvailableTokenType[]>([]);
	const [error, setError] = useState<boolean>(false);
	const dispatch = useDispatch();
	const accountPaginateInitState: PaginateParams = getClientAccountInitPagination(userId);
	const { urlPaginateParams } = usePaginationUrlState(accountPaginateInitState);

	const createTokenAccount = async (ccy: string) => {
		try {
			await postCreateTokenyAccount(userId, ccy);

			await dispatch(getAccounts('TOKEN', urlPaginateParams));
			fetchData();
			showSuccessNotification('Token account created');
		} catch (e) {
			showErrorNotification(e);
		}
	};


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				setError(false);
				const result = await getUserAvailableCustomTokens(userId); 
				setTokens(result);
			} catch (e) {
				setError(true);
				showErrorNotification(e);
			}
		};
		get();
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const tokenLine = (item: AvailableTokenType) =>
		<Table.Row key={`${item.symbol}$`} >
			<Table.Cell width={1}><Image className='token-avatar' avatar src={item.logo} /></Table.Cell>
			<Table.Cell width={1}>{item.symbol} </Table.Cell>
			<Table.Cell width={1}>{item.description} </Table.Cell>
			<Table.Cell width={1}>{item.proc} </Table.Cell>
			<Table.Cell width={1}>{item.currency} </Table.Cell>
			<Table.Cell width={2}>
				<ComponentWithConfirmation onConfirm={() => createTokenAccount(item.symbol)} confirmContent={'Are you sure you want create Token account?'}>
					<Button
						primary
						disabled={!checkWritePermission(permissions, PermissionType.ACCOUNTS)}
					>
						Create Token account</Button>
				</ComponentWithConfirmation>
			</Table.Cell>
		</Table.Row >;

	if (error) return <div>Error: {error}</div>;
	return (<>
		{tokens?.length > 0 &&
			<Table id='table' celled sortable textAlign='center' padded='very' striped>
				<Table.Header fullWidth className='tableHeader'>
					<Table.Row className="tableHeaders">
						<Table.HeaderCell>Logo</Table.HeaderCell>
						<Table.HeaderCell>Symbol</Table.HeaderCell>
						<Table.HeaderCell>Description</Table.HeaderCell>
						<Table.HeaderCell>Process</Table.HeaderCell>
						<Table.HeaderCell>Currency</Table.HeaderCell>
						<Table.HeaderCell>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body className="tableBody">
					{tokens?.length > 0 && tokens?.map(item => tokenLine(item))}
				</Table.Body>
			</Table>
		}
	</>
	);

};

export default TokenAccounts;
