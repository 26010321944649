import { format, parse } from 'date-fns';
const dateFormat = 'dd MMM yy hh:mm:ss';

export const convertDate = (originalDate: Date, pattern: string): string => {
	if (originalDate) {
		const date = new Date(originalDate);
		return format(date, pattern);
	}
	else return '';
};

export const formatDate = (originalDate: string, originalFormat?: string): string => {
	if (originalFormat) {
		return format(parse(originalDate, originalFormat, new Date()), dateFormat);
	}
	const date = new Date(originalDate);
	return format(date, dateFormat);
};

export const formatDateWithPattern = (originalDate: string, dateFormat: string, originalFormat?: string): string => {
	if (originalFormat) {
		return format(parse(originalDate, originalFormat, new Date()), dateFormat);
	}
	const date = new Date(originalDate);
	return format(date, dateFormat);
};

export const formatUTCDateWithPattern = (originalDate: string, dateFormat: string, originalFormat?: string): string => {
	if (originalFormat) {
		return format(parse(originalDate, originalFormat, new Date().getUTCDate()), dateFormat);
	}
	const date = new Date(originalDate).getUTCDate();
	return format(date, dateFormat);
};

export const convertDateFromEpoch = (epoch: any) => {
	return new Date(epoch).toString();
};
