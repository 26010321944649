export interface VaultBalanceStore {
    loading: boolean,
    list: Array<VaultBalance>,
    error: string,
	count: number,
    totalPages: number,
    pageSize: number
}

export interface VaultBalance {
    id: number,
    fromAccountId: number,
    fromWalletId: string, 
    transferDate?: string,
	transactionType?: string, 
    amount: BigInt
}

export enum VaultTransactionType { 
    INTEREST_PAYMENT = 'Interest Payment',
    DEPOSIT_REPAYMENT = 'Deposit Repayment' ,
	TRANSFER='Transfer'
}

