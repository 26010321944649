import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { showErrorNotification } from '../swal/slice';
import { AddValuePayload, ChangeKYBStatusPayload, Compliance, ComplianceStore, RiskEvalutionResponse, UpdateKycDataPayload } from './types';

const initialState: ComplianceStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'compliance',
	initialState,
	reducers: {
		setCompliance(state, action: PayloadAction<Array<Compliance>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setCompliance, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getComplianceList = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/compliance/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setCompliance(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getForm = async (applicantId: string) => {
	const response = await request.get(`/api/admin/compliance/applicant/${applicantId}`);
	const { data } = response;
	return data;
};

export const getComplianceDetails = async (applicantId: string) => {
	const response = await request.get(`/api/admin/compliance/v3/applicant/${applicantId}`);
	const { data } = response;
	return data;
};

export const downloadAttachment = async (key: string, name: string) => {
	const response = await request.get(`/api/admin/compliance/file/${key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};

export const approveKyc = async (applicantId: string) => {
	const response = await request.post('/api/admin/compliance/approve', { applicantId });
	const { data } = response;
	return data;
};

export const rejectKyc = async (applicantId: string, rejectionReason: string, final: boolean) => {
	const response = await request.post('/api/admin/compliance/reject', { applicantId, rejectionReason, final });
	const { data } = response;
	return data;
};

export const editValue = async (id: number, newValue: string) => {
	const response = await request.post('/api/admin/compliance/edit/value', { id, newValue });
	const { data } = response;
	return data;
};

export const addValue = async (payload: AddValuePayload) => {
	const response = await request.post('/api/admin/compliance/add/value', payload);
	const { data } = response;
	return data;
};

export const postFile = async (payload: FormData) => {
	const response = await request.post('/api/admin/compliance/file', payload);
	const { data } = response;
	return data;
};

export const postSyncOnfido = async (applicantId: string, userId: number) => {
	const response = await request.post('/api/admin/compliance/onfido/sync', { applicantId, userId });
	const { data } = response;
	return data;
};

export const postSyncSumSub = async (applicantId: string, userId: number) => {
	const response = await request.post('/api/admin/compliance/sumsub/sync', { applicantId, userId });
	const { data } = response;
	return data;
};

export const updateKycData = async (payload: UpdateKycDataPayload) => {
	const response = await request.post('/api/admin/compliance/kyc/data', payload);
	const { data } = response;
	return data;
};


export const postApproveSection = async (applicantId: string, sectionType: string) => {
	const response = await request.post('/api/admin/compliance/section/approve', { applicantId, sectionType });
	const { data } = response;
	return data;
};

export const postRejectSection = async (applicantId: string, sectionType: string, rejectionReason: string) => {
	const response = await request.post('/api/admin/compliance/section/reject', { applicantId, sectionType, rejectionReason });
	const { data } = response;
	return data;
};

export const postUpdateProfile = async (userId: number, businessName: string, regNumber: string, address: string, country: string, companyCity?: string, companyPostalCode?: string) => {
	const response = await request.post('/api/admin/compliance/profile/update', { userId, businessName, regNumber, address, country, companyCity, companyPostalCode });
	const { data } = response;
	return data;
};

export const changeKYBStatus = async (payload: ChangeKYBStatusPayload) => {
	const response = await request.post('/api/admin/compliance/status/change', payload);
	const { data } = response;
	return data;
};

export const changeKYBPaymentStatus = async (id: number, status: string) => {
	const response = await request.post('/api/admin/compliance/payment/status', { id, status });
	const { data } = response;
	return data;
};

export const exportApplicant = async (userId: number) => {
	const response = await request.get(`/api/admin/compliance/export-applicant/${userId}`);
	const { data } = response;
	return data;
};

export const getRiskEvaluation = async (userId: number): Promise<RiskEvalutionResponse[]> => {
	const response = await request.get(`/api/admin/risk-evalutation/${userId}`);
	const { data } = response;
	return data;
};

export const postRiskVerified = async (userId: number): Promise<RiskEvalutionResponse[]> => {
	const response = await request.post('/api/admin/users/risk-verified', { risksVerified: true, userId: userId });
	const { data } = response;
	return data;
};

export default slice.reducer;
