import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Grid, Header, Segment, Table } from 'semantic-ui-react';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { withRouter } from 'react-router-dom';

import { getBalanceFirstLine, getStatementsAccountBalanceApi, setActiveItems, setStatementAccountBalance } from '@/features/statements/accountBalanceSlice';
import BalanceDownload from './BalanceDownload'; 
import usePeriodLov from '@/helpers/customHook/usePeriodLov';
import { StatementAccountBalance } from '@/features/statements/statementsTypes';
import CustomTable, { transformFilterToObject } from '@/components/Shared/Table/Table';
import _ from 'lodash';
import BalanceTree from '../Shared/BalanceTree/BalanceTree';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import BalanceUpdateProcesses from './BalanceRefersh/BalanceUpdateProcesses';
import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';



const BankAccountBalances = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading, activeItems } = useSelector(
		(state: RootState) => state.accountBalance
	);
	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const paginateInitialState: PaginateParams = { orderBy: 'viewPk', sort: 'ASC', skip: 0, filter: 'hierarchyLevel=1', take: 50 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams); 
	const { periodLov } = usePeriodLov();



	useEffect(() => {
		if (pagination.filter !== 'hierarchyLevel=1') { 
			const filterObj: any = transformFilterToObject(pagination.filter); 

			if (filterObj?.periodName) {
				//dispatch(getStatementsAccountBalance(pagination));
				dispatch(getBalanceFirstLine(filterObj.periodName, pagination));
			}
			dispatch(setActiveItems([]));
		}
	}, [dispatch, pagination, pagination.filter]);

	useEffect(() => {
		dispatch(setStatementAccountBalance([]));
	}, [dispatch]);

	const handleClick = async (item: StatementAccountBalance) => {

		const filerObj = transformFilterToObject(pagination.filter);
		const newFilterObj = { ...filerObj, parentAccountId: item.bankAccountId.toString() };
		//remove some of filters
		const newFilterKeys = Object.keys(newFilterObj).filter(p => p !== 'hierarchyLevel' && p !== 'bankAccountId') || [];
		const newFilter = newFilterKeys.map(p => p + '=' + newFilterObj[p]).join(';');
		const nextLevelParams = { ...pagination, filter: newFilter };
 
		const data = await getStatementsAccountBalanceApi(nextLevelParams);
		if (data) {
			const result = _.unionBy(list, data, 'viewPk');
			dispatch(setStatementAccountBalance(result));

		}

		if (activeItems.includes(item.viewPk)) {
			dispatch(setActiveItems(activeItems.filter(p => p !== item.viewPk)));
		}
		else { dispatch(setActiveItems(activeItems.concat(item.viewPk))); }

	};
	const tableBody = (<>
		< BalanceTree
			allBalancies={list}
			activeItems={activeItems}
			handleClick={handleClick}
		/>
		{list.length === 0 && <Table.Row>
			<Table.Cell textAlign='center' colSpan={5}>No Balancies yet.</Table.Cell>
		</Table.Row>
		}
	</>
	);

	const filter: FilterParam[] = [ 
		{
			field: 'periodName',
			label: 'Period Name',
			type: 'select',
			options: periodLov,
			required: true
		}
	];



	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid id="bank-account-balances">
			<Segment>
				<Header as='h2'>Account Balancies</Header>
				<Divider hidden />
				<Grid columns={2}>
					<Grid.Row>
						<Grid.Column width={10}>
							<h3>Export </h3>
							<Divider hidden />
							<BalanceDownload />
						</Grid.Column>
						<Grid.Column width={6}>
							<BalanceUpdateProcesses writePermission={checkWritePermission(permissions, PermissionType.RECONCILIATIONS)} />
							<Divider hidden />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<h3>Review </h3>
				<CustomTable
					filter={filter}
					loading={loading}
					paginateInitialState={pagination}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					pagination={pagination}
					urlPagination={true}
					hideFooter={true}
				/>
			</Segment>
		</Container>
	);
};

export default withRouter(BankAccountBalances);
