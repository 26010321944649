import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Icon, Select, Header } from 'semantic-ui-react';
import NavbarLogo from './NavbarLogo';
import './Navbar.css';
import { logout } from '@features/user/userSlice';
import { showErrorNotification } from '@features/swal/slice';
import { RootState } from '@/rootReducer';
import { getWhitelabelOptions, postWhitelabel } from '@/features/whitelabel/whitelabelSlice';
import useWhiteLabelLov from '@/helpers/customHook/useWhiteLabelLov';



const Navbar = (): React.ReactElement => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { status, accrossAllWhitelabels } = useSelector(
		(state: RootState) => state.user
	);
	const { whitelabel } = useSelector(
		(state: RootState) => state.whitelabel
	);

	const { whitelabelOptions } = useWhiteLabelLov();
	const [whitelabelValue, setWhitelabelValue] = useState<string>(whitelabel);


	useEffect(() => {
		if (accrossAllWhitelabels) {
			dispatch(getWhitelabelOptions());
		}
	}, [accrossAllWhitelabels, dispatch, getWhitelabelOptions]);


	const signout = async () => {
		try {
			await dispatch(logout());
			return history.push('/auth');
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	const handleChange = (val) => {
		dispatch(postWhitelabel(val));
		setWhitelabelValue(val);
		history.push('/');
	};


	return (
		<Menu id="navbar" borderless stackable>
			<div className='mobileNavbarLogo'>
				<Menu.Item header>
					<Icon as={NavLink} to="/" className="logo">
						<NavbarLogo />
					</Icon>
					<Header style={{ marginLeft: 14 }} as='h2' content='System Managment Console' />
				</Menu.Item>
			</div>
			{status && status.loggedIn && (<Menu.Menu position="right">
				{accrossAllWhitelabels && <Menu.Item>
					<Select
						className="onBehalfOf"
						name="onBehalfOf"
						placeholder="Select whitelabel"
						label='On behalf of whitelabel'
						options={whitelabelOptions}
						value={whitelabelValue}
						onChange={(e, { value }) => handleChange(value)}
					/>
				</Menu.Item>}

				<Menu.Item>
					<Icon name='sign out alternate' className='signoutIcon' size='big' onClick={() => signout()} />
				</Menu.Item>


			</Menu.Menu>)}

		</Menu>
	);
};

export default Navbar;
