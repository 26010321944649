import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Segment, Table, Icon, Popup } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import NumberFormat from 'react-number-format';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { getExchangeOrders } from '@/features/exchangeOrder/slice';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { ExchangeOrder } from '@/features/exchangeOrder/types';
import ExchangeModal from './ExchangeModal';

interface Props {
	writePermission: boolean,
}

const ExchangeOrders = ({ writePermission }: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.exchangeOrder
	);

	const paginateInitialState: PaginateParams = { orderBy: 'documentId', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [openExchangeModal, setOpenExchangeModal] = useState<boolean>(false);
	const [currentOrder, setCurrentOrder] = useState<ExchangeOrder>(null);


	const statusOptions = [
		
		{
			key: 'PENDING',
			text: 'Pending All',
			value: 'PENDING',
		},
		{
			key: 'PENDING_FX',
			text: 'Pending exchange',
			value: 'PENDING_FX',
		},
		{
			key: 'COMPLETED',
			text: 'Completed',
			value: 'COMPLETED',
		},
		{
			key: 'REJECTED',
			text: 'Rejected',
			value: 'REJECTED',
		},
		{
			key: 'CANCELED',
			text: 'Canceled',
			value: 'CANCELED',
		} 
	];


	const filter: FilterParam[] = [
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: statusOptions
		},
		{
			field: 'fromIntegration',
			label: 'Integration',
			type: 'input'
		},
		{
			field: 'docNumber',
			label: 'Document Nr',
			type: 'input',
		},
		{
			field: 'transactionNr',
			label: 'Transaction Nr',
			type: 'input',
		},
		{
			field: 'clientId',
			label: 'Client Id',
			type: 'input'
		},
		{
			field: 'fromWalletId',
			label: 'From Wallet Id',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'docNumber',
			name: 'Document Nr',
			position: 1,
			sortable: false
		}, 
		{
			field: 'status',
			name: 'Status',
			position: 2,
			sortable: false
		},
		{
			field: 'fromIntegration',
			name: 'Integration',
			position: 3,
			sortable: false
		},
		{
			field: 'clientId',
			name: 'Client ID',
			position: 4,
			sortable: false
		},
		{
			field: 'fromAccount',
			name: 'From Account',
			position: 5,
			sortable: false
		},
		{
			field: 'toAccount',
			name: 'To Account',
			position: 6,
			sortable: false
		},
		{
			field: 'amount',
			name: 'Exchange Amount',
			position: 7,
			sortable: false
		},
		{
			field: 'details',
			name: 'Details',
			position: 8,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 9,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getExchangeOrders(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const handleExchange = (order: ExchangeOrder) => {
		setCurrentOrder(order);
		setOpenExchangeModal(true);
		(true);
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell>{entry.docNumber}</Table.Cell> 
						<Table.Cell>{entry.status}</Table.Cell>
						<Table.Cell>{entry.fromIntegration}</Table.Cell>
						<Table.Cell>{entry.clientId}</Table.Cell>
						<Table.Cell>{entry.fromWalletId}</Table.Cell>
						<Table.Cell>{entry.toWalletId}</Table.Cell>
						<Table.Cell>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								prefix={`${entry?.fromCurrencyCode ?? ''} `}
								value={entry.changeAmount} /></Table.Cell>
						<Table.Cell>{entry.note}</Table.Cell>
						<Table.Cell >
							<Popup content='Perform Exchange'
								trigger={<Icon className='tableActionIcon'
									disabled={!writePermission}
									size='large'
									name="exchange"
									onClick={() => handleExchange(entry)}
								/>} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>No currency exchanges.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				{currentOrder &&
					<ExchangeModal
						open={openExchangeModal}
						order={currentOrder}
						onClose={() => setOpenExchangeModal(false)}
						onSubmit={fetchData}
						writePermission={writePermission}
					/>}
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};

export default ExchangeOrders;
