import React from 'react';

import { PaymentConfigurationType } from '@/features/payments/paymentTypes';
import { Grid, Header, Table } from 'semantic-ui-react';
import './PaymentConfigurations.css';

interface Props {
	paymentConfigs: PaymentConfigurationType[],
	selectedConfig: PaymentConfigurationType,
	handleConfigChange: (value: PaymentConfigurationType) => void
}

const PaymentConfigurations = ({ paymentConfigs, selectedConfig, handleConfigChange }: Props) => (
	<Grid columns={1} id='payment-configuration' >
		<Grid.Row >
			<Grid.Column>
				<Header as='h5'>Choose Payment</Header>
				<div className='config-content' >
					<Table celled textAlign='center' padded='very'>
						<Table.Header fullWidth className='tableHeader'>
							<Table.Row className="tableHeaders">
								{/* 	<Table.HeaderCell></Table.HeaderCell> */}
								<Table.HeaderCell>Operation</Table.HeaderCell>
								<Table.HeaderCell>Short Description</Table.HeaderCell>
								<Table.HeaderCell>From Wallet Id</Table.HeaderCell>
								<Table.HeaderCell>To Wallet Id</Table.HeaderCell>
								<Table.HeaderCell>From Account</Table.HeaderCell>
								<Table.HeaderCell>To Accounts</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body >
							{paymentConfigs.map((entry) => {
								const selectedItem = selectedConfig?.paymentConfigId === entry.paymentConfigId;
								return (
									<Table.Row key={`${entry.paymentConfigId}$`}
										onClick={() => handleConfigChange(entry)}
										className={`config-row ${selectedItem && 'config-row-selected'}`} >
										{/* 		<Table.Cell width={1} >
											<Radio
												checked={selectedItem}
												onChange={() => handleConfigChange(entry, values, setValues)} 
											/>
										</Table.Cell> */}
										<Table.Cell width={1}>{entry.operationName}</Table.Cell>
										<Table.Cell width={1}>{entry.shortDescription}</Table.Cell>
										<Table.Cell width={1}>{entry.fromWalletId}</Table.Cell>
										<Table.Cell width={1}>{entry.toWalletId}</Table.Cell>
										<Table.Cell width={3} textAlign='left'>Bank: {entry.fromBankName}
											<br /> Client: {entry.fromCustomerName}
											<br /> Iban: {entry.fromAccountNumIban}
										</Table.Cell>
										<Table.Cell width={3} textAlign='left'>Bank: {entry.toBankName}
											<br /> Client: {entry.toCustomerName}
											<br /> Iban: {entry.toAccountNumIban}
										</Table.Cell>
									</Table.Row>
								);
							})}
							{paymentConfigs.length === 0 && <Table.Row>
								<Table.Cell textAlign='center' colSpan={8}>Payments not configured for this account.</Table.Cell>
							</Table.Row>}
						</Table.Body>

					</Table>
				</div>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

export default PaymentConfigurations;
