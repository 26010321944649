/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import NumberFormat from 'react-number-format';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { getVaultsPayments } from '@features/vaultsPayments/slice';


const VaultsLiabilities = (props): React.ReactElement => {


	const [proc] = useState<string>(props.match.params.proc);


	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.vaultsPayments
	);

	const paginateInitialState: PaginateParams = { orderBy: 'transferDate', sort: 'ASC', skip: 0, filter: '', take: 50 };

	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const statusOptions = [
		{
			key: 'TRUE',
			text: 'TRUE',
			value: 'TRUE',
		},
		{
			key: 'FALSE',
			text: 'FALSE',
			value: 'FALSE',
		},
	];

	const filter: FilterParam[] = [
		{
			field: 'completed',
			label: 'Completed Status',
			type: 'select',
			options: statusOptions
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'transferDate',
			name: 'Payment Date',
			position: 1,
			sortable: true
		},
		{
			field: 'walletId',
			name: 'Wallet ID',
			position: 2,
			sortable: false
		},
		{
			field: 'transactionType',
			name: 'Transaction Type',
			position: 3,
			sortable: false
		},
		{
			field: 'completed',
			name: 'Completed Status',
			position: 4,
			sortable: false
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 5,
			sortable: false
		},
		{
			field: 'vaultDepositAutoReturns',
			name: 'Total Auto Extend',
			position: 6,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getVaultsPayments(proc, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.walletId}${index}`} className={entry.completed ? 'liability-row--completed' : 'liability-row--not-completed'}>
						<Table.Cell width={1}>{entry.pmtDate}</Table.Cell>
						<Table.Cell width={1}>{entry.walletId}</Table.Cell>
						<Table.Cell width={1}>{entry.transactionType}</Table.Cell>
						<Table.Cell width={1}>{entry.completed ? 'TRUE' : 'FALSE'}</Table.Cell>
						<Table.Cell width={1}>
							{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} />
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.vaultDepositAutoReturns} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={9}>No data yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Grid.Row columns={2}>
					<Grid.Column><Header as='h2'>Vault Liabilities</Header></Grid.Column>
					<Grid.Column floated='right'>
						<Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
					</Grid.Column>
				</Grid.Row>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default VaultsLiabilities;
