import React, { useEffect, useState } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
	Icon,
	Message,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { getNames } from 'i18n-iso-countries';
import { getProcessFeeTypes } from '@/features/processes/processesSlice';
import { showErrorNotification } from '@/features/swal/slice';
import { ProcessFee, ProcessFeeType } from '@/features/processes/processesTypes';
import { getUserLevels } from '@features/user/userSlice';


interface Props {
	open: boolean;
	onClose: (feeType?: string, feeAmount?: number, feePercent?: number, feeMonth?: number, minFee?: number,
		userProfileType?: string, userLevelId?: number, riskGroupCode?: string, clientId?: string, country?: string, currency?: string) => void;
	edit: ProcessFee;
}

const ProcessFeeModal = ({ open, onClose, edit }: Props): React.ReactElement => {
	const countries = getNames('en');
	const [processTypes, setProcessTypes] = useState<Array<ProcessFeeType>>([]);
	const [userLevelsOptions, setUserLevelsOptions] = useState<any>([]);
	const [processTypeOptions, setProcessTypeOptions] = useState<any>([]);
	const [countriesOptions, setCountriesOptions] = useState<any>(null);
	const [selectedFeeType, setSelectedFeeType] = useState<ProcessFeeType>(null);

	const riskGroupOptions = [
		{
			key: 'NO',
			text: 'No',
			value: 'NO',
		},
		{
			key: 'LOW',
			text: 'Low',
			value: 'LOW',
		},
		{
			key: 'MEDIUM',
			text: 'Medium',
			value: 'MEDIUM',
		},
		{
			key: 'HIGH',
			text: 'High',
			value: 'HIGH',
		},
		{
			key: 'PEP',
			text: 'PEP',
			value: 'PEP',
		}
	];

	useEffect(() => {
		const options = [];
		Object.keys(countries)
			.map($code => {
				options.push({ key: $code, text: countries[$code], value: $code });
			});
		setCountriesOptions(options);
	}, []);

	useEffect(() => {
		const get = async () => {
			try {
				const data = await getProcessFeeTypes();
				setProcessTypes(data);

				const userLevelsData = await getUserLevels();
				const userLevelsOpt = [];
				userLevelsData.map(entry => {
					userLevelsOpt.push({ key: entry.userLevelId, value: entry.userLevelId, text: entry.description });
				});
				setUserLevelsOptions(userLevelsOpt);

				const processTypeOpt = [];
				data.map(entry => {
					processTypeOpt.push({ key: entry.fee, value: entry.fee, text: entry.name });
				});
				setProcessTypeOptions(processTypeOpt);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, []);

	useEffect(() => {
		if (!edit || !processTypes) return;
		setSelectedFeeType(processTypes.find(el => el.fee === edit.fee));
	}, [edit, processTypes]);

	const currencyOptions = [
		{ key: 'EUR', value: 'EUR', text: 'EUR' },
		{ key: 'USD', value: 'USD', text: 'USD' },
		{ key: 'GBP', value: 'GBP', text: 'GBP' }
	];

	const initialValues = {
		feeType: edit ? edit.fee : '',
		currency: edit ? edit.ccy : '',
		country: edit && edit.countryCode !== null ? edit.countryCode : '',
		userId: edit && edit.clientId !== null ? edit.clientId : '',
		riskGroupCode: edit && edit.riskGroupCode !== null ? edit.riskGroupCode : '',
		userLevelId: edit && edit.userLevelId !== null ? edit.userLevelId : '',
		userProfileType: edit && edit.userProfileType !== null ? edit.userProfileType : '',
		feeAmount: edit ? edit.feeAmount : 0,
		feePercent: edit ? edit.feePercent : 0,
		feeMonth: edit ? edit.feeMonth : 0,
		minFee: edit ? edit.minFee : 0,
	};

	const validationSchema = Yup.object({
		feeType: Yup.string()
			.required('You must select fee type'),
		currency: Yup.string()
			.required('You must select currency'),
		userProfileType: Yup.string()
			.required('You must select user profile type'),
	});

	/*useEffect(() => {
		console.log(initialValues);
	}, [initialValues]);
*/
	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { feeType, feeAmount, feePercent, feeMonth, minFee, userProfileType, userLevelId, riskGroupCode, userId, country, currency } = formData;
		setSelectedFeeType(null);
		onClose(
			feeType,
			feeAmount === '' ? 0 : feeAmount,
			feePercent === '' ? 0 : feePercent,
			feeMonth === '' ? 0 : feeMonth,
			minFee === '' ? 0 : minFee,
			userProfileType === '' ? null : userProfileType,
			userLevelId === '' ? null : userLevelId,
			riskGroupCode === '' ? null : riskGroupCode,
			userId === '' ? null : userId,
			country === '' ? null : country,
			currency
		);
	};

	const handleFeeTypeOnChange = (value: any, setFieldValue: any) => {
		setSelectedFeeType(processTypes.find(el => el.fee === value));
		setFieldValue('feeAmount', 0);
		setFieldValue('feePercent', 0);
		setFieldValue('minFee', 0);
		setFieldValue('feeMonth', 0);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
				enableReinitialize={true}
			>
				{({ errors, isSubmitting, dirty, setFieldValue }) => (
					<Form
						size="large">
						<Header content="Add process fee" />

						<Select
							label='Type'
							fluid
							name="feeType"
							options={processTypeOptions}
							onChange={(element, { value }) => handleFeeTypeOnChange(value, setFieldValue)}
							clearable
							selection
							disabled={edit !== null}
						/>
						{selectedFeeType && <Message info icon>
							<Icon name='info' />
							<Message.Content>
								<Message.Header>Description</Message.Header>
								{selectedFeeType.description}
							</Message.Content>
						</Message>}

						<Select
							label='Currency'
							fluid
							name="currency"
							options={currencyOptions}
							clearable
							selection
							disabled={edit !== null}
						/>
						<Divider />


						{selectedFeeType && <>
							{(selectedFeeType.feeType === 'FIXED' || selectedFeeType.feeType === 'FIXED_MONTH') && <Input
								label='Amount'
								type='number'
								fluid
								name="feeAmount"
							/>}

							{selectedFeeType.feeType === 'FIXED_MONTH' && <Input
								label='Monthly fee'
								type='number'
								fluid
								name="feeMonth"
							/>}

							{(selectedFeeType.feeType === 'PAYMENT' || selectedFeeType.feeType === 'VOLUME') && <Input
								label='Fee percent'
								type='number'
								fluid
								name="feePercent"
							/>}

							{(selectedFeeType.feeType === 'PAYMENT' || selectedFeeType.feeType === 'VOLUME') && <Input
								label='Min fee'
								type='number'
								fluid
								name="minFee"
							/>}

							<Divider />
							{selectedFeeType.feeType === 'PAYMENT' && <Select
								label='Country'
								fluid
								name="country"
								options={countriesOptions}
								clearable
								selection
								search
							/>}
							<Input
								label='User'
								fluid
								name="userId"
							/>
							<Select
								label='User Level'
								fluid
								name="userLevelId"
								options={userLevelsOptions}
								clearable
								selection
							/>
							<Select
								label='Risk Group'
								fluid
								name="riskGroupCode"
								options={riskGroupOptions}
								clearable
								selection
							/>
							<Select
								label='Profile type'
								fluid
								name="userProfileType"
								options={[{ key: 'INDIVIDUAL', value: 'INDIVIDUAL', text: 'Individual' }, { key: 'BUSINESS', value: 'BUSINESS', text: 'Business' }]}
								selection
								errorPrompt
							/>
							<Divider />
						</>}

						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										Save
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Cancel' onClick={() => { setSelectedFeeType(null); onClose(); }} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default ProcessFeeModal;
