/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PaginateParams } from '@/helpers/globalTypes';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '../swal/slice';
import { faqDetails, faqDetailsPayload, FaqDetailsStore } from './faqTypes';

const initialState: FaqDetailsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'faqDetails',
	initialState,
	reducers: {
		setFaqDetais(state, action: PayloadAction<Array<faqDetails>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setFaqDetais, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;


export const getFaqDetails = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/faq/details/list/', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setFaqDetais(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const deleteFaqDetails = async (id: number) => {
	const response = await request.delete(`/api/admin/faq/details/${id}`);
	const { data } = response;
	return data;
};

export const postFaqDetails = async (payload: faqDetailsPayload) => {
	const response = await request.post('/api/admin/faq/details', payload);
	const { data } = response;
	return data;
};

export const putFaqDetails = async (payload: faqDetailsPayload) => {
	const response = await request.put('/api/admin/faq/details', payload);
	const { data } = response;
	return data;
};

export default slice.reducer;
