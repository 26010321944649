import React from 'react';
import {
	Modal,
	Header,
	Button,
	Grid,
	Divider,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { showErrorNotification } from '@/features/swal/slice';
import { KycAddress } from '@/features/kycIssues/types';
import { convertDate } from '@/helpers/date';
import { createKycAddress, updateKycAddress } from '@/features/kycIssues/slice';
import { LookupValueType } from '@/features/fnd/fndTypes';

interface Props {
	open: boolean,
	item: KycAddress,
	writeAllowed: boolean,
	userId: number,
	type: LookupValueType,
	countriesOptions?: any,
	onSuccess?: () => void,
	onClose: () => void,
}

const KycAddressesModal = ({ open, item, writeAllowed, userId, type, countriesOptions, onClose, onSuccess }: Props): React.ReactElement => {

	const initialValues = {
		isActive: item?.isActive ?? true,
		activeFrom: item?.activeFrom ? convertDate(item.activeFrom, 'yyyy-MM-dd') : convertDate(new Date(), 'yyyy-MM-dd'),
		activeTo: item?.activeTo ? convertDate(item.activeTo, 'yyyy-MM-dd') : '',
		languageCode: item?.languageCode ?? 'IT',
		authorityName: item?.authorityName ?? '',
		authorityCode: item?.authorityCode ?? '',
		addressLine1: item?.addressLine1 ?? '',
		addressLine2: item?.addressLine2 ?? '',
		district: item?.district ?? '',
		city: item?.city ?? '',
		municipality: item?.municipality ?? '',
		county: item?.county ?? '',
		province: item?.province ?? '',
		region: item?.region ?? '',
		state: item?.state ?? '',
		country: item?.country ?? '',
		postalCode: item?.postalCode ?? '',
		locAuthorityName: item?.locAuthorityName ?? '',
		locAuthorityCode: item?.locAuthorityCode ?? '',
		locAddressLine1: item?.locAddressLine1 ?? '',
		locAddressLine2: item?.locAddressLine2 ?? '',
		locDistrict: item?.locDistrict ?? '',
		locCity: item?.locCity ?? '',
		locMunicipality: item?.locMunicipality ?? '',
		locCounty: item?.locCounty ?? '',
		locProvince: item?.locProvince ?? '',
		locRegion: item?.locRegion ?? '',
		locState: item?.locState ?? '',
		locCountry: item?.locCountry ?? '',
		locPostalCode: item?.locPostalCode ?? '',
		attribute1: item?.attribute1 ?? '',
		attribute2: item?.attribute2 ?? '',
		attribute3: item?.attribute3 ?? '',
		attribute4: item?.attribute4 ?? '',
		attribute5: item?.attribute5 ?? '',
		attribute6: item?.attribute6 ?? '',
		attribute7: item?.attribute7 ?? '',
		attribute8: item?.attribute8 ?? '',
		attribute9: item?.attribute9 ?? '',
		attribute10: item?.attribute10 ?? '',
	};


	const validationSchema = Yup.object({
	});

	const handleDiscard = () => {
		onClose();
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			setSubmitting(true);

			const payload: any = { ...item, ...formData };

			if (item?.id) {
				const to = payload.activeTo ? new Date(payload.activeTo) : null;
				const from = payload.activeFrom ? new Date(payload.activeFrom) : null;

				await updateKycAddress({ ...payload, activeFrom: from, activeTo: to, });
			}
			else {
				await createKycAddress({ ...payload, addressType: type.lookupCode, userId });
			}
			onSuccess && onSuccess();
		}
		catch (e) {
			console.log(e);
			showErrorNotification(e);
		}
		finally {
			setSubmitting(false);
			onClose();
		}
	};

	return <Modal
		open={open}
		size='large'
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, values, dirty }) => {
					return (
						<Form size="large">
							<Header content={type?.description ?? 'Address Details'} />
							<Grid columns={3}>
								<Grid.Row>
									<Grid.Column>
										<Input
											label='Active From'
											name='activeFrom'
											type='date'
											disabled={true}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Active To'
											name='activeTo'
											type='date'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column verticalAlign='middle' >
										<Checkbox fitted
											label='Active'
											name="isActive" />
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Header as='h3'>In English</Header>
							<Divider />
							<Grid columns={3}>
								<Grid.Row>
									<Grid.Column>
										<Input
											label='Authority Name'
											name='authorityName'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column   >
										<Input
											label='Authority Code'
											name='authorityCode'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column   >
										<Input
											label='Municipality'
											name='municipality'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid columns={2}>
								<Grid.Row>
									<Grid.Column >
										<Input
											label='Address Line1'
											name='addressLine1'
											disabled={!writeAllowed}
											errorPrompt />
										<Input
											label='Address Line2'
											name='addressLine2'
											disabled={!writeAllowed}
											errorPrompt />
										<Input
											label='District'
											name='district'
											disabled={!writeAllowed}
											errorPrompt />

										<Input
											label='City'
											name='city'
											disabled={!writeAllowed}
											errorPrompt />

										<Input
											label='State'
											name='state'
											disabled={!writeAllowed}
											errorPrompt />

									</Grid.Column>
									<Grid.Column>
										<Select
											label='Country'
											fluid
											name="country"
											options={countriesOptions}
											search
											clearable
											selection
											errorPrompt
											disabled={!writeAllowed}
										/>
										<Input
											label='Province'
											name='province'
											disabled={!writeAllowed}
											errorPrompt />

										<Input
											label='Region'
											name='region'
											disabled={!writeAllowed}
											errorPrompt />

										<Input
											label='County'
											name='county'
											disabled={!writeAllowed}
											errorPrompt />

										<Input
											label='PostalCode'
											name='postalCode'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
								</Grid.Row>
							</Grid>

							<Header as='h3'>In Local Language</Header>
							<Divider />
							<Grid columns={4} fluid>
								<Grid.Row>
									<Grid.Column>
										<Input
											label='Language Code'
											name='languageCode'
											disabled={true} />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Authority Name'
											name='locAuthorityName'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column >
										<Input
											label='Authority Code'
											name='locAuthorityCode'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column  >
										<Input
											label='Municipality'
											name='locMunicipality'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
								</Grid.Row>
							</Grid>


							<Grid columns={2}>
								<Grid.Row>
									<Grid.Column >
										<Input
											label='Address Line 1'
											name='locAddressLine1'
											disabled={!writeAllowed}
											errorPrompt />
										<Input
											label='Address Line 2'
											name='locAddressLine2'
											disabled={!writeAllowed}
											errorPrompt />
										<Input
											label='District'
											name='locDistrict'
											disabled={!writeAllowed}
											errorPrompt />
										<Input
											label='City'
											name='locCity'
											disabled={!writeAllowed}
											errorPrompt />

										<Input
											label='State'
											name='locState'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Select
											label='Country'
											fluid
											name="locCountry"
											options={countriesOptions}
											search
											clearable
											selection
											errorPrompt
											disabled={!writeAllowed}
										/>
										<Input
											label='Province'
											name='locProvince'
											disabled={!writeAllowed}
											errorPrompt />
										<Input
											label='Region'
											name='locRegion'
											disabled={!writeAllowed}
											errorPrompt />
										<Input
											label='County'
											name='locCounty'
											disabled={!writeAllowed}
											errorPrompt />
										<Input
											label='PostalCode'
											name='locPostalCode'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Header as='h3'>Attributes</Header>
							<Divider />
							<Grid columns={5}>
								<Grid.Row>
									<Grid.Column>
										<Input
											label='Attribute 1'
											name='attribute1'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Attribute 2'
											name='attribute2'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Attribute 3'
											name='attribute3'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Attribute 4'
											name='attribute4'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Attribute 5'
											name='attribute5'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
								</Grid.Row>
							</Grid>
							<Grid columns={5}>
								<Grid.Row>
									<Grid.Column>
										<Input
											label='Attribute 6'
											name='attribute6'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Attribute 7'
											name='attribute7'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Attribute 8'
											name='attribute8'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Attribute 9'
											name='attribute9'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
									<Grid.Column>
										<Input
											label='Attribute 10'
											name='attribute10'
											disabled={!writeAllowed}
											errorPrompt />
									</Grid.Column>
								</Grid.Row>
							</Grid>

							<Grid columns={2}>
								<Grid.Row>
									<Grid.Column>
										<SubmitButton
											disabled={isSubmitting || !isEmpty(errors) || !dirty || (values.reportedToAuthority)}
											primary size="large" type="submit"
											floated='right'>
											Save
										</SubmitButton>
									</Grid.Column>
									<Grid.Column>
										<Button floated='left' secondary size="large" content='Discard' onClick={handleDiscard} />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>);
				}}
			</Formik>
		</Modal.Content>
	</Modal >;
};

export default KycAddressesModal;
