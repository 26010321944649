import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { getKycIssuesList } from '@/features/kycIssues/slice';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import { formatDateWithPattern } from '@/helpers/date';

// interface Props {
// 	writePermission: boolean,
// }

const KYCIssues = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.kycIssues
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const countries = getNames('en');

	const filter: FilterParam[] = [
		{
			field: 'email',
			label: 'Email',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'firstName',
			label: 'First Name',
			type: 'input'
		},
		{
			field: 'lastName',
			label: 'Last Name',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'clientId',
			name: 'Client ID',
			position: 1,
			sortable: true
		},
		{
			field: '',
			name: 'Name',
			position: 2,
			sortable: false
		},
		{
			field: 'service',
			name: 'Provider',
			position: 3,
			sortable: false
		},
		{
			field: 'serviceId',
			name: 'Applicant ID',
			position: 4,
			sortable: false
		},
		{
			field: 'documentType',
			name: 'Document type',
			position: 5,
			sortable: false
		},
		{
			field: 'documentNumber',
			name: 'Document Number',
			position: 6,
			sortable: false
		},
		{
			field: 'issueDate',
			name: 'Issue Date',
			position: 7,
			sortable: false
		},
		{
			field: 'expiryDate',
			name: 'Expiry Date',
			position: 8,
			sortable: false
		},
		{
			field: 'issuingCountry',
			name: 'Issuing Country',
			position: 9,
			sortable: false
		},
		{
			field: 'gender',
			name: 'Gender',
			position: 10,
			sortable: false
		},
		{
			field: 'identificationNumber',
			name: 'Identification Nr.',
			position: 11,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 12,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getKycIssuesList(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);


	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const openPOA = (userid: number) => {
		history.push(`/poa/${userid}`);
	};



	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.clientId}</Table.Cell>
						<Table.Cell width={2}>{`${entry.firstName} ${entry.lastName}`}</Table.Cell>
						<Table.Cell width={2} style={{ backgroundColor: entry.service === null ? '#d6242469' : '' }}>{entry.service}</Table.Cell>
						<Table.Cell width={2} style={{ backgroundColor: entry.serviceId === null ? '#d6242469' : '' }}>{entry.serviceId}</Table.Cell>
						<Table.Cell width={2} style={{ backgroundColor: entry.documentType === null ? '#d6242469' : '' }}>{entry.documentType}</Table.Cell>
						<Table.Cell width={1} style={{ backgroundColor: entry.documentNumber === null ? '#d6242469' : '' }}>{entry.documentNumber}</Table.Cell>
						<Table.Cell width={1} style={{ backgroundColor: entry.issueDate === null ? '#d6242469' : '' }}>{entry.issueDate === null ? '' : formatDateWithPattern(entry.issueDate, 'dd/MM/yyyy')}</Table.Cell>
						<Table.Cell width={1} style={{ backgroundColor: (entry.expiryDate === null || new Date(entry.expiryDate) < new Date()) ? '#d6242469' : '' }}>{entry.expiryDate === null ? '' : formatDateWithPattern(entry.expiryDate, 'dd/MM/yyyy')}</Table.Cell>
						<Table.Cell width={1} style={{ backgroundColor: entry.issuingCountry === null ? '#d6242469' : '' }}>{entry.issuingCountry === null ? '' : countries[alpha3ToAlpha2(entry.issuingCountry)]}</Table.Cell>
						<Table.Cell width={1} style={{ backgroundColor: entry.gender === null ? '#d6242469' : '' }}>{entry.gender}</Table.Cell>
						<Table.Cell width={1} style={{ backgroundColor: entry.identificationNumber === null ? '#d6242469' : '' }}>{entry.identificationNumber}</Table.Cell>
						<Table.Cell width={2}>
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.id)} />} />
							<Popup content='Open user POA' trigger={<Icon className='tableActionIcon' size='large' name="file alternate outline" onClick={() => openPOA(entry.id)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={12}>Not issues present.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>KYC Issues</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default KYCIssues;
