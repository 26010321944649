/* eslint-disable @typescript-eslint/no-unused-vars */
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Dimmer, Divider, Grid, Header, Icon, Loader, Popup, Segment, Table } from 'semantic-ui-react';
import { getVaults, postLogoUpload, postVaults, toogleVault } from '@/features/vaults/vaultsSlice';
import { Vault, VaultApprovalType, VaultConfig, VaultPeriodEnum } from '@/features/vaults/vaultsTypes';
import VaultModal from './VaultModal';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation'; 

interface Props {
	writePermission: boolean
}

const VaultsSetup = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, error, loading } = useSelector(
		(state: RootState) => state.vaults
	);

	const [openVaultModal, setOpenVaultModal] = useState<boolean>(false);
	const [editVault, setEditVault] = useState<Vault>(null);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getVaults());
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [dispatch]);

	useEffect(() => {
		fetchData();
	}, [dispatch]);

	const handleAddVault = async () => {
		setEditVault(null);
		setOpenVaultModal(true);
	};

	const handlEditVault = (vault: Vault) => {
		setEditVault(vault);
		setOpenVaultModal(true);
	};

	const handleCloseVaultModal = async (name?: string,
		description?: string,
		currency?: string,
		yearlyInterestRate?: number,
		interestPaymentPeriod?: VaultPeriodEnum,
		depositPeriodMonths?: number,
		minDepositAmount?: number,
		autoExtendDays?: number,
		skipPaymentIfAmountLessThan?: number,
		skipPaymentIfDaysLessThan?: number,
		minimalTransferableAmount?: number,
		maxAmountWithoutApproval?: number,
		systemAccountIntegration?: string,
		approvalType?: VaultApprovalType,
		userLevelId?: number,
		userGroupId?: number,
		profileType?: string,
		vault?: Vault,
		depositSystemAccount?: string,
		interestSystemAccount?: string,
		repaymentSystemAccount?: string,
		logo?: any,
		depositTrade?: boolean,
		compoundInterest?: boolean,
		serviceProvider?: string,
	) => {

		setOpenVaultModal(false);

		if (name) {
			try { 

				let logoUrl = null;
				if (logo) {
					const formData = new FormData();
					formData.append('file', logo);
					const fileRecord = await postLogoUpload(formData);
					const { logoUri } = fileRecord;
					logoUrl = logoUri;
				}


				const payload: Vault = {
					name,
					description,
					currency,
					yearlyInterestRate,
					interestPaymentPeriod,
					depositPeriodMonths,
					skipPaymentIfAmountLessThan,
					skipPaymentIfDaysLessThan,
					minimalTransferableAmount,
					maxAmountWithoutApproval,
					approvalType,
					minDepositAmount,
					systemAccountIntegration,
					autoExtendDays,
					depositSystemAccount,
					interestSystemAccount,
					repaymentSystemAccount,
					depositTrade: depositTrade??false,
					compoundInterest: compoundInterest??false,
					serviceProvider
				}; 

				if (vault) {
					payload.systemVaultAccountId = vault.systemVaultAccountId;
				}

				if (vault) {
					payload.proc = vault.proc;
				}

				if (userGroupId) {
					payload.userGroupId = userGroupId;
				}
				if (userLevelId) {
					payload.userLevelId = userLevelId;
				}
				if (profileType && profileType !== 'NONE') {
					payload.userProfileType = profileType;
				}

				if(logoUrl) {
					payload.logoLink = logoUrl;
				}
 
				await postVaults(payload);
				showSuccessNotification(vault ? 'Vault updated' : 'Vault added');
			} catch (e) {
				console.log(e);
				showErrorNotification(e);
			}

			fetchData();
		}

		setEditVault(null);

	};

	const toogleProc = async (proc: Vault) => {
		try {

			const config: VaultConfig = {
				config: proc.config.config,
				rolesAllowed: proc.config.rolesAllowed,
				disabled: proc.config.disabled ? false : true
			};

			await toogleVault({
				proc: proc.proc,
				userGroupId: proc.userGroupId ?? null,
				userLevelId: proc.userLevelId ?? null,
				userProfileType: proc.userProfileType ?? null,
				config
			});
			showSuccessNotification(proc.config.disabled ? 'Vault enabled' : 'Vault disabled');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<VaultModal
					open={openVaultModal}
					vault={editVault}
					onClose={handleCloseVaultModal}
					writePermission={writePermission}
				/>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Vaults Setup</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button
								floated='right'
								primary
								disabled={!writePermission}
								onClick={handleAddVault}
							>
								Add Vault</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<>
					<Dimmer active={loading} inverted>
						<Loader />
					</Dimmer>
					<Table id='table' celled sortable textAlign='center' padded='very' striped>
						<Table.Header fullWidth className='tableHeader'>
							<Table.Row className="tableHeaders">
								<Table.HeaderCell>Name</Table.HeaderCell>
								<Table.HeaderCell>Description</Table.HeaderCell>
								<Table.HeaderCell>Currency</Table.HeaderCell>
								<Table.HeaderCell>Interest Rate</Table.HeaderCell> 
								<Table.HeaderCell>Deposit Period</Table.HeaderCell>
								<Table.HeaderCell>Profile Type</Table.HeaderCell>
								<Table.HeaderCell>User Level</Table.HeaderCell>
								<Table.HeaderCell>User Group</Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body className="tableBody">
							{list.map((entry, index) => {
								return (
									<Table.Row key={`${entry.wlpProcessId}${index}`} >
										<Table.Cell width={2}>{entry.name}</Table.Cell>
										<Table.Cell width={2}>{entry.description}</Table.Cell>
										<Table.Cell width={1}>{entry.processProperties.ccy}</Table.Cell>
										<Table.Cell width={1}>{(parseFloat(entry.config.config.yearlyInterestRate) * 100).toFixed(2)}%</Table.Cell>
										<Table.Cell width={1}>{entry.config.config.depositPeriodMonths}</Table.Cell>
										<Table.Cell width={1}>{entry.userProfileType}</Table.Cell>
										<Table.Cell width={1}>{entry.userLevelId}</Table.Cell>
										<Table.Cell width={1}>{entry.userGroupId}</Table.Cell>
										<Table.Cell width={2}>
											<Popup content='Edit vault' trigger={<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="cogs" onClick={() => handlEditVault(entry)} />} />
											{!entry.config.disabled &&
												<ComponentWithConfirmation popup='Disable vault' onConfirm={() => toogleProc(entry)} confirmContent={'Are you sure you want to disable vault?'}>
													<Icon disabled={!writePermission} className='tableActionIcon' color='green' size='large' name="check circle" />
												</ComponentWithConfirmation>}
											{entry.config.disabled &&
												<ComponentWithConfirmation popup='Enable vault' onConfirm={() => toogleProc(entry)} confirmContent={'Are you sure you want to enable vault?'}>
													<Icon disabled={!writePermission} className='tableActionIcon' color='red' size='large' name="times circle" />
												</ComponentWithConfirmation>}
										</Table.Cell>
									</Table.Row>
								);
							})}
							{list.length === 0 && <Table.Row>
								<Table.Cell textAlign='center' colSpan={9}>Not vaults yet.</Table.Cell>
							</Table.Row>}
						</Table.Body>
					</Table>
				</>
			</Segment>
		</Container>
	);
};

export default VaultsSetup;
