import request from '../../services/request';
import { CompleteUserSecurityReportPayload, CreateUserSecurityReportPayload, UpdateUserSecurityReportPayload, UserSecurityReport } from './types';




export const getUserSecurityReport = async (userId: number): Promise<UserSecurityReport[]> => {
	const response = await request.get(`/api/admin/user-report/${userId}`);
	const { data } = response;
	return data;
};


export const createSecurityReport = async (payload: CreateUserSecurityReportPayload): Promise<UserSecurityReport> => {
	const response = await request.put('/api/admin/user-report', payload);
	const { data } = response;
	return data;
};

export const updateSecurityReport = async (payload: UpdateUserSecurityReportPayload): Promise<UserSecurityReport> => {
	const response = await request.post('/api/admin/user-report/update', payload);
	const { data } = response;
	return data;
};



export const completeSecurityReport = async (payload: CompleteUserSecurityReportPayload) => {
	const form = new FormData();
	form.append('file', payload.file);
	form.append('reportId', `${payload.reportId}`);
	form.append('explanation', `${payload.explanation}`);
	form.append('reportedToAuthority', `${payload.reportedToAuthority}`);


	const response = await request.post('/api/admin/user-report/complete', form);
	const { data } = response;
	return data;
};

export const acknSecurityReport = async (reportId: number): Promise<UserSecurityReport[]> => {
	const response = await request.post('/api/admin/user-report/ackn', { reportId });
	const { data } = response;
	return data;
};

export const addAttachments = async (payload: FormData) => {
	const response = await request.post('/api/admin/user-report/attachments', payload);
	const { data } = response;
	return data;
};

export const downloadAttachment = async (key: string, name: string, type: 'attachment' | 'finding') => {
	const response = await request.get(`/api/admin/user-report/file/${type}/${key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};


