import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { showErrorNotification } from '../swal/slice';
import { UserGroup, UserGroupStore, UserGroupUsers } from './types';

const initialState: UserGroupStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
	usersList: [],
	usersCount: 0,
	usersTotalPages: 0,
	usersPageSize: 0,
	usersError: null,
	usersLoading: false
};


const slice = createSlice({
	name: 'userGroups',
	initialState,
	reducers: {
		setUserGroups(state, action: PayloadAction<Array<UserGroup>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		},
		setUserGroupsUsers(state, action: PayloadAction<Array<UserGroupUsers>>) {
			state.usersList = action.payload;
		},
		setUsersLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.usersLoading = payload;
		},
		setUsersError: (state, { payload }: PayloadAction<string>) => {
			state.usersError = payload;
		},
		setUsersCount: (state, { payload }: PayloadAction<number>) => {
			state.usersCount = payload;
		},
		setUsersTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.usersTotalPages = payload;
		},
		setUsersPageSize: (state, { payload }: PayloadAction<number>) => {
			state.usersPageSize = payload;
		}
	}
});

export const { setUserGroups, setLoading, setError, setCount, setTotalPage, setPageSize,
	setUserGroupsUsers, setUsersLoading, setUsersError, setUsersCount, setUsersTotalPage, setUsersPageSize } = slice.actions;

export const getUserGroupList = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/user-groups/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setUserGroups(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getUserGroupUsersList = (userGroupId: number, payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setUsersLoading(true));
			const response = await request.get(`/api/admin/user-groups/users/${userGroupId}/list`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setUserGroupsUsers(data.list));
			dispatch(setUsersCount(data.count));
			dispatch(setUsersPageSize(payload.take));
			dispatch(setUsersTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setUsersLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setUsersLoading(false));
		}
	};
};

export const postUserGroup = async (name: string) => {
	const response = await request.post('/api/admin/user-groups', { name });
	const { data } = response;
	return data;
};

export const putUserGroup = async (name: string, userGroupId: number) => {
	const response = await request.put('/api/admin/user-groups', { name, userGroupId });
	const { data } = response;
	return data;
};

export const deleteUserFromUserGroup = async (userId: number, userGroupId: number) => {
	const response = await request.delete(`/api/admin/user-groups/user/${userGroupId}/${userId}`);
	const { data } = response;
	return data;
};

export const addUserToUserGroup = async (clientId: string, userGroupId: number) => {
	const response = await request.post('/api/admin/user-groups/user', { userGroupId, clientId });
	const { data } = response;
	return data;
};

export default slice.reducer;
