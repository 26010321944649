import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { showErrorNotification } from '../swal/slice';
import { ExchangeOrder, ExchangeOrderCompletePayload, ExchangeOrderStartPayload, ExchangeOrderStore } from './types';
import request from '../../services/request';

const initialState: ExchangeOrderStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'exchangeOrder',
	initialState,
	reducers: {
		setExchangeOrder(state, action: PayloadAction<Array<ExchangeOrder>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setExchangeOrder, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getExchangeOrders = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/exchange-document/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setExchangeOrder(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const startExchange = async (userId: number, operation: string, payload: ExchangeOrderStartPayload): Promise<any> => {
	const response = await request.post('/api/admin/exchange-document', { userId, operation, data: payload });
	const { data } = response;
	return data;
};

export const completeExchange = async (userId: number, operation: string, payload: ExchangeOrderCompletePayload): Promise<any> => {
	const response = await request.post('/api/admin/exchange-document', { userId, operation, data: payload });
	const { data } = response;
	return data;
};

export const rejectExchange = async (userId: number, operation: string, documentId: number): Promise<any> => {
	const response = await request.post('/api/admin/exchange-document', { userId, operation, data: { documentId, docStatus: 'REJECT' } });
	const { data } = response;
	return data;
};

export default slice.reducer;
