/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { CurrentWhitelabel, WhiteLabel, WhitelabelStore } from './whitelabelTypes';


const initialState: WhitelabelStore = {
	loading: false,
	error: null,
	whitelabel: null,
	list: []
};


const slice = createSlice({
	name: 'whitelabel',
	initialState,
	reducers: {
		setWhitelabel(state, action: PayloadAction<string>) {
			state.whitelabel = action.payload;
		},
		clearWhitelabel(state) {
			state.whitelabel = null;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setWhitelabelList(state, action: PayloadAction<WhiteLabel[]>) {
			state.list = action.payload;
		},
	}
});

export const { setWhitelabel, setWhitelabelList, setLoading, setError, clearWhitelabel } = slice.actions;


export const postWhitelabel = (agentCode: string): AppThunk => {
	return async dispatch => {
		const response = await request.post('/api/admin/auth/whitelabel', { agentCode });
		sessionStorage.setItem('wlp', agentCode);
		dispatch(setWhitelabel(agentCode));
		const { data } = response;
		return data;
	};
};

export const getWhitelabelOptions = (): AppThunk => {
	return async dispatch => {
		const response = await request.get('/api/admin/agent/select');
		const { data } = response;

		dispatch(setWhitelabelList(data));
		return data;
	};
};

export const getCurrentWhitelabel = async (): Promise<CurrentWhitelabel> => {
	const response = await request.get('/api/admin/agent/current');
	const { data } = response;
	return data;
};

export default slice.reducer;


