import React, { useState } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid
} from 'semantic-ui-react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { ExchangeOrder, ExchangeOrderStatus } from '@/features/exchangeOrder/types';
import OrderDetails from './OrderDetails';
import { isEmpty } from 'lodash';
import { completeExchange, rejectExchange, startExchange } from '@/features/exchangeOrder/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';


interface Props {
	open: boolean;
	onClose: () => void,
	onSubmit: () => void,
	order: ExchangeOrder,
	writePermission: boolean
}

const ExchangeModal = ({ open, order, onClose, onSubmit, writePermission }: Props): React.ReactElement => {


	const adminUserId = useSelector((state: RootState) => state.user.status.adminUserId);

	const [feeAccountId, setFeeAccountId] = useState<number>(null);

	const initialValues = {
		rate: '',
		feeFromAccountId: '',
		feeAmount: '',
		details: '',
		cjOrderDate: '',
		cjFromReference: '',
		cjToReference: ''
	};

	const availableAmount = feeAccountId === order?.fromAccountId ? parseFloat(order.fromAvailable) : parseFloat(order.toAvailable);

	const validationSchema = Yup.object(

		order.status === ExchangeOrderStatus.PENDING_FX ? {
			rate: Yup.number().required('Required').test(
				'min',
				'Rate must be greater than 0!',
				(value) => value > 0
			),
			details: Yup.string().required('Required'),
			cjOrderDate: Yup.string().required('Required'),
			feeAmount: Yup.number().test(
				'min',
				'Fee mount must be greater than 0!',
				(value) => value ? value > 0 : true
			).test(
				'total',
				'Amount is bigger than available',
				(value) => value ? value <= availableAmount : true
			),
			cjFromReference: Yup.string().required('Required'),
			cjToReference: Yup.string().required('Required')
		} : {});

	const feeAccountOptions = [
		{ key: order.fromAccountId, value: order.fromAccountId, text: order.fromWalletId + ' (' + order.fromCurrencyCode + ')' },
		{ key: order.toAccountId, value: order.toAccountId, text: order.toWalletId + ' (' + order.toCurrencyCode + ')' }];

	const disable = parseFloat(order.fromAvailable) <= 0;
	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);
		const { submitBtn } = formData;

		const userInput: {
			rate,
			feeFromAccountId,
			feeAmount,
			details,
			cjOrderDate,
			cjFromReference,
			cjToReference
		} = formData;
		try {
			if (submitBtn === ExchangeOrderStatus.PENDING) {
				await startExchange(
					order.userId,
					order.fxProcName
					, {
						docStatus: 'LOCK',
						documentId: order.documentId,
						fromAccountId: order.fromAccountId,
						toAccountId: order.toAccountId,
						changeAmount: order.changeAmount * -1
					});
			}

			if (submitBtn === ExchangeOrderStatus.PENDING_FX) {
				await completeExchange(order.userId, order.fxProcName,
					{
						docStatus: 'COMPLETE',
						documentId: order.documentId,
						fromAccountId: order.fromAccountId,
						toAccountId: order.toAccountId,
						changeAmount: order.changeAmount * -1,
						fxRate: userInput.rate,
						details: userInput.details,
						feeFromAccountId: userInput.feeFromAccountId,
						feeAmount: userInput.feeAmount,
						cjOrderDate: userInput.cjOrderDate,
						bankRefFrom: userInput.cjFromReference,
						bankRefTo: userInput.cjToReference,
						updatedBy: adminUserId
					});
			}
			onSubmit();
			showSuccessNotification('Submitted');
		}
		catch (e) {
			showErrorNotification(e);
		}
		finally {
			setSubmitting(false);
			onClose();
		}
	};

	const rejectOrder = async () => {
		try {
			await rejectExchange(order.userId, order.fxProcName, order.documentId);
			onSubmit();
		} catch (e) {
			showErrorNotification(e);
		}
		finally { onClose(); }
	};

	const closeModal = () => {
		onClose();
	};

	const handleFeeAccountChange = (value) => {
		setFeeAccountId(value);
	};

	const feeCurrency = feeAccountId === order?.fromAccountId ? order?.fromCurrencyCode : order?.toCurrencyCode;

	return <Modal
		id="exchange-order-modal"
		size="small"
		open={open}
		onClose={closeModal}>
		<Modal.Content>
			<Header content='Perform Exchange' />
			<OrderDetails order={order} />
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ isSubmitting, errors, setFieldValue, handleSubmit }) => (
					<Form size="large">
						{order.status === ExchangeOrderStatus.PENDING_FX && <>
							<Input
								label='Rate'
								fluid
								name="rate"
								type='number'
								disabled={!writePermission}
								errorPrompt
							/>
							<Select
								label='Dedicate Service Fee from '
								fluid
								name="feeFromAccountId"
								value={feeAccountId}
								clearable
								errorPrompt
								options={feeAccountOptions}
								onChange={(element, { value }) => handleFeeAccountChange(value)} />

							<Input
								label={`Fee amount in ${feeCurrency}`}
								fluid
								name="feeAmount"
								type='number'
								disabled={!writePermission}
								errorPrompt
							/>
							<Input
								label='Details'
								fluid
								name="details"
								disabled={!writePermission}
								errorPrompt
							/>
							<Divider />
							<Input
								label='Order date'
								fluid
								name="cjOrderDate"
								type='datetime-local'
								disabled={!writePermission}
								errorPrompt
							/>
							<Input
								label={'Bank Reference ' + order.fromCurrencyCode}
								fluid
								name="cjFromReference"
								disabled={!writePermission}
								errorPrompt
							/>
							<Input
								label={'Bank Reference ' + order.toCurrencyCode}
								fluid
								name="cjToReference"
								disabled={!writePermission}
								errorPrompt
							/>
						</>
						}
						<Divider hidden />
						<Grid columns={3}>
							<Grid.Row>
								{(order?.status === ExchangeOrderStatus.PENDING || order?.status === ExchangeOrderStatus.PENDING_FX) &&
									<Grid.Column >
										<Button
											disabled={isSubmitting || !writePermission}
											secondary
											onClick={rejectOrder}
											content='Reject Exchange'
											type="button"
											size="large" />
									</Grid.Column>
								}
								{order?.status === ExchangeOrderStatus.PENDING &&
									<Grid.Column >
										<SubmitButton
											disabled={isSubmitting || !writePermission ||
												!order.documentId ||
												!order.fromAccountId ||
												!order.toAccountId ||
												!order.changeAmount
											}
											primary
											size="large"
											type="submit"
											onClick={() => {
												setFieldValue('submitBtn', ExchangeOrderStatus.PENDING);
												handleSubmit();
											}}>
											Start Exchange
										</SubmitButton>
									</Grid.Column>
								}
								{order?.status === ExchangeOrderStatus.PENDING_FX &&
									<Grid.Column >
										<SubmitButton
											disabled={isSubmitting || !writePermission || !isEmpty(errors) || disable}
											primary
											size="large"
											type="submit"
											onClick={() => {
												setFieldValue('submitBtn', ExchangeOrderStatus.PENDING_FX);
												handleSubmit();
											}}>
											Complete Exchange
										</SubmitButton>
									</Grid.Column>
								}
								<Grid.Column>
									<Button secondary size="large" type="button" content='Close Form' onClick={closeModal} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};


export default ExchangeModal;


