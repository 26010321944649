/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PaginateParams } from '@/helpers/globalTypes';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '../swal/slice';
import { FilesStore, File, FileUploadPayload } from './filesTypes';

const initialState: FilesStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'files',
	initialState,
	reducers: {
		setFiles(state, action: PayloadAction<Array<File>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setFiles, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;


export const getFiles = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/files/list-for-wlp/', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setFiles(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const upload = async (payload: FileUploadPayload) => {
	const response = await request.post('/api/admin/files/upload', payload);
	const { data } = response;
	return data;
};

export const downloadFile = async (fileId: number, name: string) => {
	const response = await request.get(`/api/admin/files/download/${fileId}`,
		{
			responseType: 'blob',
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = `${name}`;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};



export default slice.reducer;
