import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import NumberFormat from 'react-number-format';
import { formatDateWithPattern } from '@/helpers/date';
import { Link } from 'react-router-dom';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { PaginateParams } from '@/helpers/globalTypes';
import useWhiteLabelLov from '@/helpers/customHook/useWhiteLabelLov';
import { downloadSystemDocuments, getSystemDocuments, setSystemDocyments } from '@/features/systemDocuments/systemDocumentsSlice';
import { showException } from '@/features/swal/slice';

const SystemDocuments = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading, isDownloading } = useSelector((state: RootState) => state.systemDocuments);

	const paginateInitialState: PaginateParams = { orderBy: 'docNumber', skip: 0, sort: 'ASC', filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
 
	const { whitelabelOptions } = useWhiteLabelLov();

	const filter: FilterParam[] = [
		{
			field: 'fromDate',
			label: 'Date From',
			type: 'input',
			inputType: 'date',
			required: true
		},
		{
			field: 'toDate',
			label: 'Date To',
			type: 'input',
			inputType: 'date',
			required: true
		},
		{
			field: 'wlpId',
			label: 'White Label',
			type: 'select',
			options: whitelabelOptions
		},
		{
			field: 'integration',
			label: 'Integration',
			type: 'input'
		},
		{
			field: 'wbhProcName',
			label: 'Process',
			type: 'input'
		},
		{
			field: 'currencyCode',
			label: 'Currency',
			type: 'input'
		},
		{
			field: 'email',
			label: 'E-mail',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client Id',
			type: 'input'
		},
		{
			field: 'walletId',
			label: 'Wallet Id',
			type: 'input'
		},
		{
			field: 'docExternalId',
			label: 'Document External Ref',
			type: 'input'
		},
		{
			field: 'accountIban',
			label: 'vIban',
			type: 'input'
		},
		{
			field: 'docNumber',
			label: 'Document Nr',
			type: 'input'
		},
		{
			field: 'accountExternalId',
			label: 'Account External Ref',
			type: 'input'
		},
		{
			field: 'transactionNumber',
			label: 'Transaction Nr',
			type: 'input'
		},
		{
			field: 'counterpartyCode',
			label: 'Counterparty Code',
			type: 'input'
		},
		{
			field: 'sysAccountType',
			label: 'System Account Type',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'wlpId',
			name: 'Wlp Id',
			position: 1,
			sortable: false
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 2,
			sortable: false
		},
		{
			field: 'docNumber',
			name: <div>Transaction Id<br />Document External Ref<br />Transaction Nr </div>,
			position: 3,
			sortable: false
		},
		{
			field: 'transDate',
			name: <div>Transaction Date<br />Value Date</div>,
			position: 4,
			sortable: false
		},
		{
			field: 'walletId',
			name: <div>Wallet Id<br />Sys Type</div>,
			position: 5,
			sortable: false
		},
		{
			field: 'wbhProcName',
			name: 'Process',
			position: 6,
			sortable: false
		},
		{
			field: 'changeAmount',
			name: 'Amount',
			position: 7,
			sortable: false
		},
		{
			field: 'cpName',
			name: 'CP Part',
			position: 8,
			sortable: false
		},
		{
			field: 'detail',
			name: 'Details',
			position: 9,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 10,
			sortable: false
		}
	];

	useEffect(() => {
		if (pagination.filter !== '') {
			dispatch(getSystemDocuments(pagination));
		}
		else if (list.length > 0) { dispatch(setSystemDocyments([])); }
	}, [pagination, dispatch]);


	const download = async () => {
		try {
			dispatch(downloadSystemDocuments(pagination));
		}
		catch (e) {
			showException(e);
		}
	};

	const downloadBtn = (
		<>
			<Divider hidden />
			<Button secondary basic
				content='Download Results'
				onClick={download}
				disabled={isDownloading || list.length < 1} />
		</>);

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry,idx) => {
				return (
					<Table.Row key={`${entry.wbhId} ${idx}`} >
						<Table.Cell>{entry.wlpId}</Table.Cell>
						<Table.Cell>{entry.integration}</Table.Cell>
						<Table.Cell>{entry.docNumber}
							<br />{entry.docExternalId}
							<br />{entry.transactionNumber}
						</Table.Cell>
						<Table.Cell>{formatDateWithPattern(entry.transDate, 'dd/MM/yyyy HH:mm:ss')}
							<br />{formatDateWithPattern(entry.transValueDate, 'dd/MM/yyyy HH:mm:ss')}
						</Table.Cell>
						<Table.Cell>{entry.walletId}
							<br />{entry.sysAcctType}
						</Table.Cell>
						<Table.Cell>{entry.wbhProcName}</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								prefix={`${entry.currencyCode} `}
								value={entry.changeAmount} />
						</Table.Cell>
						{(entry.cpName || entry.cpAccount) ?
							<Table.Cell>{entry.cpName}
								<br />{entry.cpAccount}
							</Table.Cell> :
							<Table.Cell>{entry.fromWalletId}
								<br />{entry.toWalletId}
							</Table.Cell>
						}
						<Table.Cell>{entry.detail}
						</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Link to={`/document/${entry.docNumber ?? ''}`}>View Document</Link>
							<br />
							<Link to={`/client/${entry.userId ?? ''}`}>View Client</Link>
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
                <Table.Cell textAlign='center' colSpan={9}>No documents yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid id='all-documents'>
			<Segment>
				<Header as='h2'>All Documents</Header>
				<Divider hidden />
				<CustomTable
					filter={filter}
					loading={loading}
					paginateInitialState={pagination}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
					contentAfterFilter={downloadBtn}
				/>
			</Segment>
		</Container>
	);
};

export default SystemDocuments;
