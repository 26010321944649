import { getSystemPaymentAccount } from '@/features/systemPaymentAccount/slice';
import { RootState } from '@/rootReducer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaginateParamsOptional } from '../globalTypes';

const useSystemPaymentAccountLov = (selectedWhiteLabel?: string, payload?: PaginateParamsOptional) => {

	const dispatch = useDispatch();

	const { whitelabel } = useSelector((state: RootState) => state.whitelabel);
	const { list: systemPaymentAccountList, status: systemPaymentAccountStatus, wlpId } = useSelector((state: RootState) => state.systemPaymentAccount);

	const queryWlp = selectedWhiteLabel ? selectedWhiteLabel : whitelabel; 
 
	useEffect(() => {
		if (systemPaymentAccountStatus === 'idle' || wlpId != queryWlp) {
			dispatch(getSystemPaymentAccount(queryWlp, payload));
		}
	}, [dispatch, queryWlp, wlpId]);

	const systemPaymentAccountLov = systemPaymentAccountList.map((p) => { return { key: p.bankAccountId.toString(), text: `${p.description + (p.walletId !== null ? ' (' + p.walletId + ')' : '')} `, value: p.bankAccountId.toString() }; });

	return { systemPaymentAccountList, systemPaymentAccountLov, systemPaymentAccountStatus };
};

export default useSystemPaymentAccountLov;
