import { getPeriods } from '@/features/periods/periodsSlice';
import { RootState } from '@/rootReducer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const usePeriodLov = () => {

	const dispatch = useDispatch();
	const { list: periodList, status: periodStatus } = useSelector((state: RootState) => state.periods);

	useEffect(() => {
		periodStatus === 'idle' && dispatch(getPeriods());
	}, [dispatch, periodStatus]);


	const periodLov = periodList.map((p) => { return { key: p.periodName, text: p.periodName, value: p.periodName }; });

	return { periodList, periodStatus, periodLov };
};

export default usePeriodLov;
