import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getLanguages, toogleLanguage } from '@/features/languages/languagesSlice';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';

interface Props {
	writePermission: boolean,
}

const Languages = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.languages
	);

	const paginateInitialState: PaginateParams = { orderBy: 'code', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);

	const headers: Array<Headers> = [
		{
			field: 'code',
			name: 'Code',
			position: 1,
			sortable: true
		},
		{
			field: 'name',
			name: 'Name',
			position: 2,
			sortable: true
		},
		{
			field: 'disabled',
			name: 'Enabled',
			position: 3,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getLanguages(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const toogleLang = async (id: number, disabled: boolean) => {
		try {
			await toogleLanguage(id);
			showSuccessNotification(disabled ? 'Language enabled' : 'Language disabled');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.code}</Table.Cell>
						<Table.Cell width={2}>{entry.name}</Table.Cell>
						<Table.Cell width={2}>
							{!entry.disabled &&
								<ComponentWithConfirmation popup='Disable language' onConfirm={() => toogleLang(entry.id, entry.disabled)} confirmContent={'Are you sure you want disable language?'}>
									<Icon disabled={!writePermission} className='tableActionIcon' color='green' size='large' name="check circle" />
								</ComponentWithConfirmation>}
							{entry.disabled &&
								<ComponentWithConfirmation popup='Enable language' onConfirm={() => toogleLang(entry.id, entry.disabled)} confirmContent={'Are you sure you want enable language?'}>
									<Icon disabled={!writePermission} className='tableActionIcon' color='red' size='large' name="times circle" />
								</ComponentWithConfirmation>}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={3}>Not languages yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Languages</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Segment>
		</Container>
	);
};


export default Languages;
