import React, { useCallback, useState } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
	Icon,
} from 'semantic-ui-react';
import { Field } from '@/features/compliance/types';
import { useDropzone } from 'react-dropzone';
import { postFile } from '@/features/compliance/slice';


interface Props {
	open: boolean;
	onClose: (newValue?: string, field?: Field, add?: boolean, file?: boolean) => void,
	field: Field;
}

const ComplianceUploadValueModal = ({ open, onClose, field }: Props): React.ReactElement => {

	const [file, setFile] = useState(null);

	const onDrop = useCallback((acceptedFiles) => {
		setFile(acceptedFiles[0]);
	}, []);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/png,image/jpg,image/jpeg,application/pdf', multiple: false });


	const removeAll = useCallback(() => {
		setFile(null);
		acceptedFiles.splice(0);
	}, []);

	const submit = async () => {
		const fileBody = new FormData();
		fileBody.append('file', file);
		const response = await postFile(fileBody);

		setFile(null);
		acceptedFiles.splice(0);
		onClose(response.key, field, field.value ? false : true, true);

	};


	return <Modal
		size="tiny"
		open={open}
		onClose={() => {
			setFile(null);
			acceptedFiles.splice(0);
			onClose();
		}}>
		<Modal.Content>
			<Header content="Edit Compliance form file" />


			<Grid className='uploadFile' style={{ marginLeft: '0', marginRight: '0', marginBottom: '1em', marginTop: '1em' }}>
				<Grid.Row>
					<div {...getRootProps()} id='file-container'>
						<input {...getInputProps()} id='file-drop' />
						<span><Icon name='upload' />  Upload file...</span>
					</div>
				</Grid.Row>
				{file &&
					<Grid.Row>
						<Icon size="large" name="file outline" />
						<div className="fileName">{file.name}</div>
						<Icon className="fileName" size="large" name='trash' link onClick={() => removeAll()} />
					</Grid.Row>
				}
			</Grid>

			<Divider />

			<Grid columns={2}>
				<Grid.Row>
					<Grid.Column >
						<Button
							primary size="large"
							floated='right'
							onClick={submit}
							disabled={!file}>
							Save
						</Button>
					</Grid.Column>
					<Grid.Column>
						<Button floated='left' secondary size="large" content='Cancel' onClick={() => {
							setFile(null);
							acceptedFiles.splice(0);
							onClose();
						}} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Modal.Content>
	</Modal>;
};

export default ComplianceUploadValueModal;
