import { PaginateParams } from '@/helpers/globalTypes';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '../swal/slice';
import { BalanceProcessStore, BalanceProcessType, CreateReportPayload, ReportRefreshPayload, ReportStatusType, ReportType } from './balanceProcessStatusType';

const initialState: BalanceProcessStore = {
	loading: false,
	list: [],
	error: null,
	count: 0
};


const slice = createSlice({
	name: 'balanceProcessStatus',
	initialState,
	reducers: {
		setBalanceProcess(state, action: PayloadAction<Array<BalanceProcessType>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
	}
});

export const { setBalanceProcess, setLoading, setError, setCount } = slice.actions;


export const getBalanceProcesses = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/statements/balance-update-status/', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setBalanceProcess(data.list));
			dispatch(setCount(data.count));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getReportStatus = async (processKey: string): Promise<ReportStatusType> => {
	const response = await request.get(`/api/admin/reports/process/${processKey}`);
	const { data } = response;
	return data;
};

export const createReport = async (payload: CreateReportPayload): Promise<ReportType> => {
	const response = await request.post('/api/admin/reports', payload);
	const { data } = response;
	return data;
};

export const submitReportRefresh = async (reportId: number, payload: ReportRefreshPayload): Promise<ReportType> => {
	const response = await request.post(`/api/admin/statements/balances-update/${reportId}`, payload);
	const { data } = response;
	return data;
};

export default slice.reducer;
