import React from 'react';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react';
import { MaintenancePostType, MaintenanceType } from '@/features/maintenance/maintenanceType';
import { convertDate } from '@/helpers/date';
import { applyMaintenanceMode, updateMaintenanceMode } from '@/features/maintenance/maintenanceSlice';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { checkWritePermission } from '@/helpers/permissions';
import { PermissionType } from '@/features/user/types';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';

interface Props {
	item: MaintenanceType,
	onClick: (item: MaintenanceType) => void,
	refreshData: () => void
}

const MaintenanceLine = ({ item, onClick, refreshData }: Props): React.ReactElement => {

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const handleApply = async () => {
		try {
			await applyMaintenanceMode();
			showSuccessNotification('Successfully submitted');
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	const handleDisable = async (item: MaintenanceType) => {
		try {
			const payload: MaintenancePostType = {
				id: item.id,
				startDate: item.startDate,
				// now plus 1 minutes
				endDate: new Date(Date.now()),
				integration: item.integration,
				processes: item.processes,
				description: item.description,
				message: item.message
			};

			await updateMaintenanceMode(payload);
			await applyMaintenanceMode();

			refreshData();
			showSuccessNotification('Successfully disabled');
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	return (
		<Grid celled id='balance-tree'>
			<Grid.Row columns={7} className='grid-custom-headers'>
				<Grid.Column> Integration</Grid.Column>
				<Grid.Column> Status </Grid.Column>
				<Grid.Column> Agents</Grid.Column>
				<Grid.Column> Processes</Grid.Column>
				<Grid.Column> Start Date</Grid.Column>
				<Grid.Column> End Date</Grid.Column>
				<Grid.Column> Actions</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={7} >
				<Grid.Column> {item?.integration} </Grid.Column>
				<Grid.Column> {item?.status}</Grid.Column>
				<Grid.Column > {item?.affectedAgents?.length > 0 ?
					item?.affectedAgents?.map(p => <div key={p}>{p}</div>) :
					<Header as='h5'>All</Header>
				} </Grid.Column>
				<Grid.Column > {item?.processes?.length > 0 &&
					item?.processes?.map(p => <div key={p}>{p}</div>
					)} </Grid.Column>
				<Grid.Column> {convertDate(item?.startDate, 'dd MMM yy hh:mm:ss')} </Grid.Column>
				<Grid.Column> {convertDate(item?.endDate, 'dd MMM yy hh:mm:ss')} </Grid.Column>
				<Grid.Column textAlign='center'>
					<Popup content={'Edit details'}
						trigger={<Icon style={{ marginRight: '1.5rem' }} className='tableActionIcon'
							size='large' name="edit"
							onClick={() => onClick(item)} />}
					/>
					<Popup content={'Apply to proccess (respective proccesses will be marked in maintenance and will not be available in portal)'}
						trigger={<Icon className='tableActionIcon'
							disabled={!checkWritePermission(permissions, PermissionType.MAINTENANCE)}
							size='large' name="play"
							onClick={handleApply} />}
					/>
					<Popup content={'Disable Maintenance Mode'}
						trigger={<Icon className='tableActionIcon'
							disabled={!checkWritePermission(permissions, PermissionType.MAINTENANCE) || new Date(item.endDate) < new Date()}
							size='large' name="close"
							onClick={() => handleDisable(item)} />}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1} >
				<Grid.Column> <strong>Description:</strong>  {item?.description}</Grid.Column>
				<Grid.Column> <strong>Message:</strong>  {item?.message}</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};


export default MaintenanceLine;
