export interface POA {
    userid: number,
    clientid: string,
    email: string,
    firstname: string,
    lastname: string,
    type: string,
    companyname: string,
    poastatus: string,
    admininprogess: boolean,
    compliancestatus: string,
    risksverified: boolean,
    riskstatus: string
}

export interface PoaStore {
    loading: boolean,
    list: Array<POA>,
    error: string,
    count: number,
    totalPages: number,
    pageSize: number
}


export enum Status {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}

export interface UserPOA {
    clientId: string,
    id: number,
    email: string,
    risksVerified?: boolean,
    mobileNumber: string,
    kycTier: number,
    profile: Profile,
    poa: Array<PoaDetails>,
    kycIdentity: KycIdentity,
    risksVerifiedByUser?: Profile,
}

export interface KycIdentity {
    id: number,
    kycId: number,
    birthDate: string,
    issuingCountry: string,
    documentType: string,
    documentNumber: string,
    issueDate: string,
    expiryDate: string,
    lastName: string,
    firstName: string,
    service: string,
    serviceId: string,
    placeOfBirth: string,
    checkId: string,
    identificationNumber?: string,
    gender?: string
}

export interface Profile {
    address: string,
    city: string,
    country: string,
    addressCountry: string,
    postalCode: string,
    firstName: string,
    lastName: string,
    state?: string
}

export interface PoaDetails {
    id: number,
    status: string,
    createdDate: string,
    key: string,
    mimeType: string,
    name: string,
    rejectionReason: string,
    adminInProgress: boolean,
    poaUpdatedByUser?: Profile
}
