import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Segment, Table, Checkbox, Select } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import NumberFormat from 'react-number-format';
import { getReconciliations } from '@/features/reconciliation/reconciliationSlice';

import './Reconciliation.css';
import { useHistory } from 'react-router-dom';
import { changeState, editItemAllowed, ReconciliationState } from './Reconcile';
import useBankAccountLov from '@/helpers/customHook/useBankAccountLov';
import useWhiteLabelLov from '@/helpers/customHook/useWhiteLabelLov';
import { PaginateParams } from '@/helpers/globalTypes';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	writePermission: boolean
} 

const headers: Array<Headers> = [
	{
		field: 'wlpId',
		name: 'White Label',
		position: 1,
		sortable: false
	},
	{
		field: 'bankAccountId',
		name: 'Bank Account',
		position: 2,
		sortable: false
	},
	{
		field: 'batchId',
		name: 'Batch Id',
		position: 3,
		sortable: false
	},
	{
		field: 'transactionId',
		name: <div>Transaction Id<br />Iban<br />Date </div>,
		position: 4,
		sortable: false
	},
	{
		field: 'customerName',
		name: 'Customer Name',
		position: 5,
		sortable: false
	},
	{
		field: 'amount',
		name: 'Amount',
		position: 6,
		sortable: false
	},
	{
		field: 'accountDocumentAmount',
		name: 'Account Document Amount',
		position: 7,
		sortable: false
	},
	{
		field: 'reconciled',
		name: 'Reconciled',
		position: 8,
		sortable: false
	},
	{
		field: 'reconilationDetails',
		name: <div>Reconilation Date<br /> Amount<br /> Group </div>,
		position: 9,
		sortable: false
	}
];


const ReconciliationSummary = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const history = useHistory();
	const [reconcile, setReconcile] = useState<ReconciliationState>({});
	const [submitting, setSubmitting] = useState<boolean>(false);

	const { bankAccountLov } = useBankAccountLov();
	const { whitelabelOptions } = useWhiteLabelLov();

	const { list, totalPages, error, loading } = useSelector((state: RootState) => state.reconciliation);

	const paginateInitialState: PaginateParams = { orderBy: 'wlpId', skip: 0, sort: 'ASC', filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const filter: FilterParam[] = [
		{
			field: 'wlpId',
			label: 'White Label',
			type: 'select',
			options: whitelabelOptions
		},
		{
			field: 'bankAccountId',
			label: 'Bank Account',
			type: 'select',
			options: bankAccountLov
		}
	];

	useEffect(() => {
		dispatch(getReconciliations(pagination));
	}, [dispatch, pagination]);

	const changeReconciliationState = (id: number, event: any, data: any) => {
		changeState(id, event, data, reconcile, setReconcile);
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.bankAccountId}-${entry.id}${index}`} >
						<Table.Cell width={1} className='editable-cell'>
							<Select
								name={`wlpId-${entry.id}`}
								options={whitelabelOptions}
								onChange={(e, data) => changeReconciliationState(entry.id, e, data)}
								value={reconcile[entry.id]?.wlpId ?? entry?.wlpId ?? ''}
								disabled={!editItemAllowed(entry.id, entry.reconciled, reconcile, writePermission)}
								selection
								required />
						</Table.Cell>
						<Table.Cell width={2} className='editable-cell'>
							<Select
								name={`bankAccountId-${entry.id}`}
								options={bankAccountLov}
								onChange={(e, data) => changeReconciliationState(entry.id, e, data)}
								value={reconcile[entry.id]?.bankAccountId?.toString() ?? entry?.bankAccountId?.toString() ?? ''}
								disabled={!editItemAllowed(entry.id, entry.reconciled, reconcile, writePermission)}
								selection
								search
								required />
						</Table.Cell>
						<Table.Cell width={1}>{entry.batchId}</Table.Cell>
						<Table.Cell width={2} textAlign='left'>{entry.transactionId}
							<br />{entry.iban}
							<br />{entry.transactionDate}</Table.Cell>
						<Table.Cell width={1}>{entry.customerName} <br />  {entry.transactionDetails}</Table.Cell>
						<Table.Cell width={1}>
							<NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true}
								prefix={entry.currencyCode + ' '}
								value={entry.amount} />
						</Table.Cell>
						<Table.Cell width={1}>{entry.accountDocumentAmount}  </Table.Cell>
						<Table.Cell width={1} className='editable-cell'>
							<Checkbox name={`reconciled-${entry.id}`}
								checked={reconcile[entry.id]?.reconciled ?? entry?.reconciled ?? false}
								onChange={(e, data) => changeReconciliationState(entry.id, e, data)}
								disabled={!writePermission}
							/>
						</Table.Cell>
						<Table.Cell width={1}>{entry.reconciliationDate}
							<br /> {entry.reconciledAmount}
							<br /> {entry.reconciledGroup}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>No reconciliations yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;

	/* 	const wlpInitValues = list.reduce((obj, item) => {
			return {
				...obj
				, ['wlpId-' + item.id.toString()]: item.wlpId ?? ''
				, ['bankAccountId-' + item.id.toString()]: item.bankAccountId ?? ''
				, ['reconciled-' + item.id.toString()]: item.reconciled ?? false
			};
		}, {});
	
		const initialValues = wlpInitValues;
		const validationSchema = Yup.object(); */

	const onReconcile = () => {
		history.push('/reconciliations/reconcile');
	};

	/* 	const onSave = () => {
			setSubmitting(true);
			const fieldArray = _.entries(formData);
			const objectGroups = _.groupBy(fieldArray, item => (item[0].split('-').reverse()[0]));
			const objectWithId = _.map(objectGroups, (item, idx) => { return { ...Object.fromEntries(item), ['id']: idx }; });
			const fixedKeys = objectWithId.map(obj => _.mapKeys(obj, (value, key) => key.toString().split('-')[0]));
			console.log(fixedKeys);
			setSubmitting(false);
		};
	 */

	const onSave = () => {
		setSubmitting(true);
		alert('Not yet supported');
		setSubmitting(false);
	};


	return (
		<Container fluid id='reconciliations'>
			<Segment>
				<Header as='h2'>Reconciliation</Header>
				<Divider hidden />
				{list.length > 0 &&
					< >
						<CustomTable
							filter={filter}
							loading={loading}
							paginateInitialState={pagination}
							tableBody={tableBody}
							totalPages={totalPages}
							setPagination={setPagination}
							header={headers}
							pagination={pagination}
							urlPagination={true}
						/>
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column>
									<Button type="submit" primary floated='right' size="large" content='Save'
										disabled={submitting}
										onClick={onSave} />
								</Grid.Column>
								<Grid.Column>
									<Button type="submit" secondary floated='left' size="large" content='Reconcile'
										disabled={submitting}
										onClick={onReconcile} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</ >
				}
			</Segment>
		</Container >
	);
};

export default ReconciliationSummary;
