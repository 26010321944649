import { showErrorNotification } from '@/features/swal/slice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionContent, AccordionTitle, Button, Header, Icon, Message, Segment, SemanticCOLORS, Table } from 'semantic-ui-react';
import { getRiskEvaluation, postRiskVerified } from '@/features/compliance/slice';
import { RiskEvalutionResponse } from '@/features/compliance/types';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import { PermissionType } from '@/features/user/types';
import { Profile } from '@/features/poa/types';

interface Props {
	userId: number,
	risksVerified: boolean,
	risksVerifiedByUser?: Profile,
	fetchUser: () => void
}
const RiskEvaluation = ({ userId, risksVerified, risksVerifiedByUser, fetchUser }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const [active, setActive] = useState<boolean>(false);
	const [userRisks, setUserRisk] = useState<RiskEvalutionResponse[]>([]);
	const { permissions } = useSelector((state: RootState) => state.user);
	const [error, setError] = useState<any>([]);
	const [toggleLoading, setToggleLoading] = useState<boolean>(false);


	const userRiskList = userRisks.filter(p => p.lookupType !== 'RISK_LEVELS') || [];
	const userRiskScore = userRisks.find(p => p.lookupType === 'RISK_LEVELS');

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				setError(false);
				const result = await getRiskEvaluation(userId) || [];
				setUserRisk(result);
			} catch (e) {
				setError(true);
				showErrorNotification(e);
			}
		};
		get();
	}, [dispatch]);

	useEffect(() => {
		fetchData();
	}, [dispatch]);

	const approve = async () => {
		setToggleLoading(true);
		try {
			await postRiskVerified(userId);
			fetchUser();
			setToggleLoading(false);
		} catch (e) {
			setToggleLoading(false);
			showErrorNotification(e);
		}
	};



	const getColor = (level: string): SemanticCOLORS => {
		if (level === 'LOW')
			return 'green';
		if (level === 'HIGH')
			return 'red';

		return 'orange';
	};

	const getApprovedBy = () => {

		if (!risksVerifiedByUser)
			return '';

		return userRiskScore?.approvedBy ? 'Auto approval' : `${risksVerifiedByUser?.firstName} ${risksVerifiedByUser?.lastName}`;
	};

	const approvedBy = getApprovedBy();

	const handleClick = () => {
		setActive(prev => !prev);
	};


	return (<Accordion fluid styled style={{ marginBottom: 5 }} >
		<AccordionTitle
			active={active}
			index={1}
			onClick={handleClick} >
			Risk Score and Assessment
			<Icon name={active ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
		</AccordionTitle>
		<AccordionContent active={active}>
			<Segment>
				<Header as='h5'>Score</Header>
				{<Table id='table' celled textAlign='center' padded='very' striped>
					<Table.Header fullWidth className='tableHeader'>
						<Table.Row>
							<Table.HeaderCell className="tableHeaders">Category</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Value</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Approved By</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Status</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Action</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body className="tableBody">
						<Table.Row>
							<Table.Cell width={1}> <Message color={getColor(userRiskScore?.lookupCode)}
							>{userRiskScore?.codeDescription ?? 'Unknown'}</Message></Table.Cell>
							<Table.Cell width={2}>{userRiskScore?.score}</Table.Cell>
							<Table.Cell width={2}>{approvedBy}</Table.Cell>
							<Table.Cell width={2}>{risksVerified ? 'Approved' : 'Pending Approval'}</Table.Cell>
							<Table.Cell width={2}>
								<ComponentWithConfirmation
									disabled={risksVerified}
									onConfirm={approve}
									confirmContent={'Are you sure you want approve user?'}>
									<Button
										primary
										loading={toggleLoading}
										content='Approve'
										disabled={!checkWritePermission(permissions, PermissionType.POA_COMPLIANCE) || risksVerified}
									/>
								</ComponentWithConfirmation>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
				}
			</Segment >
			<Segment>
				<Header as='h5'>Assessment</Header>
				{error ? <div style={{ paddingTop: '4px', paddingBottom: '4px' }}>Cannot get assesment data</div> :
					<Table id='table' celled textAlign='center' padded='very' striped>
						<Table.Header fullWidth className='tableHeader'>
							<Table.Row>
								<Table.HeaderCell className="tableHeaders" textAlign='left'>Type</Table.HeaderCell>
								<Table.HeaderCell className="tableHeaders">Value</Table.HeaderCell>
								<Table.HeaderCell className="tableHeaders">Score</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body className="tableBody">
							{userRiskList?.map((entry) => {
								return (
									<Table.Row key={`${entry.id}$`} >
										<Table.Cell width={2} textAlign='left'>{entry.typeDescription}</Table.Cell>
										<Table.Cell width={2}>{entry.codeDescription === entry.valueDescription ? entry.valueDescription : `${entry.codeDescription}  (${entry.value})`}</Table.Cell>
										<Table.Cell width={1}>{entry.score}</Table.Cell>
									</Table.Row>
								);
							})}
						</Table.Body>
					</Table>
				}
			</Segment>
		</AccordionContent>
	</Accordion>
	);
};


export default RiskEvaluation;
