import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getPoaList } from '@/features/poa/slice';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

export const normalizeString = (value: string) => {

	const v = value ? value.toString().toUpperCase().replaceAll('_', ' ') : '';
	return v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();
};

const Poa = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.poa
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const statusOptions = [
		{
			key: 'PENDING',
			text: 'Pending',
			value: 'PENDING',
		},
		{
			key: 'REJECTED',
			text: 'Rejected',
			value: 'REJECTED',
		},
		{
			key: 'APPROVED',
			text: 'Approved',
			value: 'APPROVED',
		},
	];


	const filter: FilterParam[] = [
		{
			field: 'compliancestatus',
			label: 'Comliance Status',
			type: 'select',
			options: statusOptions
		},
		{
			field: 'poastatus',
			label: 'Poa Status',
			type: 'select',
			options: statusOptions
		},
		{
			field: 'riskstatus',
			label: 'Risks Status',
			type: 'select',
			options: [{
				key: 'risksatus',
				text: 'Done',
				value: 'DONE',
			},
			{
				key: 'pending',
				text: 'Pending approval',
				value: 'PENDING APPROVAL',
			}]
		},
		{
			field: 'admininprogess',
			label: 'Admin in progress',
			type: 'select',
			options: [{
				key: 'true',
				text: 'In Progress',
				value: 'true',
			},
			{
				key: 'false',
				text: 'Pending',
				value: 'false',
			}]
		},
		{
			field: 'clientid',
			label: 'Client ID',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'clientid',
			name: 'Client ID',
			position: 1,
			sortable: false
		},
		{
			field: 'compliancestatus',
			name: 'Compliance Status',
			position: 2,
			sortable: false
		},
		{
			field: 'riskstatus',
			name: 'Risks status',
			position: 3,
			sortable: false
		},
		{
			field: 'poastatus',
			name: 'Poa Status',
			position: 4,
			sortable: false
		},
		{
			field: 'email',
			name: 'Email',
			position: 5,
			sortable: false
		},
		{
			field: 'firstname',
			name: 'First Name',
			position: 6,
			sortable: false
		},
		{
			field: 'lastname',
			name: 'Last Name',
			position: 7,
			sortable: false
		},
		{
			field: 'admininprogess',
			name: 'POA Admin in Progress',
			position: 8,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 9,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getPoaList(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const history = useHistory();
	const openPOA = (userid: number) => {
		history.push(`/poa/${userid}`);
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.userid}-${index}`} >
						<Table.Cell width={2}>{entry.clientid}</Table.Cell>
						<Table.Cell width={2}>{normalizeString(entry.compliancestatus)}</Table.Cell>
						<Table.Cell width={2}>{normalizeString(entry.riskstatus)}</Table.Cell>
						<Table.Cell width={2}>{entry.poastatus}</Table.Cell>
						<Table.Cell width={2}>{entry.email}</Table.Cell>
						<Table.Cell width={2}>{entry.firstname}</Table.Cell>
						<Table.Cell width={2}>{entry.lastname}</Table.Cell>
						<Table.Cell width={2}>{entry.admininprogess ? 'In progress' : 'Pending'}</Table.Cell>
						<Table.Cell width={2}>
							<Popup content='Open user POA' trigger={<Icon className='tableActionIcon' size='large' name="file alternate outline" onClick={() => openPOA(entry.userid)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={7}>Empty list.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Internal compliance</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default Poa;
