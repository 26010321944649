import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { Client, ClientsStore } from './types';
import { PaginateParams } from '@/helpers/globalTypes';
import { showErrorNotification } from '../swal/slice';

const initialState: ClientsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 25,
};


const slice = createSlice({
	name: 'clients',
	initialState,
	reducers: {
		setClients(state, action: PayloadAction<Array<Client>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setClients, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getClients = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/users/list/USER', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setClients(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getSystemClients = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/users/list/ADMIN', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setClients(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getClient = async (userId: number) => {
	const response = await request.get(`/api/admin/users/${userId}`);
	const { data } = response;
	return data;
};


export const getAdminRolesOptions = async (userId: number) => {
	const response = await request.get(`/api/admin/users/available-roles/${userId}`);
	const { data } = response;
	return data;
};

export const postAdminRole = async (roleId: number, userId: number) => {
	const response = await request.post('/api/admin/users/role', { roleId, userId });
	const { data } = response;
	return data;
};

export const deselectAdminRole = async (userId: number) => {
	const response = await request.post('/api/admin/users/role/deselect', { userId });
	const { data } = response;
	return data;
};

export const disableClient = async (userId: number, disable: boolean) => {
	const response = await request.post('/api/admin/users/disable', { userId, disable });
	const { data } = response;
	return data;
};

export const detainClient = async (userId: number, detain: boolean, reason?: string) => {
	const response = await request.post('/api/admin/users/detain', { userId, detain, reason });
	const { data } = response;
	return data;
};

export const changeRiskGroup = async (code: string, isFixed: boolean, userId: number) => {
	const response = await request.post('/api/admin/users/riskgroup', { userId, code, isFixed });
	const { data } = response;
	return data;
};

export const monitorUserTransaction = async (monitored: boolean, userId: number) => {
	const response = await request.post('/api/admin/tx-monitoring/user', { userId, monitored });
	const { data } = response;
	return data;
};

export const reset2FA = async (userId: number) => {
	const response = await request.post('/api/admin/users/2fa/reset', { userId });
	const { data } = response;
	return data;
};

export const resetMobilePin = async (userId: number) => {
	const response = await request.post('/api/admin/users/passcode/reset', { userId });
	const { data } = response;
	return data;
};

export const confirmEmail = async (userId: number) => {
	const response = await request.post('/api/admin/users/confirm-email', { userId });
	const { data } = response;
	return data;
};

export const changeEmail = async (userId: number, email: string) => {
	const response = await request.post('/api/admin/users/email/main', { userId, email });
	const { data } = response;
	return data;
};


export const createKyc = async (userId: number, service: string, serviceId: string, checkId?: string) => {
	const response = await request.post('/api/admin/users/kyc/create', { userId, service, serviceId, checkId });
	const { data } = response;
	return data;
};

export const updateKyc = async (kycId: number, service: string, serviceId: string, checkId?: string) => {
	const response = await request.post('/api/admin/users/kyc/update', { kycId, service, serviceId, checkId });
	const { data } = response;
	return data;
};

export const updateKycService = async (kycId: number, service: string) => {
	const response = await request.post('/api/admin/users/kyc/update', { kycId, service });
	const { data } = response;
	return data;
};

export const updateKycServiceId = async (kycId: number, serviceId: string) => {
	const response = await request.post('/api/admin/users/kyc/update', { kycId, serviceId });
	const { data } = response;
	return data;
};

export const updateKycCheckId = async (kycId: number, checkId: string) => {
	const response = await request.post('/api/admin/users/kyc/update', { kycId, checkId });
	const { data } = response;
	return data;
};

export const changePhone = async (userId: number, phoneNumber: string) => {
	const response = await request.post('/api/admin/users/change-phone', { userId, phoneNumber });
	const { data } = response;
	return data;
};

export const getMonitoredUserTransaction = async (userId: number) => {
	const response = await request.get(`/api/admin/tx-monitoring/user/${userId}`);
	const { data } = response;
	return data;
};

export const resetKycCheckCount = async (userId: number, kycId: number) => {
	const response = await request.post('/api/admin/users/kyc/reset-check-count', { userId, kycId });
	const { data } = response;
	return data;
};

export const clearUserKyc = async (applicantId: string) => {
	const response = await request.post('/api/admin/users/kyc/clear', { applicantId });
	const { data } = response;
	return data;
};

export const confirmPhone = async (userId: number) => {
	const response = await request.post('/api/admin/users/confirm-phone', { userId });
	const { data } = response;
	return data;
};

export default slice.reducer;
