import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { formatDateWithPattern } from '@/helpers/date';
import { getTickets } from '@/features/tickets/ticketsSlice';
import { useHistory } from 'react-router-dom';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import SupportEmailTicketDetailsModal from './SupportEmailTicketDetailsModal';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

const Tickets = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.tickets
	);

	const paginateInitialState: PaginateParams = { orderBy: 'modifiedDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [ticketId, setTicketId] = useState<number>(null);

	const statusOptions = [
		{
			key: 'PENDING_ADMIN',
			text: 'Pending admin',
			value: 'PENDING_ADMIN',
		},
		{
			key: 'PENDING_USER',
			text: 'Pending user',
			value: 'PENDING_USER',
		},
		{
			key: 'SOLVED',
			text: 'Solved',
			value: 'SOLVED',
		},
		{
			key: 'REJECTED',
			text: 'Rejected',
			value: 'REJECTED',
		}
	];

	const incidentTypeOptions = [
		{
			key: 'NOTIFICATION',
			text: 'Notification',
			value: 'NOTIFICATION',
		},
		{
			key: 'INCIDENT',
			text: 'Incident',
			value: 'INCIDENT',
		},
		{
			key: 'STOCK_ORDER',
			text: 'Stock order',
			value: 'STOCK_ORDER',
		},
		{
			key: 'SUPPORT_BY_EMAIL',
			text: 'Support by email',
			value: 'SUPPORT_BY_EMAIL',
		},
	];

	const filter: FilterParam[] = [
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: statusOptions
		},
		{
			field: 'incidentType',
			label: 'Incident type',
			type: 'select',
			options: incidentTypeOptions
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input',
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'title',
			name: 'Subject',
			position: 1,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 2,
			sortable: true
		},
		{
			field: 'modifiedDate',
			name: 'Updated date',
			position: 3,
			sortable: true
		},
		{
			field: 'createdDate',
			name: 'Raised date',
			position: 4,
			sortable: true
		},
		{
			field: 'userId',
			name: 'Client ID',
			position: 5,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 6,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getTickets(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const history = useHistory();
	const openTicket = (ticketId: number) => {
		history.push(`/ticket/${ticketId}`);
	};

	const handleClose = (status?: string) => {
		setOpenModal(false);
		setTicketId(null);
		if (status) {
			showSuccessNotification(`Ticket successfully ${status}.`);
		}
	};

	const tableBody = (
		<Table.Body className="tableBody">
			<SupportEmailTicketDetailsModal
				open={openModal}
				ticketId={ticketId}
				onClose={handleClose}
			/>
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={8}>{entry.title}</Table.Cell>
						<Table.Cell width={2}>{entry.status}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.modifiedDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.createdDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>{entry.clientId}</Table.Cell>
						<Table.Cell width={2}>
							{ //entry.incidentType !== 'SUPPORT_BY_EMAIL' ?
								<Popup content='Open ticket details' trigger={<Icon className='tableActionIcon' size='large' name="envelope open outline" onClick={() => openTicket(entry.id)} />} />
								//: <Popup content='Open support by email' trigger={<Icon className='tableActionIcon' size='large' name="envelope open outline" onClick={() => { setTicketId(entry.id); setOpenModal(true); }} />} />
							}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>Not tickets yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Tickets</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default Tickets;
