import { PaginateParams } from '@/helpers/globalTypes';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '../swal/slice';
import { AdminRolesResposeTypes, AdminsRoleType, AdminsStore, AdminType, CreateAdminPayload, EmiListType } from './adminTypes';

const initialState: AdminsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'admins',
	initialState,
	reducers: {
		setAdmins(state, action: PayloadAction<Array<AdminType>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setAdmins, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;


export const getAdmins = (type: AdminsRoleType, payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get(`/api/admin/system-admins/list/${type}`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setAdmins(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};
export default slice.reducer;


export const getIsEmi = async (): Promise<EmiListType> => {
	const response = await request.get('/api/admin/system-admins/is-emi');
	const { data } = response;
	return data;
};

export const getAdminRoles = async (type: AdminsRoleType): Promise<AdminRolesResposeTypes[]> => {
	const response = await request.get(`/api/admin/system-admins/roles/${type}`);
	const { data } = response;
	return data;
};

export const addAdmin = async (type: AdminsRoleType, payload: CreateAdminPayload) => {
	const response = await request.post(`/api/admin/system-admins/create/${type}`, payload);
	const { data } = response;
	return data;
};
