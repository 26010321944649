import { Partner } from '@/features/compliance/types';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionTitle, Divider, Grid, Icon, List, Segment } from 'semantic-ui-react';
import '../../Clients/ClientDetails/Profile.css';

interface Props {
    partners: Partner[];
}

const Partners = ({ partners }: Props) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    };

    return (
        <>
            {partners.map((partner, index) =>
                <Accordion fluid styled key={index} style={{marginBottom: '4px'}}>
                    <AccordionTitle
                        active={activeIndex === index}
                        index={index}
                        onClick={handleClick}
                    >
                        {partner.partnerCompanyName}
                        <Icon name={activeIndex === index ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === index}>
                        {activeIndex === index && <PartnerForm partner={partner} />}
                    </AccordionContent>
                </Accordion>
            )}
        </>
    );
};

interface DProps {
    partner: Partner;
}

const PartnerForm = ({ partner }: DProps) => {
    const countries = getNames('en');
    return (
        <Grid stackable columns={2}>
            <Grid.Column className="profiledatacolumn">
                <Segment className="usersegment" basic>
                    <List id="profile-data">
                        <List.Item>
                            <List.Content style={{padding: '0px'}}>
                                <List.Header className="color-grey">Company name</List.Header>
                                <List.Description>{partner.partnerCompanyName}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{padding: '0px'}}>
                                <List.Header className="color-grey">Incorporation country</List.Header>
                                <List.Description>{countries[alpha3ToAlpha2(partner.partnerIncorporationCountry)]}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{padding: '0px'}}>
                                <List.Header className="color-grey">Type</List.Header>
                                <List.Description>{partner.partnerType}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                    </List>
                </Segment>
            </Grid.Column>
            <Grid.Column className="profiledatacolumn">
                <Segment className="usersegment" basic>
                    <List id="profile-data">
                        <List.Item>
                            <List.Content style={{padding: '0px'}}>
                                <List.Header className="color-grey">Planned number of monthly transactions</List.Header>
                                <List.Description>{partner.plannedNumberOfMonthlyTransactions}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{padding: '0px'}}>
                                <List.Header className="color-grey">Planned volume of monthly transactions</List.Header>
                                <List.Description>{partner.plannedVolumeOfMonthlyTransactons}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                    </List>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default Partners;
