import { PaginateParams } from '@/helpers/globalTypes';
import request from '@/services/request';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showException } from '../swal/slice';
import { SystemDocument, SystemDocumentStore } from './systemDocumentsTypes';

const initialState: SystemDocumentStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
	isDownloading: false,
};

const slice = createSlice({
	name: 'systemDocuments',
	initialState,
	reducers: {
		setSystemDocyments(state, action: PayloadAction<Array<SystemDocument>>) {
			state.list = action.payload || [];
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		},
		setDownloading: (state, { payload }: PayloadAction<boolean>) => {
			state.isDownloading = payload;
		},
	}
});

export const { setSystemDocyments, setDownloading, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getSystemDocuments = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const filterParams = {
				filter: payload.filter,
				orderBy: payload.orderBy,
				sort: payload.sort.toUpperCase(),
				skip: payload.skip.toString(),
				take: payload.take
			};
			const response = await request.get('/api/admin/system-documents/list', { params: filterParams });
			const { data } = response;

			dispatch(setSystemDocyments(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			dispatch(setLoading(false));
			showException(e);
		}
	};
};


export const downloadSystemDocuments = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setDownloading(true));
			const filterParams = {
				filter: payload.filter,
				orderBy: payload.orderBy,
				sort: payload.sort.toUpperCase(),
				skip: 0,
				take: 1000000
			};
			const response = await request.get('api/admin/system-documents/export',
				{
					responseType: 'blob',
					params: filterParams,
				});
  
			const url = window.URL.createObjectURL(response.data);
			const link = document.createElement('a');
			const d = new Date();
			link.download = `document_export_${d.toJSON()}.csv`;
			link.href = url;
			link.className = 'hidden';
			document.body.appendChild(link);

			link.onclick = function () {
				requestAnimationFrame(function () {
					URL.revokeObjectURL(url);
					setTimeout(() => link.remove(), 300);
				});
			};

			link.click();
			dispatch(setDownloading(false));
		}
		catch (e) {
			showException(e);
			dispatch(setDownloading(false));
		}
		finally { dispatch(setDownloading(false)); }
	};
};

export default slice.reducer;
