/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Divider, Header, Loader, Segment, Table } from 'semantic-ui-react';
import { showErrorNotification } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import { PaginateParams } from '@helpers/globalTypes';
import { getVaultsPayments } from '@features/vaultsPayments/slice';
import NumberFormat from 'react-number-format';

interface Props {
	proc: string,
}

const UpcomingLiabilities = ({ proc }: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const { list, loading } = useSelector(
		(state: RootState) => state.vaultsPayments
	);

	const paginateInitialState: PaginateParams = { orderBy: 'transferDate', sort: 'ASC', skip: 0, filter: 'completed=FALSE', take: 5 };

	const [pagination] = useState<PaginateParams>(paginateInitialState);


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getVaultsPayments(proc, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);


	return (
		<Segment>
			<Header as='h2'>Upcoming Liabilities</Header>
			<Divider hidden />
			<div className='omnibus'>
				<Dimmer active={loading} inverted>
					<Loader />
				</Dimmer>
				<Table id='table' celled textAlign='center' padded='very' striped>
					<Table.Header fullWidth className='tableHeader'>
						<Table.Row>
							<Table.HeaderCell className="tableHeaders">Payment Date</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Transaction Type</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Amount</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Total Auto Extend</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body className="tableBody">
						{list.length > 0 && list.map((entry, index) => {
							return (
								<Table.Row key={`${entry.walletId}${index}`} >
									<Table.Cell width={1}>{entry.pmtDate}</Table.Cell>
									<Table.Cell width={1}>{entry.transactionType}</Table.Cell>
									<Table.Cell width={2}>
										{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} />
									</Table.Cell>
									<Table.Cell width={2}>
										{entry.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.vaultDepositAutoReturns} />
									</Table.Cell>
								</Table.Row>
							);
						})}
						{list.length === 0 && <Table.Row>
							<Table.Cell textAlign='center' colSpan={5}>No data yet.</Table.Cell>
						</Table.Row>}
					</Table.Body>
				</Table>
			</div>
		</Segment>
	);
};

export default UpcomingLiabilities;
