export interface Compliance {
	createdDate: string,
	final: boolean,
	id: number,
	kycUpdatedDate: string,
	name: string,
	profileType: string,
	rejectionReason: string,
	result: string,
	resultDate: string,
	service: 'ONFIDO' | 'SUMSUB' | 'ADVISIONARY' | 'ADVISIONARY_V3',
	serviceId: string,
	serviceStatus: string,
	status: string,
	tier: number,
	type: string,
	updatedDate: string,
	userId: number,
	businessName: string,
	companyAddress: string,
	companyCountry: string,
	companyRegNumber: string,
	checkCount: number,
	companyCity?: string,
	companyPostalCode?: string,
}

export interface ComplianceStore {
	loading: boolean,
	list: Array<Compliance>,
	error: string,
	count: number,
	totalPages: number,
	pageSize: number
}

export interface Form {
	code: string,
	client: string,
	type: string,
	sections: Section[],
	id: number,
}

export interface Section {
	formId: number,
	name: string,
	order: number,
	translation: Translation[],
	groups: Group[],
	id: number,
}

export interface Translation {
	fieldName: string,
	label: string,
	description: string,
	languageCode: string
}

export interface Group {
	sectionId: number,
	name: string,
	order: number,
	multiple: boolean,
	hideLabel: boolean,
	translation: Translation[],
	fields: Field[],
	id: number
}

export interface Field {
	groupId: number,
	fieldId: number,
	name: string,
	order: number,
	type: string,
	required: boolean,
	multiple: boolean,
	condition: string,
	format: string,
	translation: Translation[],
	selectOptions: Array<any>,
	value: Value
}

export interface Value {
	id: number,
	value: string,
	file: boolean,
	fileDetails: {
		name: string
	},
	editedByAdmin: boolean
}

export interface AddValuePayload {
	name: string,
	value: string,
	applicantId: string,
	formId: number,
	sectionId: number,
	groupId: number,
	fieldId: number,
	checkCount: number,
	file: boolean
}

export interface UpdateKycDataPayload {
	applicantId: string,
	firstName: string,
	lastName: string,
	dateOfBirth: Date,
	documentType: string,
	documentNumber: string,
	issuingCountry: string,
	issueDate: Date,
	expiryDate: Date,
	placeOfBirth: string,
	identificationNumber: string,
	gender: string
}



export interface V3AdvisionaryFormSections {
	name: string,
	status: 'PENDING' | 'APPROVED' | 'REJECTED',
	rejectionReason: string,
	updatedByAdmin: boolean,
	values: any
}

export enum SectionType {
    BUSINESS_INFORMATION = 'BUSINESS_INFORMATION',
    DOCUMENTS = 'DOCUMENTS',
    DIRECTORS = 'DIRECTORS',
    SHAREHOLDERS = 'SHAREHOLDERS',
    PARTNERS = 'PARTNERS',
    TRANSFERS = 'TRANSFERS'
}

export interface KycPayment {
	amount: string,
	ccy: string,
	reference: string,
	cancelled: boolean,
	paid: boolean,
	createdDate: string,
	id: string,
	proofOfPayment: string
}

export interface Director {
    directorFirstName: string,
    directorLastName: string,
    directorBirthDate: string,
    directorPep: boolean,
    directorUsCitizen: boolean,
}

export interface Shareholder {
    shareHolderFirstName: string,
    shareHolderLastName: string,
    shareHolderBirthDate: string,
    shareHolderPercentageOfOwnerShip: number,
    shareHolderPOA: string,
    shareHolderPep: boolean,
    shareHolderUsCitizen: boolean,
}

export interface Partner {
    partnerCompanyName: string,
    partnerIncorporationCountry: string,
    plannedNumberOfMonthlyTransactions: number,
    plannedVolumeOfMonthlyTransactons: number,
    partnerType: string,
}

export interface ChangeKYBStatusPayload {
	applicantId: string,
	status: 'REJECTED' | 'APPROVED' | 'WAITING_FOR_PAYMENT',
	final?: boolean,
	rejectionReason?: string,
	amount?: number,
	ccy?: string
}

export interface RiskEvalutionResponse {
	id: number,
	lookupType: string,
	typeDescription: string,
	lookupCode: string,
	codeDescription: string,
	value: string, 
	valueDescription: string,
	score: number,
	approvedBy: string
}
