import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getCards } from '@/features/cards/slice';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';



const Cards = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.cards
	);

	const paginateInitialState: PaginateParams = { orderBy: 'id', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams} = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	
	const statusOptions = [
		{
			key: 'ACTIVE',
			text: 'Active',
			value: 'ACTIVE',
		},
		{
			key: 'ORDERED',
			text: 'Ordered',
			value: 'ORDERED',
		},
		{
			key: 'BLOCKED',
			text: 'Blocked',
			value: 'BLOCKED',
		},
		{
			key: 'UNKNOWN',
			text: 'Unknown',
			value: 'UNKNOWN',
		},
		{
			key: 'REQUESTED',
			text: 'Requested',
			value: 'REQUESTED',
		},
		{
			key: 'PENDING',
			text: 'Pending',
			value: 'PENDING',
		},
		{
			key: 'ORDER_COMPLETED',
			text: 'Order Completed',
			value: 'ORDER_COMPLETED',
		},
		{
			key: 'APPROVED',
			text: 'Approved',
			value: 'APPROVED',
		},
		{
			key: 'REJECTED',
			text: 'Rejected',
			value: 'REJECTED',
		},
		{
			key: 'SUSPENDED',
			text: 'Suspended',
			value: 'SUSPENDED',
		},
		{
			key: 'ACTIVATING',
			text: 'Activating',
			value: 'ACTIVATING',
		},
		{
			key: 'INACTIVE',
			text: 'Inactive',
			value: 'INACTIVE',
		},
		{
			key: 'LOST',
			text: 'Lost',
			value: 'LOST',
		},
		{
			key: 'STOLEN',
			text: 'Stolen',
			value: 'STOLEN',
		},
		{
			key: 'EXPIRED',
			text: 'Expired',
			value: 'EXPIRED',
		}
	];

	const typeOptions = [
		{
			key: '0',
			text: 'Physical',
			value: '0',
		},
		{
			key: 'true',
			text: 'Virtual',
			value: 'true',
		}
	];

	const filter: FilterParam[] = [
		{
			field: 'email',
			label: 'Email',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: statusOptions
		},
		{
			field: 'virtual',
			label: 'Card type',
			type: 'select',
			options: typeOptions
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'name',
			name: 'Card Holder',
			position: 1,
			sortable: true
		},
		{
			field: 'virtual',
			name: 'Type',
			position: 2,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 3,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 4,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 5,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 6,
			sortable: true
		},
		{
			field: 'trackingNumber',
			name: 'Tracking Number',
			position: 7,
			sortable: false
		},
		{
			field: 'email',
			name: 'Email',
			position: 8,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 9,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getCards(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const openCard = (id: number) => {
		history.push(`/card/${id}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={4}>{entry.name}</Table.Cell>
						<Table.Cell width={2}>{entry.virtual ? 'Virtual' : 'Physical'}</Table.Cell>
						<Table.Cell width={2}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balance} />
						</Table.Cell>
						<Table.Cell width={1}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balanceOnHold} />
						</Table.Cell>
						<Table.Cell width={1}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balanceFrozen} />
						</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.status)}</Table.Cell>
						<Table.Cell width={1}>{entry.trackingNumber ?? 'No tracking number'}</Table.Cell>
						<Table.Cell width={2}>{entry.email}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='Open card details' trigger={<Icon className='tableActionIcon' size='large' name="credit card outline" onClick={() => openCard(entry.id)} />} />
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={9}>You dont have cards yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Cards</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default Cards;
