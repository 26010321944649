/* eslint-disable @typescript-eslint/no-unused-vars */
import { showErrorNotification } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRootProcesses } from '@/features/processes/processesSlice';
import Processes from './Processes';
import { Container, Divider, Header, Segment } from 'semantic-ui-react';
import LoadingPage from '@/pages/LoadingPage';

interface Props {
	writePermission: boolean
}

const RootProcesses = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, error, loading } = useSelector(
		(state: RootState) => state.processes
	);


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getRootProcesses());
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [dispatch]);

	useEffect(() => {
		fetchData();
	}, [dispatch, fetchData]);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Root Processes</Header>
				<Divider hidden />
				<Processes writePermission={writePermission} list={list} fetchData={fetchData} loading={loading} />
			</Segment>
		</Container>
	);
};


export default RootProcesses;
