import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Divider, Header, Segment, Table, Button } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import NumberFormat from 'react-number-format';
import { useHistory, withRouter } from 'react-router-dom';
import { getPaymentHistory } from '@/features/payments/historySlice';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import './Payment.css';

interface Props {
	match: {
		params: {
			bankAccountId: number,
		}
	}
}

const PaymentHistory = (props: Props): React.ReactElement => {

	const [bankAccountId] = useState<number>(props.match.params.bankAccountId);
	const history = useHistory();

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.paymentHistory
	);

	const paginateInitialState: PaginateParams = { orderBy: 'wlpId', skip: 0, sort: 'ASC', filter: `bankAccountId=${bankAccountId}`, take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);


	const headers: Array<Headers> = [
		{
			field: 'wlpId',
			name: 'White Label',
			position: 1,
			sortable: false
		},
		{
			field: 'paymentNumber',
			name: 'Payment Number',
			position: 2,
			sortable: false
		},
		{
			field: 'creationDate',
			name: 'Creation Date',
			position: 3,
			sortable: false
		},
		{
			field: 'createdByUser',
			name: 'Created By User',
			position: 4,
			sortable: false
		},
		{
			field: 'description',
			name: 'Description',
			position: 5,
			sortable: false
		},
		{
			field: 'walletId',
			name: 'WalletId',
			position: 6,
			sortable: false
		},
		{
			field: 'operationCode',
			name: 'Operation',
			position: 7,
			sortable: false
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 8,
			sortable: false
		},
		{
			field: 'paymentDate',
			name: 'Payment Date',
			position: 9,
			sortable: false
		},
		{
			field: 'bankReconRef',
			name: 'Reconciliation Ref',
			position: 10,
			sortable: false
		},
		{
			field: 'docNumber',
			name: 'Document Nr',
			position: 11,
			sortable: false
		}
	];

	useEffect(() => {
		dispatch(getPaymentHistory(pagination));
	}, [pagination, dispatch]);



	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.paymentId}${index}`} >
						<Table.Cell width={1}>{entry.paymentNumber}</Table.Cell>
						<Table.Cell width={1}>{entry.wlpId}</Table.Cell>
						<Table.Cell width={1}>{entry.creationDate}</Table.Cell>
						<Table.Cell width={1}>{entry.createdByUser}</Table.Cell>
						<Table.Cell width={1}>{entry.description}</Table.Cell>
						<Table.Cell width={1}>{entry.walletId}</Table.Cell>
						<Table.Cell width={1}>{entry.operationCode}
							<br />{entry.shortDescription}
						</Table.Cell>
						<Table.Cell width={1}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								prefix={entry.currencyCode + ' '}
								value={entry.amount} />
						</Table.Cell>
						<Table.Cell width={1}>{entry.paymentDate}
							<br />{entry.paymentDescription}
						</Table.Cell>
						<Table.Cell width={1}>{entry.bankReconRef}</Table.Cell>
						<Table.Cell width={1}>{entry.docNumber}</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>No data yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid id='payment-history'>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Payment History View</Header></Grid.Column>
						<Grid.Column floated='right'><Button floated='right' primary onClick={() => history.goBack()}>Go back</Button></Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true} />
			</Segment>
		</Container>
	);
};



export default withRouter(PaymentHistory);
