import { PaginateParams } from '@/helpers/globalTypes';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBalanceProcesses } from '@/features/statements/balanceProcessStatusSlice';
import { Grid, } from 'semantic-ui-react';
import { RootState } from '@/rootReducer';
import './BalanceUpdateProcesses.css';
import ProcessLine from './ProcessLine';

interface Props {
	writePermission: boolean,
}
const BalanceUpdateProcesses = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();

	const paginateInitialState: PaginateParams = { orderBy: 'description', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const { list } = useSelector((state: RootState) => state.balanceProcessStatus);
	useEffect(() => {
		dispatch(getBalanceProcesses(paginateInitialState));
	}, []);

	return (
		list?.length > 0 ?
			<Grid celled columns={3} textAlign='center' id='balance-process-update'>
				<Grid.Row className='grid-custom-headers'>
					<Grid.Column>Bank Account</Grid.Column> 
					<Grid.Column>Refresh from</Grid.Column>
					<Grid.Column>Action</Grid.Column>
				</Grid.Row>
				{list.map(p => <ProcessLine key={p.processKey} entry={p} writePermission={writePermission} />)}
			</Grid> :
			<></>
	);
};

export default BalanceUpdateProcesses;
