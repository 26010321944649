import { showErrorNotification } from '@/features/swal/slice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { getVaultTransactions } from '@features/fireblocks/slice';
import { Transaction } from '@features/fireblocks/types';

interface Props {
	vaultId: string,
	assetId: string,
	type: string,
}

const Transactions = ({ vaultId, assetId, type }: Props): React.ReactElement => {

	const dispatch = useDispatch();

	const [loading, setLoading] = useState<boolean>(true);
	const [transactions, setTransactions] = useState<Array<Transaction>>(null);

	const columns: Array<string> = [
		'ID',
		'Amount',
		'Status',
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			setLoading(true);
			try {
				const data = await getVaultTransactions(vaultId, { assetId, type });
				setTransactions(data);
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setLoading(false);
			}
		};
		get();
	}, [dispatch, vaultId, assetId]);

	useEffect(() => {
		fetchData();
	}, [dispatch, vaultId, assetId, fetchData]);

	return (
		<>
			<Dimmer active={loading} inverted>
				<Loader />
			</Dimmer>
			<Table id='table' celled sortable textAlign='center' padded='very' striped>
				<Table.Header fullWidth className='tableHeader'>
					<Table.Row className="tableHeaders">
						{columns.map((column, index) => <Table.HeaderCell
							key={`${column}${index}`}>{column}
						</Table.HeaderCell>)}
					</Table.Row>
				</Table.Header>
				<Table.Body className="tableBody">
					{transactions?.map((entry, index) => {
						return (
							<Table.Row key={`${entry.id}${index}`} >
								<Table.Cell width={2}>{entry.id}</Table.Cell>
								<Table.Cell width={2}>{entry.assetId}
									<NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} />
								</Table.Cell>
								<Table.Cell width={2}>{entry.status}</Table.Cell>
							</Table.Row>
						);
					})}
					{transactions?.length === 0 && <Table.Row>
						<Table.Cell textAlign='center' colSpan={9}>No transactions yet.</Table.Cell>
					</Table.Row>}
				</Table.Body>
			</Table>
		</>
	);
};


export default Transactions;
