import { LookupValueType } from '@/features/fnd/fndTypes';
import { KycAddress } from '@/features/kycIssues/types';
import React, { useState } from 'react';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react';
import KycAddressesModal from './KycAddressesModal';

interface Props {
	type: LookupValueType,
	writeAllowed: boolean,
	userId: number,
	address?: KycAddress,
	countriesOptions?: any,
	onSuccess?: () => void,
}

const KycAddressesLine = ({ type, address, writeAllowed, userId, countriesOptions, onSuccess }: Props): React.ReactElement => {

	const [open, setOpen] = useState<boolean>(false);

	const onFormClose = () => {
		setOpen(false);
	};

	return (<>
		<KycAddressesModal
			userId={userId}
			open={open}
			item={address}
			onClose={onFormClose}
			writeAllowed={writeAllowed}
			type={type}
			countriesOptions={countriesOptions}
			onSuccess={onSuccess} />
		<Grid columns={2}>
			<Grid.Row style={{ alignItems: 'center' }}>
				<Header as='h5' style={{ margin: '0 20px' }}>{type.description}</Header>
				<Popup content={'Edit details'}
					trigger={<Icon className='tableActionIcon'
						size='large' name="edit"
						onClick={() => setOpen(true)} />}
				/>
			</Grid.Row >
		</Grid>
		<Grid celled columns={6} id='balance-tree'>
			<Grid.Row className='grid-custom-headers'>
				<Grid.Column style={{ background: 'transparent' }}>  </Grid.Column>
				<Grid.Column> Country </Grid.Column>
				<Grid.Column> Address Line 1 </Grid.Column>
				<Grid.Column> Address Line 2 </Grid.Column>
				<Grid.Column> Municipality</Grid.Column>
				<Grid.Column> Region</Grid.Column>
			</Grid.Row>
			<Grid.Row  >
				<Grid.Column >
					<Header as='h5'> EN </Header>
				</Grid.Column>
				<Grid.Column> {address?.country} </Grid.Column>
				<Grid.Column> {address?.addressLine1}</Grid.Column>
				<Grid.Column> {address?.addressLine2}</Grid.Column>
				<Grid.Column> {address?.municipality}</Grid.Column>
				<Grid.Column> {address?.region}</Grid.Column>
			</Grid.Row>
			<Grid.Row  >
				<Grid.Column >
					<Header as='h5'> Local </Header>
				</Grid.Column>
				<Grid.Column> {address?.locCountry} </Grid.Column>
				<Grid.Column> {address?.locAddressLine1}</Grid.Column>
				<Grid.Column> {address?.locAddressLine2}</Grid.Column>
				<Grid.Column> {address?.locMunicipality}</Grid.Column>
				<Grid.Column> {address?.locRegion}</Grid.Column>
			</Grid.Row>
			<Grid.Row  >
				<Grid.Column >
					<Header as='h5'> Attributes </Header>
				</Grid.Column>
				<Grid.Column> {address?.attribute1} </Grid.Column>
				<Grid.Column> {address?.attribute2} </Grid.Column>
				<Grid.Column> {address?.attribute3} </Grid.Column>
				<Grid.Column> {address?.attribute4} </Grid.Column>
				<Grid.Column> {address?.attribute5} </Grid.Column>
			</Grid.Row>
		</Grid>
	</>

	);
};


export default KycAddressesLine;
