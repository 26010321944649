/* eslint-disable */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PaginateParams } from '@/helpers/globalTypes';
import request from '@/services/request';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showException } from '../swal/slice';
import { Reconciliation, ReconciliationStore } from './reconciliationTypes';

const initialState: ReconciliationStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0
};

const slice = createSlice({
	name: 'reconciliation',
	initialState,
	reducers: {
		setReconciliation(state, action: PayloadAction<Array<Reconciliation>>) {
			state.list = action.payload || [];
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setReconciliation, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getReconciliations = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const filterParams = {
				filter: payload.filter,
				orderBy: payload.orderBy,
				sort: payload.sort.toUpperCase(),
				skip: payload.skip.toString(),
				take: payload.take
			};
			/* const response = await request.get('api/admin/statements/payment-config', { params: filterParams });
			const { data } = response; */
			const tmp = {
				count: 3,
				list: [{
					id: 10001,
					wlpId: 'COSPAY',
					bankAccountId: 10011,
					batchId: 123,
					transactionId: 23412,
					reconciled: false,
					iban: 'ASDF9204570VSDFS',
					customerName: 'Sun Company',
					amount: 100,
					currencyCode: 'EUR',
					reconciledAmount: 50
				},
				{
					id: 10002,
					wlpId: 'DTS',
					bankAccountId: 10056,
					batchId: 444,
					transactionId: 1,
					reconciled: true,
					iban: 'LVLVLVF9204570VSDFS',
					customerName: 'Moon Company',
					amount: 200,
					currencyCode: 'EUR',
					reconciledAmount: 40
				},
				{
					id: 10003,
					wlpId: 'DTS',
					bankAccountId: 10056,
					batchId: 444,
					transactionId: 1,
					reconciled: true,
					iban: 'LVLVLVF9204570VSDFS',
					customerName: 'Moon Company',
					amount: 200,
					currencyCode: 'EUR',
					reconciledAmount: 0
				}]
			};
			const data = tmp;

			dispatch(setReconciliation(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			dispatch(setLoading(false));
			showException(e);
		}
	};
};

export default slice.reducer;
