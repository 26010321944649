import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { _TokenRequest } from './TokenRequests';


interface Props {
	open: boolean;
	onClose: (fromAmount?: number, toAmount?: number, expirationInHours?: number, requestId?: number) => void,
	tokenRequest: _TokenRequest
}

const ConfirmRequestModal = ({ open, onClose, tokenRequest }: Props): React.ReactElement => {

	const initialValues = {
		amount: tokenRequest? tokenRequest.direction === 'BUY' ? tokenRequest.tokenRequest.toAmount : tokenRequest.tokenRequest.fromAmount : '',
		price: tokenRequest?.tokenPrice??'',
		expirationInHours: 1
	};

	const validationSchema = Yup.object({
		amount: Yup.number()
			.required('You must enter an amount'),
		price: Yup.string()
			.required('You must enter a price'),
	});

	const expirationOptions = [
		{
			key: 1,
			value: 1,
			text: '1h'
		},
		{
			key: 3,
			value: 3,
			text: '3h'
		},
		{
			key: 6,
			value: 6,
			text: '6h'
		},
		{
			key: 12,
			value: 12,
			text: '12h'
		},
	];

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { amount, price, expirationInHours } = formData;

		const fromAmount = tokenRequest.direction === 'SELL' ? amount : amount * price;
		const toAmount = tokenRequest.direction === 'BUY' ? amount : amount * price;
		onClose(fromAmount, toAmount, expirationInHours, tokenRequest.tokenRequest.id);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting }) => (
					<Form
						size="large">
						<Header content={`Confirm ${tokenRequest.direction === 'BUY' ? tokenRequest.tokenRequest.toCcy : tokenRequest.tokenRequest.fromCcy} request`} />

						<Input
							label='Amount'
							fluid
							name="amount"
							type='number'
							disabled
						/>
						<Input
							label='Price'
							fluid
							name="price"
							type='number'
						/>
						<Select
							label='Expiration in hours'
							fluid
							name="expirationInHours"
							options={expirationOptions}
						/>
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors)}
										primary size="large" type="submit"
										floated='right' >
										Confirm
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default ConfirmRequestModal;
