import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Divider,
	Grid, Header,
	Modal
} from 'semantic-ui-react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

interface Props {
	open: boolean;
	onClose: (fromWalletId?: string, toWalletId?: string, amount?: number, reference?: string) => void,
}

const InternalTransferModal = ({ open, onClose }: Props): React.ReactElement => {
	const { permissions } = useSelector(
		(state: RootState) => state.user
	);
	// const vaults = useSelector((state: RootState) => state.vaultProcesses.list);




	const validationSchema = Yup.object({
		amount: Yup.number().required('Amount is required').min(0, 'Fee min value is 0'),
		reference: Yup.string().required('Reference is required').min(6, 'Reference min characters count is 6'),
		toWalletId: Yup.string().required('Reference is required'),
		fromWalletId: Yup.string().required('Reference is required'),
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const {
			amount,
			reference,
			toWalletId,
			fromWalletId
		} = formData;

		onClose(fromWalletId, toWalletId, amount, reference);
	};


	return (<Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={{
					amount: '0.00',
					reference: '',
					toWalletId: '',
					fromWalletId: '',
				}}
				onSubmit={submit}
				validationSchema={validationSchema}
				render={({ setFieldValue, isSubmitting, errors }) => {
					return (
						<Form>
							<Header content="Internal system transfer" />
							<Header as='h5' style={{ fontWeight: 400 }}>Internal system transfer allowed only to system accounts and between accounts with the same integration.</Header>
							<Input
								label='From wallet ID'
								fluid
								errorPrompt
								name="fromWalletId"
							/>
							<Input
								label='To wallet ID'
								fluid
								errorPrompt
								name="toWalletId"
							/>
							<Input
								label='Amount'
								fluid
								errorPrompt
								name="amount"
								type='number'
							/>
							<TextArea
								label='Reference'
								name="reference"
								errorPrompt
								fluid
							/>

							<Divider hidden />
							<Grid columns={2}>
								<Grid.Row centered>
									<Grid.Column>
										<SubmitButton primary
											type="submit"
											loading={isSubmitting}
											disabled={!checkWritePermission(permissions, PermissionType.INTERNAL_SYSTEM_TRANSFERS) || isSubmitting || !isEmpty(errors)}
											floated='right'>
											Execute
										</SubmitButton>
									</Grid.Column>
									<Grid.Column>
										<Button floated='left' secondary content='Discard' onClick={() => onClose()} />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					);
				}}
			/>

		</Modal.Content>
	</Modal>);
};

export default InternalTransferModal;
