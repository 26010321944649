import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getClients } from '@/features/clients/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { UserType } from '@/features/user/types';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router';
import { formatDateWithPattern } from '@/helpers/date';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';


const BusinessClients = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.clients
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: `type=${UserType.BUSINESS}`, take: 25 };
	const { urlPaginateParams} = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const history = useHistory();

	const businessFilter: FilterParam[] = [
		{
			field: 'email',
			label: 'Email',
			type: 'input'
		},
		{
			field: 'mobileNumber',
			label: 'Phone number',
			type: 'input'
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'companyName',
			label: 'Company Name',
			type: 'input'
		},
		{
			field: 'companyRegNumber',
			label: 'Company Reg Number',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'clientId',
			name: 'Client ID',
			position: 1,
			sortable: true
		},
		{
			field: 'email',
			name: 'Email',
			position: 2,
			sortable: true
		},
		{
			field: 'companyName',
			name: 'Company Name',
			position: 3,
			sortable: true
		},
		{
			field: 'companyRegNumber',
			name: 'Company Reg Number',
			position: 4,
			sortable: true
		},
		{
			field: 'kycTier',
			name: 'KYC tier',
			position: 5,
			sortable: true
		},
		{
			field: 'createdDate',
			name: 'Registered Date',
			position: 6,
			sortable: true
		},
		{
			field: 'confirmedMobileNumber',
			name: 'Confirmed phone number',
			position: 7,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 8,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getClients(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.clientId}${entry.email}${index}`} >
						<Table.Cell width={1}>{entry.clientId}</Table.Cell>
						<Table.Cell width={3}>{entry.email}</Table.Cell>
						<Table.Cell width={3}>{entry.companyName}</Table.Cell>
						<Table.Cell width={3}>{entry.companyRegNumber}</Table.Cell>
						<Table.Cell width={2}>{entry.kycTier === 0 ? 'Unverified' : 'Verified'}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.createdDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>{entry.confirmedMobileNumber}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='View client details' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.id)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>You dont have clients yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Business Clients</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={businessFilter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default BusinessClients;
