
import { LookupValueType } from '@/features/fnd/fndTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionContent, AccordionTitle, Icon, Segment } from 'semantic-ui-react';
import KycAddressesLine from './KycAddressesLine';
import { KycAddress } from '@/features/kycIssues/types';
import { getKycAddressList } from '@/features/kycIssues/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { getFndLookupsValues } from '@/features/fnd/fndSlice';
import { isEmpty } from 'lodash';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import { PermissionType } from '@/features/user/types';
import { useSelector } from 'react-redux';

interface Props {
	userId: number,
	countriesOptions?: any,
}
const KycAddresses = ({ userId, countriesOptions }: Props): React.ReactElement => {

	const [types, setTypes] = useState<LookupValueType[]>([]);
	const [active, setActive] = useState<boolean>(false);
	const [kycAddresses, setKycAddresses] = useState<KycAddress[]>([]);
	const [loading, setLoading] = useState(false);
	const { permissions } = useSelector((state: RootState) => state.user);
	const writeAllowed = checkWritePermission(permissions, PermissionType.POA_COMPLIANCE);

	const handleClick = () => {
		setActive(prev => !prev);
	};

	const fetchTypes = useCallback(() => {
		const get = async () => {
			try {
				const data = await getFndLookupsValues('KYC_IDENTITY_ADR_TYPE', 'EN');
				setTypes(data);
			} catch (e) {
				setTypes(null);
				showErrorNotification(e);
			}
		};
		get();
	}, []);

	useEffect(() => {
		fetchTypes();
	}, [fetchTypes]);

	const fetchAddresses = useCallback(() => {
		const get = async () => {
			try {
				setLoading(true);
				const data = await getKycAddressList(userId);
				setKycAddresses(data?.list || []);
			} catch (e) {
				setTypes(null);
				showErrorNotification(e);
			}
			finally { setLoading(false); }
		};
		get();
	}, []);

	useEffect(() => {
		fetchTypes();
	}, [fetchTypes]);


	useEffect(() => {
		fetchAddresses();
	}, [fetchAddresses]);



	return (<Accordion fluid styled style={{ marginBottom: 5 }} >
		<AccordionTitle
			active={active}
			index={1}
			onClick={handleClick} >
			KYC Addresses
			<Icon name={active ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
		</AccordionTitle>
		<AccordionContent active={active}>
			<Segment>
				{!isEmpty(types) && !loading && types.map(type => {
					const address = kycAddresses?.length > 0 ? kycAddresses.find(p => p.addressType === type.lookupCode) : null;  

					return (<KycAddressesLine
						key={type.lookupValuesId}
						countriesOptions={countriesOptions}
						address={address}
						userId={userId}
						type={type}
						writeAllowed={writeAllowed}
						onSuccess={fetchAddresses} />);
				})}
			</Segment>

		</AccordionContent>
	</Accordion>
	);
};


export default KycAddresses;
