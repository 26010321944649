/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '../swal/slice';
import { Statistics, StatisticsStore } from './statisticsTypes';

const initialState: StatisticsStore = {
	loading: false,
	statistics: null,
	error: null,
};


const slice = createSlice({
	name: 'statistics',
	initialState,
	reducers: {
		setStatistics(state, action: PayloadAction<Statistics>) {
			state.statistics = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setRisks(state, action: PayloadAction<number>) {
			state.pendingRiskApprovals = action.payload;
		},
	}
});

export const { setStatistics, setLoading, setError, setRisks } = slice.actions;


export const getStatistics = (): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/dashboard/'); 
			const riskResponse = await request.get('/api/admin/dashboard/pending/risks');
			const { data } = response;
			dispatch(setStatistics(data));
			dispatch(setLoading(false));
			dispatch(setRisks(riskResponse?.data?.count));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
		finally {
			dispatch(setLoading(false));
		}
	};
};


export const getPendingRiskApprovals = async () => {
	const response = await request.put('/api/admin/dashboard/pending/risks');
	const { data } = response;
	return data;
};


export default slice.reducer;
