import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getSystemClients } from '@/features/clients/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import { formatDateWithPattern } from '@/helpers/date';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';



const SystemUsers = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.clients
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams} = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const disabledOptions = [
		{
			key: '0',
			text: 'Active',
			value: '0',
		},
		{
			key: '1',
			text: 'Disabled',
			value: '1',
		},
	];

	const indivudalFilter: FilterParam[] = [
		{
			field: 'email',
			label: 'Email',
			type: 'input'
		},
		{
			field: 'mobileNumber',
			label: 'Phone number',
			type: 'input'
		},
		{
			field: 'firstName',
			label: 'First Name',
			type: 'input'
		},
		{
			field: 'lastName',
			label: 'Last Name',
			type: 'input'
		},
		{
			field: 'disabled',
			label: 'Disabled',
			type: 'select',
			options: disabledOptions
		},
	];

	const headers: Array<Headers> = [
		{
			field: 'email',
			name: 'Email',
			position: 1,
			sortable: true
		},
		{
			field: 'firstName',
			name: 'First Name',
			position: 2,
			sortable: true
		},
		{
			field: 'lastName',
			name: 'Last Name',
			position: 3,
			sortable: true
		},
		{
			field: 'type',
			name: 'Type',
			position: 4,
			sortable: true
		},
		{
			field: 'disabled',
			name: 'Disabled',
			position: 5,
			sortable: true
		},
		{
			field: 'lastLoginDate',
			name: 'Last Login Date',
			position: 6,
			sortable: true
		},
		{
			field: 'createdDate',
			name: 'Registered Date',
			position: 7,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 8,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getSystemClients(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.clientId}${entry.email}${index}`} >
						<Table.Cell width={3}>{entry.email}</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.firstName)}</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.lastName)}</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.type)}</Table.Cell>
						<Table.Cell width={1}>{entry.disabled ? 'Disabled' : 'Active'}</Table.Cell>
						<Table.Cell width={2}>{entry.lastLoginDate ? formatDateWithPattern(entry.lastLoginDate, 'dd/MM/yyyy HH:mm:ss') : 'Not logged in'}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.createdDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='View client details' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.id)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>You dont have system users yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>System Users</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={indivudalFilter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default SystemUsers;
