import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { changeLimit, getCountryLimits } from '@/features/countryLimits/countryLimitsSlice';
import { CountryLimit } from '@/features/countryLimits/countryLimitsTypes';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';

interface Props {
	writePermission: boolean,
}

const CountryLimits = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.countryLimits
	);

	const paginateInitialState: PaginateParams = { orderBy: 'country', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);


	const filter: FilterParam[] = [
		{
			field: 'countryCode',
			label: 'Country Code',
			type: 'input'
		},
		{
			field: 'country',
			label: 'Country',
			type: 'input'
		},

	];

	const headers: Array<Headers> = [
		{
			field: 'countryCode',
			name: 'Country code',
			position: 1,
			sortable: true
		},
		{
			field: 'country',
			name: 'Country',
			position: 2,
			sortable: true
		},
		{
			field: 'iban',
			name: 'IBAN',
			position: 3,
			sortable: false
		},
		{
			field: 'physicalCard',
			name: 'Physical card',
			position: 4,
			sortable: false
		},
		{
			field: 'virtualCard',
			name: 'Virtual card',
			position: 5,
			sortable: false
		},
		{
			field: 'trust',
			name: 'Trust',
			position: 6,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getCountryLimits(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);


	const changeCountryLimit = async (countryLimit: CountryLimit, type: 'iban' | 'vcard' | 'pcard' | 'trust') => {
		const payload: CountryLimit = {
			country: countryLimit.country,
			countryCode: countryLimit.countryCode,
			iban: countryLimit.iban,
			trust: countryLimit.trust,
			virtualCard: countryLimit.virtualCard,
			physicalCard: countryLimit.physicalCard,
			id: countryLimit.id
		};

		switch (type) {
		case 'iban':
			payload.iban = !countryLimit.iban;
			break;
		case 'vcard':
			payload.virtualCard = !countryLimit.virtualCard;
			break;
		case 'pcard':
			payload.physicalCard = !countryLimit.physicalCard;
			break;
		case 'trust':
			payload.trust = !countryLimit.trust;
			break;
		}
		try {
			await changeLimit(payload);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.countryCode}</Table.Cell>
						<Table.Cell width={2}>{entry.country}</Table.Cell>
						<Table.Cell width={2}>
							{entry.iban &&
								<ComponentWithConfirmation onConfirm={() => changeCountryLimit(entry, 'iban')} confirmContent={'Are you sure you want disable IBAN?'}>
									<Icon disabled={!writePermission} className='tableActionIcon' color='green' size='large' name="check circle" />
								</ComponentWithConfirmation>}
							{!entry.iban && <ComponentWithConfirmation onConfirm={() => changeCountryLimit(entry, 'iban')} confirmContent={'Are you sure you want enable IBAN?'}>
								<Icon disabled={!writePermission} className='tableActionIcon' color='red' size='large' name="times circle" />
							</ComponentWithConfirmation>}
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.physicalCard &&
								<ComponentWithConfirmation onConfirm={() => changeCountryLimit(entry, 'pcard')} confirmContent={'Are you sure you want disable physical card?'}>
									<Icon disabled={!writePermission} className='tableActionIcon' color='green' size='large' name="check circle" />
								</ComponentWithConfirmation>}
							{!entry.physicalCard && <ComponentWithConfirmation onConfirm={() => changeCountryLimit(entry, 'pcard')} confirmContent={'Are you sure you want enable physical card?'}>
								<Icon disabled={!writePermission} className='tableActionIcon' color='red' size='large' name="times circle" />
							</ComponentWithConfirmation>}
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.virtualCard &&
								<ComponentWithConfirmation onConfirm={() => changeCountryLimit(entry, 'vcard')} confirmContent={'Are you sure you want disable virtual card?'}>
									<Icon disabled={!writePermission} className='tableActionIcon' color='green' size='large' name="check circle" />
								</ComponentWithConfirmation>}
							{!entry.virtualCard && <ComponentWithConfirmation onConfirm={() => changeCountryLimit(entry, 'vcard')} confirmContent={'Are you sure you want enable virtual card?'}>
								<Icon disabled={!writePermission} className='tableActionIcon' color='red' size='large' name="times circle" />
							</ComponentWithConfirmation>}
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.trust &&
								<ComponentWithConfirmation onConfirm={() => changeCountryLimit(entry, 'trust')} confirmContent={'Are you sure you want disable trust?'}>
									<Icon disabled={!writePermission} className='tableActionIcon' color='green' size='large' name="check circle" />
								</ComponentWithConfirmation>}
							{!entry.trust && <ComponentWithConfirmation onConfirm={() => changeCountryLimit(entry, 'trust')} confirmContent={'Are you sure you want enable trust?'}>
								<Icon disabled={!writePermission} className='tableActionIcon' color='red' size='large' name="times circle" />
							</ComponentWithConfirmation>}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>You dont have documents yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Country limits</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Segment>
		</Container>
	);
};


export default CountryLimits;
