import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getAccounts, postApplyCommission, postChangeCommissionDate, postInternalSystemTransfer, postUpdateBalance } from '@/features/accounts/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { getPaginationToUrl, Headers, getDefaultFilterUpdates } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import UpdateBalance from './UpdateBalance';
import { Account, DepositRequest } from '@/features/accounts/types';
import NumberFormat from 'react-number-format';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { formatDateWithPattern } from '@/helpers/date';
import ChangeCommDate from './ChangeCommDate';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import Omnibus from '@/components/Dashboard/Fireblocks/Omnibus';
import { VaultAsset } from '@features/fireblocks/types';
import VaultDepositModal from '@/components/Dashboard/Vaults/VaultDepositModal';
import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import InternalTransferModal from './InternalTransferModal';

interface Props {
	type: string,
	writePermission: boolean,
	take?: 5 | 10 | 25 | 50
}

const Accounts = ({ type, writePermission, take }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.accounts
	);

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const paginateInitialState: PaginateParams = { orderBy: 'balance', sort: 'DESC', skip: 0, filter: '', take: take ? take : 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [updateBalance, setUpdateBalance] = useState<boolean>(false);
	const [withdrawal, setWithdrawal] = useState<boolean>(false);
	const [currentAccount, setCurrentAccount] = useState<Account>(null);
	const [changeCommDate, setChangeCommDate] = useState<boolean>(false);
	const [openVaultDepositModal, setOpenVaultDepositModal] = useState<boolean>(false);
	const [openInternalTransferModal, setOpenInternalTransferModal] = useState<boolean>(false);

	const integrationOptions = [
		{
			key: 'BANKINGCIRCLE',
			text: 'Bankingcircle',
			value: 'BANKINGCIRCLE',
		},
		{
			key: 'CAMBRIDGE',
			text: 'Cambridge',
			value: 'CAMBRIDGE',
		},
		{
			key: 'CLEARJUNCTION_POOL',
			text: 'Clearjunction',
			value: 'CLEARJUNCTION_POOL',
		},
		{
			key: 'RAILSBANK',
			text: 'Railsbank',
			value: 'RAILSBANK',
		},
		{
			key: 'TRUST',
			text: 'Trust',
			value: 'TRUST',
		},
		{
			key: 'FIREBLOCKS',
			text: 'Fireblocks',
			value: 'FIREBLOCKS',
		},
	];

	const statusOptions = [
		{
			key: 'ACTIVE',
			text: 'Active',
			value: 'ACTIVE',
		},
		{
			key: 'ORDERED',
			text: 'Ordered',
			value: 'ORDERED',
		},
		{
			key: 'BLOCKED',
			text: 'Blocked',
			value: 'BLOCKED',
		},
		{
			key: 'DELETED',
			text: 'Deleted',
			value: 'DELETED',
		},
	];

	const filter: FilterParam[] = [
		{
			field: 'walletId',
			label: 'Wallet ID',
			type: 'input'
		},
		...(type === 'TRUST'
			? [
				{
					field: 'label',
					label: 'Label',
					type: 'input' as const // Specify the type as 'input'
				}
			]
			: []
		),
		{
			field: 'integration',
			label: 'Integration',
			type: 'select',
			options: integrationOptions
		},
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'currency',
			label: 'Currency',
			type: 'input'
		},
		{
			field: 'email',
			label: 'Email',
			type: 'input',
		},
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: statusOptions
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Wallet ID',
			position: 1,
			sortable: true
		},
		{
			field: 'email',
			name: 'Email',
			position: 2,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 3,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 4,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 5,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 6,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 7,
			sortable: true
		},
		{
			field: 'monthCommDate',
			name: 'Commission date',
			position: 8,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 9,
			sortable: false
		}
	];

	const trustHeaders: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Wallet ID',
			position: 1,
			sortable: true
		},
		{
			field: 'label',
			name: 'Label',
			position: 2,
			sortable: true
		},
		{
			field: 'email',
			name: 'Email',
			position: 3,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 4,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 5,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 6,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 7,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 8,
			sortable: true
		},
		{
			field: 'monthCommDate',
			name: 'Commission date',
			position: 9,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 10,
			sortable: false
		}
	];

	const cryptoHeaders: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Wallet ID',
			position: 1,
			sortable: true
		},
		{
			field: 'email',
			name: 'Email',
			position: 2,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 3,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 4,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 5,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 6,
			sortable: true
		},
		{
			field: 'vaultBalance',
			name: 'Vault Balance',
			position: 7,
			sortable: false
		},
		{
			field: 'status',
			name: 'Status',
			position: 8,
			sortable: true
		},
		{
			field: 'monthCommDate',
			name: 'Commission date',
			position: 9,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 10,
			sortable: false
		}
	];

	const tablesHeaders = type === 'CRYPTO'
		? cryptoHeaders
		: type === 'TRUST'
			? trustHeaders
			: headers;


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getAccounts(type, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch, type]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, type, fetchData]);

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const openAccount = (accountId: number) => {
		history.push(`/account/${accountId}`);
	};
	const openVault = (vaultId: string) => {
		history.push(`/fireblocks/vault/${vaultId}`);
	};

	const handleVaultDepositModal = async (account: Account) => {
		setCurrentAccount(account);
		setOpenVaultDepositModal(true);
	};

	const handleBalanceUpdate = (account: Account, withdrawal: boolean) => {
		setWithdrawal(withdrawal);
		setCurrentAccount(account);
		setUpdateBalance(true);
	};

	const onCloseUpdateBalanceModal = async (amount?: number, details?: string) => {
		setUpdateBalance(false);
		if (amount && details) {
			try {
				const formData: DepositRequest = {
					accountId: currentAccount.id,
					userId: currentAccount.userId,
					ccy: currentAccount.currency,
					amount: amount,
					details: details,
				};
				await postUpdateBalance(formData, withdrawal ? 'MANUAL_WITHDRAWAL' : 'MANUAL_DEPOSIT');
				showSuccessNotification(withdrawal ? 'Balance withdrew!' : 'Balance added!');
				fetchData();
			}
			catch (e) {
				showErrorNotification(e);
			}
		}
		setCurrentAccount(null);
	};

	const applyCommission = async (accountId: number) => {
		try {
			await postApplyCommission(accountId);
			showSuccessNotification('Commission applied');
			fetchData();
		} catch (err) {
			showErrorNotification(err);
		}
	};

	const handleCommDateChange = (account: Account) => {
		setCurrentAccount(account);
		setChangeCommDate(true);
	};

	const onCloseChangeCommonDate = async (commDate: number) => {
		setChangeCommDate(false);
		if (commDate) {
			try {
				await postChangeCommissionDate(currentAccount.id, commDate);
				showSuccessNotification('Commission date changed');
				fetchData();
			} catch (err) {
				showErrorNotification(err);
			}
		}

		setCurrentAccount(null);
	};

	const cryptoVaults = (vaults) => {
		return (
			<>
				{vaults?.map((entry, index) => {
					return (
						<div key={index}>{`${entry?.asset} ${entry?.balance}`}</div>
					);
				})}
			</>
		);
	};

	const filterByCurrency = (asset: VaultAsset) => {
		const paginationState = { ...pagination };

		paginationState.filter = getDefaultFilterUpdates(paginationState.filter, `currency=${asset.id}`);
		paginationState.skip = 0;

		setPagination(paginationState);
		getPaginationToUrl(paginationState, location.pathname);
		history.push(getPaginationToUrl(paginationState, location.pathname));
	};


	const handleInternalTransferModal = async (fromWalletId?: string, toWalletId?: string, amount?: number, reference?: string) => {
		if (fromWalletId && toWalletId && amount && reference) {
			try {
				await postInternalSystemTransfer(fromWalletId, toWalletId, amount, reference);
				showSuccessNotification('Internal transfer completed');
				fetchData();
			} catch (err) {
				showErrorNotification('Internal transfer failed, make sure that you have entered the correct data.');
			}
		}

		setOpenInternalTransferModal(false);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			<UpdateBalance
				onClose={onCloseUpdateBalanceModal}
				open={updateBalance}
				withdrawal={withdrawal}
			/>
			<ChangeCommDate
				onClose={onCloseChangeCommonDate}
				open={changeCommDate}
			/>
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.walletId}${index}`} >
						<Table.Cell width={1}>{entry.walletId}</Table.Cell>
						{type === 'TRUST' && <Table.Cell width={2}>{entry.label}</Table.Cell>}
						<Table.Cell width={2}>{entry.email}</Table.Cell>
						<Table.Cell width={1}>{capitalizeFirstLetter(entry.integration)}</Table.Cell>
						<Table.Cell width={1}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balance} />
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balanceOnHold} />
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balanceFrozen} />
						</Table.Cell>
						{type === 'CRYPTO' && <Table.Cell width={2}>{cryptoVaults(entry.cryptoVaults)}</Table.Cell>}
						<Table.Cell width={1}>{capitalizeFirstLetter(entry.status)}</Table.Cell>
						<Table.Cell width={1}>{formatDateWithPattern(entry.monthCommDate, 'dd/MM/yyyy')}

							<Popup content='Change commission date' trigger={<Icon style={{ marginLeft: '0.5em' }} className='tableActionIcon' size='large' name="edit" onClick={() => handleCommDateChange(entry)} />} />
							{new Date(entry.monthCommDate).getTime() < new Date().getTime() && <ComponentWithConfirmation popup='Apply commission' onConfirm={() => applyCommission(entry.id)} confirmContent={'Are you sure you want apply comission for this account?'}>
								<Icon className='tableActionIcon' size='large' name="play circle outline" onClick={() => applyCommission(entry.id)} />
							</ComponentWithConfirmation>}
						</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='Open account details' trigger={<Icon className='tableActionIcon' size='large' name="money bill alternate outline" onClick={() => openAccount(entry.id)} />} />
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
							{type === 'TRUST' && <Popup content='Update balance' trigger={<Icon className='tableActionIcon' size='large' name="plus" disabled={!writePermission} onClick={() => handleBalanceUpdate(entry, false)} />} />}
							{type === 'TRUST' && <Popup content='Withdraw balance' trigger={<Icon className='tableActionIcon' size='large' name="minus" disabled={!writePermission} onClick={() => handleBalanceUpdate(entry, true)} />} />}
							{type === 'CRYPTO' && entry.externalId && <Popup content='Open vault' trigger={<Icon className='tableActionIcon' size='large' name="external alternate" onClick={() => openVault(entry.externalId)} />} />}
							{type === 'TOKEN' && entry.customToken && <Popup content='DEPOSIT' trigger={<Icon className='tableActionIcon' size='large' name="file alternate" onClick={() => handleVaultDepositModal(entry)} />} />}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={9}>You dont have accounts yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			{type === 'TOKEN' && <VaultDepositModal
				account={currentAccount}
				open={openVaultDepositModal}
				onClose={() => { setOpenVaultDepositModal(false); fetchData(); }}
			/>}
			{type === 'CRYPTO' && <Omnibus assetOnClick={filterByCurrency} />}
			<InternalTransferModal
				onClose={handleInternalTransferModal}
				open={openInternalTransferModal}
			/>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>{capitalizeFirstLetter(type)} Accounts</Header></Grid.Column>
						<Grid.Column>
							<Button
								floated='right'
								primary
								disabled={!checkWritePermission(permissions, PermissionType.INTERNAL_SYSTEM_TRANSFERS)}
								onClick={() => setOpenInternalTransferModal(true)}
							>
								Internal transfer</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={tablesHeaders}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default Accounts;
