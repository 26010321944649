import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { showErrorNotification } from '../swal/slice';
import { DocumentsStore, Document } from './types';
import { ManualAdjustFeePayload } from '@features/documents/types';

const initialState: DocumentsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'documents',
	initialState,
	reducers: {
		setDocuments(state, action: PayloadAction<Array<Document>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setDocuments, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getDocuments = (type: string, payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get(`/api/admin/documents/list/${type}/filtered`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setDocuments(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getDocument = async (documentNumber: string) => {
	const response = await request.get(`/api/admin/documents/${documentNumber}`);
	const { data } = response;
	return data;
};

export const rejectDocument = async (documentNumber: string, type: string) => {
	const response = await request.post('/api/admin/documents/reject', { documentNumber, type });
	const { data } = response;
	return data;
};

export const approveDocument = async (documentNumber: string, type: string) => {
	const response = await request.post('/api/admin/documents/approve', { documentNumber, type });
	const { data } = response;
	return data;
};


export const downloadAttachment = async (key: string, name: string) => {
	const response = await request.get(`/api/admin/documents/attachment/${key}?name=${name}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};

export const getWalletTransactionTypeFees = async (type: string) => {
	const response = await request.get(`/api/admin/documents/adjustment/walletTransactionTypeFees/${type}`);
	const { data } = response;
	return data;
};

export const getDocumentTransactionLines = async (type: string, documentNr: string) => {
	const response = await request.get(`/api/admin/documents/adjustment/getDocumentTransactionLines/${type}/${documentNr}`);
	const { data } = response;
	return data;
};

export const postOperations = async (userId: number, operation: string, payload: ManualAdjustFeePayload): Promise<any> => {
	const response = await request.post('/api/admin/documents/operations', { userId, operation, data: payload });
	const { data } = response;
	return data;
};


export default slice.reducer;
