import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { IntegrationsUnassignedPayment } from '@/features/integrationsUnassignedPayments/integrationsUnassignedPaymentsTypes';
import { getAccountByWalletId } from '@/features/accounts/slice';
import { Account } from '@/features/accounts/types';


interface Props {
	open: boolean;
	onClose: (account?: Account) => void,
	unassignedPayment: IntegrationsUnassignedPayment,
}

const IntegrationsUnassingedPaymentModal = ({ open, onClose, unassignedPayment }: Props): React.ReactElement => {

	const initialValues = {
		walletId: unassignedPayment?.suggestedPaymentDetails ?? ''
	};

	const validationSchema = Yup.object({
		walletId: Yup.string()
			.required('Enter wallet ID')
	});

	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		const { walletId } = formData;
		try {
			const accountDetails = await getAccountByWalletId(walletId);

			if (accountDetails.type !== 'WALLET') {
				setFieldError('walletId', 'Enter Crypto account’s wallet ID');
				return;
			}

			if (accountDetails.currency !== unassignedPayment.ccy) {
				setFieldError('walletId', 'Wrong wallet currency');
				return;
			}


			onClose(accountDetails);
		}
		catch (e) {
			setFieldError('walletId', 'Wallet Id not found.');

		}

	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting }) => (
					<Form
						size="large">
						<Header content="Match payment" />

						<Input
							label='Wallet ID'
							name="walletId"
							errorPrompt
						/>
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors)}
										primary size="large" type="submit"
										floated='right' >
										Match
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default IntegrationsUnassingedPaymentModal;
