import React from 'react';
import { StatementAccountBalance } from '@/features/statements/statementsTypes';
import { Accordion, AccordionContent, AccordionTitle, Grid } from 'semantic-ui-react';
import BalanceLine from './BalanceLine';
import './BalanceTree.css';

interface LevelItemProps {
	levelBalancies: StatementAccountBalance[],
	allBalancies: StatementAccountBalance[],
	activeItems: string[],
	handleClick: (item: StatementAccountBalance) => void
}

const LevelItem = ({ levelBalancies, allBalancies, activeItems, handleClick }: LevelItemProps) => {

	return (
		<>
			{levelBalancies.map((item) => {
				const itemKey = item.viewPk;
				const itemHierarchy = item.hierarchyAccountType;
				const nextLevel = allBalancies.filter(p => p.parentAccountId === item.bankAccountId && p.periodName === item.periodName) || [];

				const isActive = activeItems.includes(itemKey);
				return (
					itemHierarchy === 'CHILD' ?
						<Grid divided
							columns={6}
							key={itemKey}
							className='balance-row'  >
							<BalanceLine balance={item} />
						</Grid> :
						<Accordion key={itemKey} fluid styled className='balance-accordion'>
							<AccordionTitle
								active={isActive}
								index={itemKey}
								onClick={() => handleClick(item)} 	>
								<Grid divided>
									<BalanceLine balance={item} active={isActive} showShevron={true} />
								</Grid >
							</AccordionTitle>
							{nextLevel.length > 0 &&
								<AccordionContent
									className='balance-content'
									active={isActive} >
									<LevelItem
										allBalancies={allBalancies}
										levelBalancies={nextLevel}
										activeItems={activeItems}
										handleClick={handleClick}
									/>
								</AccordionContent>
							}
						</Accordion>);
			})}
		</>);
};


interface BalanceTreerops {
	allBalancies: StatementAccountBalance[],
	activeItems: string[],
	handleClick: (item: StatementAccountBalance) => void
}


const BalanceTree = ({ allBalancies, activeItems, handleClick }: BalanceTreerops) => {

	const minLevel = Math.min(...allBalancies.map(p => p.hierarchyLevel));

	const firtLevel = allBalancies.filter(p => p.hierarchyLevel === minLevel);

	return (<>
		<Grid id='balance-tree' celled className='break-cell-content'>
			<Grid.Row className='grid-custom-headers' >
				<Grid.Column width={5}>Description</Grid.Column>
				<Grid.Column width={2}>Begin Balance</Grid.Column>
				<Grid.Column width={2}>End Balance</Grid.Column>
				<Grid.Column width={2}>Period DR</Grid.Column>
				<Grid.Column width={2}>Period CR</Grid.Column>
				<Grid.Column width={3}>Actions</Grid.Column>
			</Grid.Row >
			< LevelItem
				levelBalancies={firtLevel}
				allBalancies={allBalancies}
				activeItems={activeItems}
				handleClick={handleClick}
			/>
			{allBalancies.length === 0 &&
				<Grid.Row >
					<Grid.Column textAlign='center' colSpan={5}>No Balancies yet.</Grid.Column>
				</Grid.Row >
			}
		</Grid>
	</>
	);
};


export default BalanceTree;


