import React, { useEffect, useState } from 'react';
import { Grid, Divider, Header, Button, Modal } from 'semantic-ui-react';
import { Form, Input, Select, SubmitButton, TextArea, Checkbox } from 'formik-semantic-ui-react';
import { PaymentConfigurationType } from '@/features/payments/paymentTypes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useWhiteLabelLov from '@/helpers/customHook/useWhiteLabelLov';
import useBankAccountLov from '@/helpers/customHook/useBankAccountLov';
import { GlLookupTypes } from '@/features/statements/statementsTypes';
import useGLLov from '@/helpers/customHook/useGLLov';
import useBankLov from '@/helpers/customHook/useBankLov';
import { getNames } from 'i18n-iso-countries';
import { showErrorNotification } from '@/features/swal/slice';
import { postPaymentConfig, putPaymentConfig } from '@/features/payments/configSlice';
import useSystemPaymentAccountLov from '@/helpers/customHook/useSystemPaymentAccountLov'; 

export enum ModalMode { EDIT = 'Edit', ADD = 'Add' }

interface Props {
	open: boolean;
	modalMode: ModalMode,
	onClose: () => void,
	onSuccess: () => void,
	paymentConfig?: PaymentConfigurationType,
	writePermission: boolean
}

const bankRequisites = (bankName?: string, bankCountry?: string, bankAddress?: string, iban?: string) => {
	return `${bankName && bankName + ','} ${bankCountry && bankCountry + ','} ${bankAddress} \n${iban}`;
};

const PaymentConfigModal = ({ open, modalMode, paymentConfig, writePermission, onClose, onSuccess }: Props): React.ReactElement => {
	
	const closeModal = (event) => {
		event.preventDefault();
		onClose();
	};
	const [countries] = useState(getNames('en'));
	const item = paymentConfig;


	const [wlpId, setWlpId] = useState<string>(item?.wlpId ?? '');
	const { whitelabelOptions } = useWhiteLabelLov();
	const { bankAccountLov } = useBankAccountLov(wlpId);
	const { systemPaymentAccountLov } = useSystemPaymentAccountLov(wlpId);
	const { options: operationLov } = useGLLov(GlLookupTypes.PAYMENT_TYPES);
	const { bankNameLov } = useBankLov(); 

	useEffect(() => { setWlpId(item?.wlpId); },
		[item?.wlpId]
	);
 
	//const bankAccountLov = bankAccountList.filter(p => p.childWldId === wlpId);
	const countryLov = Object.keys(countries).map(k => { return { key: k, text: countries[k], value: countries[k] }; });
 
	const handleWlpChange = (event, data) => {
		setWlpId(data.value);
	};

	const initialValues = {
		isActive: item?.isActive === 'Y' ? true : false,
		isTechnical: item?.isTechnical === 'Y' ? true : false,
		wlpId: item?.wlpId ?? '',
		operationCode: item?.operationCode ?? '',
		bankAccountId: item?.bankAccountId ?? '',
		//
		fromBankAccountId: item?.fromBankAccountId ?? '',
		fromAccountId: item?.fromAccountId?.toString() ?? '',
		fromWalletId: item?.fromWalletId ?? '',
		fromBankName: item?.fromBankName ?? '',
		fromBankCountry: item?.fromBankCountry ?? '',
		fromBankAddress: item?.fromBankAddress ?? '',
		fromSwiftCode: item?.fromSwiftCode ?? '',
		fromAccountNumIban: item?.fromAccountNumIban ?? '',
		fromCustomerName: item?.fromCustomerName ?? '',
		fromCustomerId: item?.fromCustomerId ?? '',
		fromCustomerAddress: item?.fromCustomerAddress ?? '',
		//
		toBankAccountId: item?.toBankAccountId ?? '',
		toAccountId: item?.toAccountId?.toString() ?? '',
		toWalletId: item?.toWalletId ?? '',
		toBankName: item?.toBankName ?? '',
		toBankCountry: item?.toBankCountry ?? '',
		toBankAddress: item?.toBankAddress ?? '',
		toSwiftCode: item?.toSwiftCode ?? '',
		toAccountNumIban: item?.toAccountNumIban ?? '',
		toCustomerName: item?.toCustomerName ?? '',
		toCustomerId: item?.toCustomerId ?? '',
		toCustomerAddress: item?.toCustomerAddress ?? '',
		//
		procName: item?.procName ?? '',
		procParam01: item?.procParam01 ?? '',
		procParam02: item?.procParam02 ?? '',
		procParam03: item?.procParam03 ?? '',
		procParam04: item?.procParam04 ?? '',
		procParam05: item?.procParam05 ?? '',
		procParam06: item?.procParam06 ?? '',
		procParam07: item?.procParam07 ?? '',
		procParam08: item?.procParam08 ?? '',
		paymentDescription: item?.paymentDescription ?? '',
		notes: item?.notes ?? '',
	};

	const validationSchema = Yup.object({
		wlpId: Yup.string().required(' '),
		operationCode: Yup.string().required(' '),
		procName: Yup.string().required(' '),
		bankAccountId: Yup.string().required(' '),
		fromWalletId: Yup.string().required(' '),
		toWalletId: Yup.string().required(' ')
	});


	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);

		const { isTechnical, isActive } = formData;

		const payload: PaymentConfigurationType = {
			...formData,
			isTechnical: isTechnical ? 'Y' : 'N',
			isActive: isActive ? 'Y' : 'N'
		};

		try {
			if (modalMode === ModalMode.ADD) {
				await postPaymentConfig(payload);
			}
			else {
				await putPaymentConfig(item?.paymentConfigId, payload);
			}
			onSuccess();
		}
		catch (e) { showErrorNotification(e); }
		finally {
			setSubmitting(false);
		}
		onClose();
	};

	const editDisabled = !writePermission;

	return (
		<Modal
			size='large'
			open={open}
			onClose={closeModal}>
			<Modal.Content>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ isSubmitting, values }) => {
						return (
							<Form
								size='small'>
								<Grid>
									<Grid.Row columns={5}>
										<Grid.Column >
											<Header content='Operation Details' />
										</Grid.Column>
										<Grid.Column floated='right' >
											<Select
												label='White Label'
												fluid
												name='wlpId'
												value={wlpId}
												search
												options={whitelabelOptions}
												onChange={handleWlpChange}
												disabled={modalMode !== ModalMode.ADD}
												errorPrompt
											/>
										</Grid.Column>
										<Grid.Column >
											<Select
												label='Bank Account'
												fluid
												name='bankAccountId'
												options={bankAccountLov}
												disabled={editDisabled}
												errorPrompt
												search
											/>
										</Grid.Column>
										<Grid.Column width={2} verticalAlign='middle' >
											<Checkbox
												label='Technical'
												name='isTechnical'
												disabled={editDisabled}
											/>
										</Grid.Column>
										<Grid.Column width={2} verticalAlign='middle' >
											<Checkbox
												label='Active'
												name='isActive'
												disabled={editDisabled}
											/>
										</Grid.Column >
									</Grid.Row>
								</Grid>
								<Divider hidden />
								<Grid columns={3} divided>
									<Grid.Row>
										<Grid.Column>
											<Header as='h3' content='From Account' />
											<Divider hidden />
											<Select
												label='Account Number'
												fluid
												name='fromAccountId'
												disabled={editDisabled}
												options={systemPaymentAccountLov}
												errorPrompt
												search
											/>
											<Input
												label='Wallet ID'
												fluid
												name='fromWalletId'
												disabled
												errorPrompt
											/>
											<TextArea
												label='Account Bank Requisites' 
												name='accountBankRequisite'
												value={bankRequisites(values.fromBankName, values.fromBankCountry, values.fromBankAddress, values.fromAccountNumIban)}
												disabled={true}
												errorPrompt
											/>

											<Select
												label='Bank'
												fluid
												name='fromBankName'
												disabled={editDisabled}
												options={bankNameLov}
												errorPrompt
												search
											/>
											<Select
												label='Bank Country'
												fluid
												name='fromBankCountry'
												options={countryLov}
												disabled={editDisabled}
												errorPrompt
												search
											/>
											<Input
												label='Bank Address'
												fluid
												name='fromBankAddress'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='BIC/SWIFT'
												fluid
												name='fromSwiftCode'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='Account Num/Iban'
												fluid
												name='fromAccountNumIban'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='Customer'
												fluid
												name='fromCustomerName'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='Customer ID'
												fluid
												name='fromCustomerId'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='Customer Address'
												fluid
												name='fromCustomerAddress'
												disabled={editDisabled}
												errorPrompt
											/>
										</Grid.Column>
										<Grid.Column>
											<Header as='h3' content='To Account' />
											<Divider hidden />
											<Select
												label='Account Number'
												fluid
												name='toAccountId'
												disabled={editDisabled}
												options={systemPaymentAccountLov}
												errorPrompt
												search
											/>
											<Input
												label='Wallet ID'
												fluid
												name='toWalletId'
												disabled
												errorPrompt
											/>
											<TextArea
												label='Account Bank Requisites' 
												name='accountBankRequisite'
												value={bankRequisites(values.toBankName, values.toBankCountry, values.toBankAddress, values.toAccountNumIban)}
												disabled={true}
												errorPrompt
											/>
											<Select
												label='Bank'
												fluid
												name='toBankName'
												options={bankNameLov}
												disabled={editDisabled}
												errorPrompt
												search
											/>
											<Select
												label='Bank Country'
												fluid
												name='toBankCountry'
												options={countryLov}
												disabled={editDisabled}
												errorPrompt
												search
											/>
											<Input
												label='Bank Address'
												fluid
												name='toBankAddress'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='BIC/SWIFT'
												fluid
												name='toSwiftCode'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='Account Num/Iban'
												fluid
												name='toAccountNumIban'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='Customer'
												fluid
												name='toCustomerName'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='Customer ID'
												fluid
												name='toCustomerId'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='Customer Address'
												fluid
												name='toCustomerAddress'
												disabled={editDisabled}
												errorPrompt
											/>
										</Grid.Column>
										<Grid.Column>
											<Header as='h3' content='Process details' />
											<Divider hidden />
											<Select
												label='Operation'
												fluid
												name='operationCode'
												disabled={editDisabled}
												options={operationLov}
												errorPrompt
											/>
											<Input
												label='System Process'
												fluid
												name='procName'
												disabled={editDisabled}
												errorPrompt
											/>
											<TextArea
												label='Short Description' 
												name='shortDescription'
												disabled={editDisabled}
												rows={1}
												errorPrompt
											/>
											<TextArea
												label='Payment Description' 
												name='paymentDescription'
												disabled={editDisabled}
												rows={2}
												errorPrompt
											/>
											<TextArea
												label='Notes' 
												name='notes'
												disabled={editDisabled}
												rows={2}
												errorPrompt
											/>
											<Input
												label='System Process Parameter 1'
												fluid
												name='procParam01'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='System Process Parameter 2'
												fluid
												name='procParam02'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='System Process Parameter 3'
												fluid
												name='procParam03'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='System Process Parameter 4'
												fluid
												name='procParam04'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='System Process Parameter 5'
												fluid
												name='procParam05'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='System Process Parameter 6'
												fluid
												name='procParam06'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='System Process Parameter 7'
												fluid
												name='procParam07'
												disabled={editDisabled}
												errorPrompt
											/>
											<Input
												label='System Process Parameter 8'
												fluid
												name='procParam08'
												disabled={editDisabled}
												errorPrompt
											/>
										</Grid.Column>
									</Grid.Row>
								</Grid>
								<Divider hidden />
								<Divider hidden />
								<Grid columns={2}>
									<Grid.Row>
										<Grid.Column >
											<SubmitButton
												disabled={isSubmitting || editDisabled}
												primary size='large' type='submit'
												floated='right' >
												{modalMode === ModalMode.EDIT ? 'Save' : modalMode}
											</SubmitButton>
										</Grid.Column>
										<Grid.Column>
											<Button floated='left' secondary size='large' content={editDisabled ? 'Close' : 'Discard'} onClick={closeModal} />
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Form>);
					}}
				</Formik>

			</Modal.Content>
		</Modal>
	);
};

export default PaymentConfigModal;
