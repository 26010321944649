import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';


interface Props {
	open: boolean;
	onClose: (status?: string) => void,
}

const ChangePaymentStatusModal = ({ open, onClose }: Props): React.ReactElement => {

	const statusOptions = [
        { key: 'CANCELLED', text: 'Cancelled', value: 'CANCELLED' },
        { key: 'PAID', text: 'Paid', value: 'PAID' },
        { key: 'WAITING_FOR_PAYMENT', text: 'Waiting for payment', value: 'WAITING_FOR_PAYMENT' }
    ];

	const initialValues = {
		status: '',
	};

	const validationSchema = Yup.object({
		status: Yup.string()
			.required('You must choose a status')

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { status } = formData;
		onClose(status);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content="Specify rejection reason" />

						<Select
                            label='Status'
                            fluid
                            name="status"
                            errorPrompt
                            options={statusOptions}
                        />
					
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										Save changes
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default ChangePaymentStatusModal;
