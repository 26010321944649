import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Confirm, Container, Divider, Header, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import NumberFormat from 'react-number-format';
import { formatDateWithPattern } from '@/helpers/date';
import { getUnassignedPayments, postMarkAsMatched } from '@/features/integrationsUnassignedPayments/integrationsUnassignedPaymentsSlice';
import { IntegrationsUnassignedPayment } from '@/features/integrationsUnassignedPayments/integrationsUnassignedPaymentsTypes';
import IntegrationsUnassingedPaymentModal from './IntegrationsUnassingedPaymentModal';
import { Account } from '@/features/accounts/types';

interface Props {
	writePermission: boolean,
}

const IntegrationsUnassignedPayments = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.integrationsUnassignedPayments
	);

	const paginateInitialState: PaginateParams = { orderBy: 'id', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);
	const [open, setOpen] = useState<boolean>(false);
	const [confirmAccount, setConfirmAccount] = useState<Account>(null);
	const [unassignedPayment, setUnassignedPayment] = useState<IntegrationsUnassignedPayment>(null);

	const filter: FilterParam[] = [
		{
			field: 'amount',
			label: 'Amount',
			type: 'input',
			inputType: 'number'
		},
		{
			field: 'paymentDetails',
			label: 'Payment Details',
			type: 'input'
		},
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: [{ key: 'NEW', value: 'NEW', text: 'New' }, { key: 'MATCHED', value: 'MATCHED', text: 'Matched' }]
		},
	];

	const headers: Array<Headers> = [
		{
			field: 'createdDate',
			name: 'Date',
			position: 1,
			sortable: true
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 2,
			sortable: true
		},
		{
			field: 'paymentDetails',
			name: 'Payment Details',
			position: 3,
			sortable: true
		},
		{
			field: '',
			name: 'Payer Name',
			position: 4,
			sortable: false
		},
		{
			field: '',
			name: 'Payer Iban',
			position: 5,
			sortable: false
		},
		{
			field: 'status',
			name: 'Status',
			position: 6,
			sortable: true
		},
		{
			field: 'suggestedPaymentDetails',
			name: 'Suggested Wallet ID',
			position: 7,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 8,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getUnassignedPayments(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const handleOnCloseMatch = async (account: Account) => {
		setOpen(false);
		setConfirmAccount(account);
	};


	const handleOnMatchConfirm = async () => {
		setOpen(false);
		if (confirmAccount?.walletId) {
			try {
				await postMarkAsMatched(unassignedPayment.id, confirmAccount?.walletId);
				showSuccessNotification('Payment Matched');
				fetchData();
				setConfirmAccount(null);
				setUnassignedPayment(null);
			} catch (e) {
				showErrorNotification(e);
			}
		}
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{formatDateWithPattern(entry.createdDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'} fixedDecimalScale={false} prefix={`${entry.ccy} `} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} />
						</Table.Cell>
						<Table.Cell width={6}>
							<Popup
								trigger={<div>{entry.paymentDetails.length > 50 ? entry.paymentDetails.slice(0, 50).concat('...') : entry.paymentDetails}</div>}
								content={entry.paymentDetails}
								position='top center'
								wide='very'
							/>
						</Table.Cell>
						<Table.Cell width={1}>{entry.payerName ?? ''}</Table.Cell>
						<Table.Cell width={1}>{entry.payerIban ?? ''}</Table.Cell>
						<Table.Cell width={2}>{entry.status === 'MATCHED' ? `Matched with ${entry.walletId}` : entry.status}</Table.Cell>
						<Table.Cell width={2}>{entry.suggestedPaymentDetails}</Table.Cell>

						<Table.Cell width={2}>
							{entry.status === 'NEW' && <Button primary disabled={!writePermission} content='MATCH' onClick={() => { setUnassignedPayment(entry); setOpen(true); }} />}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>Not unassigned payments yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;


	return (
		<Container fluid>
			<Confirm
				open={!!confirmAccount}
				onCancel={() => setConfirmAccount(null)}
				onConfirm={handleOnMatchConfirm}
				content={`Please confirm matching to wallet id ${confirmAccount?.walletId}, currency: ${confirmAccount?.currency}, account status: ${confirmAccount?.status}, client: ${confirmAccount?.firstName} ${confirmAccount?.lastName}.`}
				size='mini'
				cancelButton='Cancel'
				confirmButton='Confirm'
			/>
			<Segment>
				<IntegrationsUnassingedPaymentModal
					open={open}
					onClose={handleOnCloseMatch}
					unassignedPayment={unassignedPayment}
				/>
				<Header as='h2'>Integrations Unassigned Payments</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Segment>
		</Container>
	);
};


export default IntegrationsUnassignedPayments;
