import { showErrorNotification } from '@/features/swal/slice';
import {downloadAttachment, getSupportEmailTicket, updateTicketStatus} from '@/features/tickets/ticketsSlice';
import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Divider,
	Grid,
	Header, Icon,
	List,
	Modal
} from 'semantic-ui-react';
import {TicketMessageAttachment} from '@features/tickets/ticketsType';


interface Props {
	open: boolean;
	onClose: (status?: string) => void,
	ticketId: number
}

const SupportEmailTicketDetailsModal = ({ open, onClose, ticketId }: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const [ticketDetails, setTicketDetails] = useState<any>(null);

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getSupportEmailTicket(ticketId);
				setTicketDetails(data);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [ticketId, dispatch]);

	const download = (id: number, attachment: TicketMessageAttachment) => {
		const get = async () => {
			try {
				downloadAttachment(id, attachment);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	};

	useEffect(() => {
		if (!ticketId) return;
		fetchData();
	}, [ticketId, dispatch]);

	const updateStatus = async (status: 'solved' | 'rejected') => {
		try {
			await updateTicketStatus(ticketId, status);
			onClose(status);
		} catch (e) {
			showErrorNotification(e);
		}
	};


	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>

			{ticketDetails && <>
				<Header as='h2'>{ticketDetails.subject}</Header>
				<List id="profile-data">
					<List.Item>
						<List.Content>
							<List.Header className="color-grey">Status</List.Header>
							<List.Description>{ticketDetails.status}</List.Description>
						</List.Content>
					</List.Item>
					<Divider />
					<List.Item>
						<List.Content>
							<List.Header className="color-grey">Email</List.Header>
							<List.Description>{JSON.parse(ticketDetails.requestMeta).email}</List.Description>
						</List.Content>
					</List.Item>
					<Divider />
					<List.Item>
						<List.Content>
							<List.Header className="color-grey">Decription</List.Header>
							<List.Description>{ticketDetails.ticketMessages[0].message}</List.Description>
						</List.Content>
					</List.Item>
					<Divider />
					<List.Item>
						<List.Content>
							<List.Header className="color-grey">Attachments</List.Header>
							{ticketDetails.ticketMessages[0].ticketAttachments.map((attachment: TicketMessageAttachment) =>
								<List.Description key={attachment.key} >
									<div className="downloadAttachments" onClick={() => download(ticketDetails.ticketMessages[0].ticketId, attachment)} style={{ width: 'fit-content' }}>
										<Icon size="small" name="download" />{attachment.name}
									</div>
								</List.Description>
							)}
						</List.Content>
					</List.Item>
				</List>

				<Grid>
					<Grid.Row style={{display: 'flex', justifyContent: 'center'}}>
						<Button floated='right'
							style={{ marginLeft: 10 }}
							primary
							onClick={() => onClose()}
							content='Cancel'
						/>
						{ticketDetails && ticketDetails.statuss !== 'REJECTED' && ticketDetails.status !== 'SOLVED' && <Button
							style={{ marginLeft: 10 }}
							color='green'
							content='Solve'
							floated='right'
							disabled={!checkWritePermission(permissions, PermissionType.SUPPORT)}
							onClick={() => updateStatus('solved')}
						/>}
						{ticketDetails && ticketDetails.status !== 'REJECTED' && ticketDetails.status !== 'SOLVED' && <Button
							style={{ marginLeft: 10 }}
							color='red'
							content='Reject'
							floated='right'
							disabled={!checkWritePermission(permissions, PermissionType.SUPPORT)}
							onClick={() => updateStatus('rejected')}
						/>}
					</Grid.Row>
				</Grid>

			</>}

		</Modal.Content>
	</Modal>;
};

export default SupportEmailTicketDetailsModal;
