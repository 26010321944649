import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SwalPayload, ErrorTranslate } from './types';
import Swal, { SweetAlertResult, SweetAlertOptions } from 'sweetalert2';
import i18n from '../../i18n';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const t = (value, params?) => {
	return params ? i18n.t(`translations:${value}`, params) : i18n.t(`translations:${value}`);
};

const initialState = {
	header: '',
	content: '',
	open: false
};

const swalSlice = createSlice({
	name: 'swal',
	initialState,
	reducers: {
		openModal(state, action: PayloadAction<SwalPayload>) {
			state.header = action.payload.header;
			state.content = action.payload.content;
			state.open = true;
		},
		closeModal(state) {
			state.header = '';
			state.content = '';
			state.open = false;
		}
	}
});

export const { openModal, closeModal } = swalSlice.actions;

export const swalSelector = (state: { statusStore: SwalPayload }) =>
	state.statusStore;

export const showSuccess = ({ title = 'swal.SUCCESS', titleParams = null, text = null, textParams = null, timer = 3000, toast = false, html = null, htmlParams = null }: { title?: string, titleParams?: any, text?: string, textParams?: any, timer?: number, toast?: boolean, html?: string, htmlParams?: any } = {}): Promise<SweetAlertResult> => {
	return Swal.fire({
		position: toast ? 'top' : 'center',
		icon: 'success',
		title: t(title, titleParams),
		text: text ? t(text, textParams) : null,
		html: html ? t(html, htmlParams) : null,
		timer,
		showConfirmButton: timer == null,
		toast: toast,
		heightAuto: false,
	});
};

export const showException = (err: any, customErrorParams?: ErrorTranslate | ErrorTranslate[]): Promise<SweetAlertResult> => {
	if (err.status === 417) {
		const errorCode: string = err.data ? err.data.error : null;
		const errorParam: string = err.data ? err.data.error_param : null;
		const errorValue: string = err.data ? err.data.param_value : null;
		let errorMessage: string = null;

		if (errorParam === 'CUSTOM_MESSAGE') {
			const options: SweetAlertOptions = {
				position: 'center',
				title: t('swal.' + errorCode.toUpperCase()),
				icon: 'error',
				text: errorValue,
				heightAuto: false
			};
			return Swal.fire(options);
		}

		if (customErrorParams) {
			let customErrors: ErrorTranslate[];
			let params: any = {};

			if (!Array.isArray(customErrorParams)) {
				customErrors = [customErrorParams];
			} else {
				customErrors = customErrorParams;
			}

			for (const customError of customErrors) {
				if (errorCode !== customError.key) {
					continue;
				}
				if (customError.keyParam && errorParam !== customError.keyParam) {
					continue;
				}

				customError.errorParams = customError.errorParams || {};
				params = customError.params || {};

				for (const errorParamKey of Object.keys(customError.errorParams)) {
					if (errorParam === errorParamKey) {
						params.param = t(customError.errorParams[errorParamKey]);
					}
				}

				if (errorValue) {
					params.param_value = errorValue;
				}

				return Swal.fire({
					position: 'center',
					icon: 'error',
					title: t(customError.title || 'errors.SERVER_ERROR'),
					text: t(customError.message, params),
					heightAuto: false,
				});
			}
		}

		if (errorCode === 'error' && errorParam) {
			t('errors.' + errorParam.toUpperCase());
		} else if (errorCode && errorParam) {
			errorMessage = t('errors.FIELD_ERROR', { field: errorParam, message: errorCode });
		} else {
			errorMessage = t('errors.SOMETHING_WENT_WRONG', { message: err.message });
		}

		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: t('errors.SERVER_ERROR'),
			text: errorMessage,
			heightAuto: false,
		});
	}

	if (err.status === 403 && err.data.message === 'two factor auth is disabled') {
		return Swal.fire({
			position: 'center',
			html: `<span>${t('errors.2FA_REQUIRED_TEXT')} </span><a href="/wallet/security">Click here to move to security settings</a>`,
			icon: 'error',
			title: t('errors.2FA_REQUIRED'),
			heightAuto: false,
		});
	}

	if (err.data && err.data.message) {
		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: t('errors.SERVER_ERROR'),
			text: err.data.message,
			heightAuto: false,
		});
	}

	if (err.message) {
		return Swal.fire({
			position: 'center',
			icon: 'error',
			title: t('errors.SERVER_ERROR'),
			text: err.message,
			heightAuto: false,
		});
	}

	return Swal.fire({
		position: 'center',
		icon: 'error',
		title: t('errors.SERVER_ERROR'),
		text: t('errors.SOMETHING_WENT_WRONG', { message: err.statusText }),
		heightAuto: false,
	});
};

export const showInfo = ({ title = 'swal.SUCCESS', titleParams = null, text = null, textParams = null, timer = 3000, toast = false }: { title?: string, titleParams?: any, text?: string, textParams?: any, timer?: number, toast?: boolean } = {}): Promise<SweetAlertResult> => {
	return Swal.fire({
		position: toast ? 'top' : 'center',
		icon: 'info',
		title: t(title, titleParams),
		text: text ? t(text, textParams) : null,
		timer,
		showConfirmButton: timer == null,
		toast: toast,
		heightAuto: false,
	});
};

export const showError = ({ title = 'swal.SUCCESS', titleParams = null, text = null, textParams = null, timer = 3000, toast = false }: { title?: string, titleParams?: any, text?: string, textParams?: any, timer?: number, toast?: boolean } = {}): Promise<SweetAlertResult> => {
	return Swal.fire({
		position: toast ? 'top' : 'center',
		icon: 'error',
		title: t(title, titleParams),
		text: text ? t(text, textParams) : null,
		timer,
		showConfirmButton: timer == null,
		toast: toast,
		heightAuto: false,
	});
};

const getErrorCode = (error) => {
	const { data } = error;
	if (data) {
		const { error, errors } = data;
		if (error) {
			return error;
		}
		if (errors) {
			const validationInfo = errors[0];
			const { error } = validationInfo;
			return error;
		}
	}
	return null;
};

export const showSuccessNotification = (content: string) => {
	toast.success(content);
};

export const showErrorNotification = (err: any, content = t('errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE')) => {
	if (err?.status === 417) {
		const errorCode: string = getErrorCode(err);
		const errorParam: string = err.data ? err.data.error_param : null;

		if (errorCode === 'required' && errorParam === 'authenticatorCode' || err.data && err.data.message === 'wrong two factor code') {
			return;
		}
	}

	toast.error(content);
};

export const showWarningNotification = (content: string) => {
	toast.warn(t(content));
};

export const showInfoNotification = (content: string) => {
	toast.info(t(content));
};

export const dismissAllNotifications = () => {
	toast.dismiss();
};

export const getErrorWithParams = (_error) => {
	const { data } = _error;
	const { error, error_param } = _error;
	if (data) {
		const { error, errors, error_param } = data;
		if (error && error_param) {
			return { error, error_param };
		} else if (error && !error_param) {
			return error;
		}
		if (errors) {
			const validationInfo = errors[0];
			const { error, error_param } = validationInfo;
			return { error, error_param };
		}
	} else if (error && error_param) {
		return { error, error_param };
	}
	return null;
};


export const getErrorMessage = (e: any) => {
	const err = getErrorWithParams(e);
	if (err?.error?.message) {
		try {
			const errorMessage = JSON.parse(err.error.message.split(' - ')[1]).errorMessage;
			return errorMessage;
		}
		catch (e) {
			return 'Something went wrong';
		}

	}
	else return null;
};

export default swalSlice.reducer;
