import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { formatDateWithPattern } from '@/helpers/date';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { AdminsRoleType, EmiListType } from '@/features/admins/adminTypes';
import { getAdmins } from '@/features/admins/adminSlice';
import AdminModal from './AdminModal';

interface Props {
	writePermission: boolean,
	adminType: AdminsRoleType,
	emi: EmiListType
}



const AdminList = ({ adminType, writePermission, emi }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.admins
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [openModal, setOpenModal] = useState<boolean>(false);

	const filter: FilterParam[] = [
		{
			field: 'firstName',
			label: 'First Name',
			type: 'input'
		},
		{
			field: 'lastName',
			label: 'Last Name',
			type: 'input'
		},
		{
			field: 'email',
			label: 'Email',
			type: 'input'
		},
		{
			field: 'mobileNumber',
			label: 'Mobile Number',
			type: 'input'
		},
	];

	const headers: Array<Headers> = [
		{
			field: 'firstName',
			name: 'First Name',
			position: 1,
			sortable: true
		},
		{
			field: 'lastName',
			name: 'Last Name',
			position: 2,
			sortable: true
		},
		{
			field: 'email',
			name: 'Email',
			position: 3,
			sortable: true
		},
		{
			field: 'disabled',
			name: 'Status',
			position: 4,
			sortable: false
		},
		{
			field: 'mobileNumber',
			name: 'Mobile Number',
			position: 5,
			sortable: true
		},
		{
			field: 'country',
			name: 'Country',
			position: 6,
			sortable: true
		},
		{
			field: 'createdDate',
			name: 'Created',
			position: 7,
			sortable: false
		},
		{
			field: 'lastLoginDate',
			name: 'Last login',
			position: 8,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getAdmins(adminType, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, adminType, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);


	const handleCloseRoleModal = () => {
		setOpenModal(false);
		fetchData();
	};

	const handleUser = async () => {
		setOpenModal(true);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.firstName}</Table.Cell>
						<Table.Cell width={2}>{entry.lastName}</Table.Cell>
						<Table.Cell width={2}>{entry.email}</Table.Cell>
						<Table.Cell width={2}>{entry.disabled ? 'Disabled' : 'Active'}</Table.Cell>
						<Table.Cell width={2}>{entry.confirmedMobileNumber === '+' ? '' : entry.confirmedMobileNumber}</Table.Cell>
						<Table.Cell width={2}>{entry.country}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.createdDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.lastLoginDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>No admins yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<AdminModal
					open={openModal}
					adminType={adminType}
					onClose={handleCloseRoleModal}
					writePermission={writePermission}
					emi={emi}
				/>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'> {adminType === AdminsRoleType.EMI_ADMIN ? 'EMI ' : 'White label '}Admins</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button
								floated='right'
								primary
								disabled={!writePermission}
								onClick={handleUser}
							>
								Add User</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default AdminList;
