import React, { useCallback, useEffect, useState } from 'react';
import {
    Modal,
    Header,
    Button,
    Divider,
    Grid,
    Icon,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { Token } from '@/features/tokens/types';
import { useDropzone } from 'react-dropzone';
import './Tokens.css';

interface Props {
    open: boolean;
    onClose: (symbol?: string, name?: string, description?: string,
        currency?: string, buyPrice?: number, sellPrice?: number, website?: string, hidden?: boolean, buyEnabled?: boolean, sellEnabled?: boolean, skipRequestPhase?: boolean, logo?: any, tradingView?: boolean,
        spread?: number, orderMaxWithoutApprove?: number, krakenAutoExecutionMaxSysAmount?: number, krakenAsset?: string, decimals?: number, displayDecimals?: number,
        sendEnabled?: boolean, networks?: string[], exchange?: string, forInfo?: boolean, token?: Token) => void,
    token: Token,
    writePermission: boolean
}

const TokenModal = ({ open, onClose, token, writePermission }: Props): React.ReactElement => {

    const [logoFile, setLogoFile] = useState(null);
    const [uploadNewLogo, setUploadNewLogo] = useState<boolean>(false);

    const initialValues = {
        symbol: token ? token.symbol : '',
        name: token ? token.name : '',
        description: token ? token.description : '',
        buyPrice: token ? token.buyPrice : '',
        sellPrice: token ? token.sellPrice : '',
        currency: token ? token.currency : 'EUR',
        website: token ? token.website : '',
        logo: token ? token.logo : '',
        hidden: token ? token.hidden : false,
        buyEnabled: token?.buyEnabled ?? true,
        sellEnabled: token?.sellEnabled ?? true,
        skipRequestPhase: token?.skipRequestPhase ?? false,
        tradingView: token ? token.tradingView : false,
        spread: token ? token.spread * 100 : '1.5',
        orderApprovalOptional: token ? token.orderApprovalOptional : false,
        orderMaxWithoutApprove: token ? token.orderMaxWithoutApprove : '',
        krakenAutoExecutionMaxSysAmount: token ? token.krakenAutoExecutionMaxSysAmount : '',
        krakenAsset: token ? token.krakenAsset : '',
        decimals: token?.decimals ?? 10,
        displayDecimals: token?.displayDecimals ?? 5,
        sendEnabled: token?.sendEnabled ?? true,
        networks: token?.networks ? token.networks.split(',') : [],
        exchange: token?.exchange ?? '',
        forInfo: token?.forInfo ?? false
    };

    const validationSchema = Yup.object({
        symbol: Yup.string()
            .required('You must enter an symbol'),
        name: Yup.string()
            .required('You must enter a name'),
        description: Yup.string()
            .required('You must enter a description'),
        currency: Yup.string()
            .required('You must select a currency'),
        buyPrice: Yup.string()
            .required('You must enter a buy price'),
        sellPrice: Yup.string()
            .required('You must enter a sell price'),
        decimals: Yup.number()
            .required('You must enter a sell decimals'),
        displayDecimals: Yup.number()
            .required('You must enter a sell display decimals'),
        networks: Yup.array()
            .required('You must select networks'),

    });

    const networkOptions = [
        { key: 'Bitcoin', text: 'Bitcoin', value: 'Bitcoin' },
        { key: 'Ethereum', text: 'Ethereum', value: 'Ethereum' },
        { key: 'BSC', text: 'Binance Smart Chain', value: 'Binance Smart Chain' },
        { key: 'Tron', text: 'Tron', value: 'Tron' }
    ];

    useEffect(() => {
        if (!token || !token?.logo) {
            setUploadNewLogo(true);
        }
    }, [token]);

    const submit = (formData, formikProps) => {
        const { setSubmitting } = formikProps;
        setSubmitting(false);

        const { symbol, name, description, currency, sellPrice, buyPrice, hidden, website, buyEnabled, sellEnabled, skipRequestPhase, tradingView, spread, orderMaxWithoutApprove, krakenAutoExecutionMaxSysAmount, krakenAsset, decimals, displayDecimals, sendEnabled, networks, exchange, forInfo } = formData;

        setLogoFile(null);
        setUploadNewLogo(false);
        onClose(symbol, name, description, currency, buyPrice, sellPrice, website, hidden, buyEnabled, sellEnabled, skipRequestPhase, logoFile ?? null, tradingView, spread, orderMaxWithoutApprove ?? null, krakenAutoExecutionMaxSysAmount ?? null, krakenAsset ?? null, decimals, displayDecimals, sendEnabled, networks, exchange, forInfo, token ?? null);
    };

    const handleLogoUpload = (file) => {
        setLogoFile(file);
    };

    const closeModal = () => {
        setLogoFile(null);
        setUploadNewLogo(false);
        onClose();
    };

    return <Modal
        size="tiny"
        open={open}
        onClose={closeModal}>
        <Modal.Content>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submit}
                enableReinitialize={true}
            >
                {({ isSubmitting }) => (
                    <Form
                        size="large">
                        <Header content={token ? 'Edit token' : 'Add new token'} />

                        <Input
                            label='Symbol'
                            fluid
                            name="symbol"
                            disabled={!writePermission || token !== null}
                            errorPrompt
                        />
                        <Input
                            label='Name'
                            fluid
                            name="name"
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <TextArea
                            label='Description'
                            fluid
                            name="description"
                            disabled={!writePermission}
                            errorPrompt
                        />

                        <Select
                            label='Currency'
                            fluid
                            name="currency"
                            errorPrompt
                            options={[{ key: 'EUR', value: 'EUR', text: 'EUR' }, { key: 'USD', value: 'USD', text: 'USD' }]}
                        />
                        <Input
                            label='Buy Price'
                            fluid
                            name="buyPrice"
                            type='number'
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <Input
                            label='Sell Price'
                            fluid
                            name="sellPrice"
                            type='number'
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <Input
                            label='Web site'
                            fluid
                            name="website"
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <Input
                            label='Spread'
                            type='number'
                            fluid
                            name="spread"
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <Input
                            label='Decimals'
                            type='number'
                            fluid
                            name="decimals"
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <Input
                            label='Display decimals'
                            type='number'
                            fluid
                            name="displayDecimals"
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <Select
                            fluid
                            label='Network'
                            name="networks"
                            multiple
                            options={networkOptions}
                            onChange={(e, v) => console.log(v.value)}
                            errorPrompt
                        />
                        <Select
                            label='Exchange'
                            fluid
                            name="exchange"
                            errorPrompt
                            options={[{ key: 'n/a', value: 'n/a', text: 'N/A' }, { key: 'kraken', value: 'kraken', text: 'Kraken' }, { key: 'kyrrex', value: 'kyrrex', text: 'Kyrrex' }]}
                        />
                        <Checkbox fitted
                            name="hidden"
                            label='Hidden'
                            disabled={!writePermission}
                        />
                        <Checkbox fitted
                            name="tradingView"
                            label='Kraken'
                            disabled={!writePermission}
                        />
                        <Checkbox fitted
                            name="buyEnabled"
                            label='Buy enabled'
                            disabled={!writePermission}
                        />
                        <Checkbox fitted
                            name="sellEnabled"
                            label='Sell enabled'
                            disabled={!writePermission}
                        />
                        <Checkbox fitted
                            name="sendEnabled"
                            label='Send enabled'
                            disabled={!writePermission}
                        />
                        <Checkbox fitted
                            name="skipRequestPhase"
                            label='Skip Request Phase'
                            disabled={!writePermission}
                        />
                        <Checkbox fitted
                            name="forInfo"
                            label='Info only'
                            disabled={!writePermission}
                        />
                        <Input
                            label='Max amount without approve'
                            type='number'
                            fluid
                            name="orderMaxWithoutApprove"
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <Input
                            label='Kraken Auto Execution Max Sys Amount'
                            type='number'
                            fluid
                            name="krakenAutoExecutionMaxSysAmount"
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <Input
                            label='Exchange pair'
                            fluid
                            name="krakenAsset"
                            disabled={!writePermission}
                            errorPrompt
                        />
                        <Divider />
                        {token?.logo && !uploadNewLogo &&
                            <>
                                <Input
                                    label='Logo'
                                    fluid
                                    value={token.logo}
                                    name="logo"
                                    disabled
                                />
                                <a onClick={() => setUploadNewLogo(true)}>Upload new logo</a>
                            </>}
                        {uploadNewLogo && <UploadLogo onUpload={handleLogoUpload} />}
                        <Divider hidden />
                        <Divider />
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column >
                                    <SubmitButton
                                        disabled={isSubmitting || !writePermission}
                                        primary size="large" type="submit"
                                        floated='right' >
                                        {token ? 'Edit' : 'Add'}
                                    </SubmitButton>
                                </Grid.Column>
                                <Grid.Column>
                                    <Button floated='left' secondary size="large" content='Discard' onClick={closeModal} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>)}
            </Formik>

        </Modal.Content>
    </Modal>;
};

interface UploadLogoProps {
    onUpload: (file) => void
}

const UploadLogo = ({ onUpload }: UploadLogoProps) => {

    const onDrop = useCallback((acceptedFiles) => {
        onUpload(acceptedFiles[0]);
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/png,image/jpg,image/jpeg', multiple: false });

    const removeAll = useCallback(() => {
        onUpload(null);
        acceptedFiles.splice(0);
    }, [onUpload]);

    return (
        <>
            <Grid style={{ marginLeft: '0', marginRight: '0', marginBottom: '1em', marginTop: '1em' }}>
                <Grid.Row><strong>Logo</strong></Grid.Row>
                <Grid.Row>
                    <div {...getRootProps()} id='file-container'>
                        <input {...getInputProps()} id='file-drop' />
                        <span><Icon name='upload' /> Upload Logo...</span>
                    </div>
                </Grid.Row>
                {acceptedFiles.length > 0 &&
                    <Grid.Row>
                        <Icon size="large" name="file outline" />
                        <div className="fileName">{acceptedFiles[0].name}</div>
                        <Icon className="fileName" size="large" name='trash' link onClick={() => removeAll()} />
                    </Grid.Row>}
            </Grid>
        </>
    );
};

export default TokenModal;


