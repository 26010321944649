
import { StatementAccountBalance } from '@/features/statements/statementsTypes';
import React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { Grid, Icon } from 'semantic-ui-react';

interface Props {
	balance: StatementAccountBalance,
	active?: boolean,
	showShevron?: boolean
}

const BalanceLine = ({ balance, active, showShevron }: Props) => {
	return (
		<Grid.Row className='balance-line' >
			<Grid.Column width={5}
				className='balance-fist-column'
				textAlign='left'
				style={{ paddingLeft: `calc(1rem * ${balance.hierarchyLevel})` }}
			>
				{showShevron &&
					<Icon name={active ? 'chevron down' : 'chevron right'} style={{ float: 'left', paddingRight: '0.5rem' }} />
				}
				{balance.description}
			</Grid.Column>
			<Grid.Column width={2}>
				<NumberFormat displayType={'text'}
					fixedDecimalScale={false}
					thousandsGroupStyle='thousand'
					thousandSeparator={true}
					value={balance.accountBeginBalance} />
			</Grid.Column>
			<Grid.Column width={2} >
				<NumberFormat displayType={'text'}
					fixedDecimalScale={false}
					thousandsGroupStyle='thousand'
					thousandSeparator={true}
					value={balance.accountEndBalance} />
			</Grid.Column>
			<Grid.Column width={2}>
				<NumberFormat displayType={'text'}
					fixedDecimalScale={false}
					thousandsGroupStyle='thousand'
					thousandSeparator={true}
					value={balance.accountPeriodDr} />
			</Grid.Column>
			<Grid.Column width={2} >
				<NumberFormat displayType={'text'}
					fixedDecimalScale={false}
					thousandsGroupStyle='thousand'
					thousandSeparator={true}
					value={balance.accountPeriodCr} />
			</Grid.Column>
			<Grid.Column width={3}>
				<Link to={`payment/account-details/${balance.bankAccountId}?filter=bankAccountId=${balance.bankAccountId};fromDate=${balance.periodDateFrom};toDate=${balance.periodDateTo}`}>
					Details</Link>
			</Grid.Column>
		</Grid.Row >
	);
};

export default BalanceLine;


