import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getUserGroupList, postUserGroup, putUserGroup } from '@/features/userGroups/slice';
import { UserGroup } from '@/features/userGroups/types';
import UserGroupModal from './UserGroupModal';
import { useHistory } from 'react-router-dom';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	writePermission: boolean,
}

const UserGroups = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.userGroups
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams} = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [userGroup, setUserGroup] = useState<UserGroup>(null);

	const headers: Array<Headers> = [
		{
			field: 'id',
			name: 'Code',
			position: 1,
			sortable: true
		},
		{
			field: 'name',
			name: 'Name',
			position: 2,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 3,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getUserGroupList(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();  
	}, [pagination, dispatch]);

	const handleEditUserGroup = (userGroup: UserGroup) => {
		setUserGroup(userGroup);
		setOpenModal(true);
	};

	const handleClose = async (name?: string) => {
		setOpenModal(false);

		if (name) {
			if (userGroup) {
				try {
					await putUserGroup(name, userGroup.id);
					showSuccessNotification('User group updated');
				} catch (e) {
					showErrorNotification(e);
				}
			} else {
				try {
					await postUserGroup(name);
					showSuccessNotification('User group added');
				} catch (e) {
					showErrorNotification(e);
				}
			}
			fetchData();
		}
	};

	const history = useHistory();
	const openUserGroup = (userGroupId: number) => {
		history.push(`/user-groups/${userGroupId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.id}</Table.Cell>
						<Table.Cell width={2}>{entry.name}</Table.Cell>
						<Table.Cell width={2}>
							<Popup content='Edit user group' trigger={<Icon className='tableActionIcon' size='large' name="edit outline" onClick={() => handleEditUserGroup(entry)} />} />
							<Popup content='View user group' trigger={<Icon className='tableActionIcon' size='large' name="users" onClick={() => openUserGroup(entry.id)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={3}>Not user groups yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<UserGroupModal
					open={openModal}
					userGroup={userGroup}
					onClose={handleClose}
				/>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>User Groups</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button
								floated='right'
								primary
								disabled={!writePermission}
								onClick={() => setOpenModal(true)}
							>
								Add User Group</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default UserGroups;
