export interface MaintenanceStore {
	loading: boolean,
	list: MaintenanceType[], 
	error: string, 
}

export interface MaintenanceType {
	id?: number,
	startDate: Date,
	endDate: Date,
	status: MaintenanceStatus,
	integration?: string,
	processes?: string[],
	description: string,
	message: string,
	affectedAgents: string[],
	agent?: string,  
}



export interface MaintenancePostType {
	id?: number,
	startDate: Date,
	endDate: Date, 
	integration?: string,
	processes?: string[],
	description: string,
	message: string,
	wlpId?: string,
}

 
 

export enum MaintenanceStatus {
	ACTIVE = 'ACTIVE',
	COMPLETED = 'COMPLETED',
	UPCOMMING = 'UPCOMING'
}
