import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from './rootReducer';
import { useSelector } from 'react-redux';
import LoadingPage from './pages/LoadingPage';
import { checkPermission, checkWritePermission } from './helpers/permissions';


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line react/prop-types
const PermissionRoute = ({ component: Component, permission, ...rest }): React.ReactElement => {
	const [loading, setLoading] = useState<boolean>(true);
	const [hasPermission, setHasPermession] = useState<boolean>(false);
	const [hasWritePermission, setHasWritePermession] = useState<boolean>(false);
	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		setHasPermession(checkPermission(permissions, permission));
		setHasWritePermession(checkWritePermission(permissions, permission));

		setLoading(false);
	}, [permission, permissions]);

	if (loading) {
		return <LoadingPage />;
	}
	return (
		<Route
			{...rest}
			render={props =>
				!hasPermission ? <Redirect to='/not-permitted' /> : <Component {...props} {...rest} writePermission={hasWritePermission} />
			}
		/>
	);
};

export default PermissionRoute;
