/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import {Button, Container, Divider, Grid, Header, List, Segment, Table} from 'semantic-ui-react';
import {showErrorNotification, showSuccessNotification} from '@/features/swal/slice';
import Loading from '@/components/Shared/Loading';
import '../Clients/ClientDetails/Profile.css';
import { useHistory } from 'react-router-dom';
import Assets from './Assets';
import Transactions from './Transactions';
import {Address, Vault, VaultAsset} from '@features/fireblocks/types';
import {getAssetAddresses, getVault, synchronizeBalance, transactionsWithinSystem} from '@features/fireblocks/slice';

const VaultDetails = (props): React.ReactElement => {

	const history = useHistory();

	const [loading, setLoading] = useState<boolean>(true);
	const [syncLoading, setSyncLoading] = useState<boolean>(false);
	const [vaultId] = useState<string>(props.match.params.vaultId);
	const [assetId] = useState<string>(props.match.params?.assetId);
	const [omnibus, setOmnibus] = useState<Vault>(null);
	const [withdrawal, setWithdrawal] = useState<Vault>(null);
	const [vault, setVault] = useState<Vault>(null);
	const [addresses, setAddresses] = useState<Array<Address>>(null);

	const addressesColumns: Array<string> = [
		'Address',
		'Description',
		'Tag',
		'Type',
		'Address Format',
	];

	const fetchData = useCallback(() => {
		const get = async () => {
			setLoading(true);
			try {
				const omnibusData = await getVault('OMNIBUS');
				setOmnibus(omnibusData);

				const withdrawalData = await getVault('WITHDRAWAL');
				setWithdrawal(withdrawalData);

				if (vaultId) {
					const vaultData = await getVault(vaultId);
					setVault(vaultData);
					if (vaultData.id && assetId) {
						let addressesData = await getAssetAddresses(vaultData.id, assetId);
						if (addressesData?.length > 1) {
							addressesData = addressesData.filter(address => address.customerRefId === null);
						}
						addressesData = addressesData.filter(address => address.type === 'Permanent');
						setAddresses(addressesData);
					}
				}
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setLoading(false);
			}
		};
		get();
	}, [vaultId, assetId]);

	useEffect(() => {
		fetchData();
	}, [vaultId, assetId, fetchData]);

	if (!vault || loading) {
		return <Loading />;
	}

	const transactionsWithinSystems = async (asset: VaultAsset) => {
		try {
			const destinationVaultID = vault.id !== omnibus.id ? omnibus.id : withdrawal.id;
			await transactionsWithinSystem({
				sourceVaultId: vault.id,
				destinationVaultID,
				asset: asset.id,
				amount: Number(asset.total)
			});
			fetchData();
			showSuccessNotification('Request sent');
		} catch (e) {
			showErrorNotification(e);
		}

	};

	const selectAsset = (asset: VaultAsset) => {
		history.push(`/fireblocks/vault/${vaultId}/${asset.id}/`);
	};

	const syncVault = async () => {
		try {
			setSyncLoading(true);
			await synchronizeBalance(vault.id);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSyncLoading(false);
		}

	};

	return (
		<Container fluid>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Account</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
							{vault.id !== omnibus.id && <Button
								style={{ marginLeft: 10 }}
								secondary
								loading={syncLoading}
								content='Sync balance'
								onClick={syncVault}
								floated='right'
							/>}
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable columns={2}>
					<Grid.Column className="profiledatacolumn">
						<Segment basic>
							<List id="profile-data">
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Name</List.Header>
										<List.Description>{vault.name}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Hidden on UI</List.Header>
										<List.Description>{vault.hiddenOnUI ? 'True' : 'False'}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Customer reference ID</List.Header>
										<List.Description>{vault.customerRefId ? vault.customerRefId : 'NULL' }</List.Description>
									</List.Content>
								</List.Item>
							</List>
						</Segment>
					</Grid.Column>
				</Grid>
				<Header as='h2'>Assets <span className="color-grey" >(click to load addresses and transactions)</span></Header>
				<Assets
					assets={vault.assets}
					onClick={selectAsset}
					onClickText='Get Asset Addresses'
					buttonOnClick={transactionsWithinSystems}
					buttonOnClickText={vault.id !== omnibus.id ? 'Send to Omnibus' : 'Send to Withdrawal'}
				/>
			</Segment>
			{addresses?.length > 0 && <Segment>
				<Header as='h2'>Addresses</Header>
				<Table id='table' celled sortable textAlign='center' padded='very' striped>
					<Table.Header fullWidth className='tableHeader'>
						<Table.Row className="tableHeaders">
							{addressesColumns.map((column, index) => <Table.HeaderCell
								key={`${column}${index}`}>{column}
							</Table.HeaderCell>)}
						</Table.Row>
					</Table.Header>
					<Table.Body className="tableBody">
						{addresses?.map((accountAddress, index) => {
							return (
								<Table.Row key={`${index}`} >
									<Table.Cell width={2}>{accountAddress.address}</Table.Cell>
									<Table.Cell width={2}>{accountAddress.description}</Table.Cell>
									<Table.Cell width={2}>{accountAddress.tag}</Table.Cell>
									<Table.Cell width={2}>{accountAddress.type}</Table.Cell>
									<Table.Cell width={2}>{accountAddress.addressFormat}</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</Segment>}
			{assetId && <Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Header as='h2'>Incoming Transactions</Header>
							<Transactions vaultId={vault.id} assetId={assetId} type='INCOMING'/>
						</Grid.Column>
						<Grid.Column>
							<Header as='h2'>Outgoing Transactions</Header>
							<Transactions vaultId={vault.id} assetId={assetId} type='OUTGOING'/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>}
		</Container>
	);
};

export default VaultDetails;
