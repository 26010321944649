import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import status from '@features/status/statusSlice';
import user from '@features/user/userSlice';
import swal from '@features/swal/slice';
import credentials from '@features/user/credentialsSice';
import clients from '@features/clients/slice';
import accounts from '@features/accounts/slice';
import fireblocks from '@features/fireblocks/slice';
import cards from '@features/cards/slice';
import transactions from '@features/transactions/slice';
import whitelabel from '@features/whitelabel/whitelabelSlice';
import documents from '@features/documents/slice';
import clientActivity from '@features/clientActivity/slice';
import countryLimits from '@/features/countryLimits/countryLimitsSlice';
import systemErrors from '@/features/systemErrors/systemErrorsSlice';
import languages from '@/features/languages/languagesSlice';
import files from '@/features/files/filesSlice';
import roles from '@/features/roles/rolesSlice';
import faq from '@/features/faq/faqSlice';
import faqDetails from '@/features/faq/faqDetailsSlice';
import unassignedPayments from '@/features/unassignedPayments/unassignedPaymentsSlice';
import integrationsUnassignedPayments from '@/features/integrationsUnassignedPayments/integrationsUnassignedPaymentsSlice';
import activityLogs from '@/features/activityLogs/activityLogsSlice';
import tickets from '@/features/tickets/ticketsSlice';
import processes from '@/features/processes/processesSlice';
import statements from '@/features/statements/statementsSlice';
import statementSummaries from '@/features/statements/statementSummariesSlice';
import statementTransactions from '@/features/statements/statementTransactionsSlice';
import balanceProcessStatus from '@/features/statements/balanceProcessStatusSlice';
import accountBalance from '@/features/statements/accountBalanceSlice';
import banks from '@/features/banks/banksSlice';
import periods from '@/features/periods/periodsSlice'; 
import vaults from '@/features/vaults/vaultsSlice';
import poa from '@/features/poa/slice';
import tokens from '@/features/tokens/slice';
import tokenRequests from '@/features/tokenRequests/slice';
import compliance from '@/features/compliance/slice';
import tokenTransfers from '@/features/tokenTransfers/slice';
import tokenWithdrawals from '@/features/tokenWithdrawals/slice';
import vaultBalance from '@/features/vaultBalances/slice';
import vaultProcesses from '@/features/vaultProcesses/slice';
import vaultsPayments from '@/features/vaultsPayments/slice';
import vaultProcessesVaults from '@/features/vaultProcessesVaults/slice';
import vaultPendingRequests from '@/features/vaultPendingRequests/slice';
import vaultPlannedTransactions from '@/features/vaultPlannedTransactions/slice';
import userGroups from '@/features/userGroups/slice';
import transactionTypes from '@/features/transactionTypes/slice';
import paymentConfig from  '@/features/payments/configSlice';
import systemAccountSummary from  '@/features/systemAccountDetails/summarySlice';
import paymentHistory from  '@/features/payments/historySlice';
import bankAccounts from  '@/features/bankAccounts/bankAccountsSlice';
import reconciliation from  '@/features/reconciliation/reconciliationSlice';
import systemAccountDetails from  '@/features/systemAccountDetails/detailsSlice';
import trustPayments from '@/features/trustPayments/slice';
import systemPaymentAccount from '@/features/systemPaymentAccount/slice';
import kycIssues from '@/features/kycIssues/slice';
import kycCalendar from '@/features/kycCalendar/slice';
import twoFA from '@features/2fa/slice';
import statistics from '@features/statistics/statisticsSlice';
import taxForm from '@features/taxForm/taxFormSlice';
import systemDocuments from '@features/systemDocuments/systemDocumentsSlice';
import amlReports from '@features/amlReports/amlReportsSlice';
import exchangeOrder from '@features/exchangeOrder/slice';
import accountPaymentPlan from '@features/accountPaymentPlan/slice';
import admins from '@features/admins/adminSlice';
import referralPrograms from '@features/referralPrograms/slice';
import referralPayments from '@features/referralPayments/slice';
import maintenance from '@features/maintenance/maintenanceSlice';

const appReducer = combineReducers({
	user, status, swal, credentials, clients, accounts, fireblocks, vaultProcesses, vaultProcessesVaults, accountPaymentPlan, vaultsPayments, cards, transactions, whitelabel, documents, clientActivity,
	countryLimits, systemErrors, languages, files, roles, faq, faqDetails, unassignedPayments, activityLogs, tickets, processes, poa, tokens, vaults, tokenRequests, compliance, tokenTransfers, tokenWithdrawals,
	vaultBalance, vaultPlannedTransactions, userGroups, statements, statementSummaries, statementTransactions, accountBalance, banks, periods,
	transactionTypes, paymentConfig, systemAccountSummary, paymentHistory, bankAccounts, reconciliation, systemAccountDetails,
	trustPayments, systemPaymentAccount, kycIssues, twoFA, kycCalendar, integrationsUnassignedPayments, statistics, taxForm,
	systemDocuments, exchangeOrder, balanceProcessStatus, amlReports, vaultPendingRequests, admins, referralPrograms, referralPayments,
	maintenance
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
	if (action.type === 'RESET_APP') {
		const { status } = state;
		state = {} as RootState;
		state = {
			...state,
			status
		};
	}
	if (action.type === 'SWITCH_REPRESENTATION') {
		state = {
			...state,
		};
	}
	return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>

export default rootReducer;
