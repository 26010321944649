import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { OmnibusAsset, OmnibusStore } from './types';

const initialState: OmnibusStore = {
	loading: false,
	list: [],
	name: '',
	error: null,
};


const slice = createSlice({
	name: 'fireblocks',
	initialState,
	reducers: {
		setOmnibusAssets(state, action: PayloadAction<Array<OmnibusAsset>>) {
			state.list = action.payload;
		},
		setOmnibusName(state, action: PayloadAction<string>) {
			state.name = action.payload;
		},
		setWithdrawalAssets(state, action: PayloadAction<Array<OmnibusAsset>>) {
			state.list = action.payload;
		},
		setWithdrawalName(state, action: PayloadAction<string>) {
			state.name = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
	}
});

export const { setLoading, setError } = slice.actions;

export const getVault = async (vaultId: string) => {
	const response = await request.get(`/api/admin/fireblocks/vault/${vaultId}`);
	const { data } = response;
	return data;
};

export const getAssetAddresses = async (vaultId: string, assetId: string) => {
	const response = await request.get(`/api/admin/fireblocks/vault/${vaultId}/addresses/${assetId}`);
	const { data } = response;
	return data;
};
export const synchronizeBalance = async (vaultId: string) => {
	const response = await request.get(`/api/admin/fireblocks/vault/${vaultId}/synchronizeBalance`);
	const { data } = response;
	return data;
};

export const getVaultTransactions = async (vaultId: string, payload: { assetId?: string, type?: string }) => {
	const response = await request.get(`/api/admin/fireblocks/vault/${vaultId}/transactions`, {
		params: {
			assetId: payload.assetId,
			type: payload.type,
		}
	});
	const { data } = response;
	return data;
};

export const vaultToOmnibus = async (assetId: string) => {
	const response = await request.post(`/api/admin/fireblocks/vaultsToOmnibus/${assetId}`);
	const { data } = response;
	return data;
};

export const transactionsWithinSystem = async (payload: {
	sourceVaultId: string,
	destinationVaultID: string,
	asset: string,
	amount: number,
}) => {
	const response = await request.post('/api/admin/fireblocks/transactionsWithinSystem', {
		sourceVaultId: payload.sourceVaultId,
		destinationVaultID: payload.destinationVaultID,
		asset: payload.asset,
		amount: payload.amount,
	});
	const { data } = response;
	return data;
};


export default slice.reducer;
