import { Director } from '@/features/compliance/types';
import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionTitle, Divider, Grid, Icon, List, Segment } from 'semantic-ui-react';
import '../../Clients/ClientDetails/Profile.css';

interface Props {
    directors: Director[];
}

const Directors = ({ directors }: Props) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    };

    return (
        <>
            {directors.map((director, index) =>
                <Accordion fluid styled key={index} style={{ marginBottom: '4px' }}>
                    <AccordionTitle
                        active={activeIndex === index}
                        index={index}
                        onClick={handleClick}
                    >
                        {`${director.directorFirstName} ${director.directorLastName}`}
                        <Icon name={activeIndex === index ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === index}>
                        {activeIndex === index && <DirectorForm director={director} />}
                    </AccordionContent>
                </Accordion>
            )}
        </>
    );
};

interface DProps {
    director: Director;
}

const DirectorForm = ({ director }: DProps) => {
    return (
        <Grid stackable columns={2}>
            <Grid.Column className="profiledatacolumn">
                <Segment className="usersegment" basic>
                    <List id="profile-data">
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">First name</List.Header>
                                <List.Description>{director.directorFirstName}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">Last Name</List.Header>
                                <List.Description>{director.directorLastName}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">Birth Date</List.Header>
                                <List.Description>{director.directorBirthDate}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                    </List>
                </Segment>
            </Grid.Column>
            <Grid.Column className="profiledatacolumn">
                <Segment className="usersegment" basic>
                    <List id="profile-data">
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">Political Exposed</List.Header>
                                <List.Description>{director.directorPep ? 'Yes' : 'No'}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                        <List.Item>
                            <List.Content style={{ padding: '0px' }}>
                                <List.Header className="color-grey">US Citizen</List.Header>
                                <List.Description>{director.directorUsCitizen ? 'Yes' : 'No'}</List.Description>
                            </List.Content>
                        </List.Item>
                        <Divider />
                    </List>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default Directors;
