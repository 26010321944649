import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getPaymentConfig, } from '@/features/payments/configSlice';
import { PaymentConfigurationType } from '@/features/payments/paymentTypes';
import PaymentConfigModal, { ModalMode } from './PaymentConfigModal';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { PaginateParams } from '@/helpers/globalTypes';
import './../Payment.css';

interface Props {
	writePermission: boolean
}

const PaymentConfig = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.paymentConfig
	);

	const paginateInitialState: PaginateParams = { orderBy: 'paymentConfigId', skip: 0, sort: 'ASC', filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);


	const [openModal, setOpenModal] = useState<boolean>(false);
	const [modalMode, setModalMode] = useState<ModalMode>(null);
	const [item, setItem] = useState<PaymentConfigurationType>(null);


	const headers: Array<Headers> = [
		{
			field: 'wlpId',
			name: 'White Label',
			position: 1,
			sortable: true
		},
		{
			field: 'description',
			name: 'Description',
			position: 2,
			sortable: true
		},
		{
			field: 'walletId',
			name: 'Wallet ID',
			position: 3,
			sortable: true
		},
		{
			field: 'operationCode',
			name: 'Operations',
			position: 4,
			sortable: true
		},
		{
			field: 'fromAccountId',
			name: 'From Wallet ID',
			position: 5,
			sortable: true
		},
		{
			field: 'toAccountId',
			name: 'To Wallet ID',
			position: 6,
			sortable: true
		},
		{
			field: 'paymentDescription',
			name: 'Comments',
			position: 7,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 8,
			sortable: false
		}
	];

	const handleAdd = () => {
		setItem(null);
		setModalMode(ModalMode.ADD);
		setOpenModal(true);
	};

	const handlEditConfig = (item: PaymentConfigurationType) => {  
		setItem(item);
		setModalMode(ModalMode.EDIT);
		setOpenModal(true);
	}; 

	const handleCloseModal = async () => {
		setOpenModal(false);
	};

	const onSuccess =() => {
		dispatch(getPaymentConfig(pagination));
	};

	useEffect(() => {
		dispatch(getPaymentConfig(pagination));
	}, [dispatch, pagination]);

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.paymentConfigId}${index}`} >
						<Table.Cell width={1}>{entry.wlpId}</Table.Cell>
						<Table.Cell width={2}>{entry.description}</Table.Cell>
						<Table.Cell width={2}>{entry.walletId}</Table.Cell>
						<Table.Cell width={2}>{entry.operationName}</Table.Cell>
						<Table.Cell width={1}>{entry.fromWalletId}</Table.Cell>
						<Table.Cell width={1}>{entry.toWalletId}</Table.Cell>
						<Table.Cell width={1}>{entry.paymentDescription}</Table.Cell>
						<Table.Cell selectable={false} width={1}>
							<Popup content='Operation Details' trigger={<Icon className='tableActionIcon' size='large' name="cogs" onClick={() => handlEditConfig(entry)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{
				list.length === 0 && <Table.Row>
					<Table.Cell textAlign='center' colSpan={5}>No Config yet</Table.Cell>
				</Table.Row>
			}
		</Table.Body >
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid id="payment-configuration">
			<Segment>
				<PaymentConfigModal
					open={openModal}
					paymentConfig={item}
					modalMode={modalMode}
					onClose={handleCloseModal}
					onSuccess={onSuccess}
					writePermission={writePermission} />
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Payment Configuration</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button
								floated='right'
								primary
								//disabled={!writePermission}
								onClick={handleAdd}
							> Add Configuration</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					loading={loading}
					paginateInitialState={pagination}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};

export default PaymentConfig;
