import { PaginateParams } from '@/helpers/globalTypes';
import request from '@/services/request';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showException } from '../swal/slice';
import { AmlReport, AmlReportStore } from './amlReportsTypes';
import { transformFilterToObject } from '@/components/Shared/Table/Table';

const initialState: AmlReportStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
	isDownloading: false,
};

const slice = createSlice({
	name: 'amlReports',
	initialState,
	reducers: {
		setAmlReports(state, action: PayloadAction<Array<AmlReport>>) {
			state.list = action.payload || [];
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		},
		setDownloading: (state, { payload }: PayloadAction<boolean>) => {
			state.isDownloading = payload;
		},
	}
});

export const { setAmlReports, setDownloading, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getLargeTransactionExport = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));


			let newFilter = payload.filter;
			let repGreaterThan;
			if (payload.filter) {
				const filerObj = transformFilterToObject(newFilter);
				if (filerObj['repGreaterThan']) {
					repGreaterThan = filerObj['repGreaterThan'];
					delete filerObj['repGreaterThan'];
				}

				newFilter = '';
				Object.entries(filerObj).map(([key, value]) => {
					if (value.toString().trim() !== '') {

						if (newFilter !== '') {
							newFilter += ';';
						}
						newFilter = newFilter.concat(`${key}=${value.toString().trim()}`);
					}
				});
			}

			const filterParams = {
				repGreaterThan,
				filter: newFilter,
				orderBy: payload.orderBy,
				sort: payload.sort.toUpperCase(),
				skip: payload.skip.toString(),
				take: payload.take,
			};
			const response = await request.get('/api/admin/reports/aml', { params: filterParams });
			const { data } = response;

			dispatch(setAmlReports(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			dispatch(setLoading(false));
			showException(e);
		}
	};
};


export const downloadLargeTransactionExport = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setDownloading(true));

			let newFilter = payload.filter;
			let repGreaterThan;
			if (payload.filter) {
				const filerObj = transformFilterToObject(newFilter);
				if (filerObj['repGreaterThan']) {
					repGreaterThan = filerObj['repGreaterThan'];
					delete filerObj['repGreaterThan'];
				}

				newFilter = '';
				Object.entries(filerObj).map(([key, value]) => {
					if (value.toString().trim() !== '') {

						if (newFilter !== '') {
							newFilter += ';';
						}
						newFilter = newFilter.concat(`${key}=${value.toString().trim()}`);
					}
				});
			}


			const filterParams = {
				repGreaterThan,
				filter: newFilter,
				orderBy: payload.orderBy,
				sort: payload.sort.toUpperCase(),
				skip: 0,
				take: 1000000
			};
			const response = await request.get('/api/admin/reports/aml/export',
				{
					responseType: 'blob',
					params: filterParams,
				});

			const url = window.URL.createObjectURL(response.data);
			const link = document.createElement('a');
			const d = new Date();
			link.download = `aml_export_${d.toJSON()}.csv`;
			link.href = url;
			link.className = 'hidden';
			document.body.appendChild(link);

			link.onclick = function () {
				requestAnimationFrame(function () {
					URL.revokeObjectURL(url);
					setTimeout(() => link.remove(), 300);
				});
			};

			link.click();
			dispatch(setDownloading(false));
		}
		catch (e) {
			showException(e);
			dispatch(setDownloading(false));
		}
		finally { dispatch(setDownloading(false)); }
	};
};



export const downloadVAspReport = async (wlpId: string, vaspRegNr: string, dateFrom: string, dateTo: string) => {
	try {
		const response = await request.get(`api/admin/reports/vasp/export/${vaspRegNr}/${dateFrom}/${dateTo}`,
			{
				responseType: 'blob',
				params: { wlpdId: wlpId },
			});
		const url = window.URL.createObjectURL(response.data);
		const link = document.createElement('a');
		link.download = `${wlpId}-${dateFrom}-${dateTo}-vasp.xml`;
		link.href = url;
		link.className = 'hidden';
		document.body.appendChild(link);

		link.onclick = function () {
			requestAnimationFrame(function () {
				URL.revokeObjectURL(url);
				setTimeout(() => link.remove(), 300);
			});
		};

		link.click();
	}
	catch (e) {
		showException(e);
	}

};

export default slice.reducer;
