import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Popup, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getTransactions } from '@/features/transactions/slice';
import { convertDateFromEpoch, formatDateWithPattern } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

interface Props {
	accountId: number,
}

const Transactions = ({ accountId }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.transactions
	);

	const paginateInitialState: PaginateParams = { orderBy: 'date', sort: 'DESC', skip: 0, filter: '', take: 5 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);


	const headers: Array<Headers> = [
		{
			field: 'documentNumber',
			name: 'Number',
			position: 1,
			sortable: true
		},
		{
			field: 'transactionType',
			name: 'Activity',
			position: 2,
			sortable: true
		},
		{
			field: 'amountOrigCcy',
			name: 'Amount',
			position: 3,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 4,
			sortable: true
		},
		{
			field: 'date',
			name: 'Date',
			position: 5,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 6,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getTransactions(accountId, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch, accountId]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, accountId, fetchData]);

	const history = useHistory();

	const openDocument = (documentNumber: string) => {
		history.push(`/document/${documentNumber}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.date}${entry.documentNumber}${index}`} >
						<Table.Cell width={2}>{entry.documentNumber}</Table.Cell>
						<Table.Cell width={2}>{entry.transactionType}</Table.Cell>
						<Table.Cell width={2}>{entry.asset} {entry.amount === null ? '0' : <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} />}</Table.Cell>
						<Table.Cell width={2}>{entry.status}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(convertDateFromEpoch(entry.date), 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='Open document details' trigger={<Icon className='tableActionIcon' size='large' name="file outline" onClick={() => openDocument(entry.documentNumber)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>This account dont have transactions yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (

		<CustomTable
			fetchData={fetchData}
			loading={loading}
			paginateInitialState={paginateInitialState}
			tableBody={tableBody}
			totalPages={totalPages}
			setPagination={setPagination}
			header={headers}
			pagination={pagination}
			hideItemsPerPage={true}
		/>
	);
};


export default Transactions;
