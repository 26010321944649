import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';


interface Props {
	open: boolean;
	initialService?: string,
	initialServiceId?: string,
	initialCheckId?: string,
	onClose: (
		service?: string,
		serviceId?: string,
		checkId?: string,
	) => void,
}

const UpdateKycModal = ({ open, onClose, initialService, initialServiceId, initialCheckId }: Props): React.ReactElement => {

	const initialValues = {
		service: initialService ?? '',
		serviceId: initialServiceId ?? '',
		checkId: initialCheckId ?? '',
	};

	const validationSchema = Yup.object({
		service: Yup.string().required('This field is required'),
		serviceId: Yup.string().required('This field is required'),
		checkId: Yup.string().when('service', {
			is: 'ONFIDO',
			then: Yup.string().required('This field is required'),
			otherwise: Yup.string().notRequired(),
		}),
	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { service, serviceId, checkId } = formData;
		onClose(service, serviceId, checkId);
	};

	const kycServices = [
		{
			key: 'ONFIDO',
			value: 'ONFIDO',
			text: 'Onfido'
		},
		{
			key: 'SUMSUB',
			value: 'SUMSUB',
			text: 'SumSub'
		}
	];

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty, values }) => (
					<Form
						size="large">
						<Header content={'Create KYC'} />
						<Select
							label='KYC provider'
							fluid
							name="service"
							options={kycServices}
							search
							clearable
							selection
							errorPrompt
						/>
						<Input
							label='Applicant ID'
							name="serviceId"
							errorPrompt
						/>
						{values.service === 'ONFIDO' && <Input
							label='Check ID'
							name="checkId"
							errorPrompt
						/>}
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										Change
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default UpdateKycModal;
