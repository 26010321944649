
export interface ReferralProgram {
    id: number,
    name: string,
    description: string,
    code: RewardTypes,
    application: RewardApplicationType,
    targetAmount: number,
    targetAmountString: string,
    paymentType: RewardPaymentType,
    rewardAmount: number,
    rewardCcy: string,
    disabled: boolean,
    startDate: string,
    endDate: string,
}

export enum RewardPaymentType {
	PERCENT = 'PERCENT',
	AMOUNT = 'AMOUNT',
}

export enum RewardApplicationType {
	IMMEDIATE = 'IMMEDIATE',
	MONTHLY = 'MONTHLY',
}

export enum RewardTypes {
	FEES = 'FEES',
	SIGN_UP_BONUS = 'SIGN_UP_BONUS',
	TRADE_TURNOVER = 'TRADE_TURNOVER',
	SIGN_UP_BONUS_TARGET = 'SIGN_UP_BONUS_TARGET',
}

export interface ReferralProgramStore {
    loading: boolean,
    list: Array<ReferralProgram>,
    error: string,
    count: number,
    totalPages: number,
    pageSize: number
}
