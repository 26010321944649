import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Divider, Header, Segment, Select, Table, TextArea } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import NumberFormat from 'react-number-format';
import { getReconciliations } from '@/features/reconciliation/reconciliationSlice';
import { editItemAllowed, ReconciliationState } from '../Reconcile';
import useBankAccountLov from '@/helpers/customHook/useBankAccountLov';
import useWhiteLabelLov from '@/helpers/customHook/useWhiteLabelLov';
import { PaginateParams } from '@/helpers/globalTypes';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	writePermission: boolean,
	reconcile: ReconciliationState,
	changeReconciliationState: (id: number, event: any, data: any) => void
}

const filter: FilterParam[] = [
	{
		field: 'iban',
		label: 'IBAN',
		type: 'input'
	}, {
		field: 'amount',
		label: 'Amount',
		type: 'input'
	},
	{
		field: 'customerName',
		label: 'Customer Name',
		type: 'input'
	},
	{
		field: 'transactionDetails',
		label: 'Description',
		type: 'input'
	}
];

const headers: Array<Headers> = [

	{
		field: 'reconciled',
		name: 'Reconcile',
		position: 1,
		sortable: false
	},
	{
		field: 'wlpId',
		name: 'White Label',
		position: 2,
		sortable: false
	},
	{
		field: 'bankAccountId',
		name: 'Bank Account',
		position: 3,
		sortable: false
	},
	{
		field: 'transactionId',
		name: <div>Transaction Id<br />Iban<br />Date </div>,
		position: 4,
		sortable: false
	},
	{
		field: 'amount',
		name: 'Currency Amount',
		position: 5,
		sortable: false
	},
	{
		field: 'customerName',
		name: 'Customer Description',
		position: 6,
		sortable: false
	},
	{
		field: 'date',
		name: 'Date',
		position: 7,
		sortable: false
	},
	{
		field: 'amount',
		name: 'Amount',
		position: 8,
		sortable: false
	}
];



const ReconcileTrx = ({ writePermission, reconcile, changeReconciliationState }: Props): React.ReactElement => {

	const dispatch = useDispatch();

	const paginateInitialState: PaginateParams = { orderBy: 'wlpId', skip: 0, sort: 'ASC', filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const { list, totalPages, error, loading } = useSelector((state: RootState) => state.reconciliation);

	const { bankAccountLov } = useBankAccountLov();
	const { whitelabelOptions } = useWhiteLabelLov();

	useEffect(() => {
		dispatch(getReconciliations(pagination));
	}, [dispatch, pagination]);


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry) => (
				<Table.Row key={`${entry.bankAccountId}-${entry.id}`}>
					<Table.Cell className='editable-cell'>
						<Checkbox name={`reconciled-${entry.id}`}
							checked={reconcile[entry.id]?.reconciled ?? entry?.reconciled ?? false}
							onChange={(e, data) => changeReconciliationState(entry.id, e, data)}
							disabled={!writePermission} />
					</Table.Cell>
					<Table.Cell className='editable-cell'>
						<Select
							name={`wlpId-${entry.id}`}
							options={whitelabelOptions}
							onChange={(e, data) => changeReconciliationState(entry.id, e, data)}
							value={reconcile[entry.id]?.wlpId ?? entry?.wlpId ?? ''}
							disabled={!editItemAllowed(entry.id, entry.reconciled, reconcile, writePermission)}
							selection
							required />
					</Table.Cell>
					<Table.Cell className='editable-cell'>
						<Select
							name={`bankAccountId-${entry.id}`}
							options={bankAccountLov}
							onChange={(e, data) => changeReconciliationState(entry.id, e, data)}
							value={reconcile[entry.id]?.bankAccountId?.toString() ?? entry?.bankAccountId?.toString() ?? ''}
							selection
							search
							required
							disabled={!editItemAllowed(entry.id, entry.reconciled, reconcile, writePermission)}
						/>
					</Table.Cell>
					<Table.Cell>{entry.transactionId}
						<br />{entry.iban}
						<br />{entry.transactionDate}</Table.Cell>
					<Table.Cell>
						<NumberFormat displayType={'text'}
							fixedDecimalScale={false}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={entry.currencyCode + ' '}
							value={entry.amount} />
					</Table.Cell>
					<Table.Cell>{entry.customerName} <br />  {entry.transactionDetails}</Table.Cell>
					<Table.Cell width={1}>{entry.reconciliationDate}
					</Table.Cell>
					<Table.Cell className='editable-cell reconcile-amount'>
						<TextArea
							name={`reconciledAmount-${entry.id}`}
							type='number'
							disabled={!editItemAllowed(entry.id, entry.reconciled, reconcile, writePermission)} />
					</Table.Cell>
				</Table.Row>
			))}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>No reconciliations yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;




	return (
		<Segment>
			<Header as='h2'>Reconciliation</Header>
			<Divider hidden />
			{list.length > 0 &&
				<CustomTable
					filter={filter}
					loading={loading}
					paginateInitialState={pagination}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			}
		</Segment>
	);
};

export default ReconcileTrx; 
