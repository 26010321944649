import React, { useState, useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { Tab, Dimmer, Loader } from 'semantic-ui-react';
import AdminList from './AdminList';
import { AdminsRoleType, EmiListType } from '@/features/admins/adminTypes';
import { getIsEmi } from '@/features/admins/adminSlice';

interface Props {
	writePermission: boolean,
}

const Admins = ({ writePermission }: Props): React.ReactElement => {

	const [emi, setEmi] = useState<EmiListType>();
	const getEmi = async () => {
		const data = await getIsEmi();
		setEmi(data);
	};

	useEffect(() => { getEmi(); }, []);

	const panes = [
		{
			menuItem: 'White Label Admins',
			render: () => <Tab.Pane>
				<AdminList writePermission={writePermission} adminType={AdminsRoleType.WLP_ADMIN} emi={emi} />
			</Tab.Pane>
		}
	];

	if (emi?.isEmi) {
		panes.push({
			menuItem: 'EMI Admins',
			render: () => <Tab.Pane>
				<AdminList writePermission={writePermission} adminType={AdminsRoleType.EMI_ADMIN} emi={emi} />
			</Tab.Pane>
		});
	}

	return (

		<Container fluid>
			{!emi ?
				<Dimmer active={!emi} inverted>
					<Loader />
				</Dimmer>
				:
				<Tab panes={panes} />
			}
		</Container>
	);
};


export default Admins;
