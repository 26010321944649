import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { confirmRequest, getTokenRequests } from '@/features/tokenRequests/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { Button, Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import { convertDateFromEpoch, formatDateWithPattern } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import { TokenRequest } from '@/features/tokenRequests/types';
import ConfirmRequestModal from './ConfirmRequestModal';
import { useHistory } from 'react-router-dom';
import { FilterParam } from '@/components/Shared/Table/TableFilters';

export interface _TokenRequest {
	tokenRequest: TokenRequest,
	tokenPrice: number,
	direction: string
}

interface Props {
	writePermission: boolean,
}

const TokenRequests = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.tokenRequests
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);
	const [open, setOpen] = useState<boolean>(false);
	const [requestToConfirm, setRequestToConfirm] = useState<_TokenRequest>(null);

	const filter: FilterParam[] = [
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: [{ key: 'REQUEST', value: 'REQUEST', text: 'Request' }, { key: 'OFFER', value: 'OFFER', text: 'Offer' }, { key: 'USED', value: 'USED', text: 'Used' }]
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'createdDate',
			name: 'Date',
			position: 1,
			sortable: true
		},
		{
			field: 'fromAmount',
			name: 'From Amount',
			position: 2,
			sortable: true
		},
		{
			field: 'toAmount',
			name: 'To Amount',
			position: 3,
			sortable: true
		},
		{
			field: 'direction',
			name: 'Direction',
			position: 4,
			sortable: false
		},
		{
			field: '',
			name: 'Token Price',
			position: 5,
			sortable: false
		},
		{
			field: 'status',
			name: 'Status',
			position: 6,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 7,
			sortable: false
		}
	];

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getTokenRequests(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const handleClose = async (fromAmount?: number, toAmount?: number, expirationInHours?: number, requestId?: number) => {
		setOpen(false);
		if (fromAmount && toAmount && expirationInHours && requestId) {
			try {
				await confirmRequest(toAmount, fromAmount, expirationInHours, requestId);
				fetchData();
			} catch (e) {
				showErrorNotification(e);
			}
		}
	};


	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			<ConfirmRequestModal
				open={open}
				tokenRequest={requestToConfirm}
				onClose={handleClose}
			/>
			{list.map((entry, index) => {

				let direction = entry.properties.direction;
				let price = 0;
				if (direction === 'BUY' || direction === 'SELL') {
					price = direction === 'BUY' ? entry.fromAmount / entry.toAmount : entry.toAmount / entry.fromAmount;
				} else {
					direction = 'FAILURE';
				}

				const _tr: _TokenRequest = {
					tokenRequest: entry,
					tokenPrice: price,
					direction: direction
				};

				return (
					<Table.Row key={`${entry.createdDate}${entry.id}${index}`} >
						<Table.Cell width={2}>{formatDateWithPattern(convertDateFromEpoch(entry.createdDate), 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={` ${entry.fromCcy}`} value={entry.fromAmount} /></Table.Cell>
						<Table.Cell width={2}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={` ${entry.toCcy}`} value={entry.toAmount} /></Table.Cell>
						<Table.Cell width={2}>{entry.properties.direction}</Table.Cell>
						<Table.Cell width={2}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={` ${direction === 'BUY' ? entry.fromCcy : entry.toCcy}`} value={price} /></Table.Cell>
						<Table.Cell width={2}>{entry.status}</Table.Cell>
						<Table.Cell width={2}>
							<Button primary disabled={!writePermission || entry.status !== 'REQUEST'} content='Confirm' onClick={() => { setRequestToConfirm(_tr); setOpen(true); }} />
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={7}>No token requests yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Token Requests</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					hideItemsPerPage={true}
					filter={filter}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};

export default TokenRequests;
