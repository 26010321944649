import { getBankAccounts } from '@/features/bankAccounts/bankAccountsSlice';
import { RootState } from '@/rootReducer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useBankAccountLov = (selectedWhiteLabel?: string) => {

	const dispatch = useDispatch();

	const { whitelabel } = useSelector((state: RootState) => state.whitelabel);
	const { list: bankAccountList, status: bankAccountStatus, wlpId } = useSelector((state: RootState) => state.bankAccounts);

	const queryWlp = selectedWhiteLabel ? selectedWhiteLabel : whitelabel;
 
	useEffect(() => {
		if (bankAccountStatus === 'idle' || wlpId != queryWlp) {
			dispatch(getBankAccounts(queryWlp));
		}
	}, [dispatch, queryWlp, wlpId]);

	const bankAccountLov = bankAccountList.map((p) => { return { key: p.bankAccountIdLov.toString(), text: p.description, value: p.bankAccountIdLov.toString() }; });

	return { bankAccountList, bankAccountLov, bankAccountStatus };
};

export default useBankAccountLov;
