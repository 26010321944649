import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import request from '@services/request';
import { logout } from '@features/user/userSlice';
import { dismissAllNotifications, showErrorNotification, showException } from '@/features/swal/slice';
import { close2FAModal, open2FAModal, request2FA, set2FAMessage } from '@/features/2fa/slice';
import { useTranslation } from 'react-i18next';

const Interceptors = (): React.ReactElement => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation('translations');

	const getStatus = (err) => {
		const { response } = err;
		if (response) {
			const { status } = response;
			return status;
		} else {
			const { status } = err;
			return status;
		}
	};

	const getData = (err) => {
		const { response } = err;
		if (response) {
			const { data } = response;
			return data;
		} else {
			const { data } = err;
			return data;
		}
	};

	useEffect(() => {
		// Register intersectors
		request.interceptors.request.use(
			(config) => {
				// Get token and add it to header "Authorization"
				const credentialsKey = process.env.REACT_APP_CREDENTIALS_KEY;
				try {
					const credentials = JSON.parse(sessionStorage.getItem(credentialsKey));
					// If user not authorized just forward the request further
					if (!credentials) {
						return config;
					}
					config.headers.Authorization = credentials.token;
				}
				catch (e) {
					console.log(e);
				}
				config.headers['Content-Type'] = 'application/json';
				return config;
			},
			(error) => Promise.reject(error)
		);
		request.interceptors.response.use(undefined, (err) => {
			try {
				const {
					config,
					response
				} = err;
				const status = getStatus(err);
				const data = getData(err);

				const originalRequest = config;
				if (data && data.errors && data.errors.length && (data.errors[0].error === 'required' && data.errors[0].error_param === 'authenticatorCode')) {
					dispatch(set2FAMessage(t('form.twofa.mandatory')));
					dispatch(open2FAModal());
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				}
				else if (data && data.error === 'required' && data.error_param === 'authenticatorCode') {
					dispatch(set2FAMessage(t('form.twofa.mandatory')));
					dispatch(open2FAModal());
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				}
				else if (data && data.error === 'required' && data.error_param === 'two_factor_auth') {
					dispatch(showException({
						data,
						status: 417
					}, [{
						key: 'required',
						title: 'Error',
						message: '2FA required. Please configure your 2FA via portal.'
					}]));
					showErrorNotification(data, '2FA required. Please configure your 2FA via portal.');
					dispatch(close2FAModal());
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				}
				else if (data && data.errors && data.errors[0].error === 'invalid' && data.errors[0].error_param === 'authenticatorCode' || data && data.message === 'wrong two factor code') {
					dispatch(set2FAMessage(t('form.twofa.invalid')));
					dispatch(open2FAModal());
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				} else if (data && data.error  === 'invalid' && data.error_param === 'authenticatorCode') {
					dispatch(set2FAMessage(t('form.twofa.invalid')));
					dispatch(open2FAModal());
					return new Promise((resolve, reject) => {
						request2FA(originalRequest, resolve, reject, response);
					});
				}
				if (data && data.errors && data.errors.length && (data.errors[0].message !== 'wrong two factor code')) {
					dispatch(close2FAModal());
				} else if (data && data.message !== 'wrong two factor code') {
					dispatch(close2FAModal());
				}

				if (status === 403) {
					history.push('/not-permitted');
				}

				if (status === 401) {
					dismissAllNotifications();
					history.push('/auth');
					dispatch(logout());
				}
				return Promise.reject(response);
			} catch (e) {
				return Promise.reject(e);
			}
		});
	}, [dispatch, history]);

	return (
		<div id="interceptors"></div>
	);
};

export default Interceptors;
