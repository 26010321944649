import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { TrustPayment } from '@/features/trustPayments/types';


interface Props {
	open: boolean;
	onClose: (transferFee?: number, externalIds?: string, trustPayment?: TrustPayment) => void,
	trustPayment: TrustPayment
}

const ExecuteTrustPaymentModal = ({ open, onClose, trustPayment }: Props): React.ReactElement => {

	const initialValues = {
		transferFee: 0,
		externalIds: ''
	};

	const validationSchema = Yup.object({
		transferFee: Yup.number()
			.required('You must enter a executedFee'),
	});


	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { transferFee, externalIds  } = formData;

		
		onClose(transferFee, externalIds, trustPayment);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting }) => (
					<Form
						size="large">
						<Header content='Execute trust payment' />

						<Input
							label='Transfer fee'
							fluid
							name="transferFee"
							type='number'
						/>
						<Input
							label='External ID'
							fluid
							name="externalIds"
						/>
						
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors)}
										primary size="large" type="submit"
										floated='right' >
										Confirm
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default ExecuteTrustPaymentModal;
