
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Divider, Grid, Header, Segment, Select } from 'semantic-ui-react';
import { showErrorNotification } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getMaintenanceMode } from '@/features/maintenance/maintenanceSlice';
import Loading from '@/components/Shared/Loading';
import MaintenanceLine from './MaintenanceLine';
import { MaintenanceType } from '@/features/maintenance/maintenanceType';
import MaintenanceModal, { maintenanceStatusOptions } from './MaintenanceModal';

const Maintenance = (): React.ReactElement => {
    const dispatch = useDispatch();
    const { list, error, loading } = useSelector((state: RootState) => state.maintenance);
    const [selected, setSelected] = useState<MaintenanceType | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const [status, setStatus] = useState<any>(null);


    const onRowClick = (item: MaintenanceType) => {
        setSelected(item);
        setOpen(true);
    };

    const onNew = () => {
        setSelected(null);
        setOpen(true);
    };
    const fetchData = useCallback((status: any) => {
        const get = async () => {
            try {
                await dispatch(getMaintenanceMode(status));
            } catch (e) {
                showErrorNotification(e);
            }
        };
        get();
    }, [dispatch]);

    useEffect(() => {
        fetchData(status);
    }, [dispatch, status]);


    const refresh = () => useCallback(() => { fetchData(status); }, [status, fetchData]);

    if (loading) { return <Loading />; }
    if (error) return <Segment>Error: {error}</Segment>;

    return (<Container fluid>
        <MaintenanceModal
            item={selected}
            open={open}
            onClose={() => setOpen(false)}
            onSuccess={() => fetchData(status)} />
        <Segment>
            <Grid>
                <Grid.Row columns={2} verticalAlign='middle' >
                    <Grid.Column> <Header as='h2'>Maintenances</Header> </Grid.Column>
                    <Grid.Column> <Button floated='right' primary content='Create new' onClick={onNew} /> </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} verticalAlign='middle' >
                    <Grid.Column>
                        Status
                    </Grid.Column>
                    <Grid.Column>
                        <Select
                            name='status'
                            value={status}
                            options={maintenanceStatusOptions}
                            clearable
                            onChange={(element, { value }) => setStatus(value)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider hidden />

            {list.map(item => <MaintenanceLine item={item} key={item.id} onClick={onRowClick} refreshData={refresh} />)}

        </Segment>
    </Container>
    );
};

export default Maintenance;
