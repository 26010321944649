import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';


interface Props {
	open: boolean;
	onClose: (commDate?: number) => void,
}

const ChangeCommDate = ({ open, onClose }: Props): React.ReactElement => {

	const initialValues = {
		commDate: '',
	};

	const validationSchema = Yup.object({
		commDate: Yup.date()
			.required('You must enter a commission date')

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { commDate } = formData;

		const epochDate = new Date(commDate).getTime();
		console.log('commission date', epochDate);
		onClose(epochDate);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content={'Change commission date'} />

						<Input
							name="commDate"
							label="Commission date"
							type="date"
							max="2999-12-31"
							errorPrompt 
							/>
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										Change
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default ChangeCommDate;
