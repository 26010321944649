export interface VaultsStore {
    loading: boolean,
    list: Array<Vault>,
    error: string
}

export enum VaultPeriodEnum {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

export interface Vault {
    yearlyInterestRate: number,
    interestPaymentPeriod: VaultPeriodEnum,
    depositPeriodMonths: number,
    skipPaymentIfAmountLessThan: number,
    skipPaymentIfDaysLessThan: number,
    minimalTransferableAmount: number,
    name: string,
    description: string,
    currency: string,
    minDepositAmount: number,
    systemVaultAccountId?: number,
    maxAmountWithoutApproval: number,
    approvalType?: VaultApprovalType,
    systemAccountIntegration?: string,
    autoExtendDays?: number,
    config?: {
        rolesAllowed?: Array<string>,
        disabled?: boolean,
        config?: {
            approvalType: string,
            autoExtendDays?: number,
            depositPeriodMonths: number,
            interestPaymentPeriod: VaultPeriodEnum,
            maxWithoutApprove: number,
            minimalTransferableAmount: number,
            skipPaymentIfAmountLessThan: number,
            skipPaymentIfDaysLessThan: number,
            systemVaultAccountId: number,
            yearlyInterestRate: string,
            minDepositAmount: number,
            systemAccountIntegration?: string,
            systemVaultAccountWalletId?: string
            systemVaultInterestAccountWalletId?: string
            systemVaultRepaymentAccountWalletId?: string,
            depositTrade?: boolean,
            logoLink?: string,
            compoundInterest?: string,
            serviceProvider?: string,
        }
    },
    processProperties?: {
        ccy: string
    }
    proc?: string,
    wlpProcessId?: number,
    userGroupId?: number,
    userProfileType?: string,
    userLevelId?: number
    depositSystemAccount?: string
    interestSystemAccount?: string
    repaymentSystemAccount?: string,
    logoLink?: string,
    depositTrade?: boolean,
    compoundInterest?: boolean, 
    serviceProvider?: string,
}

export interface VaultConfig {
    config: any,
    disabled: boolean,
    rolesAllowed: Array<string>,
}

export interface VaultDepositPayload {
    amount: number,
    accountId: number,
}

export interface ToogleVaultPayload {
    proc: string,
    userLevelId?: number,
    userGroupId?: number,
    userProfileType?: string
    config: VaultConfig
}


export interface VaultProviders {
    id: number,
    serviceOwner: string,
    ownerKey: string | null,
    integration: string,
    emiWlpId: string,
    wlpId: string,
    activeFrom: Date,
    activeTo: Date,
    enabled: boolean
}

export enum VaultApprovalType {
    NONE = 'NONE',
    THREE_STAGE = 'THREE_STAGE',
    TWO_STAGE = 'TWO_STAGE',
    ONE_STAGE = 'ONE_STAGE',
    ONE_STAGE_ONCE_BY_INTEGRATION = 'ONE_STAGE_ONCE_BY_INTEGRATION'
}
