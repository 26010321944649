/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { showErrorNotification } from '@/features/swal/slice';
import Loading from '@/components/Shared/Loading';
import { checkWritePermission } from '@/helpers/permissions';
import { PermissionType } from '@/features/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Accordion, AccordionContent, AccordionTitle, Container, Divider, Icon } from 'semantic-ui-react';
import { Process } from '@/features/processes/processesTypes';
import { getProcess } from '@/features/processes/processesSlice';
import Processes from './Processes';
import ProcessDetailsSection from './ProcessDetails';
import ProcessCountries from './ProcessCountries';
import ProcessFeeTable from './ProcessFee';


const ProcessDetails = (props): React.ReactElement => {

	const [processName] = useState<string>(props.match.params.processName);
	const [process, setProcess] = useState<Process>(null);
	const [loading, setLoading] = useState<boolean>(false);


	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			setLoading(true);
			try {
				const data = await getProcess(processName);
				setProcess(data);
				setLoading(false);
			} catch (e) {
				showErrorNotification(e);
				setLoading(false);
			}
		};
		get();
	}, [processName]);

	useEffect(() => {
		fetchData();
	}, [processName, fetchData]);

	const [activeIndex, setActiveIndex] = useState<number>(-1);

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};


	if (!process) {
		return <Loading />;
	}

	return (
		<>
			{process && <ProcessDetailsSection writePermission={checkWritePermission(permissions, PermissionType.USERS)} permissions={permissions} fetchData={fetchData} process={process} goBack={() => props.history.goBack()} />}
			<Divider hidden />
			<Container id='accordian'>

				{process.subProcesses.length > 0 && <Accordion fluid styled style={{ marginBottom: 5 }} >
					<AccordionTitle
						active={activeIndex === 0}
						index={0}
						onClick={handleClick}
					>
						Sub processes
						<Icon name={activeIndex === 0 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
					</AccordionTitle>
					<AccordionContent active={activeIndex === 0}>
						{activeIndex === 0 && <Processes list={process.subProcesses} writePermission={checkWritePermission(permissions, PermissionType.PROCESSES)} fetchData={fetchData} loading={loading} />}
					</AccordionContent>
				</Accordion>}


				<Accordion fluid styled style={{ marginBottom: 5 }} >
					<AccordionTitle
						active={activeIndex === 5}
						index={5}
						onClick={handleClick}
					>
						Fees
						<Icon name={activeIndex === 5 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
					</AccordionTitle>
					<AccordionContent active={activeIndex === 5}>
						<AccordionContent active={activeIndex === 5}>
							{activeIndex === 5 && <ProcessFeeTable writePermission={checkWritePermission(permissions, PermissionType.PROCESSES)} process={process} />}
						</AccordionContent>
					</AccordionContent>
				</Accordion>

				{process.root && <Accordion fluid styled style={{ marginBottom: 5 }} >
					<AccordionTitle
						active={activeIndex === 1}
						index={1}
						onClick={handleClick}
					>
						Available Countries
						<Icon name={activeIndex === 1 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
					</AccordionTitle>
					<AccordionContent active={activeIndex === 1}>
						{activeIndex === 1 && <ProcessCountries writePermission={checkWritePermission(permissions, PermissionType.PROCESSES)} fetchData={fetchData} process={process} />}
					</AccordionContent>
				</Accordion>}


			</Container>

		</>
	);
};


export default ProcessDetails;
