import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { Button, Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { formatDateWithPattern } from '@/helpers/date';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { executePayment, getTrustPayments, rejectPayment } from '@/features/trustPayments/slice';
import NumberFormat from 'react-number-format';
import ExecuteTrustPaymentModal from './ExecuteTrustPaymentModal';
import { TrustPayment, TrustPaymentExecutePayload } from '@/features/trustPayments/types';
import TrustPaymentDetailsModal from './TrustPaymentDetailsModal';



interface Props {
	writePermission: boolean,
}

const TrustPayments = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.trustPayments
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);
	const [openExecuteModal, setOpenExecuteModal] = useState<boolean>(false);
	const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false);
	const [trustPayment, setTrustPayment] = useState<TrustPayment>(null);

	const headers: Array<Headers> = [
		{
			field: 'createdDate',
			name: 'Date',
			position: 1,
			sortable: true
		},
		{
			field: '',
			name: 'Name',
			position: 2,
			sortable: false
		},
		{
			field: 'documentNumber',
			name: 'Document number',
			position: 3,
			sortable: true
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 4,
			sortable: true
		},
		{
			field: 'externalIds',
			name: 'External ID',
			position: 5,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 6,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 7,
			sortable: true
		}
	];

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getTrustPayments(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const reject = async (id: number) => {
		try {
			await rejectPayment(id);
			showSuccessNotification('Trust payment rejected');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const openDetails = (trustPayment: TrustPayment) => {
		setTrustPayment(trustPayment);
		setOpenDetailsModal(true);
	};


	const handleCloseExecuteModal = async (transferFee?: number, externalIds?: string, trustPayment?: TrustPayment) => {
		setOpenExecuteModal(false);
		if (trustPayment) {
			try {

				const payload: TrustPaymentExecutePayload = {
					trustPaymentId: trustPayment.id,
					transferFee,
					externalIds
				};

				await executePayment(payload);
				showSuccessNotification('Trust transfer executed');
				fetchData();
			} catch (e) {
				showErrorNotification(e);
			}
		}

		setTrustPayment(null);
	};

	const handleCloseDetailsModal = () => {
		setTrustPayment(null);
		setOpenDetailsModal(false);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			<ExecuteTrustPaymentModal
				open={openExecuteModal}
				onClose={handleCloseExecuteModal}
				trustPayment={trustPayment}
			/>
			<TrustPaymentDetailsModal
				open={openDetailsModal}
				onClose={handleCloseDetailsModal}
				trustPayment={trustPayment}
			/>
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.documentNumber}${entry.id}${index}`} >
						<Table.Cell width={2}>{formatDateWithPattern(entry.createdDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={1}>{entry.profileType === 'INDIVIDUAL' ? `${entry.firstName} ${entry.lastName}` : entry.companyName}</Table.Cell>
						<Table.Cell width={1}>{entry.documentNumber}</Table.Cell>
						<Table.Cell width={1}><NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${entry.ccy} `} value={Math.abs(entry.amount)} /></Table.Cell>
						<Table.Cell width={1}>{entry.externalIds}</Table.Cell>
						<Table.Cell width={1}>{entry.status}</Table.Cell>
						<Table.Cell width={4}>
							<Button primary disabled={!writePermission || entry.status !== 'NEW'} content='Execute' onClick={() => { setTrustPayment(entry); setOpenExecuteModal(true); }} />
							<ComponentWithConfirmation onConfirm={() => reject(entry.id)} confirmContent={'Are you sure you want reject trust payment?'}>
								<Button primary disabled={!writePermission || entry.status !== 'NEW'} content='Reject' />
							</ComponentWithConfirmation>
							<Popup content='View details' trigger={<Icon className='tableActionIcon' size='large' name="eye" onClick={() => openDetails(entry)} />} />
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={7}>No trust payments yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Trust Payments</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					hideItemsPerPage={true}
				/>
			</Segment>
		</Container>
	);
};

export default TrustPayments;
