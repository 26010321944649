import React from 'react';
import { Container, Divider, Grid, Header, Segment } from 'semantic-ui-react';
import { Formik } from 'formik';
import { Input, Select, SubmitButton, Form } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import useWhiteLabelLov from '@/helpers/customHook/useWhiteLabelLov';
import { downloadVAspReport } from '@/features/amlReports/amlReportsSlice';
import { convertDate } from '@/helpers/date';

const AMLVsapReports = (): React.ReactElement => {

	const { whitelabelOptions } = useWhiteLabelLov();

	const validationSchema = Yup.object({
		wlpId: Yup.string().required(),
		vaspRegNr: Yup.string().required(),
		dateFrom: Yup.string().required(),
		dateTo: Yup.string().required(),
	});

	const initialValues = {
		wlpId: '',
		vaspRegNr: '',
		dateFrom: '',
		dateTo: ''
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);
		try {
			const { wlpId, vaspRegNr, dateFrom, dateTo } = formData;
			const from = convertDate(new Date(dateFrom), 'dd.MM.YYY');
			const to = convertDate(new Date(dateTo), 'dd.MM.YYY');
			await downloadVAspReport(wlpId, vaspRegNr, from, to);
			setSubmitting(false);
		}
		finally {
			setSubmitting(false);
		}
	};

	return (
		<Container fluid id='all-documents'>
			<Segment>
				<Header as='h2'>IT Vasp OAM Transaction Reports</Header>
				<Divider hidden />
				<div id='table-layout' >
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={submit}
					>{({ isSubmitting, isValid, dirty }) => {
						return (
							<Form >
								<Grid id='filters'>
									<Grid.Row>
										<Select
											name='wlpId'
											label='White label (WLP)'
											options={whitelabelOptions}
											selection
										/>
										<Input
											label='VaspNr'
											name='vaspRegNr'
											errorPrompt />
										<Input
											label='Date From'
											name='dateFrom'
											type='date'
											errorPrompt />
										<Input
											label='Date To'
											name='dateTo'
											type='date'
											errorPrompt />

									</Grid.Row>
									<Grid.Row id='buttons'>
										<SubmitButton type='submit'
											disabled={isSubmitting || !isValid || !dirty}
											primary basic>Download
										</SubmitButton>
									</Grid.Row>
								</Grid>
							</Form>);
					}}
					</Formik>
				</div>
			</Segment>
		</Container>
	);
};

export default AMLVsapReports;
