import React from 'react';
import {
	Modal,
	Button,
	Divider,
	Grid,
	Segment,
	List,
} from 'semantic-ui-react';
import { UnassignedPayment } from '@/features/unassignedPayments/unassignedPaymentsTypes';
import Accounts from '../Accounts/Accounts';
import { formatDateWithPattern } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import '../Clients/ClientDetails/Profile.css';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { checkWritePermission } from '@/helpers/permissions';
import { PermissionType } from '@/features/user/types';


interface Props {
	open: boolean;
	onClose: (markAsMatch?: boolean) => void,
	details: UnassignedPayment,
}

const UnassignedPaymentsModal = ({ open, onClose, details }: Props): React.ReactElement => {
	
	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	return <Modal
		size="fullscreen"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			{details && <Segment className="usersegment" basic style={{ marginTop: 0 }}>
				<List id="profile-data">
					<List.Item>
						<List.Content>
							<List.Header className="color-grey">File Name</List.Header>
							<List.Description>{details.fileName}</List.Description>
						</List.Content>
					</List.Item>
					<Divider />
					<List.Item>
						<List.Content>
							<List.Header className="color-grey">Entry Date</List.Header>
							<List.Description>{formatDateWithPattern(details.entryDate, 'dd/MM/yyyy HH:mm:ss')}</List.Description>
						</List.Content>
					</List.Item>
					<Divider />
					<List.Item>
						<List.Content>
							<List.Header className="color-grey">Amount</List.Header>
							<List.Description>{details.ccy} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={details.amount} /></List.Description>
						</List.Content>
					</List.Item>
					<Divider />
					<List.Item>
						<List.Content>
							<List.Header className="color-grey">Payment Details</List.Header>
							<List.Description>{details.paymentDetails}</List.Description>
						</List.Content>
					</List.Item>
				</List>
			</Segment>}
			<Divider hidden />
			<Accounts type='ALL' take={5} writePermission={!checkWritePermission(permissions, PermissionType.ACCOUNTS)} />
			<Divider hidden />
			<Grid columns={2}>
				<Grid.Row>
					<Grid.Column >
						<Button
							floated='right' primary size='large' content='Mark as matched' onClick={() => onClose(true)}
						/>
					</Grid.Column>
					<Grid.Column>
						<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Modal.Content >
	</Modal >;
};

export default UnassignedPaymentsModal;
