import React, { useEffect } from 'react';
import * as countries from 'i18n-iso-countries';
import Dashboard from './pages/Dashboard';
import Auth from './pages/Auth';
import NotFound from './pages/NotFound';
import LoadingPage from './pages/LoadingPage';
import Swal from './components/Swal';
import Interceptors from './components/Interceptors';
import ProtectedRoute from './ProtectedRoute';
import { useTranslation } from 'react-i18next';
import './App.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import PageTop from '@/components/Shared/PageTop';
import { Container } from 'semantic-ui-react';
import TwoFA from './components/TwoFA';

const App = (): React.ReactElement => {
	const { ready } = useTranslation('translations', { useSuspense: false });
	

	useEffect(() => {
		// TODO switch between locales
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
	}, []);

	if (!ready) return <LoadingPage />;

	return (
		<Container fluid className="app-layout">
			<Router>
				<Interceptors />
				<Swal />
				<TwoFA />
				<PageTop>
					<Switch>
						<Route path="/auth">
							<Auth />
						</Route>
						<ProtectedRoute path='/' component={Dashboard} />
						<Route component={NotFound} />
					</Switch>
				</PageTop>
			</Router >
		</Container>

	);
};

export default App;
