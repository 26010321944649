import { PaginateParams } from '@/helpers/globalTypes';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showException } from '../swal/slice';
import { GeneralLedgerLov, GlLookupTypes, StatementBatch, StatementBankAccount, StatementsStore } from './statementsTypes';

const initialState: StatementsStore = {
	loading: false,
	//status: 'idle',
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
	pagination: { orderBy: 'stmDateFrom', skip: 0, sort: 'DESC', filter: '', take: 25 },
};


const slice = createSlice({
	name: 'statements',
	initialState,
	reducers: {
		setStatements(state, action: PayloadAction<Array<StatementBatch>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		},
		setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
			state.pagination = payload;
		},
		/*setStatus: (state, { payload }: PayloadAction<'idle' | 'loading' | 'succeeded' | 'failed'>) => {
			state.status = payload;
		},*/
	}
});

export const {
	setStatements, setLoading, setError, setCount, setTotalPage, setPageSize, setPagination } = slice.actions;



export const getStatementBatches = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const filterParams = {
				filter: payload.filter,
				orderBy: payload.orderBy,
				sort: payload.sort.toUpperCase(),
				skip: payload.skip.toString(),
				take: payload.take
			};
			const response = await request.get('/api/admin/statements/batches', { params: filterParams });
			const { data } = response;
			dispatch(setStatements(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			//setStatus('succeeded');
			return data;
		} catch (e) {
			dispatch(setLoading(false));
			showException(e);
		}
	};
};


export const getGlLookup = async (lookupType: GlLookupTypes): Promise<GeneralLedgerLov[]> => {
	const response = await request.get(`/api/admin/statements/lookup/${lookupType}`);
	const { data } = response;
	return data;
};

export const getBankAccountList = async (payload: PaginateParams): Promise<StatementBankAccount[]> => {
	const filterParams = {
		filter: payload.filter,
		orderBy: payload.orderBy,
		sort: payload.sort.toUpperCase(),
		skip: payload.skip.toString(),
		take: payload.take
	};

	const response = await request.get('/api/admin/statements/bank-accounts-list', { params: filterParams });
	const { data } = response;
	return data;
};


export default slice.reducer;
