import { Permission } from '@/features/user/types';


export const checkPermission = (permissions: Array<Permission>, permission: string): boolean => {
	if (permissions.find((v) => v.permission === permission)) {
		return true;
	} else {
		return false;
	}
};

export const checkWritePermission = (permissions: Array<Permission>, permission: string): boolean => {
	if (permissions.find((v) => v.permission === permission && v.writePermission === true)) {
		return true;
	} else {
		return false;
	}
};
