import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getAccounts } from '@/features/accounts/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import NumberFormat from 'react-number-format';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	type: string,
	writePermission: boolean,
}

const SystemAccounts = ({ type }: Props): React.ReactElement => {

	const dispatch = useDispatch();

	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.accounts
	);

	const paginateInitialState: PaginateParams = { orderBy: 'balance', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);


	const integrationOptions = [
		{
			key: 'BANKINGCIRCLE',
			text: 'Bankingcircle',
			value: 'BANKINGCIRCLE',
		},
		{
			key: 'CAMBRIDGE',
			text: 'Cambridge',
			value: 'CAMBRIDGE',
		},
		{
			key: 'CLEARJUNCTION_POOL',
			text: 'Clearjunction',
			value: 'CLEARJUNCTION_POOL',
		},
		{
			key: 'CLEARJUNCTION_WALLET',
			text: 'Clearjunction wallet',
			value: 'CLEARJUNCTION_WALLET',
		},
		{
			key: 'MODULR',
			text: 'ModulR',
			value: 'MODULR',
		},
		{
			key: 'RAILSBANK',
			text: 'Railsbank',
			value: 'RAILSBANK',
		},
		{
			key: 'TRUST',
			text: 'Trust',
			value: 'TRUST',
		},
		{
			key: 'DECTA',
			text: 'Decta',
			value: 'DECTA',
		},
		{
			key: 'STOCKS',
			text: 'Stocks',
			value: 'STOCKS',
		},
		{
			key: 'KRAKEN',
			text: 'Kraken',
			value: 'KRAKEN',
		},
		{
			key: 'COINBASE',
			text: 'Coinbase',
			value: 'COINBASE',
		},
		{
			key: 'TOKENS',
			text: 'Tokens',
			value: 'TOKENS',
		},
		{
			key: 'FIREBLOCKS',
			text: 'Fireblocks',
			value: 'FIREBLOCKS',
		},
		{
			key: 'IXOPAY',
			text: 'Ixopay',
			value: 'IXOPAY',
		},
	];

	const typeOptions = [
		{
			key: 'balancingAccount',
			text: 'Balancing account',
			value: 'balancingAccount',
		},
		{
			key: 'emiBalancingAccount',
			text: 'EMI balancing account',
			value: 'emiBalancingAccount',
		},
		{
			key: 'extBalancingAccount',
			text: 'External balancing account',
			value: 'extBalancingAccount',
		},
		{
			key: 'feeAccount',
			text: 'Fee account',
			value: 'feeAccount',
		},
		// {
		// 	key: 'poolingTransitIncomingAccount',
		// 	text: 'Pooling transaction incoming account',
		// 	value: 'poolingTransitIncomingAccount',
		// },
		// {
		// 	key: 'poolingTransitOutgoingAccount',
		// 	text: 'Pooling transaction outgoing account',
		// 	value: 'poolingTransitOutgoingAccount',
		// },
		{
			key: 'poolingStockAccount',
			text: 'Pooling stock account',
			value: 'poolingStockAccount',
		},
		{
			key: 'feeStockAccount',
			text: 'Fee stock account',
			value: 'feeStockAccount',
		},
		{
			key: 'transactionFeeAccount',
			text: 'Transaction fee account',
			value: 'transactionFeeAccount',
		}, {
			key: 'riskAccount',
			text: 'Risk account',
			value: 'riskAccount',
		},
		{
			key: 'vaultAccount',
			text: 'Vault account',
			value: 'vaultAccount',
		},
		{
			key: 'vaultInterestAccount',
			text: 'Vault interest account',
			value: 'vaultInterestAccount',
		},
		{
			key: 'vaultRepaymentAccount',
			text: 'Vault repayment account',
			value: 'vaultRepaymentAccount',
		},
		{
			key: 'gasAccount',
			text: 'Gas account',
			value: 'gasAccount',
		},
		{
			key: 'profitLossAccount',
			text: 'Profit loss account',
			value: 'profitLossAccount',
		},
		{
			key: 'referralAccount',
			text: 'Referral account',
			value: 'referralAccount',
		},
	];

	const filter: FilterParam[] = [
		{
			field: 'walletId',
			label: 'Wallet ID',
			type: 'input'
		},
		{
			field: 'integration',
			label: 'Integration',
			type: 'select',
			options: integrationOptions
		},
		{
			field: 'sysAcctType',
			label: 'Type',
			type: 'select',
			options: typeOptions
		},
		{
			field: 'label',
			label: 'Label',
			type: 'input',
		},
	];

	const headers: Array<Headers> = [
		{
			field: 'walletId',
			name: 'Wallet ID',
			position: 1,
			sortable: true
		},
		{
			field: 'integration',
			name: 'Integration',
			position: 2,
			sortable: true
		},
		{
			field: 'label',
			name: 'Label',
			position: 3,
			sortable: true
		},
		{
			field: 'balance',
			name: 'Balance',
			position: 4,
			sortable: true
		},
		{
			field: 'balanceOnHold',
			name: 'Balance on Hold',
			position: 5,
			sortable: true
		},
		{
			field: 'balanceFrozen',
			name: 'Balance Frozen',
			position: 6,
			sortable: true
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getAccounts(type, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch, type]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, type, fetchData]);


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.walletId}${index}`} >
						<Table.Cell width={2}>{entry.walletId}</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.integration)}</Table.Cell>
						<Table.Cell width={2}>{entry.label}</Table.Cell>
						<Table.Cell width={2}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balance} />
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balanceOnHold} />
						</Table.Cell>
						<Table.Cell width={2}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.balanceFrozen} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={7}>You dont have accounts yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Grid columns={2}>
					<Grid.Row>
						<Grid.Column>
							<Header as='h2'>{capitalizeFirstLetter(type)} Accounts</Header>
						</Grid.Column>
						<Grid.Column>
							<Button primary icon="exchange" floated='right'
								iconposition='left'
								content="Transfer between accounts"
								onClick={() => alert('not implemented yet')}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default SystemAccounts;
