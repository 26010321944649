import { useEffect, useState } from 'react';
import { getGlLookup } from '@/features/statements/statementsSlice';
import { GlLookupTypes } from '@/features/statements/statementsTypes';

const useGLLov = (lookupType: GlLookupTypes) => {

	const [options, setOptions] = useState<any>(); 

	useEffect(() => {
		const fetch = async () => {
			const list = await getGlLookup(lookupType);  
			const lovOptions = list.map((p) => { return { key: p.code, text: p.name, value: p.code }; });
			setOptions(lovOptions);
		};
		fetch();
	}, []);

	return { options };
};

export default useGLLov;

