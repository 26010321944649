import React, { useState, useEffect, useCallback } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
	Item,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, Select } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { AdminRolesResposeTypes, AdminsRoleType, CreateAdminPayload, EmiListType } from '@/features/admins/adminTypes';
import { alpha2ToAlpha3, alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { addAdmin, getAdminRoles } from '@/features/admins/adminSlice';
import { getErrorWithParams, showErrorNotification, showSuccess } from '@/features/swal/slice';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { getFndLookupsValues } from '@/features/fnd/fndSlice';
import { LookupValueType } from '@/features/fnd/fndTypes';

interface Props {
	open: boolean;
	adminType: AdminsRoleType,
	emi: EmiListType,
	onClose: () => void,
	writePermission: boolean
}


const AdminModal = ({ open, emi, adminType, onClose, writePermission }: Props): React.ReactElement => {

	const [phoneNumberField, setPhoneNumberField] = useState<string>('');
	const currentWlp = useSelector((state: RootState) => state.whitelabel.whitelabel);
	const [countryLov, setCountryLov] = useState<LookupValueType[]>([]);
	const countries = getNames('en');
	const countriesCodeOptions = countryLov.map(p => {
		return ({ key: p.lookupCode, text: `(${p.attribute1}) ${countries[alpha3ToAlpha2(p.lookupCode)]}`, value: p.attribute1 });
	});

	const countriesOptions = Object.keys(countries)
		.map($code => {
			return ({ key: alpha2ToAlpha3($code), text: countries[$code], value: alpha2ToAlpha3($code) });
		});

	const [roles, setRoles] = useState<AdminRolesResposeTypes[]>([]);

	const roleOptions = roles?.length > 0 ? roles.map(code => {
		return ({ key: code.id, text: code.label, value: code.id });
	}) : [];

	const getRoles = async () => {
		const data = await getAdminRoles(adminType);
		setRoles(data);
	};

	useEffect(() => { getRoles(); }, [adminType]);



	useEffect(() => {
		const get = async () => {
			const date = new Date(); try {
				const data = await getFndLookupsValues('SMS_AWS', 'EN');
				const codes = data
					.filter(p => p.enabled && new Date(p.endDateActive) >= date && new Date(p.startDateActive) <= date)
					|| [];

				setCountryLov(codes);
			} catch (e) {
				setCountryLov(null);
				showErrorNotification(e);
			}
		};
		get();
 
	}, []);


	const getWhitelabelOptions = useCallback(() => {
		const option = [{ key: currentWlp, text: currentWlp, value: currentWlp }];
		if (adminType === AdminsRoleType.WLP_ADMIN && emi.isEmi) {
			const adminOption = emi.emiWlpList
				.filter(code => code.wlpId !== currentWlp)
				.map(code => {
					return ({ key: code.wlpId, text: code.name, value: code.wlpId });
				}) || [];
			return [...option, ...adminOption];
		}
		else { return option; }

	}, [currentWlp, emi.isEmi, adminType]);

	const witelabelOptions = getWhitelabelOptions();

	const initialValues = {
		email: '',
		firstName: '',
		lastName: '',
		phoneCode: '',
		mobileNumber: '',
		country: '',
		wlpAdminWlpId: currentWlp ?? '',
		roleId: roles?.length > 0 ? roles[0].id : ''
	};

	const validateEmail = (email: string | undefined) => {
		return Yup.string().email().isValidSync(email);
	};


	const validatePhone = (phone: string | undefined) => {
		return Yup.number().integer().positive().test(
			(phone) => {
				return isValidPhoneNumber(`+${phone}`);
			}
		).isValidSync(phone);
	};

	const validationSchema = Yup.object({
		email: Yup.string()
			.required('Required')
			.test('email', 'Wrong email format', (value) => { return validateEmail(value); }),
		firstName: Yup.string()
			.required('Required'),
		lastName: Yup.string()
			.required('Required'),
		phoneCode: Yup.string().required('Required'),
		mobileNumber: Yup.string()
			.required('Required')
			.matches(/^([+]|[1-9])[0-9]+$/, 'Wrong phone number')
			.test('mobileNumber', 'Wrong phone number', (value, ctx) => {
				return validatePhone(`${ctx.parent.phoneCode}${value}`);
			}),
		country: Yup.string()
			.required('Required'),
		wlpAdminWlpId: Yup.string()
			.required('Required'),
		roleId: Yup.string()
			.required('Required')

	});

	const submit = async (formData) => {
		try {
			const { mobileNumber, phoneCode, email, firstName, lastName, country, wlpAdminWlpId, roleId } = formData;
			const phoneNumber = `${phoneCode}${mobileNumber}`;

			const payload: CreateAdminPayload = { email, firstName, lastName, country, wlpAdminWlpId, roleId, type: adminType, mobileNumber: phoneNumber };

			await addAdmin(adminType, payload);
			setPhoneNumberField('');
			showSuccess();
			onClose();
		}
		catch (e) {
			const err = getErrorWithParams(e);
			if (err.error === 'duplicate' && err.error_param === 'email') {
				showErrorNotification(err, 'Such email already exists');
			}
			if (err.error === 'duplicate' && err.error_param === 'mobileNumber') {
				showErrorNotification(err, 'Such phone number already exists');
			}
			if (e && err.error === 'already_exists' && err.error_param === 'role') {
                showErrorNotification(err, 'User with owner role already exists');
            }
			else {
				showErrorNotification(e);
			}
		}
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
				enableReinitialize={true}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content={`Add new ${adminType === AdminsRoleType.EMI_ADMIN ? 'EMI' : 'White Label'} admin`} />

						<Input
							label='First Name'
							fluid
							name="firstName"
							disabled={!writePermission}
							errorPrompt

						/>
						<Input
							label='Last Name'
							fluid
							name="lastName"
							disabled={!writePermission}
							errorPrompt
						/>
						<Input
							label='Email'
							fluid
							name="email"
							disabled={!writePermission}
							errorPrompt
						/>
						<span className='label'>Mobile Number</span>
						<Item.Group divided>
							<Item>
								<div style={{ paddingRight: '8px' }} >
									<Select
										name="phoneCode"
										label='Country code'
										options={countriesCodeOptions}
										errorPrompt
										search
										fluid
									/>
								</div>
								<Input
									autoComplete="off"
									autoFocus
									label='Phone number'
									name="mobileNumber"
									type='number'
									value={phoneNumberField}
									onChange={(e, v) => setPhoneNumberField(v.value.replace(/\D/g, ''))}
									disabled={!writePermission}
									errorPrompt

								/>
							</Item>
						</Item.Group>
						{/* <Input
							label='Mobile Number'
							fluid
							name="mobileNumber"
							value={phoneNumberField}
							onChange={(e, v) => setPhoneNumberField(v.value.replace(/\D/g, ''))}
							disabled={!writePermission}
							errorPrompt
							//type='number'
						/> */}
						<Select
							label='Role'
							fluid
							name="roleId"
							options={roleOptions}
							selection
							disabled={!writePermission}
							errorPrompt
						/>
						<Select
							label='Country'
							fluid
							name="country"
							options={[{ key: 'EMPTY', value: null, text: '' }, ...countriesOptions]}
							selection
							search
							disabled={!writePermission}
							errorPrompt
						/>
						<Select
							label='White Label'
							fluid
							name="wlpAdminWlpId"
							options={witelabelOptions}
							selection
							disabled={!writePermission}
							errorPrompt
						/>
						<Divider />
						<Divider hidden />
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty || !writePermission}
										primary size="large" type="submit"
										floated='right' >
										{'Add'}
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default AdminModal;
