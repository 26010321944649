import React, { useState } from 'react';
import { createReport, getReportStatus, submitReportRefresh } from '@/features/statements/balanceProcessStatusSlice';
import { Button, Grid, } from 'semantic-ui-react';
import { formatDateWithPattern } from '@/helpers/date';
import { BalanceProcessType, ReportRefreshPayload } from '@/features/statements/balanceProcessStatusType';
import { showErrorNotification } from '@/features/swal/slice';
import RefreshParameters from './RefreshParameters';

interface Props {
	writePermission: boolean,
	entry: BalanceProcessType
}
const ProcessLine = ({ entry, writePermission }: Props): React.ReactElement => {

	const [submitting, setSubmitting] = useState<boolean>(false);
	const [updateBalance, setUpdateBalance] = useState<boolean>(false);

	const startUpdate = async (from?: string, to?: string) => {

		if (!from || !to || !entry?.processKey) {
			setUpdateBalance(false);
			return;
		}
		setSubmitting(true);

		try {

			if (!entry?.processKey) {
				showErrorNotification(null, 'Process key is not defined');
				return;
			}
			const result = await getReportStatus(entry.processKey);
			if (result || result?.count === 0) {
				const report = await createReport({ processKey: entry.processKey, processName: entry.processName });

				const refreshPayload: ReportRefreshPayload = {
					rootBankAccountId: entry.rootBankAccountId,
					integration: entry.integration,
					lastUpdate: entry.lastUpdate,
					processName: entry.processName,
					processKey: entry.processKey,
					updateFrom: entry.updateFrom,
					updateTo: entry.updateTo

				};
				await submitReportRefresh(report.id, refreshPayload);
			}
		} catch (e) {
			showErrorNotification(e);
		}
		finally {
			setSubmitting(false);
			setUpdateBalance(false);
		}
	};

	return (
		<>
			<RefreshParameters
				onClose={startUpdate}
				open={updateBalance}
			/>
			<Grid.Row >
				<Grid.Column>{entry?.description}</Grid.Column>
				<Grid.Column >{entry?.updateFrom ? formatDateWithPattern(entry.updateFrom, 'dd-MM-yy') : ''}</Grid.Column>
				<Grid.Column >
					<Button primary
						disabled={!writePermission || !entry?.processKey || submitting}
						content='Update'
						onClick={() => setUpdateBalance(true)} />
				</Grid.Column>
			</Grid.Row>
		</>
	);
};

export default ProcessLine;
