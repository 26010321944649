import Loading from '@/components/Shared/Loading';
import { getStatistics } from '@/features/statistics/statisticsSlice';
import {AccountStatsType, BalancesSumByCCyType} from '@/features/statistics/statisticsTypes';
import { showErrorNotification } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Divider, Grid, Header, Icon, Popup, Segment, Select, Statistic } from 'semantic-ui-react';


interface AccountItemsProps {
	label: string,
	accountStats: AccountStatsType,
	onClick?: () => void
}

const AccountItems = ({ label, accountStats, onClick }: AccountItemsProps) => {
	return (<Grid>
		<Grid.Row textAlign='center'>
			<Grid.Column>
				<Statistic label={label} value={accountStats?.totalCount ?? 0} onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'cursor' }} />
				<Divider />
				<Divider hidden />
				<Statistic.Group horizontal size='mini' style={{ alignContent: 'center' }}>
					{_.entries(accountStats?.accountsMap).map(item =>
						<Statistic key={item[0]} label={item[1] ?? 0} value={item[0]} />)
					}
				</Statistic.Group>
			</Grid.Column>
		</Grid.Row>
	</Grid>);
};

const BalancesSumByCCy = ({ balancesSumByCCy }: BalancesSumByCCyType) => {
	return (
		<Statistic.Group horizontal size='mini' style={{ alignContent: 'center' }}>
			{_.entries(balancesSumByCCy).map(item =>
				<Statistic key={item[0]} label={numberWithCommas(item[1] ?? 0)} value={item[0]} />)
			}
		</Statistic.Group>
	);
};

const numberWithCommas = (value: number) => {
	return value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
};


const Statistics = (): React.ReactElement => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { statistics, error, pendingRiskApprovals } = useSelector(
		(state: RootState) => state.statistics
	);
	const [refreshInterval, setRefreshInterval] = useState<any>(0);

	const { whitelabel } = useSelector(
		(state: RootState) => state.whitelabel
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getStatistics());
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [dispatch, whitelabel]);

	useEffect(() => {
		fetchData();
	}, [dispatch, fetchData, whitelabel]);

	useEffect(() => {
		if (refreshInterval === 0) return;
		const interval = setInterval(() => {
			fetchData();
		}, refreshInterval * 1000);
		return () => clearInterval(interval);
	}, [refreshInterval]);


	if (!statistics) {
		return <Loading />;
	}



	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Grid>
					<Grid.Row>
						<Grid.Column width={10}>
							<Header as='h2'>Dashboard</Header>
						</Grid.Column>
						<Grid.Column width={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
							<div style={{ marginRight: '1em' }}>Auto refresh:</div>
							<Select
								value={refreshInterval}
								options={[{ key: 0, value: 0, text: 'Never' }, { key: 30, value: 30, text: '30 seconds' }, { key: 60, value: 60, text: '1 minute' }, { key: 360, value: 360, text: '5 minutes' }]}
								onChange={(e, v) => setRefreshInterval(v.value)}
							/>
							<Popup
								trigger={<Icon size='large' className='sync' style={{ cursor: 'pointer', marginLeft: '1em' }} onClick={fetchData} />}
								content={'Refresh'}
								position='top center'
								wide='very'
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<Grid columns={3}>
					<Grid.Row >
						<Grid.Column>
							<Header size='large' textAlign='center'>Pooling IBAN</Header>
							<BalancesSumByCCy balancesSumByCCy={statistics?.poolingIbanSum?.balancesSumByCCy}/>
						</Grid.Column>
						<Grid.Column>
							<Header size='large' textAlign='center'>Pooling</Header>
							<BalancesSumByCCy balancesSumByCCy={statistics?.poolingSum?.balancesSumByCCy}/>
						</Grid.Column>
						<Grid.Column>
							<Header size='large' textAlign='center'>Trust</Header>
							<BalancesSumByCCy balancesSumByCCy={statistics?.trustSum?.balancesSumByCCy}/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider />
				<Divider hidden />
				<Grid columns={4}>
					<Grid.Row textAlign='center'>
						{statistics.cardApprovals !== null && <Grid.Column><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/cards/all?filter=status=PENDING&take=25&skip=0&orderBy=id&sort=DESC')} label='Pending Cards' value={statistics.cardApprovals} /></Grid.Column>}
						<Grid.Column><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/support-management?filter=status=PENDING_ADMIN&take=25&skip=0&orderBy=modifiedDate&sort=DESC')} label='Pending Tickets' value={statistics.supportTickets} /></Grid.Column>
						<Grid.Column><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/exchange?filter=status%3DPENDING&take=25&skip=0&orderBy=documentId&sort=DESC')} label='Pending Exchanges' value={statistics.pendingExchangeOrders} /></Grid.Column>
						{statistics.longRunningActivitiesLastDay !== null && <Grid.Column ><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/system-errors')} label='Activity errors' value={statistics.longRunningActivitiesLastDay} /></Grid.Column>}
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<Divider />
				<Divider hidden />
				<Grid columns={5}>
					<Grid.Row textAlign='center'>
						{statistics.kybApproval !== null && <Grid.Column ><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/compliance?filter=service=ADVISIONARY;status=CREATED&take=25&skip=0&orderBy=createdDate&sort=DESC')} label='Pending KYB' value={statistics.kybApproval} /></Grid.Column>}
						{statistics.poaApprovals !== null && <Grid.Column><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/poa?filter=poastatus=PENDING&take=25&skip=0&orderBy=createdDate&sort=DESC')} label='Pending POA' value={statistics.poaApprovals} /></Grid.Column>}
						{pendingRiskApprovals !== null && <Grid.Column><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/poa?filter=riskstatus=PENDING%20APPROVAL&take=25&skip=0&orderBy=createdDate&sort=DESC')} label='Risks Pending Approval' value={pendingRiskApprovals} /></Grid.Column>}
						{statistics.kycIssues !== null && <Grid.Column><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/kyc-issues')} label='KYC Issues' value={statistics.kycIssues} /></Grid.Column>}
						{statistics.videoAml !== null && <Grid.Column><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/kyc-calendar?filter=status=PENDING&take=25&skip=0&orderBy=startTime&sort=ASC')} label='Pending KYC meetings' value={statistics.videoAml} /></Grid.Column>}
					</Grid.Row>
				</Grid>

				{(statistics.tokenRequest !== null || statistics.tokenTrades !== null || statistics.tokenWithdrawals !== null) && <>
					<Divider hidden />
					<Divider />
					<Divider hidden />
					<Grid columns={4}>
						<Grid.Row textAlign='center'>
							<Grid.Column><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/tokens/requests?filter=status=REQUEST&take=25&skip=0&orderBy=createdDate&sort=DESC')} label='Token Request' value={statistics.tokenRequest ?? 0} /></Grid.Column>
							<Grid.Column><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/tokens/trades?filter=status=EXECUTED&take=25&skip=0&orderBy=createdDate&sort=DESC')} label='Token Trades' value={statistics.tokenTrades ?? 0} /></Grid.Column>
							<Grid.Column ><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/tokens/withdrawals?filter=status=NEW&take=25&skip=0&orderBy=createdDate&sort=DESC')} label='Token Withdrawals' value={statistics.tokenWithdrawals ?? 0} /></Grid.Column>
                            <Grid.Column ><Statistic style={{ cursor: 'pointer' }} onClick={() => history.push('/vaults/pending-requests')} label='Vaults Pending requests' value={statistics.pendingVaultRequests ?? 0} /></Grid.Column>
						</Grid.Row>
					</Grid>
				</>}
				<Divider hidden />
				{(statistics.vaultTransactions !== null || statistics.trustTransactions !== null || statistics.bankTransactions !== null) && <>
					<Divider hidden />
					<Divider />
					<Divider hidden />
					<Grid columns={4}>
						<Grid.Row textAlign='center'>
							<Grid.Column><Statistic label='Vault transactions' value={statistics.vaultTransactions ?? 0} /></Grid.Column>
							<Grid.Column><Statistic label='Trust transactions' value={statistics.trustTransactions ?? 0} /></Grid.Column>
							<Grid.Column ><Statistic label='Bank transactions' value={statistics.bankTransactions ?? 0} /></Grid.Column>
						</Grid.Row>
					</Grid>
				</>}
				<Divider hidden />
				{(statistics.bankAccounts !== null
					|| statistics.cryptoAccounts !== null
					|| statistics.tokenAccounts !== null
					|| statistics.vaultAccounts !== null) && <>
						<Divider hidden />
						<Divider />
						<Divider hidden />
						<Grid columns={5}>
							<Grid.Row >
								<Grid.Column>
									<AccountItems label='Client Bank Accounts' accountStats={statistics.bankAccounts} onClick={() => history.push('/accounts/bank')} />
								</Grid.Column>
								<Grid.Column>
									<AccountItems label='Client Crypto Accounts' accountStats={statistics.cryptoAccounts} />
								</Grid.Column>
								<Grid.Column>
									<AccountItems label='Client Trust Accounts' accountStats={statistics.trustAccounts} onClick={() => history.push('/accounts/trust')} />
								</Grid.Column>
								<Grid.Column>
									<AccountItems label='Client Vault Accounts' accountStats={statistics.vaultAccounts} onClick={() => history.push('/vaults/accounts')} />
								</Grid.Column>
								<Grid.Column>
									<AccountItems label='Client Token Accounts' accountStats={statistics.tokenAccounts} onClick={() => history.push('/tokens/accounts')} />
								</Grid.Column>

							</Grid.Row>
						</Grid>
					</>}
				<Divider hidden />
			</Segment>
		</Container >
	);
};


export default Statistics;
