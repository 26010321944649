/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './Profile.css';
import {
	Grid,
	Card,
	Segment,
	List,
	Container,
	Header,
	Divider,
	Button,
	Select,
	Icon,
	Popup,
	Confirm
} from 'semantic-ui-react';
import { formatDateWithPattern } from '@helpers/date';
import { Client } from '@/features/clients/types';
import { Permission, PermissionType, UserType } from '@/features/user/types';
import Loading from '@/components/Shared/Loading';
import { changeEmail, changePhone, changeRiskGroup, confirmEmail, confirmPhone, deselectAdminRole, detainClient, disableClient, getAdminRolesOptions, getMonitoredUserTransaction, monitorUserTransaction, postAdminRole, reset2FA, resetMobilePin } from '@/features/clients/slice';
import { getErrorWithParams, showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import DetainReasonModal from './DetainReasonModal';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import ChangeRiskGroupModal from './ChangeRiskGroupModal';
import TransactionMonitoringModal from './TransactionMonitoringModal';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { checkPermission, checkWritePermission } from '@/helpers/permissions';
import CreateTicketForUserModal from '../../Tickets/CreateTicketForUserModal';
import { createTicketForUser } from '@/features/tickets/ticketsSlice';
import ChangeEmailModal from './ChangeEmailModal';
import ChangePhoneModal from './ChangePhoneModal';

type Props = {
	goBack: () => void,
	fetchData: () => void,
	client: Client,
	writePermission: boolean
	permissions: Array<Permission>
}


const ClientProfile = ({ client, goBack, fetchData, writePermission, permissions }: Props): React.ReactElement => {

	const [adminRoleOptions, setAdminRoleOptions] = useState([]);
	const [changeAdminRole, setChangeAdminRole] = useState<boolean>(false);
	const [adminRole, setAdminRole] = useState<any>(null);
	const [askConfirm, setAskConfirm] = useState<boolean>(false);
	const [detainReasonModal, setDetainReasonModal] = useState<boolean>(false);
	const [openRiskGroupModal, setOpenRiskGroupModal] = useState<boolean>(false);
	//const [openUserGroupModal, setOpenUserGroupModal] = useState<boolean>(false);
	const [openCreateTicketModal, setOpenCreateTicketModal] = useState<boolean>(false);
	const [openTransactionMonitoringModal, setOpenTransactionMonitoringModal] = useState<boolean>(false);
	const [openChangeEmailModal, setOpenChangeEmailModal] = useState<boolean>(false);
	const [openChangePhoneModal, setOpenChangePhoneModal] = useState<boolean>(false);
	const [countries] = useState(getNames('en'));

	useEffect(() => {
		const fetch = async () => {
			const list = await getAdminRolesOptions(client.id);
			const options = [];
			options.push({ value: 'none', key: 'none', text: 'Deselect' });
			if (list.length) {
				list.forEach(item => {
					options.push({ value: item.id, key: item.id, text: item.label });
				});
			}
			setAdminRoleOptions(options);
		};
		fetch();
	}, [client.id]);


	const handleChange = async (val) => {
		setAdminRole(val);
		setChangeAdminRole(false);
		setAskConfirm(true);
	};

	const selectAdminRole = async (confirmed: boolean) => {
		if (confirmed) {
			try {
				if (adminRole === 'none') {
					await deselectAdminRole(client.id);
				} else {
					await postAdminRole(adminRole, client.id);
				}
			} catch (e) {
				showErrorNotification(e);
			}
			fetchData();
		}

		setAskConfirm(false);
	};

	const handleDetain = async (reason?: string) => {
		try {
			if (reason) {
				await detainClient(client.id, true, reason);
			} else {
				await detainClient(client.id, false);
			}
		} catch (e) {
			showErrorNotification(e);
		}
		fetchData();
	};


	const handleDisable = async () => {
		try {
			await disableClient(client.id, !client.disabled);
		} catch (e) {
			showErrorNotification(e);
		}
		fetchData();
	};

	const onCloseDetainReasonModal = async (reason?: string) => {
		setDetainReasonModal(false);
		if (reason) {
			handleDetain(reason);
		}
	};

	const onCloeseChangeRiskGroupModal = async (riskGroup?: string, riskGroupFixed?: boolean) => {
		setOpenRiskGroupModal(false);
		if (riskGroup) {
			try {
				await changeRiskGroup(riskGroup, riskGroupFixed, client.id);
			} catch (e) {
				showErrorNotification(e);
			}
			fetchData();
		}
	};

	const onCloseChangeTransactionMonitored = async (monitored?: boolean) => {
		setOpenTransactionMonitoringModal(false);
		if (monitored !== null && monitored !== undefined) {
			try {
				await monitorUserTransaction(monitored, client.id);
				if (isMonitorTransactions !== monitored) {
					setIsMonitorTransaction(monitored);
				}
			} catch (e) {
				showErrorNotification(e);
			}
		}
	};

	const resetTwoFA = async () => {
		try {
			await reset2FA(client.id);
		} catch (e) {
			showErrorNotification(e);
		}
		fetchData();
	};

	const resetPasscode = async () => {
		try {
			await resetMobilePin(client.id);
		} catch (e) {
			showErrorNotification(e);
		}
		fetchData();
	};

	const confirmClientEmail = async () => {
		try {
			await confirmEmail(client.id);
		} catch (e) {
			showErrorNotification(e);
		}
		fetchData();
	};

	const [isMonitorTransactions, setIsMonitorTransaction] = useState<boolean>(false);

	useEffect(() => {
		const fetch = async () => {
			const data = await getMonitoredUserTransaction(client.id);
			setIsMonitorTransaction(data.monitored);
		};
		fetch();
	}, [client]);

	const handleCreateTicketModal = async (message?: string, subject?: string, selectedFiles?: any[]) => {
		setOpenCreateTicketModal(false);
		if (message) {
			try {
				const formData = new FormData();
				formData.append('userId', client.id.toString());
				formData.append('description', message);
				formData.append('subject', subject);

				if (selectedFiles) {
					selectedFiles.map(file => {
						formData.append('files', file);
					});
				}

				await createTicketForUser(formData);
				showSuccessNotification('Message successfully sent');

			} catch (e) {
				showErrorNotification(e);
			}
		}

	};

	const handleChangeEmailClose = async (email?: string) => {
		setOpenChangeEmailModal(false);
		if (email) {
			try {
				await changeEmail(client.id, email);
				showSuccessNotification('Email changed');
			} catch (e) {
				const err = getErrorWithParams(e);
				if (e && err.error === 'duplicate' && err.error_param === 'email') {
					showErrorNotification(err, 'User with this email already exists');
				} else {
					showErrorNotification(e);
				}
			}
			fetchData();
		}
	};

	const handleChangePhoneClose = async (phone?: string) => {
		setOpenChangePhoneModal(false);
		if (phone) {
			try {
				await changePhone(client.id, phone);
				showSuccessNotification('Phone changed');
			} catch (e) {
				const err = getErrorWithParams(e);
				if (e && err.error === 'duplicate' && err.error_param === 'phoneNumber') {
					showErrorNotification(err, 'User with this phone already exists');
				} else {
					showErrorNotification(e);
				}
			}
			fetchData();
		}
	};

	const confirmPhoneNumber = async () => {
		try {
			await confirmPhone(client.id);
			showSuccessNotification('Phone confirmed');
		} catch (e) {
			showErrorNotification(e);
		}
		fetchData();
	};

	if (!client) {
		return <Loading />;
	}
	
	return (
		<Container>
			<DetainReasonModal
				onClose={onCloseDetainReasonModal}
				open={detainReasonModal}
			/>
			<Confirm
				open={askConfirm}
				onCancel={() => selectAdminRole(false)}
				onConfirm={() => selectAdminRole(true)}
				content='Are you sure you want change admin role?'
				size='mini'
				cancelButton='No'
				confirmButton='Yes'
			/>
			<CreateTicketForUserModal
				open={openCreateTicketModal}
				onClose={handleCreateTicketModal}
			/>
			<Segment className="profilesegment" padded>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Client</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button floated='right' primary onClick={() => goBack()}>Go back</Button>
							<Button floated='right' secondary onClick={() => setOpenCreateTicketModal(true)}>Create ticket</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable columns={2}  >
					<Grid.Column className="userdatacolumn">
						<Card id="profile-card">
							<Card.Content textAlign='center'>
								{client.profile.type !== UserType.BUSINESS && <Card.Header>{client.profile.firstName} {client.profile.lastName}</Card.Header>}
								{client.profile.type === UserType.BUSINESS && <Card.Header>{client.profile.companyName}</Card.Header>}
							</Card.Content>
							<Divider hidden />
							<Card.Content>
								<List>
									<List.Item >
										<List.Content floated='right' className={client.disabled ? 'color-red' : 'color-green'}>{client.disabled ? 'Inactive' : 'Active'}</List.Content>
										<List.Content>Status</List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right'>{client.clientId}</List.Content>
										<List.Content>Client ID</List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right'>{client.riskGroupCode}</List.Content>
										<List.Content>Risk group</List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right' className={client.emailConfirmed ? 'color-green' : 'color-red'}>
											{client.emailConfirmed ? 'Verified' : 'Unverified'}
											{!client.emailConfirmed &&
												<ComponentWithConfirmation popup='Confirm email' onConfirm={confirmClientEmail} confirmContent={'Are you sure you want confirm user email?'}>
													<Icon disabled={!writePermission} style={{ marginLeft: 4 }} name='thumbs up' />
												</ComponentWithConfirmation>}
										</List.Content>
										<List.Content>Email</List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right' className={client.twoFactorAuthEnabled ? 'color-green' : 'color-red'}>
											{client.twoFactorAuthEnabled ? 'Enabled' : 'Disabled'}
											{client.twoFactorAuthEnabled &&
												<ComponentWithConfirmation popup='Reset 2FA' onConfirm={resetTwoFA} confirmContent={'Are you sure you want reset 2FA?'}>
													<Icon disabled={!writePermission} style={{ marginLeft: 4 }} name='undo' />
												</ComponentWithConfirmation>}
										</List.Content>
										<List.Content>2FA</List.Content>
									</List.Item>
									
									<List.Item>
										<List.Content floated='right' className={client.mobilePinEnabled ? 'color-green' : 'color-red'}>
											{client.mobilePinEnabled ? 'Enabled' : 'Disabled'}
											{client.mobilePinEnabled &&
												<ComponentWithConfirmation popup='Reset mobile passcode' onConfirm={resetPasscode} confirmContent={'Are you sure you want reset client mobile passcode?'}>
													<Icon disabled={!checkPermission(permissions, PermissionType.RESET_PASSCODE)} style={{ marginLeft: 4 }} name='undo' />
												</ComponentWithConfirmation>}
										</List.Content>
										<List.Content>Mobile Passcode</List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right' className={client.profile.politicalExposed ? 'color-red' : 'color-green'}>{client.profile.politicalExposed ? 'Yes' : 'No'}</List.Content>
										<List.Content>Political exposed</List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right' className={client.profile.usCitizen ? 'color-red' : 'color-green'}>{client.profile.usCitizen ? 'Yes' : 'No'}</List.Content>
										<List.Content>US citizen</List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right' className={client.kycTier === 0 ? 'color-red' : 'color-green'}>{client.kycTier === 0 ? 'Unverified' : 'Verified'}</List.Content>
										<List.Content>{client.profile.type === UserType.BUSINESS ? 'KYB' : 'KYC'} </List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right' className={client.profile.sumSubChanged ? 'color-red' : 'color-green'}>{client.profile.sumSubChanged ? 'Yes' : 'No'}</List.Content>
										<List.Content>Profile data changed by Compliance Team </List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right'>{formatDateWithPattern(client.createdDate, 'dd/MM/yyyy HH:mm:ss')}</List.Content>
										<List.Content>Registration date</List.Content>
									</List.Item>

									<List.Item>
										<List.Content floated='right'>{formatDateWithPattern(client.lastLoginDate, 'dd/MM/yyyy HH:mm:ss')}</List.Content>
										<List.Content>Last login date</List.Content>
									</List.Item>

									{client.detained && client.detainedReason && <List.Item>
										<List.Content>Detained reason</List.Content>
										<List.Content floated='right'>{client.detainedReason}</List.Content>
									</List.Item>}

									{client.profile.type === UserType.INDIVIDUAL && <List.Item>
										{changeAdminRole && <List.Content floated='right'>
											<Select
												className="adminRole"
												name="adminRole"
												placeholder="Select role"
												options={adminRoleOptions}
												//value={adminRole}
												onChange={(element, { value }) => handleChange(value)}
											/>
										</List.Content>}
										{!changeAdminRole && <List.Content floated='right'>
											{client.adminRole ? client.adminRole.label : 'Not selected'}
											<Icon disabled={!checkWritePermission(permissions, PermissionType.ROLES)} style={{ marginLeft: 4 }} name='edit outline' onClick={() => setChangeAdminRole(true)} />
										</List.Content>}
										<List.Content>Admin role</List.Content>
									</List.Item>}
								</List>

								<Grid columns={2}>
									<Grid.Row>
										<Grid.Column>
											{client.detained &&
												<ComponentWithConfirmation onConfirm={() => handleDetain(null)} confirmContent={'Are you sure you want unfreeze client?'}>
													<Button
														floated='right'
														secondary
														content={'Unfreeze'}
														disabled={!writePermission}
													/>
												</ComponentWithConfirmation>}
											{!client.detained && <Button
												floated='right'
												onClick={() => setDetainReasonModal(true)}
												content={'Freeze'}
												color='red'
												disabled={!writePermission}
											/>}
										</Grid.Column>
										<Grid.Column>

											{client.disabled &&
												<ComponentWithConfirmation onConfirm={handleDisable} confirmContent={'Are you sure you want enable client?'}>
													<Button
														floated='left'
														secondary
														content={'Enable'}
														disabled={!writePermission}
													/>
												</ComponentWithConfirmation>}
											{!client.disabled &&
												<ComponentWithConfirmation onConfirm={handleDisable} confirmContent={'Are you sure you want disable client?'}>
													<Button
														floated='left'
														color='red'
														content={'Disable'}
														disabled={!writePermission}
													/>
												</ComponentWithConfirmation>}

										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Card.Content>
						</Card>
					</Grid.Column>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<ChangeEmailModal
								open={openChangeEmailModal}
								onClose={handleChangeEmailClose}
							/>
							<ChangePhoneModal
								open={openChangePhoneModal}
								onClose={handleChangePhoneClose}
							/>
							<List id="profile-data">
								{client.profile.type !== UserType.BUSINESS && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Name</List.Header>
											<List.Description>{client.profile.firstName} {client.profile.lastName}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Birth date</List.Header>
											<List.Description>{formatDateWithPattern(client.profile.birthDate, 'dd/MM/yyyy')}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Document issuing country</List.Header>
											<List.Description>{countries[alpha3ToAlpha2(client.profile.country)]}</List.Description>
										</List.Content>
									</List.Item>
									<Divider /></div>}
								{client.profile.type === UserType.BUSINESS && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Company name</List.Header>
											<List.Description>{client.profile.companyName}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Company Reg Number</List.Header>
											<List.Description>{client.profile.companyRegNumber}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Company address</List.Header>
											<List.Description>{client.profile.companyAddress}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Company country</List.Header>
											<List.Description>{countries[alpha3ToAlpha2(client.profile.companyCountry)]}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
								</div>}
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Email</List.Header>
										<List.Description>{client.email} <Icon onClick={() => setOpenChangeEmailModal(true)} disabled={!writePermission} style={{ marginLeft: 4, cursor: 'pointer' }} name='edit' /></List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Phone number</List.Header>
										<List.Description>
											<Grid>
												<Grid.Row style={{ marginLeft: '1rem' }}>
													{client.profile.mobileNumber}<Icon onClick={() => setOpenChangePhoneModal(true)} disabled={!checkPermission(permissions, PermissionType.CHANGE_PHONE)} style={{ marginLeft: 4, cursor: 'pointer' }} name='edit' />
													{client.mobileNumberConfirmed  && <p style={{ marginLeft: '1em', color: 'green' }}>(Confirmed)</p>}
													{!client.mobileNumberConfirmed  &&
														<ComponentWithConfirmation onConfirm={() => confirmPhoneNumber()} confirmContent={'Are you sure you want confirm phone?'}>
															<Button
																color='green'
																content='Confirm'
																disabled={!writePermission}
																size='mini'
																style={{ marginLeft: '1em' }}
															/>
														</ComponentWithConfirmation>}

												</Grid.Row>
											</Grid>
										</List.Description>
									</List.Content>
								</List.Item>
							</List>
							<Divider hidden />
							<Divider hidden />
							<Grid>
								<ChangeRiskGroupModal
									open={openRiskGroupModal}
									riskGroupCode={client.riskGroupCode}
									fixedRiskGroup={client.riskGroupFixed}
									onClose={onCloeseChangeRiskGroupModal}
								/>
								<TransactionMonitoringModal
									open={openTransactionMonitoringModal}
									onClose={onCloseChangeTransactionMonitored}
									isMonitored={isMonitorTransactions}
								/>
								<Grid.Row>
									<Button
										primary
										content='Change risk group'
										onClick={() => setOpenRiskGroupModal(true)}
										fluid
										disabled={!writePermission}
									/>
								</Grid.Row>
								<Grid.Row>
									<Button
										primary
										content='Transactions monitoring'
										onClick={() => setOpenTransactionMonitoringModal(true)}
										fluid
										disabled={!writePermission}
									/>
								</Grid.Row>
								{/* <Grid.Row>
									<Button
										primary
										content='Change user group'
										onClick={() => setOpenUserGroupModal(true)}
										fluid
										disabled={!writePermission}
									/>
								</Grid.Row> */}
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid>
			</Segment>
		</Container >
	);
};

export default ClientProfile;
