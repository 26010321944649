import { showErrorNotification } from '@/features/swal/slice';
import { Formik } from 'formik';
import { Form, Input, ResetButton, Select, SubmitButton } from 'formik-semantic-ui-react';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import './Table.css';

export interface SelectOption {
	key: string | number,
	text: string,
	value: string | boolean | number
}

export interface FilterParam {
	field: string,
	label: string,
	type: 'input' | 'select',
	options?: SelectOption[],
	inputType?: string,
	required?: boolean,
	onChange?: (value: any) => void
}

interface Props {
	filter: FilterParam[],
	activeFilter?: string,
	handleFilterChange: (filterString: string, clear: boolean) => void
}
const TableFilters = ({ filter, activeFilter, handleFilterChange }: Props): React.ReactElement => {

	const activeFilterObj = activeFilter && activeFilter.split(';').reduce(
		(obj, str) => {
			const parts = str.split('=');
			return { ...obj, [parts[0]]: parts[1] };
		}, {});

	const prepareInitValues = () => {

		const initValues = {};
		filter.map(entry => {
			initValues[entry.field] = activeFilterObj[entry.field] ?? '';
		});

		return initValues;
	};

	const initialValues = prepareInitValues();

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			let filterString = '';
			Object.entries(formData).map(([key, value]) => {
				if (value.toString().trim() !== '') {

					if (filterString !== '') {
						filterString += ';';
					}
					filterString = filterString.concat(`${key}=${value.toString().trim()}`);
				}
			});

			if (filterString !== '') {
				handleFilterChange(filterString, false);
			} else {
				handleFilterChange(null, true);
			}

		} catch (err) {
			showErrorNotification(err);
		} finally {
			setSubmitting(false);
		}
	};

	const handleFilterField = (entry) => {
		if (entry.inputType === 'date') {
			return (
				<Input
					key={entry.field}
					className='field'
					label={entry.label}
					name={entry.field}
					type={entry.inputType ? entry.inputType : ''}
					required={entry.required}
					placeholder='YYYY/MM/DD'
					max="2999-12-31"
					errorPrompt
					clearable
				/>
			);
		} else if (entry.type === 'input') {
			return (
				<Input 
					key={entry.field}
					className='field'
					label={entry.label}
					name={entry.field}
					type={entry.inputType ? entry.inputType : ''}
					required={entry.required}
				/>);
		} else if (entry.type === 'select') {
			return (
				<Select
					key={entry.field}
					className='field'
					name={entry.field}
					label={entry.label}
					options={entry.options}
					clearable
					selection
					search
					required={entry.required}
					onChange={(event, data) => entry?.onChange && entry.onChange(data.value)}
				/>
			);
		}
	};

	return (
		<div>
			<Formik
				initialValues={initialValues}
				onSubmit={submit}
				enableReinitialize
			>{({ isSubmitting, values }) => {
				const mandatoryFields = filter.filter(p => p.required);
				const isEmptyMandatoryField = mandatoryFields.some(p => values[p.field] === '');
				return (
					<Form >
						<Grid id='filters'>
							<Grid.Row>
								{filter.map(entry =>
									handleFilterField(entry)
								)}
							</Grid.Row>
							<Grid.Row id='buttons'>
								<SubmitButton type='submit'
									disabled={isSubmitting || isEmptyMandatoryField}
									primary>Search</SubmitButton>
								<ResetButton basic onClick={() => handleFilterChange(null, true)} type='reset'>Clear</ResetButton>
							</Grid.Row>
						</Grid>

					</Form>
				);
			}}
			</Formik>
		</div >
	);

};

export default TableFilters;
