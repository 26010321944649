import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { showErrorNotification } from '../swal/slice';
import { AddTokenPayload, AvailableTokenType, Token, TokenDepositRequest, TokensStore } from './types';

const initialState: TokensStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'tokens',
	initialState,
	reducers: {
		setTokens(state, action: PayloadAction<Array<Token>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setTokens, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getTokensList = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/tokens/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setTokens(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const postLogoUpload = async (payload: FormData): Promise<any> => {
	const response = await request.post('/api/admin/tokens/logo', payload);
	const { data } = response;
	return data;
};

export const postToken = async (payload: AddTokenPayload) => {
	const response = await request.post('/api/admin/tokens', payload);
	const { data } = response;
	return data;
};

export const getAvailableCustomTokens = async (): Promise<AvailableTokenType[]> => {
	const response = await request.get('/api/admin/tokens/custom/available');
	const { data } = response;
	return data;
};
export const getUserAvailableCustomTokens = async (userId: number): Promise<AvailableTokenType[]> => {
	const response = await request.get(`/api/admin/tokens/custom/available/user/${userId}`);
	const { data } = response;
	const tmp = Array.isArray(data) ? data : [];
	return tmp;
};

export const postCreateTokenyAccount = async (userId: number, ccy: string) => {
	const response = await request.post('/api/admin/tokens/create/account', {userId, ccy});
	const { data } = response;
	return data;
};


export const postTokenUpdateBalance = async (payload: TokenDepositRequest, type: 'MANUAL_DEPOSIT' | 'MANUAL_WITHDRAWAL') => {
	const response = await request.post(`/api/admin/tokens/update/${type}`, payload);
	const { data } = response;
	return data;
};

export default slice.reducer;
