import React, { useState } from 'react';
import { Grid, Header, Button, Modal, Divider } from 'semantic-ui-react';
import { Form, SubmitButton } from 'formik-semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { showErrorNotification } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { MakePaymentType, PaymentConfigurationType } from '@/features/payments/paymentTypes';
import { postPayment } from '@/features/payments/configSlice';
import { SystemAccountSummaryType } from '@/features/systemAccountDetails/types';
import { isEmpty } from 'lodash';
import './PaymentModal.css';
import PaymentConfigurations from './Components/PaymentConfigurations';
import SystemAccountInfo from './Components/SystemAccountInfo';
import Payment from './Components/Payment';

export enum ModalMode { VIEW = 'View', EDIT = 'Edit', ADD = 'Add' }

interface Props {
	open: boolean;
	systemAccount: SystemAccountSummaryType,
	onClose: () => void,
	writePermission: boolean
}

const PaymentModal = ({ open, systemAccount, onClose, writePermission }: Props): React.ReactElement => {
	const closeModal = (event) => {
		event.preventDefault();
		setSelectedConfig(null);
		onClose();
	};

	const paymentConfigsAll = useSelector((state: RootState) => state.paymentConfig.list);
	const [selectedConfig, setSelectedConfig] = useState<PaymentConfigurationType>(null);

	const paymentConfigs = paymentConfigsAll && paymentConfigsAll
		.filter(p => p.isActive === 'Y' &&
			p.bankAccountId === systemAccount?.bankAccountId
		);

	const initialValues = {
		operation: selectedConfig?.paymentConfigId,
		currency: systemAccount?.currencyCode ?? '',
		amount: '',
		transactionDate: '',
		details: selectedConfig?.paymentDescription ?? '',
		reconciliationRef: '',
		platformReference: '',
		toAccountId: '',
		//---
		fromBank: selectedConfig?.fromBankName ?? '',
		fromIban: selectedConfig?.fromAccountNumIban ?? '',
		fromCustomerName: selectedConfig?.fromCustomerName ?? '',
		fromCustomerId: selectedConfig?.fromCustomerId ?? '',
		fromAddress: selectedConfig?.fromBankAddress ?? '',
		//---
		toBank: selectedConfig?.toBankName ?? '',
		toIban: selectedConfig?.toAccountNumIban ?? '',
		toCustomerName: selectedConfig?.toCustomerName ?? '',
		toCustomerId: selectedConfig?.toCustomerId ?? '',
		toAddress: selectedConfig?.toBankAddress ?? '',
	};


	const handleConfigChange = (value: PaymentConfigurationType) => {
		setSelectedConfig(value);
	};

	const validationSchema = Yup.object({
		operation: Yup.string().required(),
		amount: Yup.string().required(' '),
		currency: Yup.string().required(' '),
		toAccountId: Yup.string().test('To Account', 'Required', (value) => (
			(selectedConfig?.operationCode !== 'TRANSFER_TO_SYS_ACCT' &&
				selectedConfig?.operationCode !== 'MAIN_ACCOUNT_TRANSFERS') || !!value))
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(true);

		try {
			const { amount, reconciliationRef, details, transactionDate, toAccountId } = formData;

			const utcDate = transactionDate ? new Date(transactionDate).toISOString() : null;
			const dataBody: MakePaymentType = {
				wlpId: systemAccount.wlpId,
				bankAccountId: systemAccount.bankAccountId,
				accountId: systemAccount.accountId,
				currencyCode: systemAccount.currencyCode,
				paymentConfigId: selectedConfig.paymentConfigId,
				amount: amount,
				bankReconRef: reconciliationRef,
				paymentDate: utcDate,
				paymentDescription: details,
				toAccountId: toAccountId
			};
			await postPayment(selectedConfig.userId, 'sys-execute-sys-payment', dataBody);

		}
		catch (e) {
			showErrorNotification(e);
		}
		finally {
			setSubmitting(false);
			onClose();
		}
	};

	const editDisabled = !writePermission;

	return (
		<Modal
			id='payment-modal'
			size='large'
			open={open}
			onClose={closeModal}>
			<Modal.Content>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
					enableReinitialize
				>
					{({ isSubmitting, errors }) => {
						return (
							<Form
								size='small'>
								<Header content='Make Payment' />
								<SystemAccountInfo systemAccount={systemAccount} />
								<PaymentConfigurations
									paymentConfigs={paymentConfigs}
									selectedConfig={selectedConfig}
									handleConfigChange={handleConfigChange} />
								<Divider hidden />
								<Payment selectedConfig={selectedConfig}
									systemAccount={systemAccount}
									editDisabled={editDisabled}
								/>
								<Grid columns={2}>
									<Grid.Row>
										<Grid.Column>
											<Button floated='right' secondary size='large' content='Discard' onClick={closeModal} />
										</Grid.Column>
										<Grid.Column >
											<SubmitButton
												disabled={isSubmitting || editDisabled || !(selectedConfig?.procName) || !isEmpty(errors)}
												primary size='large' type='submit'
												floated='left' >
												Make Payment
											</SubmitButton>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Form>);
					}}
				</Formik>
			</Modal.Content>
		</Modal>
	);
};

export default PaymentModal;
