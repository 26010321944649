import { PaginateParams } from '@/helpers/globalTypes';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showException } from '../swal/slice';
import { DownloadBalanceProps, StatementAccountBalance, StatementAccountBalanceStore } from './statementsTypes';

const initialState: StatementAccountBalanceStore = {
	loading: false,
	list: [],
	activeItems: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
	isDownloading: false,
};


const slice = createSlice({
	name: 'accountBalance',
	initialState,
	reducers: {
		setStatementAccountBalance(state, action: PayloadAction<Array<StatementAccountBalance>>) {
			state.list = action.payload;
		},
		setActiveItems(state, action: PayloadAction<Array<string>>) {
			state.activeItems = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		},
		setDownloading: (state, { payload }: PayloadAction<boolean>) => {
			state.isDownloading = payload;
		},
	}
});

export const { setStatementAccountBalance, setActiveItems, setLoading, setError, setCount, setTotalPage, setPageSize, setDownloading } = slice.actions;



export const getStatementsAccountBalance = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));

			const response = await request.get('/api/admin/statements/balances', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setStatementAccountBalance(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			dispatch(setLoading(false));
			showException(e);
			//dispatch(setError(e.statusText));
		}
	};
};

export const getBalanceFirstLine = (period: string, payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));

			const response = await request.get(`/api/admin/statements/balance-first-line/${period}`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setStatementAccountBalance(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			dispatch(setLoading(false));
			showException(e);
			//dispatch(setError(e.statusText));
		}
	};
};



export const getStatementsAccountBalanceApi = async (payload: PaginateParams): Promise<StatementAccountBalance[]> => {
	const response = await request.get('/api/admin/statements/balances', {
		params: {
			filter: payload.filter,
			orderBy: payload.orderBy,
			sort: payload.sort.toUpperCase(),
			skip: payload.skip.toString(),
			take: payload.take
		}
	});
	const { data } = response;
	return data.list;
};



export const downloadBalance = (payload: DownloadBalanceProps): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setDownloading(true));
			const response = await request.get(`api/admin/statements/balanceExport/${payload.rootBankAccountId}/${payload.period}/${payload.format}`,
				{
					responseType: 'blob',
					params: { type: payload.format },
				});
			const url = window.URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.download = `${payload.period}-balance.csv`;
			link.href = url;
			link.className = 'hidden';
			document.body.appendChild(link);

			link.onclick = function () {
				requestAnimationFrame(function () {
					URL.revokeObjectURL(url);
					setTimeout(() => link.remove(), 300);
				});
			};

			link.click();
			dispatch(setDownloading(false));
		}
		catch (e) {
			showException(e);
			dispatch(setDownloading(false));
		}
		finally { dispatch(setDownloading(false)); }
	};
};




export default slice.reducer;
