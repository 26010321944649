import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { getPrograms } from '@/features/referralPrograms/slice';
import { RewardPaymentType } from '@/features/referralPrograms/types';
import NumberFormat from 'react-number-format';
import { formatDateWithPattern } from '@/helpers/date';

interface Props {
	writePermission: boolean,
}

const ReferralPrograms = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.referralPrograms
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const headers: Array<Headers> = [
		{
			field: 'name',
			name: 'Name',
			position: 1,
			sortable: false
		},
		{
			field: 'description',
			name: 'Description',
			position: 2,
			sortable: false
		},
		{
			field: 'code',
			name: 'Type',
			position: 3,
			sortable: false
		},
		{
			field: 'application',
			name: 'Payment method',
			position: 4,
			sortable: false
		},
		{
			field: 'rewardAmount',
			name: 'Reward',
			position: 5,
			sortable: false
		},
		{
			field: 'targetAmount',
			name: 'Target amount',
			position: 6,
			sortable: false
		},
		{
			field: 'disabled',
			name: 'Enabled',
			position: 7,
			sortable: false
		},
		{
			field: 'startDate',
			name: 'Period',
			position: 8,
			sortable: false
		},
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getPrograms(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.code}${index}`} >
						<Table.Cell width={2}>{entry.name}</Table.Cell>
						<Table.Cell width={2}>{entry.description}</Table.Cell>
						<Table.Cell width={2}>{entry.code}</Table.Cell>
						<Table.Cell width={2}>{entry.application}</Table.Cell>
						<Table.Cell width={2}>
							{entry.paymentType === RewardPaymentType.PERCENT ? `${entry.rewardAmount}%` :
								<NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.rewardAmount} suffix={entry.rewardCcy} />}
						</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.targetAmount} suffix={entry.rewardCcy} />
						</Table.Cell>
						<Table.Cell width={2}>
							{!entry.disabled && <Icon disabled={!writePermission} className='tableActionIcon' color='green' size='large' name="check circle" />}
							{entry.disabled && <Icon disabled={!writePermission} className='tableActionIcon' color='red' size='large' name="times circle" />}
						</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.startDate, 'dd/MM/yyyy')} - {formatDateWithPattern(entry.endDate, 'dd/MM/yyyy')}</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>Not programs yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Referral programs</Header></Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default ReferralPrograms;
