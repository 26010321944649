import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import {Form, Input, Select, SubmitButton} from 'formik-semantic-ui-react';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
	Button,
	Divider,
	Grid, Header,
	Modal
} from 'semantic-ui-react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import { Account } from '@/features/accounts/types';
import {getVaultByCcy} from '@features/vaultProcesses/slice';
import {postOperations} from '@features/vaults/vaultsSlice';

interface Props {
	account: Account,
	open: boolean;
	onClose: () => void,
}

const VaultDepositModal = ({open, onClose, account}: Props): React.ReactElement => {
	const {permissions} = useSelector(
		(state: RootState) => state.user
	);
	// const vaults = useSelector((state: RootState) => state.vaultProcesses.list);

	const [procListOptions, setProcListOptions] = useState<any>(null);
	const [vaultsLoading, setVaultsLoading] = useState<any>(false);

	useEffect(() => {
		const get = async () => {
			setProcListOptions([]);
			try {
				setVaultsLoading(true);
				if (account) {
					if (account?.currency) {
						const vaults = await getVaultByCcy(account.currency);
						if (vaults?.list?.length > 0) {
							const vaultOptions = [];
							vaults.list.map(entry => {
								vaultOptions.push({ key: entry.proc, value: entry.proc, text: entry.vaultName });
							});
							setProcListOptions(vaultOptions);
						} else {
							throw `No vaults found for currency ${account.currency}`;
						}
					} else {
						throw 'No currency found';
					}
				}
			} catch (e) {
				showErrorNotification(e, e);
			} finally {
				setVaultsLoading(false);
			}
		};
		get();
	}, [account]);


	const validationSchema = Yup.object({
		amount: Yup.number().required('Amount is required').min(0, 'Fee min value is 0'),
		vault: Yup.string().required('Vault is required'),
	});

	const submit = async (formData, formikProps) => {
		const {setSubmitting} = formikProps;
		const {
			amount,
			vault,
		} = formData;

		try {
			await postOperations(account.userId, vault, {
				amount: Number(amount),
				accountId: account.id,
			});
			onClose();
			showSuccessNotification('Service fee successfully');
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
		}
	};


	return (<Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={{
					amount: account?.balance ? `${account.balance}` : '0.00',
					vault: '',
				}}
				onSubmit={submit}
				validationSchema={validationSchema}
				render={({setFieldValue, isSubmitting, errors}) => {
					return (
						<Form>
							<Header content="Deposit" />
							<Input
								label={`Amount ${account.currency}`}
								fluid
								errorPrompt
								name="amount"
								type='number'
							/>
							<Select
								label='Vault'
								fluid
								clearable
								errorPrompt
								name="vault"
								loading={vaultsLoading}
								options={procListOptions}
								onChange={(element, { value }) => setFieldValue('vault', value)}
							/>
							<Divider hidden/>
							<Grid columns={2}>
								<Grid.Row centered>
									<Grid.Column>
										<SubmitButton primary
											type="submit"
											loading={isSubmitting}
											disabled={!checkWritePermission(permissions, PermissionType.SUPPORT) || isSubmitting || !isEmpty(errors)}
											floated='right'>
											Execute
										</SubmitButton>
									</Grid.Column>
									<Grid.Column>
										<Button floated='left' secondary content='Discard' onClick={() => onClose()}/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					);
				}}
			/>

		</Modal.Content>
	</Modal>);
};

export default VaultDepositModal;
