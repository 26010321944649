import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Period, PeriodsStore } from './periodsTypes';
import { showException } from '../swal/slice';

const initialState: PeriodsStore = {
	status: 'idle',
	error: null,
	list: []
};


const slice = createSlice({
	name: 'period',
	initialState,
	reducers: {
		setPeriods(state, action: PayloadAction<Array<Period>>) {
			state.list = action.payload;
		},
		setLoading: (state) => {
			state.status = 'loading';
		},
		setSucceeded: (state) => {
			state.status = 'succeeded';
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
			state.status = 'failed';
		},
	}
});

export const { setPeriods, setLoading, setSucceeded, setError } = slice.actions;


export const getPeriods = (): AppThunk => {
	return async dispatch => {
		try { 
			dispatch(setLoading());
			const response = await request.get('/api/admin/statements/periods');
			const { data } = response; 
			dispatch(setPeriods(data));
			dispatch(setSucceeded());
			return data;
		} catch (e) {
			showException(e);
			dispatch(setError(e.statusText));
		}
	};
};

export default slice.reducer;
