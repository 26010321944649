import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { showErrorNotification } from '@/features/swal/slice';
import { getClientActivity } from '@/features/clientActivity/slice';
import { formatDateWithPattern } from '@/helpers/date';

interface Props {
	userId: number,
}

const ClientActivity = ({ userId }: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.clientActivity
	);
	const paginateInitialState: PaginateParams = { orderBy: 'created_date', sort: 'DESC', skip: 0, filter: '', take: 5 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);

	const headers: Array<Headers> = [
		{
			field: 'action',
			name: 'Action',
			position: 1,
			sortable: false
		},
		{
			field: 'source',
			name: 'Source',
			position: 2,
			sortable: false
		},
		{
			field: 'ip_address',
			name: 'IP address',
			position: 3,
			sortable: false
		},
		{
			field: 'location',
			name: 'Location',
			position: 4,
			sortable: false
		},
		{
			field: 'created_date',
			name: 'When',
			position: 5,
			sortable: true
		},
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getClientActivity(userId, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch, userId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	
	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.created_date}${index}`} >
						<Table.Cell width={4}>{entry.action}</Table.Cell>
						<Table.Cell width={3}>{entry.source}</Table.Cell>
						<Table.Cell width={3}>{entry.ip_address}</Table.Cell>
						<Table.Cell width={3}>{entry.location}</Table.Cell>
						<Table.Cell width={3}>{formatDateWithPattern(entry.created_date, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>Client does not have any activity yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<CustomTable
			fetchData={fetchData}
			loading={loading}
			paginateInitialState={paginateInitialState}
			tableBody={tableBody}
			totalPages={totalPages}
			setPagination={setPagination}
			header={headers}
			pagination={pagination}
			hideItemsPerPage={true}
		/>
	);

};

export default ClientActivity;
