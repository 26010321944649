import { PaginateParams } from '@/helpers/globalTypes';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showException } from '../swal/slice';
import { PaymentHistoryType, PaymentHistoryStore } from './paymentTypes';

const initialState: PaymentHistoryStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0
};

const slice = createSlice({
	name: 'paymentHistory',
	initialState,
	reducers: {
		setPaymentHistory(state, action: PayloadAction<Array<PaymentHistoryType>>) {
			state.list = action.payload ?? [];
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const {
	setPaymentHistory, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getPaymentHistory = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const filterParams = {
				filter: payload.filter,
				orderBy: payload.orderBy,
				sort: payload.sort.toUpperCase(),
				skip: payload.skip.toString(),
				take: payload.take
			};
			const response = await request.get('/api/admin/payments/history', { params: filterParams });
			const { data } = response; 
			dispatch(setPaymentHistory(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			dispatch(setLoading(false));
			showException(e);
		}
	};
};


export default slice.reducer;
