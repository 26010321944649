import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '@/store';
import { PaginateParams } from '@/helpers/globalTypes';
import { Account, AccountsStore, DepositRequest } from './types';
import { showErrorNotification } from '../swal/slice';

const initialState: AccountsStore = {
	loading: false,
	list: [],
	account: null,
	error: null,
	count: 0,
	totalPages: 0,
	pageSize: 0,
};


const slice = createSlice({
	name: 'accounts',
	initialState,
	reducers: {
		setAccounts(state, action: PayloadAction<Array<Account>>) {
			state.list = action.payload;
		},
		setAccount(state, action: PayloadAction<Account>) {
			state.account = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPages = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		}
	}
});

export const { setAccounts, setAccount, setLoading, setError, setCount, setTotalPage, setPageSize } = slice.actions;

export const getAccounts = (type: string, payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/accounts/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					type: type,
				}
			});
			const { data } = response;
			dispatch(setAccounts(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(payload.take));
			dispatch(setTotalPage(Math.ceil(data.count / payload.take)));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getAccount = async (accountId: number) => {
	const response = await request.get(`/api/admin/accounts/${accountId}`);
	const { data } = response;
	return data;
};

export const changeAccountStatus = async (accountId: number, status: 'ACTIVE' | 'BLOCKED') => {
	const response = await request.post(`/api/admin/accounts/${accountId}/status/${status}`);
	const { data } = response;
	return data;
};

export const postUpdateBalance = async (payload: DepositRequest, type: 'MANUAL_DEPOSIT' | 'MANUAL_WITHDRAWAL') => {
	const response = await request.post(`/api/admin/accounts/update/${type}`, payload);
	const { data } = response;
	return data;
};

export const postCreateBusinessCJAccount = async (userId: number, iban: string = null, accountId: number = null) => {
	const response = await request.post('/api/admin/accounts/create/business', { userId, iban, accountId });
	const { data } = response;
	return data;
};

export const postCreateCustodyAccount = async (userId: number) => {
	const response = await request.post('/api/admin/accounts/create/trust', { userId });
	const { data } = response;
	return data;
};

export const postApplyCommission = async (accountId: number) => {
	const response = await request.post('/api/admin/accounts/commission/apply', { accountId });
	const { data } = response;
	return data;
};

export const changeClosureDate = async (accountId: number, closureDate: number) => {
	const response = await request.post('/api/admin/accounts/closure/change', { accountId, closureDate });
	const { data } = response;
	return data;
};

export const postChangeCommissionDate = async (accountId: number, epochDate: number) => {
	const response = await request.post('/api/admin/accounts/commission/update-date', { accountId, epochDate });
	const { data } = response;
	return data;
};
export const postCreateChildAccount = async (parentAccountId: number) => {
	const response = await request.post('/api/admin/accounts/create/child', { parentAccountId });
	const { data } = response;
	return data;
};

export const getAccountByWalletId = async (walletId: string): Promise<Account> => {
	const response = await request.get(`/api/admin/accounts/wallet/${walletId}`);
	const { data } = response;
	return data;

};

export const getUserAccounts = async (userId: number, ccy: string) => {
	const response = await request.get(`/api/admin/accounts/list/user/${userId}/${ccy}`);
	const { data } = response;
	return data;
};

export const postInternalSystemTransfer = async (fromWalletId: string, toWalletId: string, amount: number, reference: string) => {
	const response = await request.post('/api/admin/internal-transfer', { fromWalletId, toWalletId, amount, reference });
	const { data } = response;
	return data;
};

export default slice.reducer;
