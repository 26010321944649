import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Divider, Header, Segment, Table, Button } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import NumberFormat from 'react-number-format';
import { useHistory, withRouter } from 'react-router-dom';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { getSystemAccountDetails } from '@/features/systemAccountDetails/detailsSlice';
import './Payment.css';
import { FilterParam } from '@/components/Shared/Table/TableFilters';

interface Props {
	match: {
		params: {
			bankAccountId: number,
		}
	}
}

const SystemAccountDetails = (props: Props): React.ReactElement => {

	const [bankAccountId] = useState<number>(props.match.params.bankAccountId);
	const history = useHistory();

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector((state: RootState) => state.systemAccountDetails);

	const paginateInitialState: PaginateParams = { orderBy: 'wlpId', skip: 0, sort: 'ASC', filter: `bankAccountId=${bankAccountId}`, take: 25 };
 
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);


	const filter: FilterParam[] = [
		{
			field: 'fromDate',
			label: 'From Date',
			type: 'input',
			inputType: 'date'
		},
		{
			field: 'toDate',
			label: 'To Date',
			type: 'input',
			inputType: 'date'
		},
		{
			field: 'description',
			label: 'Description',
			type: 'input'
		},
		{
			field: 'procRepName',
			label: 'Process',
			type: 'input'
		},
	];


	const headers: Array<Headers> = [ 
		{
			field: 'description',
			name: 'Description',
			position: 1,
			sortable: false
		},  
		{
			field: 'procRepName',
			name: 'Process',
			position: 2,
			sortable: false
		},
		{
			field: 'trxPeriod',
			name: 'Period',
			position: 3,
			sortable: true
		},
		{
			field: 'trxMmDay',
			name: 'Day',
			position: 4,
			sortable: true
		},
		{
			field: 'transactionType',
			name: 'Trx Type',
			position: 5,
			sortable: false
		},
		{
			field: 'transactionCategory',
			name: 'Trx Category',
			position: 6,
			sortable: false
		},
		{
			field: 'dr',
			name: 'DR',
			position: 7,
			sortable: false
		},
		{
			field: 'cr',
			name: 'CR',
			position: 8,
			sortable: false
		},
		{
			field: 'trxCount',
			name: 'Trcx Count',
			position: 9,
			sortable: false
		}
	];

	useEffect(() => {
		dispatch(getSystemAccountDetails(pagination));
	}, [pagination, dispatch]);

 
	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, idx) => {
				return (
					<Table.Row key={`${entry.bankAccountId}-${entry.trxPeriod}-${idx}`} > 
						<Table.Cell width={2}>{entry.description}</Table.Cell> 
						<Table.Cell width={1}>{entry.procRepName}</Table.Cell>
						<Table.Cell width={1}>{entry.trxPeriod}</Table.Cell>
						<Table.Cell width={1}>{entry.trxMmDay}</Table.Cell>
						<Table.Cell width={1}>{entry.transactionType}</Table.Cell>
						<Table.Cell width={1}>{entry.transactionCategory}</Table.Cell>
						<Table.Cell width={1}>
							<NumberFormat displayType={'text'}fixedDecimalScale={false}
								thousandsGroupStyle='thousand' thousandSeparator={true}
								value={entry.dr} />
						</Table.Cell>
						<Table.Cell width={1}>
							<NumberFormat displayType={'text'} fixedDecimalScale={false}
								thousandsGroupStyle='thousand' thousandSeparator={true}
								value={entry.cr} />
						</Table.Cell>
						<Table.Cell width={1}>{entry.trxCount}</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>No data yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid id='system-account-details'>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>System Account Details</Header></Grid.Column>
						<Grid.Column floated='right'><Button floated='right' primary onClick={() => history.goBack()}>Go back</Button></Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					loading={loading}
					filter={filter}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true} />
			</Segment>
		</Container>
	);
};



export default withRouter(SystemAccountDetails);
