import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { formatDateWithPattern } from '@/helpers/date';
import { approveMeeting, cancelMeeting, getKycCalendarList } from '@/features/kycCalendar/slice';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { KycCalendar } from '@/features/kycCalendar/types';
import { createEvent, EventAttributes } from 'ics';
import { downloadFile } from '@/helpers/download';
import { getCurrentWhitelabel } from '@/features/whitelabel/whitelabelSlice';
import { CurrentWhitelabel } from '@/features/whitelabel/whitelabelTypes';

interface Props {
	writePermission: boolean,
}


const KYCCalendar = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.kycCalendar
	);

	const { whitelabel } = useSelector(
		(state: RootState) => state.whitelabel
	);

	const paginateInitialState: PaginateParams = { orderBy: 'startTime', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [currentWL, setCurrentWL] = useState<CurrentWhitelabel>(null);

	const getCurrentWL = useCallback(() => {
		const get = async () => {
			try {
				setCurrentWL(await getCurrentWhitelabel());
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		getCurrentWL();
	}, [whitelabel]);

	const filter: FilterParam[] = [
		{
			field: 'name',
			label: 'Name',
			type: 'input'
		},
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: [
				{
					key: 'PENDING',
					value: 'PENDING',
					text: 'Pending'
				},
				{
					key: 'APPROVED',
					value: 'APPROVED',
					text: 'Approved'
				},
				{
					key: 'CANCELED',
					value: 'CANCELED',
					text: 'Canceled'
				}
			]
		},
		{
			field: 'applicantId',
			label: 'Applicant ID',
			type: 'input'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'name',
			name: 'Name',
			position: 1,
			sortable: true
		},
		{
			field: 'startTime',
			name: 'Start Time',
			position: 2,
			sortable: true
		},
		{
			field: 'endTime',
			name: 'End Time',
			position: 3,
			sortable: true
		},
		{
			field: 'applicantId',
			name: 'Applicant ID',
			position: 4,
			sortable: false
		},
		{
			field: 'status',
			name: 'Status',
			position: 5,
			sortable: false
		},
		{
			field: 'language',
			name: 'Language',
			position: 6,
			sortable: false
		},
		{
			field: '',
			name: 'Admin',
			position: 7,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 8,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getKycCalendarList(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);


	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const openPOA = (userid: number) => {
		history.push(`/poa/${userid}`);
	};

	const approve = async (id: number) => {
		try {
			await approveMeeting(id);
			fetchData();
			showSuccessNotification('Meeting approved');
		} catch (err) {
			showErrorNotification('Failed to approve meeting');
		}
	};

	const reject = async (id: number) => {
		try {
			await cancelMeeting(id);
			fetchData();
			showSuccessNotification('Meeting canceled');
		} catch (err) {
			showErrorNotification('Failed to cancel meeting');
		}
	};

	const downloadCalendarEvent = (entry: KycCalendar) => {
		const startDate = new Date(parseInt(entry.appointmentId));
		const event: EventAttributes = {
			start: [startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate(), startDate.getHours(), startDate.getMinutes()],
			duration: { minutes: 15 },
			title: `${currentWL.name} - Video Verification`,
			description: `Your Individual Verification video call with our compliance officer have been accepted. Please use this link at your scheduled time - ${entry.link}`,
			url: entry.link,
			location: entry.link,
			status: 'CONFIRMED',
			busyStatus: 'BUSY',
			organizer: { name: entry.adminName, email: entry.adminEmail },
			attendees: [
				{ name: entry.name, email: entry.adminEmail, rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
				{ name: entry.adminName, email: entry.userEmail, role: 'REQ-PARTICIPANT' }
			]
		};

		createEvent(event, (error, value) => {
			if (error) {
				console.log(error);
				return;
			}

			downloadFile({ fileName: `${entry.name.replace(/\s+/g, '-')}.ics`, fileContents: value, mimeType: 'text/calendar' });
		});


	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.name}</Table.Cell>
						<Table.Cell width={3}>{formatDateWithPattern(entry.startTime, 'dd/MM/yyyy HH:mm')}</Table.Cell>
						<Table.Cell width={3}>{formatDateWithPattern(entry.endTime, 'dd/MM/yyyy HH:mm')}</Table.Cell>
						<Table.Cell width={2}>{entry.applicantId}</Table.Cell>
						<Table.Cell width={1}>{entry.status}</Table.Cell>
						<Table.Cell width={1}>{entry.language}</Table.Cell>
						<Table.Cell width={1}>{entry.updatedByAdmin !== null ? entry.adminName : ''}</Table.Cell>
						<Table.Cell width={3}>
							{entry.status === 'PENDING' && <>
								<ComponentWithConfirmation onConfirm={() => approve(entry.id)} confirmContent={'Are you sure you want approve meeting?'}>
									<Button
										color='green'
										content='Approve'
										disabled={!writePermission}
									/>
								</ComponentWithConfirmation>
								<ComponentWithConfirmation onConfirm={() => reject(entry.id)} confirmContent={'Are you sure you want cancel meeting?'}>
									<Button
										color='red'
										content='Cancel'
										disabled={!writePermission}
									/>
								</ComponentWithConfirmation>
							</>}
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
							<Popup content='Open user POA' trigger={<Icon className='tableActionIcon' size='large' name="file alternate outline" onClick={() => openPOA(entry.userId)} />} />
							{entry.status === 'APPROVED' && <Popup content='Download iCal event' trigger={<Icon className='tableActionIcon' size='large' name="calendar alternate outline" onClick={() => downloadCalendarEvent(entry)} />} />}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>Not meetings present.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>KYC Calendar</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default KYCCalendar;
