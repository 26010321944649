import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import isEmpty from 'is-empty';
import {
	Message,
	Grid,
	Segment,
	Divider,
	Container,
	Image,
	Transition,
	Ref
} from 'semantic-ui-react';
import { getDeviceInfo } from '@helpers/browser';
import logo from '@assets/registrationLogo.svg';
import './Login.css';
import { login, logout } from '@features/user/userSlice';
import { DeviceInfo } from '@features/user/types';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const Login = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const history = useHistory();
	const dispatch = useDispatch();
	const testRef = useRef();
	const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(null);
	const [loginError, setError] = useState<boolean>(false);

	const initialValues = {
		email: '',
		password: ''
	};

	const validationSchema = Yup.object({
		email: Yup.string()
			//.email(t('form.validator.email'))
			.required(t('form.validator.required')),
		password: Yup.string()
			.required(t('form.validator.required')),
	});

	useEffect(() => {
		async function getBrowserInfo() {
			setDeviceInfo(await getDeviceInfo());
		}
		getBrowserInfo();
	}, []);

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setError(false);
		try {
			await dispatch(logout());
			const { email, password } = formData;
			await dispatch(login({ email, password, deviceInfo }));
			history.push('/');
		}
		catch ({ data }) {
			setSubmitting(false);
			setError(true);
		}
	};




	return (
		<Container fluid style={{ height: '100%' }}>
			<Grid id="login" verticalAlign="middle" centered stackable divided="vertically">
				<Grid.Column>
					<Segment>
						<Segment basic style={{ marginBottom: 0 }} textAlign='center'>
							<Image src={logo} centered />
							<h2 style={{ marginTop: 5 }}>SYSTEM MANAGMENT CONSOLE</h2>
							<div className="ui hidden divider" />
						</Segment>
						<Segment basic style={{ marginTop: 0 }}>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={submit}
							>
								{({ errors, isSubmitting, dirty }) => (
									<Ref innerRef={testRef}>
										<Form
											size="large"
											error={loginError}>
											<Transition visible={loginError} animation='scale' duration={500}>
												<Message
													error
													content={t('errors.EMAIL_OR_PASSWORD_INCORRECT')} />
											</Transition>
											<Input
												fluid
												name="email"
												icon="envelope"
												iconPosition="left"
												placeholder={t('form.placeholder.email')}
												type="email"
												errorPrompt
											/>
											<Input
												fluid
												name="password"
												icon="lock"
												iconPosition="left"
												placeholder={t('form.placeholder.password')}
												type="password"
												errorPrompt
											/>
											<Divider fitted hidden />
											<Divider hidden />
											<SubmitButton
												disabled={isSubmitting || !isEmpty(errors) || !dirty}
												primary size="large" type="submit" fluid >
												{t('login.submit')}
											</SubmitButton>
										</Form>
									</Ref>)}
							</Formik>
						</Segment>
					</Segment>
				</Grid.Column>
			</Grid>
		</Container >
	);
};

export default Login;
