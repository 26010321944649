import React, { useCallback, useEffect, useState } from 'react';
import { acknSecurityReport, getUserSecurityReport } from '@/features/userSecurityReport/slice';
import { Button, Grid, Icon, Popup, Table } from 'semantic-ui-react';
import { PermissionType } from '@/features/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { checkWritePermission } from '@/helpers/permissions';
import ChangeReportModal from './ChangeReportModal';
import { UserSecurityReport, UserSecurityReportStatus } from '@/features/userSecurityReport/types';
import NumberFormat from 'react-number-format';
import { convertDate } from '@/helpers/date';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { showErrorNotification } from '@/features/swal/slice';
import CompleteReportModal from './CompleteReportModal';

interface Props {
	userId: number,
}

const ClientSecurityReport = ({ userId }: Props): React.ReactElement => {
	const [userSecurityReport, setUserSecurityReport] = useState<UserSecurityReport[]>(null);
	const [error, setError] = useState(false);
	const { permissions } = useSelector((state: RootState) => state.user);
	const [openEditModal, setEditModal] = useState<boolean>(false);
	const [openCompleteModal, setOpenCompleteModal] = useState<boolean>(false);
	const [report, setReport] = useState<UserSecurityReport>(null);
	const writeAllowed = checkWritePermission(permissions, PermissionType.POA_COMPLIANCE);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				setError(false);
				const data = await getUserSecurityReport(userId);
				setUserSecurityReport(data);
			} catch (e) {
				setError(true);
				setUserSecurityReport(null);
			}
		};
		get();
	}, [userId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const onCloseModal = async () => {
		setEditModal(false);
	};


	const onCompleteReport = async () => {
		setOpenCompleteModal(false);
	};


	const onNewReport = () => {
		setReport(null);
		setEditModal(true);
	};

	const handleAckn = async (reportId: number) => {
		try {
			await acknSecurityReport(reportId);
			fetchData();
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	if (error) return <div>Error: {error}</div>;

	const itemLine = (item: UserSecurityReport) =>
		<Table.Row key={`${item.id}$`} onClick={() => setReport(item)}>
			<Table.Cell width={1}> {item.status}</Table.Cell>
			<Table.Cell width={1}>{item.reportedToAuthority ? 'Yes' : 'No'} </Table.Cell>
			<Table.Cell width={1}>{convertDate(item.createdDate, 'dd/MM/yyyy')} </Table.Cell>
			<Table.Cell width={1}> {convertDate(item.acknDate, 'dd/MM/yyyy')}</Table.Cell>
			<Table.Cell width={1}>{convertDate(item.completionDate, 'dd/MM/yyyy')}</Table.Cell>
			<Table.Cell width={1}><NumberFormat displayType={'text'}
				fixedDecimalScale={true}
				thousandsGroupStyle='thousand'
				thousandSeparator={true}
				decimalScale={2}
				value={item.trxAmount} /></Table.Cell>
			<Table.Cell width={2}>
				<>
					{!item.acknDate &&
						<Popup content='Acknowledge'
							trigger={<ComponentWithConfirmation onConfirm={() => handleAckn(item.id)}
								confirmContent={'Are you sure you want to Acknowledge'}>
								<Icon disabled={!writeAllowed} className='tableActionIcon' size='large' name="check" />
							</ComponentWithConfirmation>} />
					}
					<Popup content='Complete' trigger={
						<Icon className='tableActionIcon' size='large' name="flag checkered"
							onClick={() => setOpenCompleteModal(true)}
							disabled={!writeAllowed || item.status === UserSecurityReportStatus.DONE || item.status === UserSecurityReportStatus.REJECTED} />} />
					<Popup content='View details' trigger={<Icon className='tableActionIcon' size='large' name="eye" onClick={() => setEditModal(true)} />} />
				</>
			</Table.Cell>
		</Table.Row >;


	if (error) return <div>Error: {error}</div>;
	return (<>
		<ChangeReportModal report={report} userId={userId} open={openEditModal} onClose={onCloseModal} onSuccess={fetchData} writeAllowed={writeAllowed} />
		<CompleteReportModal reportId={report?.id} open={openCompleteModal} onClose={onCompleteReport} onSuccess={fetchData} />
		<Grid>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Button
						floated='right'
						primary
						disabled={!checkWritePermission(permissions, PermissionType.POA_COMPLIANCE)}
						onClick={onNewReport}
					> Create SAR report</Button>
				</Grid.Column>
			</Grid.Row>
		</Grid>
		{userSecurityReport?.length > 0 &&
			<Table id='table' celled sortable textAlign='center' padded='very' striped>
				<Table.Header fullWidth className='tableHeader'>
					<Table.Row className="tableHeaders">
						<Table.HeaderCell>Status</Table.HeaderCell>
						<Table.HeaderCell>Reported To Authority</Table.HeaderCell>
						<Table.HeaderCell>Created Date</Table.HeaderCell>
						<Table.HeaderCell>Ackn Date</Table.HeaderCell>
						<Table.HeaderCell>Completion Date</Table.HeaderCell>
						<Table.HeaderCell>Amount (EUR)</Table.HeaderCell>
						<Table.HeaderCell>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body className="tableBody">
					{userSecurityReport?.length > 0 && userSecurityReport?.map(item => itemLine(item))}
				</Table.Body>
			</Table>
		}
	</>);
};

export default ClientSecurityReport;
