import { PaginateParamsOptional } from '@/helpers/globalTypes';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showException } from '../swal/slice';
import { SystemAccountAccount, SystemAccountAccountStore } from './types';

const initialState: SystemAccountAccountStore = {
	status: 'idle',
	error: null,
	wlpId: null,
	list: []
};


const slice = createSlice({
	name: 'systemPaymentAccount',
	initialState,
	reducers: {
		setSystemPaymentAccount(state, action: PayloadAction<Array<SystemAccountAccount>>) {
			state.list = action.payload;
		},
		setLoading: (state) => {
			state.status = 'loading';
		},
		setSucceeded: (state) => {
			state.status = 'succeeded';
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
			state.status = 'failed';
		},
		setWlp: (state, { payload }: PayloadAction<string>) => {
			state.wlpId = payload;
		},
	}
});

export const { setSystemPaymentAccount, setLoading, setSucceeded, setError, setWlp } = slice.actions;


export const getSystemPaymentAccount = (wlpId: string, payload?: PaginateParamsOptional): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading());
			dispatch(setWlp(wlpId));
			const filterParams = {
				filter: payload?.filter ?? '',
				orderBy: payload?.orderBy ?? 'description',
				sort: payload?.sort?.toUpperCase() ?? 'asc',
				skip: payload?.skip?.toString() ?? '0',
			};
			const response = await request.get(`/api/admin/payments/accounts/${wlpId}`, { params: filterParams });
			const { data } = response;
			dispatch(setSystemPaymentAccount(data.list));
			dispatch(setSucceeded());
			return data;
		} catch (e) {
			showException(e);
			dispatch(setError(e.statusText));
		}
	};
};

export default slice.reducer;
