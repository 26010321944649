import { PaginateParams } from '@/helpers/globalTypes';

export enum GlLookupTypes {
	TRANSACTION_TYPE = 'BST_TRANSACTION_TYPE',
	PAYMENT_TYPES = 'BLN_PAYMENT_TYPES',
	AML_LARGE_TRX_EMI = 'AML_LARGE_TRX_EMI'
}

export interface GeneralLedgerLov {
	code: string,
	name: string,
	description?: string
}

export interface StatementBatch {
	id: number,
	loadProcessStatus: string,
	bankId: number,
	rootWlpId: string,
	rootBankAccountId: number,
	rootBankAccountName: string,
	periodName: string,
	stmDateFrom: string,
	stmDateTo: string
	ccyBankAccountId: number,
	description: string,
	currencyCode: string,
	beginBalance: number,
	endBalance: number,
	periodDr: number,
	periodCr: number,
	totalFee: number,
	totalReservedAmount: number,
	totalTrxIn: number,
	totalTrxOut: number
}

export interface StatementsStore {
	loading: boolean,
	//status: 'idle' | 'loading' | 'succeeded' | 'failed',
	list: Array<StatementBatch>,
	error: string,
	count: number,
	totalPages: number,
	pageSize: number,
	pagination: PaginateParams
}

export interface StatementSummary {
	id: number,
	parentBankAccount: number,
	bankAccountId: number,
	wlpId: string,
	bankAccount: string,
	stmDateFrom: string,
	stmDateTo: string,
	currencyCode: string,
	beginBalance?: number,
	periodDr?: number,
	periodCr?: number
}

export interface StatementSummariesStore {
	loading: boolean,
	list: Array<StatementSummary>,
	error: string,
	count: number,
	totalPages: number,
	pageSize: number
}

/* interface Address {
	countryCode: string,
	region: string,
	city: string,
	postalCode: string,
	address: string,
} */

/* interface CardPrenseter {
	id: string,
	role: string,
	type: string,
	name: string,
	ledgerId: string,
	account: string,
	swiftCode: string,
	iban: string,
	bankName: string,
	address: Address,
	//PSP - payment service provider
	pspAcccountLocation: string
} */

export interface StatementTransaction {
	id: number,
	headerId: number,
	stmBatchId: number,
	periodName: string,
	bankId: number,
	rootBankAccountId: number,
	rootWlpId: string,
	rootDescription: string,
	bankAccountId: number,
	description: string,
	accountId: number,
	wlpId: string,
	integration: string,
	walletId: string,
	sysAcctType: string,
	reconciliationStatus: string,
	transactionDate: string,
	settlementDate: string,
	orderNumber: string,
	reference: string,
	trxType: string,
	currencyCode: string,
	amount: 0,
	feeAmount: 0,
	paymentDetails: string,
	customerName: string,
	customerIban: string,
	customerAccountNumber: string,
	customerBankCode: string,
	cpRole: string,
	cpType: string,
	cpName: string,
	cpIban: string,
	cpAccountNumber: string,
	cpSwiftCode: string,
	cpBankName: string
}

export interface StatementTransactionsStore {
	loading: boolean,
	list: Array<StatementTransaction>,
	error: string,
	count: number,
	totalPages: number,
	pageSize: number
}

export interface StatementAccountBalance {
	viewPk: string;
	balanceId?: number,
	bankId?: number,
	bankAccountId: number,
	accountId?: number,
	walletId?: string,
	externalId?: string,
	wlpId?: string,
	parentWlpId?: string,
	rootBankAccountId?: number,
	parentAccountId?: number,
	treeViewOrder?: number,
	hierarchyLevel: number
	accountType?: string,
	currencyCode?: string,
	description?: string,
	hierarchyAccountType?: string,
	iban?: string,
	periodName?: string,
	periodDateFrom?: string,
	periodDateTo?: string,
	accountBeginBalance?: number,
	accountEndBalance?: number,
	accountPeriodDr?: number,
	accountPeriodCr?: number,
	bankBeginBalance?: number,
	bankEndBalance?: number,
	bankPeriodDr?: number,
	bankPeriodCr?: number,
}

export interface StatementAccountBalanceStore {
	loading: boolean,
	list: Array<StatementAccountBalance>,
	activeItems: string[],
	error: string,
	count: number,
	totalPages: number,
	pageSize: number,
	isDownloading: boolean
}



export interface DownloadBalanceProps {
	rootBankAccountId: number, 
	period: string,
	format: 'SHORT' | 'LONG'
}


export interface StatementBankAccount {
	bankAccountIdLov: number,
	wlpId: string,
	rootWlpId: string,
	integration: string,
	sysAcctType: string,
	bankId: number,
	parentAccountId: number,
	rootBankAccountId: number,
	hierarchyAccountType: string,
	accountType: string,
	internal_code: string,
	currencyCode: string,
	description: string,
	treeAccountPath: string,
	iban: string,
	treeViewOrder: string,
	hierarchyLevel: string,
	statementExtract: string,
	statementToXero: string
}
