import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Checkbox, Form, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { faqDetails } from '@/features/faq/faqTypes';


interface Props {
	open: boolean;
	onClose: (question?: string, answer?: string, disabled?: boolean) => void,
	faqDetail: faqDetails,
}

const FaqDetailsModal = ({ open, onClose, faqDetail }: Props): React.ReactElement => {

	const initialValues = {
		question: faqDetail ? faqDetail.question : '',
		answer: faqDetail ? faqDetail.answer : '',
		disabled: faqDetail ? faqDetail.disabled : false,
	};

	const validationSchema = Yup.object({
		question: Yup.string()
			.required('You must enter a question'),
		answer: Yup.string()
			.required('You must enter a answer')
	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { question, answer, disabled } = formData;
		onClose(question, answer, disabled);
	};



	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
				enableReinitialize={true}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content={faqDetail ? 'Edit FAQ' : 'Add new FAQ'} />

						<TextArea
							label='Question'
							rows={2}
							name="question"
						/>
						<TextArea
							label='Answer'
							rows={7}
							name="answer"
						/>
						<Checkbox fitted
							name="disabled"
							label='Disabled'
						/>

						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										{faqDetail ? 'Edit' : 'Add'}
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default FaqDetailsModal;
