import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { approveTaxForm, getTaxForms } from '@/features/taxForm/taxFormSlice';
import { useHistory } from 'react-router-dom';

interface Props {
	writePermission: boolean,
}

const TaxForm = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.taxForm
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);

	const headers: Array<Headers> = [
		{
			field: 'clientid',
			name: 'Client ID',
			position: 1,
			sortable: true
		},
		{
			field: 'email',
			name: 'Email',
			position: 2,
			sortable: true
		},
		{
			field: 'name',
			name: 'Name',
			position: 3,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 4,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getTaxForms(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const approve = async (id: number) => {
		try {
			await approveTaxForm(id);
			fetchData();
			showSuccessNotification('Tax form approved');
		} catch (e) {
			showErrorNotification(e);
		}
	};


	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.userid}${index}`} >
						<Table.Cell width={2}>{entry.clientid}</Table.Cell>
						<Table.Cell width={2}>{entry.email}</Table.Cell>
						<Table.Cell width={2}>{entry.type === 'BUSINESS' ? entry.companyname : `${entry.firstname} ${entry.lastname}`}</Table.Cell>
						<Table.Cell width={2}>
							<ComponentWithConfirmation onConfirm={() => approve(entry.userid)} confirmContent={'Are you sure you want approve user tax form?'}>
								<Button
									color='green'
									content='Approve'
									disabled={!writePermission}
								/>
							</ComponentWithConfirmation>
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userid)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={4}>Not tax forms yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Tax Form</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
				/>
			</Segment>
		</Container>
	);
};


export default TaxForm;
