/* eslint-disable react/prop-types */
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getErrorWithParams, showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { UserType } from '@/features/user/types';
import { addUserToUserGroup, deleteUserFromUserGroup, getUserGroupUsersList } from '@/features/userGroups/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import AddUserToUserGroupModal from './AddUserToUserGroupModal';



const UserGroupUsers = (props): React.ReactElement => {

	const dispatch = useDispatch();
	const [userGroupId] = useState<number>(props.match.params.userGroupId);
	const [openModal, setOpenModal] = useState<boolean>(false);


	const { usersList, usersTotalPages, usersError, usersLoading } = useSelector(
		(state: RootState) => state.userGroups
	);

	const paginateInitialState: PaginateParams = { orderBy: 'clientId', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);

	const filter: FilterParam[] = [
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'firstName',
			label: 'First Name',
			type: 'input'
		},
		{
			field: 'lastName',
			label: 'Last Name',
			type: 'input'
		},
		{
			field: 'companyName',
			label: 'Company Name',
			type: 'input'
		},

	];

	const headers: Array<Headers> = [
		{
			field: 'clientId',
			name: 'Client ID',
			position: 1,
			sortable: true
		},
		{
			field: '',
			name: 'Name/CompanyName',
			position: 2,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 3,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getUserGroupUsersList(userGroupId, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const handleRemoveUserFromUserGroup = async (userId: number) => {
		try {
			await deleteUserFromUserGroup(userId, userGroupId);
			showSuccessNotification('User removed from user group');
		} catch (e) {
			showErrorNotification(e);
		}
		fetchData();
	};

	const handleClose = async (clientId?: string) => {
		setOpenModal(false);
		if (clientId) {
			try {
				await addUserToUserGroup(clientId, userGroupId);
				showSuccessNotification('User added to user group');
			} catch (e) {
				const err = getErrorWithParams(e);
				if (e && err.error === 'invalid' && err.error_param === 'clientId') {
					showErrorNotification(err, 'Invalid client ID');
				} else {
					showErrorNotification(e);
				}
			}
			fetchData();
		}
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{usersList.map((entry) => {
				return (
					<Table.Row key={`${entry.clientId}$`} >
						<Table.Cell width={2}>{entry.clientId}</Table.Cell>
						<Table.Cell width={2}>{entry.type === UserType.BUSINESS ? entry.companyName : `${entry.firstName} ${entry.lastName}`}</Table.Cell>
						<Table.Cell width={2}>
							<Popup content='Remove user from user group' trigger={<Icon className='tableActionIcon' size='large' name="trash alternate outline" onClick={() => handleRemoveUserFromUserGroup(entry.userId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{usersList.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={3}>Not users yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (usersError) return <div>Error: {usersError}</div>;
	return (
		<Container fluid>
			<Segment>
				<AddUserToUserGroupModal
					open={openModal}
					onClose={handleClose}
				/>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>User Group Users</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button
								floated='right'
								primary
								onClick={() => setOpenModal(true)}
							>
								Add User</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={usersLoading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={usersTotalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					filter={filter}
				/>
			</Segment>
		</Container>
	);

};

export default UserGroupUsers;
