/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { showErrorNotification } from '@/features/swal/slice';
import { clearUserKyc, getClient, resetKycCheckCount } from '@/features/clients/slice';
import Loading from '@/components/Shared/Loading';
import ClientProfile from './Profile';
import { Client } from '@/features/clients/types';
import { checkPermission, checkWritePermission } from '@/helpers/permissions';
import { PermissionType } from '@/features/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Accordion, AccordionContent, AccordionTitle, Button, Container, Divider, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react';
import ClientAccounts from './ClientAccounts';
import ClientCards from './ClientCards';
import ClientActivity from './ClientActivity';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { useHistory } from 'react-router-dom';
import TokenAccounts from './TokenAccounts';
import ClientSecurityReport from './ClientSecurityReport/ClientSecurityReport';


const ClientDetails = (props): React.ReactElement => {

	const [userId] = useState<number>(props.match.params.userId);
	const [client, setClient] = useState<Client>(null);
	const history = useHistory();

	const { permissions } = useSelector(
		(state: RootState) => state.user
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getClient(userId);
				setClient(data);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [userId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const [activeIndex, setActiveIndex] = useState<number>(-1);

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};

	const resetCheckCount = async (kycId: number) => {
		try {
			await resetKycCheckCount(client.id, kycId);
		} catch (e) {
			showErrorNotification(e);
		}
		fetchData();
	};

	const clearKyc = async (applicantId: string) => {
		try {
			await clearUserKyc(applicantId);
		} catch (e) {
			showErrorNotification(e);
		} finally {
			fetchData();
		}
	};

	const openPOA = (userid: number) => {
		history.push(`/poa/${userid}`);
	};


	if (!client) {
		return <Loading />;
	}

	return (
		<div>
			{client && <ClientProfile writePermission={checkWritePermission(permissions, PermissionType.USERS)} permissions={permissions} fetchData={fetchData} client={client} goBack={() => props.history.goBack()} />}
			<Divider hidden />
			{client && <Container>
				<Segment>
					<Grid>
						<Grid.Row columns={2}>
							<Grid.Column>
								<Header as='h2'>Compliance</Header>
							</Grid.Column>
							<Grid.Column>
								<Button
									floated='right'
									primary
									disabled={!checkWritePermission(permissions, PermissionType.POA_COMPLIANCE)}
									onClick={() => openPOA(client.id)}
								>
									Internal Compliance</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Table id='table' celled textAlign='center' padded='very' striped>
						<Table.Header fullWidth className='tableHeader'>
							<Table.Row>
								<Table.HeaderCell className="tableHeaders">Applicant ID</Table.HeaderCell>
								<Table.HeaderCell className="tableHeaders">Provider</Table.HeaderCell>
								<Table.HeaderCell className="tableHeaders">Status</Table.HeaderCell>
								<Table.HeaderCell className="tableHeaders">Rejection Reason</Table.HeaderCell>
								<Table.HeaderCell className="tableHeaders">Check Count</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body className="tableBody">
							{client.kyc.length > 0 && client.kyc.map((entry) => {
								return (
									<Table.Row key={`${entry.id}$`} >
										<Table.Cell width={4}>{entry?.kycIdentity?.serviceId ?? ''}</Table.Cell>
										<Table.Cell width={1}>{entry?.kycIdentity?.service ?? ''}</Table.Cell>
										<Table.Cell width={1}>{entry.status}</Table.Cell>
										<Table.Cell width={8}>{entry.rejectionReason}</Table.Cell>
										<Table.Cell width={2}>
											{entry.checkCount}
											{entry.kycIdentity.service === 'ONFIDO' &&
												<ComponentWithConfirmation popup='Reset Check count' onConfirm={() => resetCheckCount(entry.id)} confirmContent={'Are you sure you want reset check count?'}>
													<Icon style={{ marginLeft: 4 }} name='undo' />
												</ComponentWithConfirmation>}
											<ComponentWithConfirmation popup='Clear all KYC data' onConfirm={() => clearKyc(entry.kycIdentity.serviceId)} confirmContent={'Are you sure you want clear all KYC data?'}>
												<Icon style={{ marginLeft: 4 }} name='trash' />
											</ComponentWithConfirmation>
										</Table.Cell>

									</Table.Row>
								);
							})}
							{client.kyc.length === 0 && <Table.Row>
								<Table.Cell textAlign='center' colSpan={5}>This client not initiated compliance verification yet</Table.Cell>
							</Table.Row>}
						</Table.Body>
					</Table>

				</Segment>
			</Container>}
			<Divider hidden />
			<Container id='accordian'>
				{checkPermission(permissions, PermissionType.ACCOUNTS) &&
					<Accordion fluid styled style={{ marginBottom: 5 }} >
						<AccordionTitle
							active={activeIndex === 0}
							index={0}
							onClick={handleClick}
						>
							Bank Accounts
							<Icon name={activeIndex === 0 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
						</AccordionTitle>
						<AccordionContent active={activeIndex === 0}>
							{activeIndex === 0 && <ClientAccounts user={client} userId={client.id} type='BANK' permissions={permissions} />}
						</AccordionContent>
					</Accordion>}
				{checkPermission(permissions, PermissionType.ACCOUNTS) &&
					<Accordion fluid styled style={{ marginBottom: 5 }} >
						<AccordionTitle
							active={activeIndex === 1}
							index={1}
							onClick={handleClick}
						>
							Wallets
							<Icon name={activeIndex === 1 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
						</AccordionTitle>
						<AccordionContent active={activeIndex === 1}>
							{activeIndex === 1 && <ClientAccounts user={client} userId={client.id} type='WALLET' permissions={permissions} />}
						</AccordionContent>
					</Accordion>}
				{checkPermission(permissions, PermissionType.ACCOUNTS) &&
					<Accordion fluid styled style={{ marginBottom: 5 }} >
						<AccordionTitle
							active={activeIndex === 2}
							index={2}
							onClick={handleClick}
						>
							Crypto
							<Icon name={activeIndex === 2 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
						</AccordionTitle>
						<AccordionContent active={activeIndex === 2}>
							{activeIndex === 2 && <ClientAccounts user={client} userId={client.id} type='CRYPTO' permissions={permissions} />}
						</AccordionContent>
					</Accordion>}
				{checkPermission(permissions, PermissionType.ACCOUNTS) &&
					<Accordion fluid styled style={{ marginBottom: 5 }} >
						<AccordionTitle
							active={activeIndex === 3}
							index={3}
							onClick={handleClick}
						>
							Trust Accounts
							<Icon name={activeIndex === 3 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
						</AccordionTitle>
						<AccordionContent active={activeIndex === 3}>
							<AccordionContent active={activeIndex === 3}>
								{activeIndex === 3 && <ClientAccounts user={client} userId={client.id} type='TRUST' permissions={permissions} />}
							</AccordionContent>
						</AccordionContent>
					</Accordion>}
				{checkPermission(permissions, PermissionType.ACCOUNTS) &&
					<Accordion fluid styled style={{ marginBottom: 5 }} >
						<AccordionTitle
							active={activeIndex === 4}
							index={4}
							onClick={handleClick}
						>
							Token Accounts
							<Icon name={activeIndex === 4 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
						</AccordionTitle>
						<AccordionContent active={activeIndex === 4}>
							{activeIndex === 4 && <ClientAccounts user={client} userId={client.id} type='TOKEN' permissions={permissions} />}
							{activeIndex === 4 && <TokenAccounts userId={client.id} permissions={permissions} />}
						</AccordionContent>
					</Accordion>}

				{checkPermission(permissions, PermissionType.CARDS) &&
					<Accordion fluid styled style={{ marginBottom: 5 }} >
						<AccordionTitle
							active={activeIndex === 6}
							index={5}
							onClick={handleClick}
						>
							Cards
							<Icon name={activeIndex === 5 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
						</AccordionTitle>
						<AccordionContent active={activeIndex === 5}>
							{activeIndex === 5 && <ClientCards userId={client.id} />}
						</AccordionContent>
					</Accordion>}

				{checkPermission(permissions, PermissionType.USERS) &&
					<Accordion fluid styled style={{ marginBottom: 5 }} >
						<AccordionTitle
							active={activeIndex === 6}
							index={6}
							onClick={handleClick}
						>
							User turnovers
							<Icon name={activeIndex === 6 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
						</AccordionTitle>
						<AccordionContent active={activeIndex === 6}>
						</AccordionContent>
					</Accordion>}

				{checkPermission(permissions, PermissionType.USERS) &&
					<Accordion fluid styled style={{ marginBottom: 5 }} >
						<AccordionTitle
							active={activeIndex === 7}
							index={7}
							onClick={handleClick}
						>
							Client activity
							<Icon name={activeIndex === 7 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
						</AccordionTitle>
						<AccordionContent active={activeIndex === 7}>
							{activeIndex === 7 && <ClientActivity userId={client.id} />}
						</AccordionContent>
					</Accordion>}
				{checkPermission(permissions, PermissionType.POA_COMPLIANCE) &&
					<Accordion fluid styled style={{ marginBottom: 5 }} >
						<AccordionTitle
							active={activeIndex === 8}
							index={8}
							onClick={handleClick}
						> Security Assessment Report (SAR)
							<Icon name={activeIndex === 8 ? 'chevron up' : 'chevron down'} style={{ float: 'right' }} />
						</AccordionTitle>
						<AccordionContent active={activeIndex === 8}>
							{activeIndex === 8 && <ClientSecurityReport userId={client.id} />}
						</AccordionContent>
					</Accordion>}

			</Container>

		</div>
	);
};


export default ClientDetails;
