import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import React from 'react';
import { Dimmer, Icon, Loader, Popup, Table } from 'semantic-ui-react';
import { toogleProcess } from '@/features/processes/processesSlice';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import { Process, ProcessConfig } from '@/features/processes/processesTypes';
import { useHistory } from 'react-router';

interface Props {
	writePermission: boolean,
	list: Array<Process>,
	fetchData: () => void,
	loading: boolean
}

const Processes = ({ writePermission, list, fetchData, loading }: Props): React.ReactElement => {

	const toogleProc = async (proc: Process) => {
		try {

			const config: ProcessConfig = {
				config: proc.config.config,
				rolesAllowed: proc.config.rolesAllowed,
				disabled: proc.config.disabled ? false : true
			};

			await toogleProcess({ proc: proc.proc, config });
			showSuccessNotification(proc.config.disabled ? 'Process enabled' : 'Process disabled');
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const history = useHistory();
	const openProcess = (processName: string) => {
		history.push(`/process/${processName}`);
	};

	return (
		<>
			<Dimmer active={loading} inverted>
				<Loader />
			</Dimmer>
			<Table id='table' celled sortable textAlign='center' padded='very' striped>
				<Table.Header fullWidth className='tableHeader'>
					<Table.Row className="tableHeaders">
						<Table.HeaderCell>Name</Table.HeaderCell>
						<Table.HeaderCell>Description</Table.HeaderCell>
						<Table.HeaderCell>Type</Table.HeaderCell>
						<Table.HeaderCell>Integration</Table.HeaderCell>
						<Table.HeaderCell>Process name</Table.HeaderCell>
						<Table.HeaderCell>Enabled</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body className="tableBody">
					{list.map((entry, index) => {
						return (
							<Table.Row key={`${entry.wlpProcessId}${index}`} >
								<Table.Cell width={2}>{entry.name}</Table.Cell>
								<Table.Cell width={2}>{entry.description}</Table.Cell>
								<Table.Cell width={2}>{entry.type}</Table.Cell>
								<Table.Cell width={2}>{entry.integration}</Table.Cell>
								<Table.Cell width={2}>{entry.proc}</Table.Cell>
								<Table.Cell width={2}>
									{!entry.config.disabled &&
										<ComponentWithConfirmation popup='Disable process' onConfirm={() => toogleProc(entry)} confirmContent={'Are you sure you want disable process?'}>
											<Icon disabled={!writePermission} className='tableActionIcon' color='green' size='large' name="check circle" />
										</ComponentWithConfirmation>}
									{entry.config.disabled &&
										<ComponentWithConfirmation popup='Enable process' onConfirm={() => toogleProc(entry)} confirmContent={'Are you sure you want enable process?'}>
											<Icon disabled={!writePermission} className='tableActionIcon' color='red' size='large' name="times circle" />
										</ComponentWithConfirmation>}
								</Table.Cell>
								<Table.Cell width={2}>
									<Popup content='Open process details' trigger={<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="cogs" onClick={() => openProcess(entry.proc)} />} />
								</Table.Cell>
							</Table.Row>
						);
					})}
					{list.length === 0 && <Table.Row>
						<Table.Cell textAlign='center' colSpan={7}>Not processes yet.</Table.Cell>
					</Table.Row>}
				</Table.Body>
			</Table>
		</>
	);
};


export default Processes;
