/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Divider, Grid, Header, Icon, List, Popup, Segment, Table } from 'semantic-ui-react';
import { showErrorNotification } from '@/features/swal/slice';
import Loading from '@/components/Shared/Loading';
import '../Clients/ClientDetails/Profile.css';
import { RootState } from '@/rootReducer';
import { PermissionType } from '@/features/user/types';
import { checkPermission, checkWritePermission } from '@/helpers/permissions';
import { useHistory } from 'react-router-dom';
import {
	approveDocument,
	downloadAttachment,
	getDocument,
	getWalletTransactionTypeFees,
	getDocumentTransactionLines,
	rejectDocument,
} from '@/features/documents/slice';
import { ApprovedBy, ApprovedStatus, Document } from '@/features/documents/types';
import { convertDateFromEpoch, formatDateWithPattern } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import './Documents.css';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import AdjustFeeModal from '@/components/Dashboard/Documents/AdjustFeeModal';

const DocumentDetails = (props): React.ReactElement => {

	const dispatch = useDispatch();
	const [documentNumber] = useState<string>(props.match.params.documentNumber);
	const [document, setDocument] = useState<Document>(null);
	const [walletTransactionTypeFees, setWalletTransactionTypeFees] = useState<any>(null);
	const [documentTransactionLines, setDocumentTransactionLines] = useState<any>(null);
	const [permissionType, setPermissionType] = useState<PermissionType>(null);
	const [approvedOrRejectedByYou, setApprovedOrRejectedByYou] = useState<boolean>(false);
	const [showAdjustFeeModal, setAdjustFeeModal] = useState<boolean>(false);
	const [isAdjustFeeModalLoading, setIsAdjustFeeModalLoading] = useState<boolean>(false);
	const history = useHistory();
	const countries = getNames('en');
	const { permissions, status } = useSelector(
		(state: RootState) => state.user
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				const data = await getDocument(documentNumber);
				setDocument(data);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [documentNumber]);

	useEffect(() => {
		fetchData();
	}, [documentNumber, dispatch, fetchData]);

	useEffect(() => {
		if (document && document.approvedBy.find(element => element.userId === status.adminUserId)) {
			setApprovedOrRejectedByYou(true);
		}
	}, [document, status.adminUserId]);

	useEffect(() => {
		if (document && document.type === 'CARD') {
			setPermissionType(PermissionType.CARD_DOCUMENTS);
		} else if (document && (document.type === 'BANK' || document.type === 'BANK_AND_CARD' || document.type === 'WALLET')) {
			setPermissionType(PermissionType.BANK_DOCUMENTS);
		} else if (document && document.type === 'CRYPTO') {
			setPermissionType(PermissionType.CRYPTO_DOCUMENTS);
		} else if (document && document.type === 'TRUST') {
			setPermissionType(PermissionType.TRUST_DOCUMENTS);
		} else if (document && document.type === 'TOKEN') {
			setPermissionType(PermissionType.TOKENS);
		}
	}, [document]);

	useEffect(() => {
		if (permissionType && document && !checkPermission(permissions, permissionType)) {
			history.push('/not-permitted');
		}
	}, [document, permissionType, permissions, history]);

	if (!document) {
		return <Loading />;
	}


	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const approveDoc = async () => {
		try {
			await approveDocument(document.documentNumber, document.type);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const rejectDoc = async () => {
		try {
			await rejectDocument(document.documentNumber, document.type);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};


	const showApprovedBy = (entry: ApprovedBy) => {
		if (entry.status === ApprovedStatus.APPROVED) {
			return (
				<List.Description key={entry.userId}>
					{`${entry.userId} - ${entry.user}`}
					<Popup content='View system user' trigger={<Icon className='actionIcon' name="user" onClick={() => openUser(entry.userId)} />} />
				</List.Description>
			);
		}
	};

	const showRejectedBy = (entry: ApprovedBy) => {
		if (entry.status === ApprovedStatus.REJECTED) {
			return (
				<List.Description key={entry.userId}>
					{`${entry.userId} - ${entry.user}`}
					<Popup content='View system user' trigger={<Icon className='actionIcon' name="user" onClick={() => openUser(entry.userId)} />} />
				</List.Description>
			);
		}
	};

	const download = (key: string, name: string) => {
		const get = async () => {
			try {
				downloadAttachment(key, name);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	};

	const openAdjustFeeModal = () => {
		setIsAdjustFeeModalLoading(true);
		const get = async () => {
			try {
				const walletTransactionTypeFees = await getWalletTransactionTypeFees(document.type);
				setWalletTransactionTypeFees(walletTransactionTypeFees);

				const documentTransactionLines = await getDocumentTransactionLines(document.type, document.documentNumber);
				setDocumentTransactionLines(documentTransactionLines);

				setAdjustFeeModal(true);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get().then(() => {
			setIsAdjustFeeModalLoading(false);
		});
	};

	const rejectionMsg = document.rejectionReason?.split('message=').pop().split(',')[0] || '';
	const rejectionDetails = document.rejectionReason?.split('details=').pop().split(')')[0] || '';
	const rejectionReason = rejectionMsg + ' ' + rejectionDetails;

	const showRejectBtn = () => {

		if (document?.remitter?.identification === 'CARDAQ') { return false; }

		return !approvedOrRejectedByYou
			&& (
				document.status === 'NEW'
				|| document.status === 'PENDING'
				|| document.status === 'APPROVAL'
				|| document.status === 'LEGAL_APPROVAL'
			);
	};
	const showApproveBtn = () => {
		return !approvedOrRejectedByYou
			&& (document.status === 'APPROVAL' || document.status === 'LEGAL_APPROVAL');
	};

	return (
		<Container fluid>
			<AdjustFeeModal
				walletTransactionTypeFees={walletTransactionTypeFees}
				documentTransactionLines={documentTransactionLines}
				documentNumber={document.documentNumber}
				userId={document.userId}
				open={showAdjustFeeModal}
				onClose={() => setAdjustFeeModal(false)}
			/>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Document</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button floated='right' primary onClick={() => props.history.goBack()}>Go back</Button>
							<Button
								style={{ marginRight: 10 }}
								secondary
								content='View user'
								disabled={!checkPermission(permissions, PermissionType.USERS)}
								onClick={() => openUser(document.userId)}
								floated='right'
							/>
							{document && (document.type === 'BANK' || document.type === 'WALLET' || document.type === 'CRYPTO') &&
								<Button
									floated='right'
									style={{ marginRight: 10 }}
									loading={isAdjustFeeModalLoading}
									secondary
									disabled={true}
									onClick={() => openAdjustFeeModal()}
								>
									Adjust fee
								</Button>
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable columns={2}>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Document number</List.Header>
										<List.Description>{document.documentNumber}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Status</List.Header>
										<List.Description>{document.status}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								{document.externalId && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">External ID</List.Header>
											<List.Description>{document.externalId}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
								</div>}
								{document.statusText && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Status text</List.Header>
											<List.Description>{document.statusText}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
								</div>}
								{document.note && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">Details</List.Header>
											<List.Description>{document.note}</List.Description>
										</List.Content>
									</List.Item>
									<Divider /></div>}
								{document.attachments && <List.Item>
									<List.Content>
										<List.Header className="color-grey">Payment Documents:</List.Header>
										{Object.entries(document.attachments).map(([key, value]) =>
											<List.Description key={key} >
												<div className="downloadAttachments" onClick={() => download(key, value)} style={{ width: 'fit-content' }}>
													<Icon size="small" name="download" />{value}
												</div>
											</List.Description>
										)}

									</List.Content>
								</List.Item>}
							</List>
						</Segment>
					</Grid.Column>


					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								<Grid columns={2}>
									<Grid.Row>
										{showApproveBtn() && <Grid.Column>
											<ComponentWithConfirmation onConfirm={approveDoc}
												confirmContent={'Are you sure you want approve document?'}>
												<Button
													color='green'
													content='Approve'
													disabled={!checkWritePermission(permissions, permissionType)}
													floated='right'
												/>
											</ComponentWithConfirmation>
										</Grid.Column>
										}
										{showRejectBtn() && <Grid.Column>
											<ComponentWithConfirmation onConfirm={rejectDoc} confirmContent={'Are you sure you want reject document?'}>
												<Button
													color='red'
													content='Reject'
													disabled={!checkWritePermission(permissions, permissionType)}
													floated='left'
												/>
											</ComponentWithConfirmation>
										</Grid.Column>
										}
									</Grid.Row>
								</Grid>
								<Divider hidden />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Approved by:</List.Header>
										{document.approvedBy.map(entry =>
											showApprovedBy(entry)
										)}
									</List.Content>
								</List.Item>
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Rejected by:</List.Header>
										{document.approvedBy.map(entry =>
											showRejectedBy(entry)
										)}
									</List.Content>
								</List.Item>
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Rejection Reason:</List.Header>
										{rejectionReason}
									</List.Content>
								</List.Item>
							</List>
						</Segment>
					</Grid.Column>
				</Grid>

				{(document.beneficiary || document.remitter) && <Grid stackable columns={2}>
					{document.beneficiary && <Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<Header as='h4'>Beneficiary</Header>
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">Name</List.Header>
									<List.Description>{document.beneficiary.name}</List.Description>
								</List.Content>
							</List.Item>
							<Divider />
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">Identity No.</List.Header>
									<List.Description>{document.beneficiary.identification ?? '⠀'}</List.Description>
								</List.Content>
							</List.Item>
							<Divider />
							{document.beneficiary.walletId && <>
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Wallet ID</List.Header>
										<List.Description>{document.beneficiary.walletId}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
							</>}
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">Payment type</List.Header>
									<List.Description>{document.beneficiary.paymentType}</List.Description>
								</List.Content>
							</List.Item>
							<Divider />
							{document.beneficiary.account && <>
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">{document?.beneficiary?.paymentType === 'SWIFT' ? 'Account Number' : 'IBAN'}</List.Header>
										<List.Description>{document.beneficiary.account}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Bank Name</List.Header>
										<List.Description>{document.beneficiary.bank}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Bank BIC/SWIFT</List.Header>
										<List.Description>{document.beneficiary.bic}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Bank address</List.Header>
										<List.Description>{document.beneficiary.address}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Bank country</List.Header>
										<List.Description>{countries[alpha3ToAlpha2(document.beneficiary.country)]}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
							</>}
							{document.beneficiary.cryptoNetwork && <>
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">Crypto network</List.Header>
										<List.Description>{document.beneficiary.cryptoNetwork}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
							</>}
						</Segment>
					</Grid.Column>}
					{document.remitter && <Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<Header as='h4'>Remitter</Header>
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">Name</List.Header>
									<List.Description>{document.remitter.name}</List.Description>
								</List.Content>
							</List.Item>
							<Divider />
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">Identity No.</List.Header>
									<List.Description>{document.remitter.identification ?? '⠀'}</List.Description>
								</List.Content>
							</List.Item>
							<Divider />
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">IBAN</List.Header>
									<List.Description>{document.remitter.account}</List.Description>
								</List.Content>
							</List.Item>
							<Divider />
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">Bank Name</List.Header>
									<List.Description>{document.remitter.bankName ?? '⠀'}</List.Description>
								</List.Content>
							</List.Item>
							<Divider />
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">Bank BIC/SWIFT</List.Header>
									<List.Description>{document.remitter.bankCode ?? '⠀'}</List.Description>
								</List.Content>
							</List.Item>
							<Divider />
							<List.Item>
								<List.Content>
									<List.Header className="color-grey">Bank country</List.Header>
									<List.Description>{countries[document.remitter.bankCountry] ?? '⠀'}</List.Description>
								</List.Content>
							</List.Item>
							<Divider />
						</Segment>
					</Grid.Column>}
				</Grid>}
			</Segment>
			<Segment>
				<Header as='h2'>Transactions</Header>
				<Table id='table' celled textAlign='center' padded='very' striped>
					<Table.Header fullWidth className='tableHeader'>
						<Table.Row>
							<Table.HeaderCell className="tableHeaders">Reference code</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Wallet ID</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Type</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Amount</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Payment details</Table.HeaderCell>
							<Table.HeaderCell className="tableHeaders">Date</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body className="tableBody">
						{document.transactions.map((entry) => {
							return (
								<Table.Row key={`${entry.referenceCode}$`} >
									<Table.Cell width={3}>{entry.referenceCode}</Table.Cell>
									<Table.Cell width={2}>{entry.walletId}</Table.Cell>
									<Table.Cell width={2}>{entry.type}</Table.Cell>
									<Table.Cell width={3}>{entry.asset} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} /></Table.Cell>
									<Table.Cell width={4}>{entry.paymentDetails}</Table.Cell>
									<Table.Cell width={3}>{formatDateWithPattern(convertDateFromEpoch(entry.date), 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>

								</Table.Row>
							);
						})}
						{document.transactions.length === 0 && <Table.Row>
							<Table.Cell textAlign='center' colSpan={6}>Document dont have transactions yet.</Table.Cell>
						</Table.Row>}
					</Table.Body>

				</Table>
			</Segment>
		</Container>
	);
};

export default DocumentDetails;
