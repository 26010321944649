import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getActivityLogs, getActivityLogsAdmins } from '@/features/activityLogs/activityLogsSlice';
import { formatDateWithPattern } from '@/helpers/date';
import { useHistory } from 'react-router-dom';
import { capitalizeFirstLetter } from '@/helpers/string';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	region: string
}

const ActivityLogsTable = ({ region }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.activityLogs
	);

	const paginateInitialState: PaginateParams = { orderBy: 'requestedDate', sort: 'DESC', skip: 0, filter: `region=${region}`, take: 25 };
	const { urlPaginateParams} = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [adminOptions, setAdminOptions] = useState([]);

	useEffect(() => {
		const fetch = async () => {
			const list = await getActivityLogsAdmins();
			const options = [];
			if (list.length) {
				list.map((item,idx) => {
					options.push({ value: item.adminid?.toString(), key: idx, text: `${item.firstname} ${item.lastname}` });
				});
			}
			setAdminOptions(options);
		};
		fetch();
	}, []); 
 
	const methodOptions = [
		{
			key: 'GET',
			text: 'GET',
			value: 'GET',
		},
		{
			key: 'POST',
			text: 'POST',
			value: 'POST',
		},
		{
			key: 'PUT',
			text: 'PUT',
			value: 'PUT',
		},
		{
			key: 'DELETE',
			text: 'DELETE',
			value: 'DELETE',
		}
	];

	const filter: FilterParam[] = [
		{
			field: 'method',
			label: 'Method',
			type: 'select',
			options: methodOptions
		},
		{
			field: 'statusCode',
			label: 'Status Code',
			type: 'input',
			inputType: 'number'
		},
		{
			field: 'requestedBy',
			label: 'Admin User',
			type: 'select',
			options: adminOptions
		}
	];


	const headers: Array<Headers> = [
		{
			field: 'activity',
			name: 'Activity',
			position: 1,
			sortable: true
		},
		{
			field: 'method',
			name: 'Method',
			position: 2,
			sortable: true
		},
		{
			field: 'statusCode',
			name: 'Status code',
			position: 3,
			sortable: true
		},
		{
			field: 'body',
			name: 'Body',
			position: 4,
			sortable: true
		},
		{
			field: 'params',
			name: 'Params',
			position: 5,
			sortable: true
		},
		{
			field: 'requestedDate',
			name: 'Requested date',
			position: 6,
			sortable: true
		},
		{
			field: 'requestedBy',
			name: 'Requested By',
			position: 7,
			sortable: true
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getActivityLogs(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.activity}</Table.Cell>
						<Table.Cell width={2}>{entry.method}</Table.Cell>
						<Table.Cell width={2}>{entry.statusCode}</Table.Cell>
						<Table.Cell width={4}>
							<Popup
								trigger={<div>{entry.body.length > 50 ? entry.body.slice(0, 50).concat('...') : entry.body}</div>}
								content={entry.body}
								position='top center'
								wide='very'
							/>
						</Table.Cell>
						<Table.Cell width={2}>
							<Popup
								trigger={<div>{entry.params.length > 50 ? entry.params.slice(0, 50).concat('...') : entry.params}</div>}
								content={entry.params}
								position='top center'
								wide='very'
							/>
						</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.requestedDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>
							{`${entry.firstName} ${entry.lastName} `}<Popup content='View requested by user' trigger={<Icon className='tableActionIcon' name="user" onClick={() => openUser(entry.requestedBy)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={7}>Not activity logs yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>{capitalizeFirstLetter(region)}</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default ActivityLogsTable;
