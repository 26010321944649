import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import { getDocuments } from '@/features/documents/slice';
import { formatDateWithPattern } from '@/helpers/date';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import Withdrawal from '@/components/Dashboard/Fireblocks/Withdrawal';
import NumberFormat from 'react-number-format';
import { CryptoDocumentType } from '@features/documents/types';

interface Props {
	type: string,
}

const Documents = ({ type }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.documents
	);

	const paginateInitialState: PaginateParams = { orderBy: 'documentDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);


	const statusOptions = [
		{
			key: 'NEW',
			text: 'New',
			value: 'NEW',
		},
		{
			key: 'APPROVAL',
			text: 'Approval',
			value: 'APPROVAL',
		},
		{
			key: 'LEGAL_APPROVAL',
			text: 'Legal approval',
			value: 'LEGAL_APPROVAL',
		},
		{
			key: 'APPROVED_BY_RISK_MANAGER',
			text: 'Approved by risk manager',
			value: 'APPROVED_BY_RISK_MANAGER',
		},
		{
			key: 'APPROVED_BY_COMPLIANCE_MANAGER',
			text: 'Approved by compliance manager',
			value: 'APPROVED_BY_COMPLIANCE_MANAGER',
		},
		{
			key: 'EMAIL_APPROVAL',
			text: 'Email approval',
			value: 'EMAIL_APPROVAL',
		},
		{
			key: 'PENDING',
			text: 'Pending',
			value: 'PENDING',
		},
		{
			key: 'PENDING_FX',
			text: 'Pending FX',
			value: 'PENDING_FX',
		},
		{
			key: 'PENDING_EXTERNALLY',
			text: 'Pending externally',
			value: 'PENDING_EXTERNALLY',
		},
		{
			key: 'APPROVED',
			text: 'Approved',
			value: 'APPROVED',
		},
		{
			key: 'LEGAL_APPROVED',
			text: 'Legal approved',
			value: 'LEGAL_APPROVED',
		},
		{
			key: 'REJECTED',
			text: 'Rejected',
			value: 'REJECTED',
		},
		{
			key: 'COMPLETED',
			text: 'Completed',
			value: 'COMPLETED',
		},
		{
			key: 'CANCELED',
			text: 'Canceled',
			value: 'CANCELED',
		},
		{
			key: 'ERROR',
			text: 'Error',
			value: 'ERROR',
		},
		{
			key: 'FAILED',
			text: 'Failed',
			value: 'FAILED',
		},
		{
			key: 'REVERTED',
			text: 'Reverted',
			value: 'REVERTED',
		},
	];

	const cryptoDocumentTypeOptions = [
		{
			key: 'TRADE',
			text: 'TRADE',
			value: 'TRADE',
		},
		{
			key: 'TRANSFER_ORDER',
			text: 'TRANSFER_ORDER',
			value: 'TRANSFER_ORDER',
		},
		{
			key: 'PAYMENT_ORDER',
			text: 'WITHDRAWAL',
			value: 'PAYMENT_ORDER',
		},
		{
			key: 'INCOMING_PAYMENT',
			text: 'DEPOSIT',
			value: 'INCOMING_PAYMENT',
		},
	];

	const documentTypeOptions = [
		{
			key: 'PAYMENT_ORDER',
			text: 'PAYMENT_ORDER',
			value: 'PAYMENT_ORDER',
		},
		{
			key: 'TRANSFER_ORDER',
			text: 'TRANSFER_ORDER',
			value: 'TRANSFER_ORDER',
		},
		{
			key: 'CARD_ORDER',
			text: 'CARD_ORDER',
			value: 'CARD_ORDER',
		},
		{
			key: 'CARD_TRANSACTION',
			text: 'CARD_TRANSACTION',
			value: 'CARD_TRANSACTION',
		},
		{
			key: 'INCOMING_PAYMENT',
			text: 'INCOMING_PAYMENT',
			value: 'INCOMING_PAYMENT',
		},
		{
			key: 'REVERSAL',
			text: 'REVERSAL',
			value: 'REVERSAL',
		},
		{
			key: 'MONTH_FEE_CALC',
			text: 'MONTH_FEE_CALC',
			value: 'MONTH_FEE_CALC',
		},
		{
			key: 'USER_LEVEL_SUBSCRIPTION',
			text: 'USER_LEVEL_SUBSCRIPTION',
			value: 'USER_LEVEL_SUBSCRIPTION',
		},
		{
			key: 'SYSTEM_DEPOSIT_FEE',
			text: 'SYSTEM_DEPOSIT_FEE',
			value: 'SYSTEM_DEPOSIT_FEE',
		},
		{
			key: 'DEACTIVATION',
			text: 'DEACTIVATION',
			value: 'DEACTIVATION',
		},
		{
			key: 'VAULT_DEPOSIT',
			text: 'VAULT_DEPOSIT',
			value: 'VAULT_DEPOSIT',
		},
		{
			key: 'FX_ORDER',
			text: 'FX_ORDER',
			value: 'FX_ORDER',
		},
		{
			key: 'MANUAL_ADJUSTMENT',
			text: 'MANUAL_ADJUSTMENT',
			value: 'MANUAL_ADJUSTMENT',
		},
		{
			key: 'TOPUP_FROM_CARD',
			text: 'TOPUP_FROM_CARD',
			value: 'TOPUP_FROM_CARD',
		},
		{
			key: 'SYSTEM_TRANSFER',
			text: 'SYSTEM_TRANSFER',
			value: 'SYSTEM_TRANSFER',
		},
	];

	const filter: FilterParam[] = [
		{
			field: 'email',
			label: 'Email',
			type: 'input'
		},
		{
			field: 'documentNumber',
			label: 'Number',
			type: 'input'
		},
		{
			field: 'type',
			label: 'Type',
			type: 'select',
			options: type === 'CRYPTO' ? cryptoDocumentTypeOptions : documentTypeOptions,
		},
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: statusOptions
		}
	];


	const headers: Array<Headers> = [
		{
			field: 'documentNumber',
			name: 'Number',
			position: 1,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 2,
			sortable: true
		},
		{
			field: 'email',
			name: 'Email',
			position: 3,
			sortable: true
		},
		{
			field: 'type',
			name: 'Type',
			position: 4,
			sortable: true
		},
		{
			field: 'documentDate',
			name: 'Document Date',
			position: 5,
			sortable: true
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 6,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 7,
			sortable: false
		}
	];

	const bankHeaders: Array<Headers> = [
		{
			field: 'documentNumber',
			name: 'Number',
			position: 1,
			sortable: true
		},
		{
			field: 'status',
			name: 'Status',
			position: 2,
			sortable: true
		},
		{
			field: 'email',
			name: 'Email',
			position: 3,
			sortable: true
		},
		{
			field: 'type',
			name: 'Type',
			position: 4,
			sortable: true
		},
		{
			field: 'documentDate',
			name: 'Document Date',
			position: 5,
			sortable: true
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 6,
			sortable: false
		},
		{
			field: 'fee',
			name: 'Fee',
			position: 7,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 8,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getDocuments(type, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch, type]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, type, fetchData]);

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const openDocument = (documentNumber: string) => {
		history.push(`/document/${documentNumber}`);
	};

	const resolveDocumentType = (documentType: string) => {
		let resolvedType = documentType;
		if (type === 'CRYPTO') {
			resolvedType = CryptoDocumentType[documentType];
		}
		return resolvedType;
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.documentNumber}${index}`} >
						<Table.Cell width={2}>{entry.documentNumber}</Table.Cell>
						<Table.Cell width={2}>{entry.status}</Table.Cell>
						<Table.Cell width={2}>{entry.email}</Table.Cell>
						<Table.Cell width={2}>{resolveDocumentType(entry.type) || entry.type}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.date, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>
							{entry.amount && <>
								{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} />
							</>}
						</Table.Cell>
						{type === 'BANK' && <Table.Cell width={2}>
							{entry.fee && <>
								{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.fee} />
							</>}
						</Table.Cell>}
						<Table.Cell selectable={false} width={2}>
							<Popup content='Open document details' trigger={<Icon className='tableActionIcon' size='large' name="file outline" onClick={() => openDocument(entry.documentNumber)} />} />
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>You dont have documents yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			{type === 'CRYPTO' && <Withdrawal />}
			<Segment>
				<Header as='h2'>{capitalizeFirstLetter(type)} Payment Documents</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={type === 'BANK' ? bankHeaders : headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default Documents;
