import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Segment, Table, Image, Grid, Button, Icon, Popup } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getTokensList, postLogoUpload, postToken } from '@/features/tokens/slice';
import TokenModal from './TokenModal';
import { AddTokenPayload, Token } from '@/features/tokens/types';
import NumberFormat from 'react-number-format';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';

interface Props {
	writePermission: boolean,
}

const Tokens = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.tokens
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'ASC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams} = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [openTokenModal, setOpenTokenModal] = useState<boolean>(false);
	const [editToken, setEditToken] = useState<Token>(null);

	const headers: Array<Headers> = [
		{
			field: 'logo',
			name: 'Logo',
			position: 1,
			sortable: false
		},
		{
			field: 'symbol',
			name: 'Symbol',
			position: 2,
			sortable: true
		},
		{
			field: 'name',
			name: 'Name',
			position: 3,
			sortable: true
		},
		{
			field: 'buyPrice',
			name: 'Buy Price',
			position: 4,
			sortable: true
		},
		{
			field: 'sellPrice',
			name: 'Sell Price',
			position: 5,
			sortable: true
		},
		{
			field: 'actionsDisallowed',
			name: 'Actions Disallowed',
			position: 6,
			sortable: true
		},
		{
			field: 'proc',
			name: 'Process',
			position: 7,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 8,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getTokensList(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);

	const handlEditToken = (token: Token) => {
		setEditToken(token);
		setOpenTokenModal(true);
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={1}><Image className='token-avatar' avatar src={entry.logo} /></Table.Cell>
						<Table.Cell width={2} disabled={entry.hidden}>{entry.symbol}</Table.Cell>
						<Table.Cell width={2} disabled={entry.hidden}>{entry.name}</Table.Cell>
						<Table.Cell width={2} disabled={entry.hidden}><NumberFormat displayType={'text'}
							fixedDecimalScale={false}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={`${entry.currency} `} value={entry.buyPrice} /></Table.Cell>
						<Table.Cell width={2} disabled={entry.hidden}><NumberFormat displayType={'text'}
							fixedDecimalScale={false}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={`${entry.currency} `} value={entry.sellPrice} /></Table.Cell>
						<Table.Cell width={2} disabled={entry.hidden}>{entry.actionsDisallowed ? 'Disallowed' : 'Allowed'}</Table.Cell>
						<Table.Cell width={2} disabled={entry.hidden}>{entry.proc} </Table.Cell>
						<Table.Cell width={2}>
							<Popup content='Edit token' trigger={<Icon className='tableActionIcon' size='large' name="edit outline" onClick={() => handlEditToken(entry)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>Not tokens yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);


	const handleAddToken = async () => {
		setEditToken(null);
		setOpenTokenModal(true);
	};

	const handleCloseTokenModal = async (symbol?: string, name?: string, description?: string,
		currency?: string, buyPrice?: number, sellPrice?: number, website?: string, hidden?: boolean, buyEnabled?: boolean, sellEnabled?: boolean, skipRequestPhase?: boolean, logo?: any, tradingView?: boolean,
		spread?: number, orderMaxWithoutApprove?: number, krakenAutoExecutionMaxSysAmount?: number, krakenAsset?: string, decimals?: number, displayDecimals?: number,
		sendEnabled?: boolean, networks?: string[], exchange?: string, forInfo?: boolean, token?: Token) => {
		setOpenTokenModal(false);

		if (symbol && name) {
			try {
				let logoUrl = null;
				if (logo) {
					const formData = new FormData();
					formData.append('file', logo);
					const fileRecord = await postLogoUpload(formData);
					const { logoUri } = fileRecord;
					logoUrl = logoUri;
				}

				const payload: AddTokenPayload = {
					symbol: symbol.toUpperCase(),
					name,
					currency,
					buyPrice,
					sellPrice,
					hidden,
					buyEnabled,
					sellEnabled,
					skipRequestPhase,
					website,
					tradingView,
					spread,
					decimals,
					displayDecimals,
					sendEnabled,
					networks: networks?.map(n => n)?.join(','),
					forInfo
				};

				console.log(JSON.stringify(payload));
				// return;

				if(exchange && exchange !== 'n/a') {
					payload.exchange = exchange;
				}
				
				if (orderMaxWithoutApprove) {
					payload.orderMaxWithoutApprove = orderMaxWithoutApprove;
				}

				if (logoUrl) {
					payload.logo = logoUrl;
				}
				if (token) {
					payload.tokenId = token.id;
				}
				if (description) {
					payload.description = description;
				}
				if (website) {
					payload.website = website;
				}
				if (krakenAutoExecutionMaxSysAmount) {
					payload.krakenAutoExecutionMaxSysAmount = krakenAutoExecutionMaxSysAmount;
				}
				if (krakenAsset) {
					payload.krakenAsset = krakenAsset;
				}

				await postToken(payload);

				showSuccessNotification(token ? 'Token updated' : 'Token added');
			} catch (e) {
				showErrorNotification(e);
			}
			fetchData();
		}
	};

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<TokenModal
					open={openTokenModal}
					token={editToken}
					onClose={handleCloseTokenModal}
					writePermission={writePermission}
				/>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Tokens</Header></Grid.Column>
						<Grid.Column floated='right'>
							<Button
								floated='right'
								primary
								disabled={!writePermission}
								onClick={handleAddToken}
							>
								Add Token</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default Tokens;
