/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '../swal/slice';
import { Process, ProcessesStore, ProcessFee, ToogleProcessPayload } from './processesTypes';

const initialState: ProcessesStore = {
	loading: false,
	list: [],
	error: null
};


const slice = createSlice({
	name: 'processes',
	initialState,
	reducers: {
		setProcesses(state, action: PayloadAction<Array<Process>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
	}
});

export const { setProcesses, setLoading, setError } = slice.actions;


export const getRootProcesses = (): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/admin/processes/list');
			const { data } = response;
			dispatch(setProcesses(data));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const toogleProcess = async (payload: ToogleProcessPayload) => {
	const response = await request.post('/api/admin/processes/toggle', payload);
	const { data } = response;
	return data;
};

export const getProcess = async (processName: string) => {
	const response = await request.get(`/api/admin/processes/details/${processName}`);
	const { data } = response;
	return data;
};

export const getProcessFee = async (processName: string) => {
	const response = await request.get(`/api/admin/processes/fee/${processName}`);
	const { data } = response;
	return data;
};

export const postProcessResetToDefault = async (processName: string) => {
	const response = await request.post('/api/admin/processes/reset/', { proc: processName });
	const { data } = response;
	return data;
};

export const putProcessCountry = async (processName: string, country: string) => {
	const response = await request.put('/api/admin/processes/country', { process: processName, country });
	const { data } = response;
	return data;
};

export const deleteProcessCountry = async (processName: string, country: string) => {
	const response = await request.delete(`/api/admin/processes/country/${processName}/${country}`);
	const { data } = response;
	return data;
};

export const deleteProcessFee = async (feeId: number) => {
	const response = await request.delete(`/api/admin/processes/fee/${feeId}`);
	const { data } = response;
	return data;
};

export const getProcessFeeTypes = async () => {
	const response = await request.get('/api/admin/processes/fee-types');
	const { data } = response;
	return data;
};

export const postProcessFee = async (payload: ProcessFee) => {
	const response = await request.post('/api/admin/processes/fee', payload);
	const { data } = response;
	return data;
};


export default slice.reducer;
