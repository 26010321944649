import { PaginateParams } from '@/helpers/globalTypes';
import { capitalizeFirstLetter } from '@/helpers/string';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Popup, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { showErrorNotification } from '@/features/swal/slice';
import { useHistory } from 'react-router-dom';
import { getCards } from '@/features/cards/slice';

interface Props {
	userId: number,
}

const ClientCards = ({ userId }: Props) => {
	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.cards
	);

	const paginateInitialState: PaginateParams = { orderBy: 'balance', sort: 'DESC', skip: 0, filter: `userId=${userId}`, take: 5 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);


	const headers: Array<Headers> = [
		{
			field: 'id',
			name: 'Card ID',
			position: 1,
			sortable: true
		},
		{
			field: 'cardNumberMask',
			name: 'Card Number Mask',
			position: 2,
			sortable: true
		},
		{
			field: 'virtual',
			name: 'Type',
			position: 3,
			sortable: false
		},

		{
			field: 'status',
			name: 'Status',
			position: 4,
			sortable: true
		},
		{
			field: '',
			name: 'Actions',
			position: 5,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getCards(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const history = useHistory();
	const openCard = (id: number) => {
		history.push(`/card/${id}`);
	};

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.id}</Table.Cell>
						<Table.Cell width={2}>{entry.cardNumberMask}</Table.Cell>
						<Table.Cell width={2}>{entry.virtual ? 'Virtual' : 'Physical'}</Table.Cell>
						<Table.Cell width={2}>{capitalizeFirstLetter(entry.status)}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Popup content='Open card details' trigger={<Icon className='tableActionIcon' size='large' name="credit card outline" onClick={() => openCard(entry.id)} />} />
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={8}>Client does not have any cards yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (

		<CustomTable
			fetchData={fetchData}
			loading={loading}
			paginateInitialState={paginateInitialState}
			tableBody={tableBody}
			totalPages={totalPages}
			setPagination={setPagination}
			header={headers}
			pagination={pagination}
			hideItemsPerPage={true}
		/>

	);
};

export default ClientCards;
