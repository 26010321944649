import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from './rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { logout, status } from '@features/user/userSlice';
import LoadingPage from './pages/LoadingPage';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ component: Component, ...rest }): React.ReactElement => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(true);
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
	const { token } = useSelector(
		(state: RootState) => state.credentials
	);

	useEffect(() => {
		if (!token) {
			setIsAuthorized(false);
			return setLoading(false);
		}

		async function getAuthorizationData() {
			try {
				const authStatus = await dispatch(status());
				const loggedIn = authStatus['loggedIn'];
				if (!loggedIn) {
					await dispatch(logout());
					setIsAuthorized(false);
					return setLoading(false);
				}
				setIsAuthorized(true);
				return setLoading(false);
			} catch (e) {
				await dispatch(logout());
				setIsAuthorized(false);
				return setLoading(false);
			}
		}

		getAuthorizationData();

	}, [dispatch, token]);

	if (loading) {
		return <LoadingPage />;
	}
	return (
		<Route
			{...rest}
			render={props =>
				isAuthorized ? <Component {...props} /> : <Redirect to='/auth'/>
			}
		/>
	);
};

export default ProtectedRoute;
