import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { TokenTransfer } from '@/features/tokenTransfers/types';


interface Props {
	open: boolean;
	onClose: (executedFromAmount?: number, executedToAmount?: number, executedFee?: number, externalIds?: string, tokenTransfer?: TokenTransfer) => void,
	tokenTransfer: TokenTransfer
}

const ExecuteTokenTransferModal = ({ open, onClose, tokenTransfer }: Props): React.ReactElement => {

	const initialValues = {
		executedFromAmount: tokenTransfer ? tokenTransfer.requestedFromAmount : 0,
		executedToAmount: tokenTransfer ? tokenTransfer.requestedToAmount : 0,
		executedFee: 0,
		externalIds: ''
	};

	const validationSchema = Yup.object({
		executedFromAmount: Yup.number()
			.required('You must enter an executedFromAmount'),
		executedToAmount: Yup.number()
			.required('You must enter a executedToAmount'),
		executedFee: Yup.number()
			.required('You must enter a executedFee'),
	});


	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { executedFromAmount, executedToAmount, executedFee, externalIds  } = formData;

		
		onClose(executedFromAmount, executedToAmount, executedFee, externalIds, tokenTransfer);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting }) => (
					<Form
						size="large">
						<Header content='Execute token transfer' />

						<Input
							label='Executed from amount'
							fluid
							name="executedFromAmount"
							type='number'
						/>
						<Input
							label='Executed to amount'
							fluid
							name="executedToAmount"
							type='number'
						/>
						<Input
							label='Executed fee'
							fluid
							name="executedFee"
							type='number'
						/>
						<Input
							label='External ID'
							fluid
							name="externalIds"
						/>
						
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors)}
										primary size="large" type="submit"
										floated='right' >
										Confirm
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default ExecuteTokenTransferModal;
