/* eslint-disable react/prop-types */
import React from 'react';
import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';
import '../Clients/ClientDetails/Profile.css';
import './Assets.css';
import NumberFormat from 'react-number-format';
import { VaultAsset } from '@features/fireblocks/types';

interface Props {
	assets?: Array<VaultAsset>,
	onClick?: (asset: VaultAsset) => void,
	onClickText?: string,
	buttonIcon?: SemanticICONS,
	buttonOnClick?: (asset: VaultAsset) => void,
	buttonOnClickText?: string,
	assetValue?: 'total' | 'totalUsersBalance';
}

const Omnibus = ({ assets, onClick, onClickText, buttonIcon = 'arrow up', buttonOnClick, buttonOnClickText, assetValue = 'total' }: Props): React.ReactElement => {

	return (
		<div className='assets'>
			{assets?.map((entry, index) => {
				return (
					<div className='assets__element' key={index}>
						{buttonOnClick && <Popup disabled={!buttonOnClickText} content={buttonOnClickText} trigger={
							<div className="transfer btn" onClick={() => { buttonOnClick(entry); }}>
								<Icon className='tableActionIcon' size='large' name={buttonIcon} onClick={() => console.log('send')} />
							</div>
						} />}
						<Popup disabled={!onClickText} content={onClickText} trigger={
							<div className="ui mini statistic btn" onClick={() => { onClick(entry); }} >
								<div className="value">
									<NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={Number(entry[assetValue]) ? entry[assetValue] : 0} /></div>
								<div className="label">{entry.id}</div>
							</div>
						} />
					</div>
				);
			})}
			{assets?.length === 0 && <div>You dont have assets yet.</div>}
		</div>
	);
};

export default Omnibus;
