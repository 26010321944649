import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Grid, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { useHistory } from 'react-router-dom';
import { convertDateFromEpoch, formatDateWithPattern } from '@/helpers/date';
import { getSystemErrors, retryWorkflow } from '@/features/systemErrors/systemErrorsSlice';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { FilterParam } from '@/components/Shared/Table/TableFilters';

interface Props {
	writePermission: boolean,
}

const SystemErrors = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.systemErrors
	);

	const paginateInitialState: PaginateParams = { orderBy: 'updatedDate', sort: 'DESC', skip: 0, filter: 'status=FAILED,CANCELLED', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const statusOptions = [
		{
			key: 'FAILED',
			text: 'Failed',
			value: 'FAILED',
		},
		{
			key: 'COMPLETED',
			text: 'Completed',
			value: 'COMPLETED',
		},
		{
			key: 'CANCELLED',
			text: 'Cancelled',
			value: 'CANCELLED',
		}, 
		{
			key: 'WAITING',
			text: 'Waiting',
			value: 'WAITING',
		}, 
		{
			key: 'ACTIVE',
			text: 'Active',
			value: 'ACTIVE',
		}, 
		{
			key: 'PENDING',
			text: 'Pending',
			value: 'PENDING',
		}, 
		{
			key: 'HOLDS',
			text: 'Holds',
			value: 'HOLDS',
		}, 
		{
			key: 'FAILED,CANCELLED',
			text: 'Failed/Cancelled',
			value: 'FAILED,CANCELLED',
		},
	];

	const filters: FilterParam[] = [
		{
			field: 'id',
			label: 'ID',
			type: 'input'
		},
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: statusOptions
		},
		{
			field: 'workflowId',
			label: 'Workflow ID',
			type: 'input'
		},
	];

	const headers: Array<Headers> = [
		{
			field: 'id',
			name: 'ID',
			position: 1,
			sortable: false
		},
		{
			field: 'act',
			name: 'Action',
			position: 2,
			sortable: false
		},
		{
			field: 'seq',
			name: 'Sequence',
			position: 3,
			sortable: false
		},
		{
			field: 'attempts',
			name: 'Attempts',
			position: 4,
			sortable: false
		},
		{
			field: 'status',
			name: 'Status',
			position: 5,
			sortable: false
		},
		{
			field: 'resolution',
			name: 'Resolution',
			position: 6,
			sortable: false
		},
		{
			field: 'details',
			name: 'Details',
			position: 7,
			sortable: false
		},
		{
			field: 'createdDate',
			name: 'Created date',
			position: 8,
			sortable: false
		},
		{
			field: 'updatedDate',
			name: 'Updated date',
			position: 9,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 10,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getSystemErrors(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, fetchData]);

	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const retry = async (id: number) => {
		try {
			await retryWorkflow(id);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={1}>{entry.id}</Table.Cell>
						<Table.Cell width={3}>{entry.act}</Table.Cell>
						<Table.Cell width={1}>{entry.seq}</Table.Cell>
						<Table.Cell width={1}>{entry.attempts}</Table.Cell>
						<Table.Cell width={1}>{entry.status}</Table.Cell>
						<Table.Cell width={1}>{entry.resolution}</Table.Cell>
						<Table.Cell width={3}>{entry.resolutionDetail}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(convertDateFromEpoch(entry.createdDate), 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(convertDateFromEpoch(entry.updatedDate), 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Grid>
								<Grid.Row textAlign='center' centered>
									<ComponentWithConfirmation popup='Retry workflow' onConfirm={() => retry(entry.id)} confirmContent={'Are you sure you want retry workflow?'}>
										<Icon disabled={!writePermission} className='tableActionIcon' size='large' name="undo" />
									</ComponentWithConfirmation>
									<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
								</Grid.Row>
							</Grid>

						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={9}>Sytem errors are empty.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Activity Errors</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filters}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default SystemErrors;
