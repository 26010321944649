import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Grid, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import NumberFormat from 'react-number-format';
import { formatDateWithPattern } from '@/helpers/date';
import { getReferralPayments } from '@/features/referralPayments/slice';
import { FilterParam } from '@/components/Shared/Table/TableFilters';

interface Props {
	writePermission: boolean,
}

const ReferralPayments = ({ writePermission }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.referralPayments
	);

	const paginateInitialState: PaginateParams = { orderBy: 'transferDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);

	const statusOptions = [
		{
			key: 'true',
			text: 'Completed',
			value: 'true',
		},
		{
			key: 'false',
			text: 'Planned',
			value: 'false',
		},
	];
	
	const typeOptions = [
		{
			key: 'FEES',
			text: 'Fees',
			value: 'FEES',
		},
		{
			key: 'SIGN_UP_BONUS',
			text: 'Sign up bonus',
			value: 'SIGN_UP_BONUS',
		},
		{
			key: 'TRADE_TURNOVER',
			text: 'trade turnover',
			value: 'TRADE_TURNOVER',
		},
		{
			key: 'SIGN_UP_BONUS_TARGET',
			text: 'Sign up bonus target',
			value: 'SIGN_UP_BONUS_TARGET',
		},
	];

	const filter: FilterParam[] = [
		{
			field: 'clientId',
			label: 'Client ID',
			type: 'input'
		},
		{
			field: 'accountFrom',
			label: 'Account from',
			type: 'input'
		},
		{
			field: 'accountTo',
			label: 'Account to',
			type: 'input'
		},
		{
			field: 'completed',
			label: 'Status',
			type: 'select',
			options: statusOptions
		},
		{
			field: 'loyaltyType',
			label: 'Type',
			type: 'select',
			options: typeOptions
		},
	];

	const headers: Array<Headers> = [
		{
			field: 'transferDate',
			name: 'Date',
			position: 1,
			sortable: false
		},
		{
			field: 'loyaltyType',
			name: 'Type',
			position: 2,
			sortable: false
		},
		{
			field: 'clientId',
			name: 'Client ID',
			position: 3,
			sortable: false
		},
		{
			field: 'userName',
			name: 'User',
			position: 4,
			sortable: false
		},
		{
			field: 'accountFrom',
			name: 'Account from',
			position: 5,
			sortable: false
		},
		{
			field: 'accountTo',
			name: 'Account to',
			position: 6,
			sortable: false
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 7,
			sortable: false
		},
		{
			field: 'status',
			name: 'Status',
			position: 8,
			sortable: false
		},

	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getReferralPayments(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{formatDateWithPattern(entry.transferDate, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>{entry.loyaltyType}</Table.Cell>
						<Table.Cell width={2}>{entry.clientId}</Table.Cell>
						<Table.Cell width={2}>{entry.userName}</Table.Cell>
						<Table.Cell width={2}>{entry.accountFrom}</Table.Cell>
						<Table.Cell width={2}>{entry.accountTo}</Table.Cell>
						<Table.Cell width={2}><NumberFormat displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} suffix={entry.ccy} /></Table.Cell>
						<Table.Cell width={2}>{entry.status}</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={4}>Not payments yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column><Header as='h2'>Referral payments</Header></Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default ReferralPayments;
