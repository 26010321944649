import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { UserGroup } from '@/features/userGroups/types';


interface Props {
	open: boolean;
	onClose: (name?: string) => void,
	userGroup: UserGroup
}

const UserGroupModal = ({ open, onClose, userGroup }: Props): React.ReactElement => {

	const initialValues = {
		name: userGroup?.name ?? ''
	};

	const validationSchema = Yup.object({
		name: Yup.string()
			.required('You must enter a name'),
	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { name } = formData;


		onClose(name);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting }) => (
					<Form
						size="large">
						<Header content={`${userGroup ? 'Edit' : 'Add'} user group`} />

						<Input
							label='Name'
							fluid
							name="name"
						/>
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors)}
										primary size="large" type="submit"
										floated='right' >
										Confirm
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default UserGroupModal;
