import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Icon, Popup, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { getComplianceList, postSyncOnfido, postSyncSumSub } from '@/features/compliance/slice';
import { useHistory } from 'react-router-dom';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import ComponentWithConfirmation from '@/components/Shared/ComponentWithConfirmation';

// interface Props {
// 	writePermission: boolean,
// }

const Compliance = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.compliance
	);

	const paginateInitialState: PaginateParams = { orderBy: 'createdDate', sort: 'DESC', skip: 0, filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);


	const filter: FilterParam[] = [
		{
			field: 'service',
			label: 'Provider',
			type: 'select',
			options: [
				{
					key: 'ONFIDO',
					value: 'ONFIDO',
					text: 'Onfido'
				},
				{
					key: 'SUMSUB',
					value: 'SUMSUB',
					text: 'SumSub'
				},
				{
					key: 'ADVISIONARY',
					value: 'ADVISIONARY',
					text: 'Advisionary'
				},
				{
					key: 'ADVISIONARY_V3',
					value: 'ADVISIONARY_V3',
					text: 'Advisionary V3'
				}
			]
		},
		{
			field: 'serviceId',
			label: 'Applicant ID',
			type: 'input'
		},
		{
			field: 'status',
			label: 'Status',
			type: 'select',
			options: [
				{
					key: 'CREATED',
					value: 'CREATED',
					text: 'Created'
				},
				{
					key: 'IN_PROGRESS',
					value: 'IN_PROGRESS',
					text: 'In progress'
				},
				{
					key: 'VERIFIED',
					value: 'VERIFIED',
					text: 'Verified'
				},
				{
					key: 'REJECTED',
					value: 'REJECTED',
					text: 'Rejected'
				},
				{
					key: 'WAITING_FOR_PAYMENT',
					value: 'WAITING_FOR_PAYMENT',
					text: 'Waiting for payment'
				}
			]
		},


	];

	const headers: Array<Headers> = [
		{
			field: 'name',
			name: 'Name',
			position: 1,
			sortable: false
		},
		{
			field: 'profileType',
			name: 'type',
			position: 2,
			sortable: true
		},
		{
			field: 'service',
			name: 'Provider',
			position: 3,
			sortable: false
		},
		{
			field: 'serviceId',
			name: 'Applicant ID',
			position: 4,
			sortable: false
		},
		{
			field: 'status',
			name: 'Status',
			position: 5,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 6,
			sortable: false
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getComplianceList(pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch]);


	const history = useHistory();
	const openUser = (userId: number) => {
		history.push(`/client/${userId}`);
	};

	const openDetails = (applicantId: string) => {
		history.push(`/compliance/${applicantId}`);
	};

	const openV3Details = (applicantId: string) => {
		history.push(`/new-compliance/${applicantId}`);
	};

	const syncOnfido = async (applicantId: string, userId: number) => {
		try {
			await postSyncOnfido(applicantId, userId);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const syncSumSub = async (applicantId: string, userId: number) => {
		try {
			await postSyncSumSub(applicantId, userId);
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};


	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={2}>{entry.profileType === 'BUSINESS' ? entry.businessName : entry.name}</Table.Cell>
						<Table.Cell width={2}>{entry.profileType}</Table.Cell>
						<Table.Cell width={2}>{entry.service}</Table.Cell>
						<Table.Cell width={2}>{entry.serviceId}</Table.Cell>
						<Table.Cell width={2}>{entry.status}</Table.Cell>
						<Table.Cell width={2}>
							<Popup content='View client' trigger={<Icon className='tableActionIcon' size='large' name="user" onClick={() => openUser(entry.userId)} />} />
							{entry.service === 'ADVISIONARY' && <Popup content='View details' trigger={<Icon className='tableActionIcon' size='large' name="eye" onClick={() => openDetails(entry.serviceId)} />} />}
							{entry.service === 'ADVISIONARY_V3' && <Popup content='View details' trigger={<Icon className='tableActionIcon' size='large' name="eye" onClick={() => openV3Details(entry.serviceId)} />} />}
							{entry.service === 'ONFIDO' &&
								<ComponentWithConfirmation popup='Sync with onfido' onConfirm={() => syncOnfido(entry.serviceId, entry.userId)} confirmContent={'Are you sure you want sync this applicant with Onfido?'}>
									<Icon className='tableActionIcon' size='large' name="sync" />
								</ComponentWithConfirmation>}
							{entry.service === 'SUMSUB' &&
								<ComponentWithConfirmation popup='Sync with sumsub' onConfirm={() => syncSumSub(entry.serviceId, entry.userId)} confirmContent={'Are you sure you want sync this applicant with SumSub?'}>
									<Icon className='tableActionIcon' size='large' name="sync" />
								</ComponentWithConfirmation>}
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={6}>You dont have compliance yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid>
			<Segment>
				<Header as='h2'>Compliance</Header>
				<Divider hidden />
				<CustomTable
					fetchData={fetchData}
					filter={filter}
					loading={loading}
					paginateInitialState={paginateInitialState}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};


export default Compliance;
