import { PermissionType } from '@/features/user/types';
import { checkWritePermission } from '@/helpers/permissions';
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import {Form, Input, Select, SubmitButton, TextArea} from 'formik-semantic-ui-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Divider,
	Grid, Header,
	Modal
} from 'semantic-ui-react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { postOperations } from '@features/documents/slice';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';

interface Props {
	walletTransactionTypeFees?: Array<string>;
	documentTransactionLines?: any;
	documentNumber: string,
	userId: number,
	open: boolean;
	onClose: () => void,
}

const AdjustFeeModal = ({open, onClose, walletTransactionTypeFees, documentTransactionLines, documentNumber, userId}: Props): React.ReactElement => {
	const adminUserId = useSelector((state: RootState) => state.user.status.adminUserId);

	const {permissions} = useSelector(
		(state: RootState) => state.user
	);

	const [adjustmentType, setAdjustmentTypeLOV] = useState<string>('');
	const [amountCurrency, setAmountCurrency] = useState<string>('');
	const [feeAccount, setFeeAccount] = useState<any>('');
	const [account, setAccount] = useState<any>('');
	const [feeAmountLimit, setFeeAmountLimit] = useState<number>(0);
	const [feeTypeOptions, setFeeTypeOptions] = useState<Array<any>>([]);

	const handleAdjustmentTypeChange = (value, setFieldValue) => {
		setFieldValue('feeType', '');
		setFieldValue('feeAccount', '');
		setFieldValue('adjustmentType', value);
		setAdjustmentTypeLOV(value);
		setFeeAmountLimit(0);
		setFeeTypeOptionsToDefault(value);
	};
	const handleFeeAccountChange = (newFeeAccount, setFieldValue) => {
		setFeeAccount(newFeeAccount);
		setFeeAmountLimit(newFeeAccount?.amount ? newFeeAccount.amount : 0);
		if (newFeeAccount?.transactionType?.length > 0) {
			setFeeTypeOptions([
				{ value: newFeeAccount.transactionType, key: newFeeAccount.transactionType, text: newFeeAccount.transactionType }
			]);
		}
		setFieldValue('feeAccount', newFeeAccount);
	};
	const handleAccountChange = (newAccount) => {
		setAccount(newAccount);
		setAmountCurrency(newAccount?.currencyCode?.length > 0 ? newAccount.currencyCode : '' );
	};

	const getUniqueAccounts = (accounts) => {
		const uniqueAccounts = [];
		const uniqueAccountsOptions = [];
		if (accounts?.length > 0) {
			accounts.forEach(item => {
				if (!uniqueAccounts.includes(item.accountWalletId)) {
					uniqueAccounts.push(item.accountWalletId);
					uniqueAccountsOptions.push({
						value: item,
						key: item.accountWalletId,
						text: item.accountWalletId,
					});
				}
			});
		}
		return uniqueAccountsOptions;
	};

	const accounts = documentTransactionLines?.filter(documentTransactionLine => documentTransactionLine.accountType === null);
	const accountOptions = getUniqueAccounts(accounts);


	const feeAccounts = documentTransactionLines?.filter(documentTransactionLine => documentTransactionLine.accountType === 'feeAccount');
	const feeAccountsOptions = getUniqueAccounts(feeAccounts);


	const adjustmentTypeOptions = [
		{
			key: 'ADD_FEE',
			text: 'Add fee',
			value: 'ADD_FEE',
		}
	];

	if (feeAccountsOptions.length > 0) {
		adjustmentTypeOptions.push({
			key: 'RETURN_FEE',
			text: 'Return fee',
			value: 'RETURN_FEE',
		});
	}

	const setFeeTypeOptionsToDefault = (adjustment) => {
		if (adjustment === 'ADD_FEE') {
			const walletTransactionTypeFeesOptions = walletTransactionTypeFees?.map(item => {
				return { value: item, key: item, text: item };
			});
			setFeeTypeOptions(walletTransactionTypeFeesOptions);
		} else {
			setFeeTypeOptions([]);
		}
	};




	const validationSchema = Yup.object({
		adjustmentType: Yup.string().required('Adjustment type LOV is required'),
		account: Yup.object().required('Account is required'),
		feeAccount: Yup.object().when('adjustmentType', {
			is: 'RETURN_FEE',
			then: Yup.object().required('Fee Account LOV is required')
		}),
		feeType: Yup.string().required('Fee Type is required'),
		feeAmount: Yup.number()
			.required('Fee Amount is required')
			.min(0, 'Fee min value is 0')
			.when('adjustmentType', {
				is: 'RETURN_FEE',
				then: Yup.number().max(feeAmountLimit, `Fee max value is ${feeAmountLimit} ${amountCurrency}`)
			}),
		transactionValueDate: Yup.string().required('Transaction Date is required')
			.test('max', 'Transaction Date is too big', function(value) {
				return new Date(value) <= new Date('2999-12-31');
			}),
		description: Yup.string().required('Description is required'),
	});

	const submit = async (formData, formikProps) => {
		const {setSubmitting} = formikProps;
		const {
			feeAmount,
			feeType,
			transactionValueDate,
			description,
		} = formData;
		try {
			await postOperations(userId, 'sys-manual-fee-adjustment', {
				adjustmentType,
				accountWalletId: account?.accountWalletId,
				documentNr: documentNumber,
				feeWalletId: feeAccount?.accountWalletId,
				feeType: feeType,
				amount: feeAmount,
				description: description,
				transValueDate: transactionValueDate,
				updatedBy: adminUserId,
			});
			onClose();
			showSuccessNotification('Adjustment successfully executed');
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
		}
	};


	return (<Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>

			<Formik
				initialValues={{
					adjustmentType: '',
					account: '',
					feeAccount: '',
					feeType: '',
					feeAmount: '0.00',
					transactionValueDate: '',
					description: '',
				}}
				onSubmit={submit}
				validationSchema={validationSchema}
				render={({setFieldValue, isSubmitting, errors, dirty}) => {
					return (
						<Form>
							<Header content={`Document number: ${documentNumber}`} />
							<Select
								label='Adjustment Type LOV'
								fluid
								clearable
								errorPrompt
								name="adjustmentType"
								options={adjustmentTypeOptions}
								onChange={(element, { value }) => handleAdjustmentTypeChange(value, setFieldValue)}
							/>
							{adjustmentType?.length > 0 && <div>
								<Select
									label='Account'
									fluid
									clearable
									errorPrompt
									name="account"
									options={accountOptions}
									onChange={(element, { value }) => handleAccountChange(value)}
								/>
								{adjustmentType === 'RETURN_FEE' && <Select
									label='Fee Account LOV'
									fluid
									clearable
									errorPrompt
									name="feeAccount"
									options={feeAccountsOptions}
									onChange={(element, { value }) => handleFeeAccountChange(value, setFieldValue)}
								/>}
								{feeTypeOptions.length > 0 && <Select
									label='Fee Type'
									fluid
									clearable
									errorPrompt
									name="feeType"
									options={feeTypeOptions}
								/>}
								<Input
									label={`Fee Amount ${amountCurrency}`}
									fluid
									errorPrompt
									name="feeAmount"
									type='number'
								/>
								<Input
									label='Transaction Value Date'
									name="transactionValueDate"
									type='datetime-local'
									errorPrompt
								/>
								<TextArea
									label='Description'
									name="description"
									errorPrompt
									style={{ minHeight: 100 }}
								/>
							</div>}
							<Divider hidden/>
							<Grid columns={2}>
								<Grid.Row centered>
									<Grid.Column>
										<SubmitButton primary
													type="submit"
													loading={isSubmitting}
													disabled={!checkWritePermission(permissions, PermissionType.SUPPORT) || isSubmitting || !isEmpty(errors) || !dirty}
													floated='right'>
											Execute
										</SubmitButton>
									</Grid.Column>
									<Grid.Column>
										<Button floated='left' secondary content='Discard' onClick={() => onClose()}/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					);
				}}
			/>

		</Modal.Content>
	</Modal>);
};

export default AdjustFeeModal;
