import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	Grid,
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';


interface Props {
	open: boolean;
	onClose: (monitored?: boolean) => void,
	isMonitored: boolean
}

const TransactionMonitoringModal = ({ open, onClose, isMonitored }: Props): React.ReactElement => {
	

	const initialValues = {
		monitored: isMonitored,
	};

	const validationSchema = Yup.object({
		monitored: Yup.boolean()
			.required('You must choose value')

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { monitored } = formData;
		onClose(monitored);
	};

	const options = [
		{
			key: 'true',
			text: 'Monitor all user transactions',
			value: true,
		},
		{
			key: 'false',
			text: 'Monitor only restricred transactions',
			value: false,
		}

	];

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						size="large">
						<Header content="Transaction monitoring" />

						<Select
							fluid
							name="monitored"
							options={options}
						/>
						
						<Divider />
						<Grid columns={2}>
							<Grid.Row>
								<Grid.Column >
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit"
										floated='right' >
										Confirm
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button floated='left' secondary size="large" content='Discard' onClick={() => onClose()} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default TransactionMonitoringModal;
