export interface Document {
    approvalType: string,
    date: string,
    documentNumber: string,
    email: string,
    status: string,
    type: string,
    userId: number
    approvedBy: ApprovedBy[],
    transactions: Transaction[],
    attachments: Map<string, string>,
    statusText: string,
	rejectionReason: string,
    beneficiary: Beneficiary,
    note: string,
    createdDate: string,
    updatedDate: string,
    externalId?: string,
    remitter: Remitter,
	currency: string,
	amount: number,
	fee: number,
}

export enum CryptoDocumentType {
	PAYMENT_ORDER = 'WITHDRAWAL',
	INCOMING_PAYMENT = 'DEPOSIT',
}

export interface Beneficiary {
    account: string,
    address: string,
    bank: string,
    beneficiaryAccountId: number,
    bic: string,
    business: boolean,
    ccy: string,
    country: string,
    cryptoNetwork: string,
    currencySymbol: string,
    identification: string,
    name: string,
    paymentType: string,
    walletId: string
}

export interface Remitter {
    remitterId: number,
    name: string,
    identification: string,
    account: string,
    bankCode: string,
    bankName: string,
    bankCountry: string
}

export interface DocumentsStore {
    loading: boolean,
    list: Array<Document>,
    error: string,
    count: number,
    totalPages: number,
    pageSize: number
}

export interface Transaction {
    referenceCode: string,
    amount: number,
    asset: string,
    type: string,
    date: Date,
    paymentDetails: string
    documentNumber: string,
    transactionType: string,
    status: string,
    userId: number,
    email: string,
    firstName: string,
    lastName: string,
    walletId?: string,
}

export interface ApprovedBy {
    userId: number,
    user: string,
    date: Date,
    status: ApprovedStatus
}

export enum ApprovedStatus {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

export interface ManualAdjustFeePayload {
    adjustmentType: string,
    accountWalletId: string,
    documentNr?: string,
    feeWalletId?: string,
    feeType: string,
    amount: number,
    description: string,
    transValueDate: Date,
    updatedBy: number
}

