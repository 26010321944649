export enum UserSecurityReportStatus {
    CREATED = 'CREATED',
    IN_REVIEW = 'IN_REVIEW',
    DONE = 'DONE',
    REJECTED = 'REJECTED'
}

export interface UserSecurityReport {
    id: number;
    userId: number;
    fullName: string;
    accountNumber: string;
    suspectDetails: string;
    trxDate: Date;
    trxAmount: number;
    details: string;
    documentList: string;
    trxSarsList: string;
    status: UserSecurityReportStatus;
    acknDate: Date;
    acknBy: number;
    completionDate: Date;
    completedBy: number;
    reportedToAuthority: boolean;
    explanation: string;
    fileName: string;
    fileMimetype: string;
    fileKey: string;
    fileSize: number;
    updatedDate: Date;
    createdDate: Date;
    createdBy: number;
    updatedBy: number;
    attachments: UserSecurityReportAttachments[];
}

export interface UserSecurityReportAttachments {
    id: number;
    reportId: number;
    fileName: string;
    fileSize: string;
    fileMimetype: string;
    fileKey: string;
    updatedDate: Date;
    createdDate: Date;
    createdBy: number;
    updatedBy: number;
}

export interface NewUserSecurityReportPayload {
    userId: number;
    fullName: string;
    accountNumber: string;
    suspectDetails: string;
    trxDate: Date;
    trxAmount: number;
    details: string;
    documentList: string;
    trxSarsList: string;
    reviewDate: Date;
    reviewedBy: number;
    acknDate: Date;
    status: UserSecurityReportStatus;
    reportedToAuthority: boolean;
    skipReportingReason: string;
    fileName: string;
    fileSize: string;
    mimetype: string;
    fileKey: string;
    updatedDate: Date;
    createdDate: Date;
    createdBy: number;
    updatedBy: number;
}



export interface CreateUserSecurityReportPayload {
    userId: number,
    fullName: string;
    accountNumber: string;
    suspectDetails: string;
    trxDate: Date;
    trxAmount: number;
    details: string;
    trxSarsList: string;
}

export interface UpdateUserSecurityReportPayload {
    reportId: number,
    fullName: string;
    accountNumber: string;
    suspectDetails: string;
    trxDate: Date;
    trxAmount: number;
    details: string;
    trxSarsList: string;
}

export interface CompleteUserSecurityReportPayload {
    reportId: number,
    explanation?: string;
    reportedToAuthority: boolean
    file?: any
}
