import React from 'react';
import { Container, Header, Icon, Segment } from 'semantic-ui-react';

const NotFound = (): React.ReactElement =>


	<Container fluid>
		<Segment textAlign='center'>
			<Header as='h1' content="404" />
			<Icon style={{color: '#de980b'}} name='exclamation triangle' size='massive' />
			<Header as='h2' content="Sorry, page not found!" />
		</Segment>
	</Container>;

export default NotFound;
