import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Header, Segment, Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { FilterParam } from '@/components/Shared/Table/TableFilters';
import { getBankAccountList, getStatementBatches, setStatements } from '@/features/statements/statementsSlice';
import NumberFormat from 'react-number-format';
import { formatDateWithPattern } from '@/helpers/date';
import { Link } from 'react-router-dom';
import useBankLov from '@/helpers/customHook/useBankLov';
import usePaginationUrlState from '@/components/Shared/Table/usePaginationUrlState';
import { PaginateParams } from '@/helpers/globalTypes';

const StatementBatches = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector((state: RootState) => state.statements);

	const paginateInitialState: PaginateParams = { orderBy: 'stmDateFrom', skip: 0, sort: 'DESC', filter: '', take: 25 };
	const { urlPaginateParams } = usePaginationUrlState(paginateInitialState);
	const [pagination, setPagination] = useState<PaginateParams>(urlPaginateParams);
	const [bankAccountLov, setBankAccountLov] = useState<any>();

	const { bankLov } = useBankLov();

	useEffect(() => {
		const fetch = async () => {
			const params: PaginateParams = { orderBy: 'treeAccountPath', skip: 0, sort: 'ASC', filter: 'accountType=ROOT_ACCOUNT', take: 100 };
			const list = await getBankAccountList(params);
			const result = list.map((p) => { return { key: p.bankAccountIdLov.toString(), text: p.description, value: p.bankAccountIdLov.toString() }; });
			setBankAccountLov(result);
		};
		fetch();
	}, []);


	const filter: FilterParam[] = [
		{
			field: 'bankId',
			label: 'Bank (mandatory)',
			type: 'select',
			options: bankLov,
			required: true
		},
		{
			field: 'rootBankAccountId',
			label: 'Bank Account',
			type: 'select',
			options: bankAccountLov,
			required: true
		},
		{
			field: 'stmBatchId',
			label: 'Batch Id',
			type: 'input'
		},
		{
			field: 'stmDateFrom',
			label: 'Date From',
			type: 'input',
			inputType: 'date'
		},
		{
			field: 'stmDateTo',
			label: 'Date To',
			type: 'input',
			inputType: 'date'
		}
	];

	const headers: Array<Headers> = [
		{
			field: 'stmBatchId',
			name: 'Batch Id',
			position: 1,
			sortable: true
		},
		{
			field: 'periodName',
			name: 'Period',
			position: 2,
			sortable: true
		},
		{
			field: 'description',
			name: 'Description',
			position: 3,
			sortable: true
		},
		{
			field: 'stmDateFrom',
			name: 'Date From',
			position: 4,
			sortable: true
		},
		{
			field: 'stmDateTo',
			name: 'Date To',
			position: 5,
			sortable: true
		},
		{
			field: 'currencyCode',
			name: 'Currency',
			position: 6,
			sortable: true
		},
		{
			field: 'beginBalance',
			name: 'Begin Balance',
			position: 7,
			sortable: false
		},
		{
			field: 'periodDr',
			name: 'Period DR',
			position: 8,
			sortable: false
		},
		{
			field: 'periodCr',
			name: 'Period CR',
			position: 9,
			sortable: false
		},
		{
			field: 'endBalance',
			name: 'End Balance',
			position: 10,
			sortable: false
		},
		{
			field: 'totalFee',
			name: 'Total Fee',
			position: 11,
			sortable: false
		},
		{
			field: '',
			name: 'Actions',
			position: 12,
			sortable: false
		}
	];

	useEffect(() => {
		if (pagination.filter !== '') {
			dispatch(getStatementBatches(pagination));
		}
		else {
			dispatch(setStatements([]));
		}
	}, [pagination, pagination.filter, dispatch]);



	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, idx) => {
				return (
					<Table.Row key={`${idx}-${entry.id}-${entry.rootWlpId}-${entry.periodName}-${entry.ccyBankAccountId}$`} >
						<Table.Cell width={2}>{entry.id}</Table.Cell>
						<Table.Cell width={2}>{entry.periodName}</Table.Cell>
						<Table.Cell width={2}>{entry.description}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.stmDateFrom, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>{formatDateWithPattern(entry.stmDateTo, 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
						<Table.Cell width={2}>{entry.currencyCode}</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={entry.beginBalance} />
						</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={entry.periodDr} />
						</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={entry.periodCr} />
						</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={entry.endBalance} />
						</Table.Cell>
						<Table.Cell width={2}>
							<NumberFormat displayType={'text'}
								fixedDecimalScale={false}
								thousandsGroupStyle='thousand'
								thousandSeparator={true}
								value={entry.totalFee} />
						</Table.Cell>
						<Table.Cell selectable={false} width={2}>
							<Link to={`/statements/summaries/${entry.id}`}>Summary</Link>
							<br />
							<Link to={`/statements/transactions/${entry.id}`}>Transactions</Link>
						</Table.Cell>
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>No reconciliations yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (
		<Container fluid id='bank-statements'>
			<Segment>
				<Header as='h2'>Bank Statement Batches</Header>
				<Divider hidden />
				<CustomTable
					filter={filter}
					loading={loading}
					paginateInitialState={pagination}
					tableBody={tableBody}
					totalPages={totalPages}
					setPagination={setPagination}
					header={headers}
					pagination={pagination}
					urlPagination={true}
				/>
			</Segment>
		</Container>
	);
};

export default StatementBatches;
