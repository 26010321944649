import { showErrorNotification } from '@/features/swal/slice';
import { PaginateParams } from '@/helpers/globalTypes';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import CustomTable, { Headers } from '@/components/Shared/Table/Table';
import { convertDateFromEpoch, formatDateWithPattern } from '@/helpers/date';
import NumberFormat from 'react-number-format';
import { getAccountPaymentPlan } from '@features/accountPaymentPlan/slice';

interface Props {
	accountId: number,
}

const AccountPaymentPlan = ({ accountId }: Props): React.ReactElement => {

	const dispatch = useDispatch();
	const { list, totalPages, error, loading } = useSelector(
		(state: RootState) => state.accountPaymentPlan
	);

	const paginateInitialState: PaginateParams = { orderBy: 'id', sort: 'DESC', skip: 0, filter: '', take: 5 };
	const [pagination, setPagination] = useState<PaginateParams>(paginateInitialState);


	const headers: Array<Headers> = [
		{
			field: 'transferDate',
			name: 'Transfer Date',
			position: 1,
			sortable: true
		},
		{
			field: 'transactionType',
			name: 'Transaction Type',
			position: 2,
			sortable: true
		},
		{
			field: 'amount',
			name: 'Amount',
			position: 3,
			sortable: true
		},
		{
			field: 'vaultName',
			name: 'Vault Name',
			position: 4,
			sortable: true
		},
		{
			field: 'completed',
			name: 'Completed',
			position: 5,
			sortable: true
		}
	];


	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getAccountPaymentPlan(accountId, pagination));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [pagination, dispatch, accountId]);

	useEffect(() => {
		fetchData();
	}, [pagination, dispatch, accountId, fetchData]);

	// const history = useHistory();
	//
	// const openDocument = (documentNumber: string) => {
	// 	history.push(`/document/${documentNumber}`);
	// };

	const tableBody = (
		<Table.Body className="tableBody">
			{list.map((entry, index) => {
				return (
					<Table.Row key={`${entry.id}${index}`} >
						<Table.Cell width={1}>{formatDateWithPattern(convertDateFromEpoch(entry.transferDate), 'dd/MM/yyyy')}</Table.Cell>
						<Table.Cell width={1}>{entry.transactionType}</Table.Cell>
						<Table.Cell width={1}>
							{entry.currency} <NumberFormat displayType={'text'} fixedDecimalScale={false} thousandsGroupStyle='thousand' thousandSeparator={true} value={entry.amount} />
						</Table.Cell>
						<Table.Cell width={1}>{entry.vaultName}</Table.Cell>
						<Table.Cell width={1}>{entry.completed ? 'TRUE' : 'FALSE'}</Table.Cell>
						{/*<Table.Cell selectable={false} width={1}>*/}
						{/*	<Popup content='Open document details' trigger={<Icon className='tableActionIcon' size='large' name="file outline" onClick={() => openDocument(entry.documentNumber)} />} />*/}
						{/*</Table.Cell>*/}
					</Table.Row>
				);
			})}
			{list.length === 0 && <Table.Row>
				<Table.Cell textAlign='center' colSpan={5}>This account dont have payment plans yet.</Table.Cell>
			</Table.Row>}
		</Table.Body>
	);

	if (error) return <div>Error: {error}</div>;
	return (

		<CustomTable
			fetchData={fetchData}
			loading={loading}
			paginateInitialState={paginateInitialState}
			tableBody={tableBody}
			totalPages={totalPages}
			setPagination={setPagination}
			header={headers}
			pagination={pagination}
			hideItemsPerPage={true}
			hideRefresh={true}
		/>
	);
};


export default AccountPaymentPlan;
